import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IUser } from "../../../application/models"

export const useBackofficeConsumptionApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  const getConsumptionReportApi = useCallback((data: {
    startDate?: Date,
    endDate?: Date,
    companyId: string
  }): Promise<{
    consumptionsList: {
      user: Omit<IUser, 'password'>
      date: Date
      total: number
      type: string
      ip: string
      createdAt: Date
      createdBy: Omit<IUser, 'password'>
      origins: {
        separatedCredits: {
          total: number
        }
        subscription: {
          total: number
        }
      }[]
      resourceId: string
      ipAddress: string
    }[]
  }> => {
    return axiosAuthInstance.get('/consumption/report', {
      params: {
        startDate: data.startDate,
        endDate: data.endDate,
        companyId: data.companyId
      }
    }).then((res) => {
      const result = res.data.result
      if (result) {
        return result
      }
    }).catch((err) => {
      return {
        consumptionsList: []
      }
    })
  }, [
    axiosAuthInstance
  ])
  return {
    getConsumptionReportApi
  }
}