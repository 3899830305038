import { View, Text, Image } from "@react-pdf/renderer";
import { BoxShadowView } from "./BoxShadowView";
import { IPDFGeneratorProps } from "./IPDFGeneratorProps";
import { styles } from "./styles";
import { SectionTitle } from "./SectionTitle";
import sociosIcon from './images/sociosIcon.png'
import { format } from "date-fns";
import lurikLogo from './images/lurikLogo.jpg'

export const SociosSection: React.FC<IPDFGeneratorProps> = ({ currentPj, image }) => {

    return <>
        <SectionTitle icon={sociosIcon} title='Quadro Societário' />
        {
            image === ''
                ? null
                : <Image src={lurikLogo} style={styles.backgroundLogo} />
        }
        <View style={styles.columnContainer}>
            <View style={styles.sociosContainer}>
                {currentPj?.socios.map((socio, columnIndex) => (
                    <View style={styles.sociosItem} key={columnIndex}>
                        <BoxShadowView>
                            <Text style={styles.label}>Nome:</Text>
                            <Text style={[styles.value, styles.highlightedValue]}>{socio.nome ? socio.nome : ` `}</Text>

                            <Text style={styles.label}>Qualificação:</Text>
                            <Text style={[styles.value, styles.highlightedValue]}>{socio.qualificacao ? socio.qualificacao : ` `}</Text>

                            <Text style={styles.label}>Data Sociedade:</Text>
                            <Text style={[styles.value, styles.highlightedValue]}>{socio.datasociedade ? format(new Date(socio.datasociedade), 'dd/MM/yyyy') : 'Sem registro'}</Text>
                        </BoxShadowView>
                    </View >
                ))}
            </View>
        </View>
    </>
}