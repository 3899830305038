import { Box } from "@mui/material"
import { useEffect } from "react"
import { DataEnrichmentForm } from "./data-enrichment-form"
import { useAppSelector } from "../../../../application/states/hooks"

export const DataEnrichmentPage = () => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Enriquecimento de Dados`
  }, [resaleConfig])
  return <>
    <Box p={3}>
      <DataEnrichmentForm />
    </Box>
  </>
}