import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { ModalContainer } from "../../modal-container"
import { AuthenticationLayout, LoginPage, RequestNewPasswordPage, UnitSearchPage, NotFoundPage } from "../../pages"
import { WorkspaceLayout, DashboardPage, ProjectExports, ProjectListPage, DataEnrichmentPage, SettingsPage } from "../../pages/workspace"
import { ProjectDetailsPage } from "../../pages/workspace/project-details"
import { UnitSearchPjDetailsPage } from "../../pages/workspace/unit-search/unit-search-pj-details"
import { PrivateRoute } from "../private-route"
import { UnknownError } from "../../pages/unknown-error"
import { BackofficePage } from "../../pages/workspace/backoffice"

export const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <ModalContainer />
        <Routes>
          <Route path="/" element={
            <Navigate to="/workspace" />
          } />
          <Route path="authentication" element={<AuthenticationLayout />}>
            <Route path="" element={<Navigate to="/authentication/login" />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="request-new-password" element={<RequestNewPasswordPage />} />
          </Route>
          <Route path="workspace" element={<WorkspaceLayout />}>
            <Route path="" element={<>
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            </>} />
            <Route path="project-exports" element={<>
              <PrivateRoute>
                <ProjectExports />
              </PrivateRoute>
            </>} />
            <Route path="projects" element={<>
              <PrivateRoute>
                <ProjectListPage />
              </PrivateRoute>
            </>} />
            <Route path="projects/details" element={<>
              <PrivateRoute>
                <ProjectDetailsPage />
              </PrivateRoute>
            </>} />
            <Route path="data-enrichment" element={<>
              <PrivateRoute>
                <DataEnrichmentPage />
              </PrivateRoute>
            </>} />
            <Route path="unit-search" element={<>
              <PrivateRoute>
                <UnitSearchPage />
              </PrivateRoute>
            </>} />
            <Route path="unit-search/details" element={<>
              <PrivateRoute>
                <UnitSearchPjDetailsPage />
              </PrivateRoute>
            </>} />
            <Route path="settings" element={<>
              <PrivateRoute>
                <SettingsPage />
              </PrivateRoute>
            </>} />
            <Route path="backoffice" element={<>
              <PrivateRoute>
                <BackofficePage />
              </PrivateRoute>
            </>} />
          </Route>
          <Route path="/unknown-error" element={<UnknownError />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}