// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IToastAlertState {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  open: boolean;
}

const initialState: IToastAlertState = {
  message: '',
  type: 'success',
  open: false
};

const toastAlertSlice = createSlice({
  name: 'toastAlert',
  initialState,
  reducers: {
    executeAlert(state, action: PayloadAction<{
      message: string,
      type: 'success' | 'error' | 'info' | 'warning'
    }>) {
      state.message = action.payload.message
      state.type = action.payload.type
      state.open = true
    },
    setToastAlertOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
    }
  },
});

export const { executeAlert, setToastAlertOpen } = toastAlertSlice.actions;
export const toastAlertReducer = toastAlertSlice.reducer;