import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useMemo } from "react";
import { Box, Divider, Grid, Typography } from '@mui/material';
import { darkenColor } from '../../../../../utils';


ChartJS.register(ArcElement, ChartTooltip, Legend);

export const ProjectReportResume = () => {
  const projectReport = useAppSelector((state) => state.project.projectReport)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  const exportedData = useMemo(() => {
    return {

      labels: ['Exportados', 'Deduplicados', 'Disponíveis', `${resaleConfig?.id !== '' ? `${resaleConfig?.name}` : 'Lurik'}`],
      datasets: [
        {
          label: 'registros',
          data: [projectReport?.generalData.usage.exported, projectReport?.generalData?.usage.blackList, projectReport?.generalData.usage.available, projectReport?.generalData.usage.lurik],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(238, 147, 56, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            `${resaleConfig?.id !== '' ? `${darkenColor(resaleConfig!.theme.colors.primary.main, 0.2)}` : 'rgb(114, 57, 137, 0.2)'}`
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(238, 147, 56, 1)',
            'rgba(54, 162, 235, 1)',
            `${resaleConfig?.id !== '' ? `${resaleConfig?.theme.colors.primary.main}` : '#723989'}`
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [projectReport?.generalData.usage.available, projectReport?.generalData.usage.blackList, projectReport?.generalData.usage.exported, projectReport?.generalData.usage.lurik, resaleConfig])



  return (
    <>
      <Box p={3} style={{
        width: '100%'
      }}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography align="center" fontWeight="bold" variant="h5" color="primary">
              Exportados
            </Typography>
            <Box p={2}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Pie color="white" data={exportedData} />
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span
                    style={{
                      color: resaleConfig?.id !== '' ? resaleConfig?.theme.colors.primary.main : '#723989'
                    }}
                  >
                    {resaleConfig?.id !== '' ? `${resaleConfig?.name}: ` : 'Lurik: '}
                  </span>
                  {projectReport?.generalData.usage.lurik.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(255, 99, 132, 1)',
                  }}>Exportados:</span>  {projectReport?.generalData.usage.exported.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(238, 147, 56, 1)',
                  }}>Deduplicados:</span>  {projectReport?.generalData.usage.blackList.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>

          <Grid item xs={12}>
            <Typography align="center" fontWeight="bold">
              <span style={{
                color: 'rgba(54, 162, 235, 1)'
              }}>Disponíveis:</span>  {projectReport?.generalData.usage.available.toLocaleString('pt-BR')}
            </Typography>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}