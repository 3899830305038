import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILocation } from '../../models';

interface ILocationState {
  locations: ILocation[]
  searchTerm: string,
  categoria: string
}

const initialState: ILocationState = {
  locations: [],
  searchTerm: '',
  categoria: 'macrorregiao'
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationsState(state, action: PayloadAction<{
      locations: ILocation[]
    }>) {
      state.locations = action.payload.locations
    },
    setLocationSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    },
    setLocationCategoriaState(state, action: PayloadAction<{
      categoria: string
    }>) {
      state.categoria = action.payload.categoria
    }

  },
});

export const {
  setLocationsState,
  setLocationSearchTermState,
  setLocationCategoriaState
} = locationSlice.actions;
export const locationReducer = locationSlice.reducer;