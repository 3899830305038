import { Slide, Button, Typography, Dialog, AppBar, Toolbar, IconButton, Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, List, ListItem, ListItemText, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useMemo, forwardRef, useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CompanyTooltip } from "../CompanyTooltip";
import { UserRecipientFormFilterPreview } from "./UserRecipientFormFilterPreview";
import { useAppSelector } from "../../../../../../../../../../application/states/hooks";
import { useUserService } from "../../../../../../../../../../use-cases/user";

export const UserRecipientForm = () => {
    const Transition = useMemo(() => {
        return forwardRef(function Transition(
            props: TransitionProps & {
                children: React.ReactElement;
            },
            ref: React.Ref<unknown>,
        ) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
    }, [])

    const companiesState = useAppSelector((state) => state.backofficeCompany)
    const userState = useAppSelector((state) => state.user)
    const { setUserSearchTerm, addEmailFilter, setUserList } = useUserService()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setUserList()
    }, [setUserList])

    return <>
        <Button
            onClick={() => {
                setOpen(true)
            }}
            endIcon={
                <AddIcon />
            }
        >
            <Typography fontWeight="bold">
                Usuários
            </Typography>
        </Button>
        <Dialog
            fullScreen
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            setOpen(false)
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Selecionar usuários
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            value={userState.searchTerm}
                            onChange={(e) => {
                                setUserSearchTerm({
                                    searchTerm: e.target.value
                                })
                            }}
                            fullWidth
                            label="Digite o e-mail do usuário que deseja buscar"
                            placeholder="Digite o e-mail do usuário que deseja buscar"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={6}>
                    <List>
                        {
                            // eslint-disable-next-line array-callback-return
                            userState.userList.filter((user) => {
                                const userCompanyId = companiesState.filters.companyId?.includes(user.companyId)
                                if (userCompanyId) {
                                    if (
                                        user?.email.toUpperCase().includes(userState.searchTerm.toUpperCase()) ||
                                        user?.name.toUpperCase().includes(userState.searchTerm.toUpperCase())
                                    ) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }
                            }).map((user) => {
                                return (<>
                                    <CompanyTooltip
                                        title={
                                            <Box
                                                style={{
                                                    maxHeight: '300px',
                                                    overflowY: 'scroll'
                                                }}
                                                p={4}
                                            >
                                                <div>
                                                    <Typography variant="h5" color="primary" fontWeight={"bold"}>
                                                        Usuário: {user.name}
                                                    </Typography>
                                                </div>
                                                <Box my={3}>
                                                    <Typography variant="h6" fontWeight={"bold"}>
                                                        Tipo: {user.userType}
                                                    </Typography>
                                                </Box>
                                                <List>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <ListItem>
                                                                <ListItemText primary={user.email} />
                                                            </ListItem>
                                                        </Grid>
                                                    </Grid>
                                                </List>
                                            </Box>
                                        }
                                        placement="right"
                                    >
                                        <div>
                                            <ListItem
                                                secondaryAction={<>
                                                </>} button
                                                onClick={() => {
                                                    addEmailFilter({
                                                        email: user.email
                                                    })
                                                    // addUserIdRecipientCreateNotification({
                                                    //     userId: user.id
                                                    // })
                                                }}
                                                sx={{ pl: 3 }}
                                            >
                                                <ListItemText primary={user.email.toUpperCase()} />
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    </CompanyTooltip>
                                </>)
                            })
                        }
                    </List>
                </Grid>
                {
                    (userState?.filters.email?.length ?? 0) > 0 && <Grid xs={6}>
                        <Box p={3} my={3}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                                        Usuários selecionados
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <UserRecipientFormFilterPreview />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Dialog>
    </>
}