import { useAppSelector } from "../../application/states/hooks"
import { PreLoader } from "./pre-loader"

export const LoadingContainer = () => {
  const loading = useAppSelector((state) => state.loading)
  return (
    <>
      <div style={{
        background: 'rgba(0,0,0,0.6)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 200000,
        display: loading.isLoading ? 'block' : 'none',
      }}>

        <PreLoader />
      </div>
    </>
  )
}