import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { validateEmail } from '../../../veles-validations'
import { IFieldProps, IFieldError } from '../../models'



interface ILoginFields {
  email: IFieldProps
  password: IFieldProps
}

interface ILoginState {
  loginModel: {
    email: string
    password: string
  }
  fields: ILoginFields,
  error?: IFieldError,
}
export const validateLoginForm = (data: {
  email: string,
  password: string
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []

  if (!data.email) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (validateEmail(data.email)) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail inválido',
        type: 'InvalidEmailError'
      }
    })
  }
  if (!data.password) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'Senha é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (data.password.length < 8) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'Senha deve ter no mínimo 8 caracteres',
        type: 'InvalidPasswordError'
      }
    })
  }
  let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')

  if (!regex.test(data.password)) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
        type: 'InvalidPasswordError'
      }
    })
  }





  return errors

}
// const initialState: ILoginState = {
//   fields: {
//     email: {
//       value: '',
//       isValid: true,
//       validate() {
//         let errorMessage: string | undefined = undefined
//         if (!this.value || this.value.length === 0) {
//           errorMessage = 'Por favor, insira um email valido'
//         }
//         errorMessage = validateEmail({
//           email: this.value
//         })
//         if (errorMessage) {
//           return {
//             type: 'E-mail',
//             message: errorMessage
//           }
//         }
//         return undefined
//       },
//     },

//     password: {
//       value: '',
//       isValid: true,
//       validate() {
//         let errorMessage: string | undefined = undefined
//         if (!this.value || this.value.length < 8) {
//           errorMessage = 'Por favor, insira uma senha valida'
//         }
//         let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
//         if (!regex.test(this.value)) {
//           errorMessage = 'A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial'
//         }

//         if (errorMessage) {
//           return {
//             type: 'Senha',
//             message: errorMessage
//           }
//         }
//         return undefined

//       },
//     },
//   }
// };

const initialState: ILoginState = {
  loginModel: {
    email: '',
    password: ''
  },
  fields: {
    email: {
      isValid: true,
      error: undefined,
    },
    password: {
      isValid: true,
      error: undefined,
    }
  }

}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginErrorForm(state, action: PayloadAction<{
      error: IFieldError
    }>) {
      state.error = action.payload.error
    },

    setLoginEmail(state, action: PayloadAction<{
      value: string,
    }>) {
      state.loginModel.email = action.payload.value
    },

    setLoginPassword(state, action: PayloadAction<{
      value: string
    }>) {
      state.loginModel.password = action.payload.value
    },
    setLoginErrors(state, action: PayloadAction<{
      errors: {
        field: string,
        fieldError: IFieldError
      }[]
    }>) {
      action.payload.errors.forEach(error => {
        if (error.field === 'email') {
          state.fields.email.isValid = false
          state.fields.email.error = error.fieldError
        } else if (error.field === 'password') {
          state.fields.password.isValid = false
          state.fields.password.error = error.fieldError
        }
      })
    },
    resetLoginErrorsState(state) {
      state.fields.email.isValid = true
      state.fields.email.error = undefined
      state.fields.password.isValid = true
      state.fields.password.error = undefined
      state.error = undefined
    },


    resetLoginState(state) {
      state.fields.email.isValid = true
      state.fields.email.error = undefined
      state.fields.password.isValid = true
      state.fields.password.error = undefined
      state.error = undefined
    }
  },
});

export const {
  setLoginPassword,
  setLoginEmail,
  setLoginErrors,
  resetLoginState,
  resetLoginErrorsState,
  setLoginErrorForm
} = loginSlice.actions;


export const loginReducer = loginSlice.reducer;