import { useCallback } from "react"
import { IProjectExport } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"

export const useProjectExportApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  const findProjectExportByUserId = useCallback((data: {
    userId: string;
    limit?: number;
    page?: number;
  }): Promise<{
    projectExportList: {
      projectExport: IProjectExport
      fileExists: boolean
    }[]
    total: number
    page: number
  }> => {
    return axiosAuthInstance.get('/project-export/company-export', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
      throw err
    })
  }, [axiosAuthInstance])

  /* This method will download the file using s */
  /* The endpoint use streams to download the file */

  const downloadProjectExportApi = useCallback((data: {
    projectExportId: string,
    exportLayoutId: string,
    fileExt: string
  }): Promise<any> => {
    return axiosAuthInstance.get('/project-export/download', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])


  const checkExportFile = useCallback((data: {
    projectExportId: string,
  }): Promise<boolean> => {
    return axiosAuthInstance.get('/project-export/check-file', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result.exists
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const recreateExportFileApi = useCallback((data: {
    projectExportId: string,
  }): Promise<boolean> => {
    return axiosAuthInstance.put('/project-export/recreate-file', {
      ...data
    }).then((response) => {
      const result = response.data.result.exists
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const getExportStatusApi = useCallback((data: {
    projectExportId: string,
  }): Promise<{
    status: 'pending' | 'completed' | 'failed' | 'processing',
    progress?: number
  }> => {

    return axiosAuthInstance.get('/project-export/status', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    })
  }, [
    axiosAuthInstance
  ])


  return {
    findProjectExportByUserId,
    downloadProjectExportApi,
    checkExportFile,
    recreateExportFileApi,
    getExportStatusApi
  }
}