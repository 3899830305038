import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { ICepList } from "../../application/models/ICepList"

export const useCepListApi = () => {
    const { axiosAuthInstance } = useAxiosInstances()
    const createCepListApi = useCallback((data: {
        name: string,
        createdBy: string
    }): Promise<ICepList> => {
        return axiosAuthInstance.post('/ceplist', data).then((result) => {
            const cepList: ICepList = result.data.result.cepList
            if (!cepList) throw new Error('Cep list not found')
            return cepList
        })
    }, [axiosAuthInstance])

    const findCepListByCompanyId = useCallback((data: {
        companyId: string
    }): Promise<ICepList[]> => {
        return axiosAuthInstance.get('/ceplist/find-by-company-id', { params: data }).then((result) => {
            const cepLists: ICepList[] = result.data.result.cepList
            if (!cepLists) throw new Error('Cep lists not found')
            return cepLists
        })
    }, [axiosAuthInstance])

    const updateCepListApi = useCallback((data: {
        id: string,
        name: string,
        list: string[],
    }): Promise<ICepList> => {
        return axiosAuthInstance.put('/ceplist', {
            id: data.id,
            name: data.name,
            list: data.list.map((item) => {
                return item.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
            })
        }).then((result) => {
            const cepList: ICepList = result.data.result.cepList
            if (!cepList) throw new Error('Cep list not found')
            return cepList
        })
    }, [axiosAuthInstance])

    return {
        createCepListApi,
        findCepListByCompanyId,
        updateCepListApi,
    }
}