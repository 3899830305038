import { ICepList } from "./ICepList"
import { IEconomicActivity } from "./IEconomicActivity"
import { ISegmento } from "./ISegmento"

export interface ILocation {
  macrorregiao?: string
  mesorregiao?: string
  microrregiao?: string
  cidade?: string
  uf?: string
  cep?: string
  bairro?: string
  logradouro?: string
  categoria?: string
  value?: string
  estado?: string
}

export interface ILocationFilterItem {
  purge?: boolean,
  location?: ILocation
}

export interface ILocationFilter {
  macrorregiao?: Array<ILocationFilterItem>
  mesorregiao?: Array<ILocationFilterItem>
  microrregiao?: Array<ILocationFilterItem>
  cidade?: Array<ILocationFilterItem>
  uf?: Array<ILocationFilterItem>
  cep?: Array<ILocationFilterItem>
  bairro?: Array<ILocationFilterItem>
  logradouro?: Array<ILocationFilterItem>
}

export interface ICepListFilterItem {
  purge?: boolean,
  cepList: ICepList
}

export interface ICepListFilter {
  name?: Array<ICepListFilterItem>,
  list?: Array<ICepListFilterItem>,
  createdBy?: Array<ICepListFilterItem>,
  companyId?: Array<ICepListFilterItem>,
  status?: Array<ICepListFilterItem>
}

export interface IRevenuesFilter {
  min?: number
  max?: number
}

export interface IStaffFilter {
  employee: {
    min?: number,
    max?: number
  },
  businessAssociate: {
    min?: number,
    max?: number,
  }
}

export interface ICreatedAtFilter {
  min?: Date
  max?: Date
}

export type IContact = 'TELEFONE' | 'WHATSAPP' | 'EMAIL' | 'ENDERECO' | 'SITE'
export const ContactTypeKeys: IContact[] = ['TELEFONE', 'EMAIL', "WHATSAPP", 'ENDERECO']

export interface IContactsFilter {
  business?: IContact[]
  partner?: IContact[]
}

export type ISector = 'COMERCIO' | 'INDUSTRIA' | 'SERVICO' | 'AGRONEGOCIO'
export const SectorKeys: ISector[] = ['COMERCIO', 'INDUSTRIA', 'SERVICO', 'AGRONEGOCIO']

export type IBusinessSize = 'MICRO' | 'PEQUENA' | 'MEDIA' | 'GRANDE' | 'MEI'
export const BusinessSizeKeys: IBusinessSize[] = ['MEI', 'MICRO', 'PEQUENA', 'MEDIA', 'GRANDE',]

export type IStatus = 'ATIVA' | 'INAPTA' | 'NULA' | 'SUSPENSA' | 'BAIXADA'
export const StatusKeys: IStatus[] = ['ATIVA', 'INAPTA', 'NULA', 'SUSPENSA', 'BAIXADA']

export type IRegimeTributario = 'MICROEMPREENDEDOR INDIVIDUAL' | 'SIMPLES NACIONAL' | 'LUCRO PRESUMIDO' | 'LUCRO REAL' | 'ISENTO' | 'LUCRO ARBITRADO'
export const RegimeTributarioKeys: IRegimeTributario[] = ['MICROEMPREENDEDOR INDIVIDUAL', 'SIMPLES NACIONAL', 'LUCRO PRESUMIDO', 'LUCRO REAL', 'ISENTO', 'LUCRO ARBITRADO']

export type IEconomicGroup = 'MATRIZ' | 'FILIAL'
export const EconomicGroupKeys: IEconomicGroup[] = ['MATRIZ', 'FILIAL']

export type SpecialStatus = 'NOT_INCLUDE' | 'ESPOLIO EV 407' | 'FALIDO' | 'INTERVENCAO' | 'LIQUIDACAO EXTRA-JUDICIAL' | 'RECUPERACAO JUDICIAL' | 'EM LIQUIDACAO'
export const SpecialStatusKeys: SpecialStatus[] = ['NOT_INCLUDE', 'ESPOLIO EV 407', 'FALIDO', 'INTERVENCAO', 'LIQUIDACAO EXTRA-JUDICIAL', 'RECUPERACAO JUDICIAL', 'EM LIQUIDACAO']

export interface IShareCapitalFilter {
  min?: number
  max?: number
}

export type IActiveDebt =
  'CIDE'
  | 'COFINS'
  | 'CSLL'
  | 'DEMAIS'
  | 'FGTS'
  | 'ICMS'
  | 'INSS'
  | 'IOF'
  | 'IPI'
  | 'IRPJ'
  | 'ISS'
  | 'ITR'
  | 'MEI'
  | 'PASEP'
  | 'PIS'

export const ActiveDebtTypeKeys: IActiveDebt[] = [
  'CIDE',
  'COFINS',
  'CSLL',
  'DEMAIS',
  'FGTS',
  'ICMS',
  'INSS',
  'IOF',
  'IPI',
  'IRPJ',
  'ISS',
  'ITR',
  'MEI',
  'PASEP',
  'PIS'
]

export interface IActiveDebtFilter {
  debt?: IActiveDebt[]
}

export type ICreditScoreDescription = 'ALTISSIMO RISCO' | 'ALTO RISCO' | 'MEDIO RISCO' | 'BAIXISSIMO RISCO' | 'BAIXO RISCO'

export const CreditScoreDescriptionsKeys: ICreditScoreDescription[] = [
  'ALTISSIMO RISCO',
  'ALTO RISCO',
  'MEDIO RISCO',
  'BAIXISSIMO RISCO',
  'BAIXO RISCO'
]

export interface IProjectFilter {
  id: string
  termsFilter?: string[]
  notIncludeTermsFilter?: string[]
  contacts?: IContactsFilter
  economicActivity?: Array<IEconomicActivity>
  justPrimaryActivity?: boolean
  sector?: ISector[]
  location?: ILocation[]
  purgeLocation?: ILocation[]
  revenues?: IRevenuesFilter
  businessSize?: IBusinessSize[]
  staff?: IStaffFilter
  economicGroup?: IEconomicGroup[]
  status?: IStatus[]
  regimeTributario?: IRegimeTributario[]
  createdAt?: ICreatedAtFilter
  segmentos?: Array<ISegmento>
  specialStatus?: SpecialStatus[]
  blackLists?: string[]
  cepLists?: string[]
  purgeCepLists?: string[]
  filterByLatLong?: boolean
  cepFilterByLatLong?: string
  radius?: number
  shareCapital?: IShareCapitalFilter
  includeActiveDebts?: boolean
  debts?: IActiveDebtFilter
  creditScoreDescriptions?: ICreditScoreDescription[]
  useGeolocationFilter?: boolean
  geolocation?: {
    lat: number
    lon: number
    radius: number
  }
}