import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Grid, TextField, Button, InputAdornment, Box, Typography } from '@mui/material'
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useLoginService } from '../../../../../use-cases/login';
import { darkenColor } from '../../../../../utils';
import { theme } from '../../../../styles';

export const LoginForm = () => {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const login = useAppSelector((state) => state.login)
  const { setEmail, setPassword, signIn } = useLoginService()

  return (
    <>
      <form onSubmit={(event) => {
        event.preventDefault()
        signIn()
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              onChange={(event) => {
                setEmail({
                  email: event.target.value
                })
              }}
              value={login.loginModel.email}
              error={!login.fields.email.isValid}
              helperText={!login.fields.email.isValid && `${login.fields.email.error?.message}`}
              label="Email" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Senha"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Button onClick={handleClickShowPassword}>
                    {showPassword ? (
                      <Visibility>

                      </Visibility>
                    ) :
                      <VisibilityOff>

                      </VisibilityOff>
                    }
                  </Button>
                </InputAdornment>,
              }}
              onChange={(event) => {
                setPassword({
                  password: event.target.value
                })
              }}
              value={login.loginModel.password}
              error={!login.fields.password.isValid}
              helperText={!login.fields.password.isValid && `${login.fields.password.error?.message}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"end"}>
              <Grid item>
                <Button variant="text" color="primary" onClick={() => {
                  navigate('/authentication/request-new-password')
                }}>
                  Esqueceu a senha?
                </Button>
              </Grid>

            </Grid>
          </Grid>
          {
            login.error && (
              <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                  <Typography color="error" variant='h6' align="center" fontWeight={"bold"}>
                    {login.error.message}
                  </Typography>
                </Box>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{
                '&:hover': {
                  backgroundColor: darkenColor(theme.palette.primary.main, 10)
                },
                color: theme.palette.primary.contrastText
              }}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      </form>

    </>
  )
}