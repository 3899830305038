import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { setSubscriptionState, setUsageState } from "../../application/states/subscription"
import { useSubscriptionApi } from "../../infra/subscription"
import { useLoadingService } from "../loading"
import { executeAlert } from "../../application/states/toast-alert"

export const useSubscriptionService = () => {
  const dispatch = useAppDispatch()
  const { findCompanySubscription, getSubscriptionUsage } = useSubscriptionApi()
  const { startLoading, stopLoading } = useLoadingService()
  const companyId = useAppSelector((state) => state.user.companyId)

  const setSubscription = useCallback(() => {
    if (!companyId || companyId === '') {
      return
    }
    
    startLoading()
    findCompanySubscription({
      companyId: companyId
    }).then((subscription) => {
      dispatch(setSubscriptionState({
        subscription: subscription
      }))
      return subscription
    }).then((subscription) => {
      getSubscriptionUsage({
        companyId: companyId
      }).then((usage) => {
        if (usage) {
          dispatch(setUsageState({
            usage: usage
          }))
        }
        stopLoading()
      }).catch((error) => {
        dispatch(executeAlert({
          message: error.message,
          type: 'error'
        }))
        stopLoading()
      })
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })
  }, [startLoading, findCompanySubscription, companyId, dispatch, getSubscriptionUsage, stopLoading])

  return {
    setSubscription
  }
}