import { Divider, Grid, Typography } from "@mui/material"
import { ReactNode } from "react"

export const FilterHeader = (props: {
  title: string
  description: ReactNode
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary" fontWeight="bold" textTransform="uppercase">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {props.description}
        </Grid>
      </Grid>

    </>
  )
}