import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SettingsTabs =
  'profile'
  | 'users' 
  | 'security' 
  | 'backOffice' 
  | 'subscription' 
  | 'consumptionLock'
  | 'whiteLabel'

interface ISettingsState {
  activeTab: SettingsTabs
}

const initialState: ISettingsState = {
  activeTab: 'profile'
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setActiveSettingsTab(state, action: PayloadAction<{
      tab: SettingsTabs
    }>) {
      state.activeTab = action.payload.tab
    }
  },
});

export const { setActiveSettingsTab } = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;