import { List, Grid, Chip, Avatar } from "@mui/material"
import { useAppSelector } from "../../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../../use-cases/project";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { theme } from "../../../../../../styles";

export const EconomicActivityFilterPreview = () => {
  const economicActivityFilterState = useAppSelector((state) => state.project?.selectedFilter?.economicActivity)
  const { removeEconomicAcivityFilter } = useProjectService()
  return (
    <>
      <List>
        <Grid container spacing={3}>
          {economicActivityFilterState?.map((economicActivity, index) => {
            return (
              <Grid item key={index}>
                <Chip sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    transition: '0.1s all',
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px 15px',
                    fontWeight: 'bold'
                  }
                }}
                  color="primary"
                  onDelete={() => {
                    removeEconomicAcivityFilter({
                      economicActivity: economicActivity
                    })
                  }}
                  avatar={
                    <Avatar
                      style={{
                        cursor: 'pointer',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                      }}>
                      <ApartmentIcon sx={{
                        "&": {
                          transition: '0.1s all'
                        },
                        "&:hover": {
                          color: "secondary.main"
                        }
                      }} />
                    </Avatar>}
                  label={`${economicActivity.cnaeCodigo.toUpperCase()} - ${economicActivity.cnaeDescricao.toUpperCase()}`} />
              </Grid>


            )

          })}
        </Grid>
      </List >
    </>
  )
}