import { Text, View, Image } from '@react-pdf/renderer';
import { styles } from './styles';

export const SectionTitle = (props: {
    title: string,
    icon: string
}) => {
    return <>
        <View style={styles.title}>
            <Image src={props.icon} style={styles.icon} />
            <Text style={styles.heading}>{props.title}</Text>
            <View style={styles.subTitleLine} />
        </View>
    </>
}