import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material"
import { useAppSelector } from "../../../../application/states/hooks"
import { theme } from "../../../styles"
import BusinessIcon from '@mui/icons-material/Business';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import GroupsIcon from '@mui/icons-material/Groups';
import { useEffect } from "react";
import { useBackofficeService } from "../../../../use-cases/backoffice";
import { BackofficeCompaniesSection } from "./company";
import { BackofficeRevenueReportPage } from "./revenue-report";
import { BackofficeConsumptionLockPage } from "./consumption-lock";
import { BackofficeUsersPage } from "./users-page";

export const BackofficePage = () => {
    const activeTab = useAppSelector((state) => state.backoffice.activeTab)
    const { setActiveTab, setResetSelectedCompany, setCompaniesList } = useBackofficeService()
    const { company } = useAppSelector((state) => state.company)
    const { user } = useAppSelector((state) => state)
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

    useEffect(() => {
        document.title = `${resaleConfig?.name ? `${resaleConfig.name}` : 'Lurik'} - Backoffice / Clientes`
    }, [resaleConfig?.name])

    useEffect(() => {
        setCompaniesList()
    }, [setCompaniesList])

    useEffect(() => {
        setResetSelectedCompany()
    }, [setResetSelectedCompany])

    return <>
        {
            company?.master === true || company?.resale === true ?
                user.userType === 'ADMIN' || user.userType === 'OWNER' || user.userType === "SELLER" ?
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem key={1}>
                                        <Paper elevation={3} style={{
                                            width: '100%'
                                        }}>
                                            <ListItemButton onClick={() => {
                                                setActiveTab({
                                                    tab: 'companies'
                                                })
                                            }} style={{
                                                background: activeTab === 'companies' ? theme.palette.primary.main : theme.palette.background.default,
                                                color: activeTab === 'companies' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                            }}>
                                                <ListItemIcon>
                                                    <BusinessIcon style={{
                                                        color: activeTab === 'companies' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                                    }} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Clientes'.toUpperCase()} />
                                            </ListItemButton>
                                        </Paper>
                                    </ListItem>
                                    {
                                        user.userType === 'ADMIN' || user.userType === 'OWNER'
                                            ? <ListItem key={2}>
                                                <Paper elevation={3} style={{
                                                    width: '100%'
                                                }}>
                                                    <ListItemButton onClick={() => {
                                                        setActiveTab({
                                                            tab: 'revenue'
                                                        })
                                                    }} style={{
                                                        background: activeTab === 'revenue' ? theme.palette.primary.main : theme.palette.background.default,
                                                        color: activeTab === 'revenue' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : theme.palette.primary.main),
                                                    }}>
                                                        <ListItemIcon>
                                                            <AssessmentIcon style={{
                                                                color: activeTab === 'revenue' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : theme.palette.primary.main),
                                                            }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Relatório de Receita".toUpperCase()} />
                                                    </ListItemButton>
                                                </Paper>
                                            </ListItem>
                                            : null
                                    }
                                    <ListItem key={3}>
                                        <Paper elevation={3} style={{
                                            width: '100%'
                                        }}>
                                            <ListItemButton onClick={() => {
                                                setActiveTab({
                                                    tab: 'consumptionLock'
                                                })
                                                setResetSelectedCompany()
                                            }} style={{
                                                background: activeTab === 'consumptionLock' ? theme.palette.primary.main : theme.palette.background.default,
                                                color: activeTab === 'consumptionLock' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                            }}>
                                                <ListItemIcon>
                                                    <SyncLockIcon style={{
                                                        color: activeTab === 'consumptionLock' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                                    }} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Limitação de Consumo'.toUpperCase()} />
                                            </ListItemButton>
                                        </Paper>
                                    </ListItem>
                                    {
                                        user.userType === 'ADMIN' || user.userType === 'OWNER' ?
                                            <ListItem key={4}>
                                                <Paper elevation={3} style={{
                                                    width: '100%'
                                                }}>
                                                    <ListItemButton onClick={() => {
                                                        setActiveTab({
                                                            tab: 'users'
                                                        })
                                                        setResetSelectedCompany()
                                                    }} style={{
                                                        background: activeTab === 'users' ? theme.palette.primary.main : theme.palette.background.default,
                                                        color: activeTab === 'users' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                                    }}>
                                                        <ListItemIcon>
                                                            <GroupsIcon style={{
                                                                color: activeTab === 'users' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) : (company.resale === false && !company.resaleId ? 'black' : resaleConfig?.theme.colors.primary.main)
                                                            }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={'Usuários'.toUpperCase()} />
                                                    </ListItemButton>
                                                </Paper>
                                            </ListItem>
                                            : null
                                    }
                                    {/* {
                                        company?.master === true ?
                                            <ListItem>
                                                <Paper elevation={3} style={{
                                                    width: '100%'
                                                }}>
                                                    <ListItemButton onClick={() => {
                                                        setActiveTab({
                                                            tab: 'notifications'
                                                        })
                                                    }} style={{
                                                        background: activeTab === 'notifications' ? theme.palette.primary.main : theme.palette.background.default,
                                                        color: activeTab === 'notifications' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) :  (company.resale === false && !company.resaleId ? 'black' : theme.palette.primary.main),
                                                    }}>
                                                        <ListItemIcon>
                                                            <NotificationAddIcon style={{
                                                                color: activeTab === 'notifications' ? (company.resale === false && !company.resaleId ? 'white' : theme.palette.primary.contrastText) :  (company.resale === false && !company.resaleId ? 'black' : theme.palette.primary.main),
                                                            }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Enviar notificações".toUpperCase()} />
                                                    </ListItemButton>
                                                </Paper>
                                            </ListItem>
                                            : null
                                    } */}
                                </List>
                            </Grid>
                            <Grid item xs={9}>
                                <Box p={2}>
                                    {
                                        activeTab === 'companies' ?
                                            (
                                                <>
                                                    <BackofficeCompaniesSection />
                                                </>
                                            ) : null
                                    }
                                    {
                                        activeTab === 'revenue' ?
                                            (
                                                <>
                                                    <BackofficeRevenueReportPage />
                                                </>
                                            ) : null
                                    }
                                    {
                                        activeTab === 'consumptionLock' ?
                                            (
                                                <>
                                                    <BackofficeConsumptionLockPage />
                                                </>
                                            ) : null
                                    }
                                    {
                                        activeTab === 'users' ?
                                            (
                                                <>
                                                    <BackofficeUsersPage />
                                                </>
                                            ) : null
                                    }
                                    {/* {
                                        activeTab === 'notifications' ?
                                            company?.master ?
                                                (
                                                    <>
                                                        <BackofficeNotificationsSection />
                                                    </>
                                                ) : null
                                            : null
                                    } */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    : null
                : null
        }
    </>
}