import { Box, Grid, Typography, Divider, TextField, InputAdornment, Button, Select, InputLabel, FormControl, MenuItem, FormHelperText, Fab, Tooltip } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useResaleConfigService } from "../../../../../use-cases/resale-config"
import { MuiFileInput } from "mui-file-input"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ptBR from 'dayjs/locale/pt-br';
import dayjs from "dayjs"
import { darkenColor, formatTelefoneBrazil, onlyNumbers } from "../../../../../utils"
import { useEffect, useState } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import fontOptionsJSON from '../../../../assets/fonts/fontOptions.json'
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { theme } from "../../../../styles"
import { validateEmail } from "../../../../../veles-validations"

export const WhiteLabelSection = () => {

    const { resaleConfig, imageFile, faviconFile, loginPageImg } = useAppSelector((state) => state.resaleConfig)
    const { userType } = useAppSelector((state) => state.user)
    const { company } = useAppSelector((state) => state.company)
    const {
        createResaleConfig,
        resetResaleConfig,
        setResaleConfigContactBeginHour,
        setResaleConfigContactEmail,
        setResaleConfigContactEndHour,
        setResaleConfigContactPhone,
        setResaleConfigDomain,
        setResaleConfigEmailProviderSettingHost,
        setResaleConfigEmailProviderSettingPassword,
        setResaleConfigEmailProviderSettingPort,
        setResaleConfigEmailProviderSettingUser,
        setResaleConfigFavicon,
        setResaleConfigImg,
        setResaleConfigName,
        setResaleConfigThemeColorsBackgroundBodyMain,
        setResaleConfigThemeColorsBackgroundPaperMain,
        setResaleConfigThemeColorsPrimaryContrastColor,
        setResaleConfigThemeColorsPrimaryMain,
        setResaleConfigThemeColorsSecondaryContrastColor,
        setResaleConfigThemeColorsSecondaryMain,
        setResaleConfigThemeFontFamily,
        updateResaleConfig,
        setResaleConfigLoginPageImg
    } = useResaleConfigService()

    dayjs.locale(ptBR)
    const beginHour = dayjs().subtract(3, 'hour')
    const endHour = dayjs().subtract(3, 'hour')
    const splitBeginHour = resaleConfig?.contact.beginHour.split(':')
    const splitEndHour = resaleConfig?.contact.endHour.split(':')
    const beginHourNumber = Number(splitBeginHour ? splitBeginHour[0] : 8)
    const beginMinuteNumber = Number(splitBeginHour ? splitBeginHour[1] : 0)
    const endHourNumber = Number(splitEndHour ? splitEndHour[0] : 18)
    const endMinuteNumber = Number(splitEndHour ? splitEndHour[1] : 0)
    const beginHourInitialState = beginHour.set('hour', beginHourNumber).set('minute', beginMinuteNumber)
    const endHourInitialState = endHour.set('hour', endHourNumber).set('minute', endMinuteNumber)

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações / White Label`
    }, [resaleConfig, resaleConfig?.name])

    return (
        <>
            {
                resaleConfig?.id === ''
                    ? null
                    : <Tooltip
                        title="Resetar todos os campos"
                        arrow
                        placement="left"
                    >
                        <Fab
                            sx={{
                                '&:hover': {
                                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                }
                            }}
                            onClick={() => {
                                resetResaleConfig()
                            }}
                            style={{
                                position: 'fixed',
                                bottom: '14%',
                                right: '5%',
                                marginTop: 1,
                                marginBottom: 5
                            }}
                            color="primary"
                            aria-label="delete"
                        >
                            <ReplyAllIcon
                                style={{
                                    color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                                }} />
                        </Fab >
                    </Tooltip>
            }
            <Tooltip
                title={resaleConfig?.id !== '' ? "Salvar configurações" : 'Adicionar configuração ao white label'}
                arrow
                placement="left"
            >
                <Fab
                    sx={{
                        '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        }
                    }}
                    onClick={() => {
                        if (resaleConfig?.id === '') {
                            createResaleConfig()
                        } else {
                            updateResaleConfig()
                        }
                    }}
                    style={{
                        position: 'fixed',
                        bottom: '5%',
                        right: '5%',
                        marginTop: 3,
                    }}
                    color="primary"
                    aria-label="add"
                >
                    {
                        resaleConfig?.id === ''
                            ? <AddIcon style={{
                                color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                            }} />
                            : <SaveIcon style={{
                                color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                            }} />
                    }
                </Fab >
            </Tooltip>
            <Box>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                White Label - Nome / Domínio
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.name}
                                error={resaleConfig?.name === '' ? true : false}
                                helperText={resaleConfig?.name === '' ? 'Digite um nome para a sua página' : null}
                                onChange={(e) => {
                                    setResaleConfigName({
                                        name: e.target.value
                                    })
                                }}
                                label="Nome"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={resaleConfig?.domain}
                                onChange={(e) => {
                                    setResaleConfigDomain({
                                        domain: e.target.value
                                    })
                                }}
                                label="Domínio"
                                fullWidth
                                type="text"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Logo - Página de Login
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={1}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} my={2}>
                            <MuiFileInput
                                id="outlined-static"
                                value={loginPageImg}
                                error={resaleConfig?.loginPageImg === '' ? true : false}
                                helperText={resaleConfig?.loginPageImg === '' ? 'É obrigatório selecionar uma imagem para a página de login' : null}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setResaleConfigLoginPageImg(e)
                                }}
                                label="Selecione uma imagem para a página de login"
                                getInputText={(file) => {
                                    return file?.name ?? 'Selecione uma imagem'
                                }}
                                InputProps={{
                                    inputProps: {
                                        accept: '.png, .jpeg, .jpg'
                                    }
                                }}
                            />
                            {
                                loginPageImg !== null ?
                                    resaleConfig?.loginPageImg ?
                                        <Grid container p={1} justifyContent={'center'}>
                                            <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                                <img src={resaleConfig?.loginPageImg} alt="Logo para a página de login" style={{ maxHeight: 180, maxWidth: 200 }} />
                                            </Grid>
                                        </Grid>
                                        : null
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Logo - Header / Favicon
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={1}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} my={2}>
                            <MuiFileInput
                                id="outlined-static"
                                value={imageFile}
                                error={resaleConfig?.img === '' ? true : false}
                                helperText={resaleConfig?.img === '' ? 'É obrigatório selecionar uma imagem para o Header' : null}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setResaleConfigImg(e)
                                }}
                                label="Selecione uma imagem para o Header"
                                getInputText={(file) => {
                                    return file?.name ?? 'Selecione uma imagem'
                                }}
                                InputProps={{
                                    inputProps: {
                                        accept: '.png, .jpeg, .jpg'
                                    }
                                }}
                            />
                            {
                                imageFile !== null ?
                                    resaleConfig?.img ?
                                        <Grid container p={1} justifyContent={'center'}>
                                            <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                                <img src={resaleConfig?.img} alt="Logo para o Header" style={{ maxHeight: 180, maxWidth: 200 }} />
                                            </Grid>
                                        </Grid>
                                        : null
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12} my={2}>
                            <MuiFileInput
                                id="outlined-static"
                                value={faviconFile}
                                error={resaleConfig?.favicon === '' ? true : false}
                                helperText={resaleConfig?.favicon === '' ? 'É obrigatório selecionar uma imagem para o Favicon' : null}
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setResaleConfigFavicon(e)
                                }}
                                label="Selecione uma imagem para o Favicon"
                                getInputText={(file) => {
                                    return file?.name ?? 'Selecione uma imagem'
                                }}
                                InputProps={{
                                    inputProps: {
                                        accept: '.png, .jpeg, .jpg'
                                    }
                                }}
                            />
                            {
                                faviconFile !== null ?
                                    resaleConfig?.favicon ?
                                        <Grid container p={1} justifyContent={'center'}>
                                            <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                                <img src={resaleConfig?.favicon} alt="Favicon" style={{ maxHeight: 150, maxWidth: 200 }} />
                                            </Grid>
                                        </Grid>
                                        : null
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Horário de Atendimento
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    sx={{
                                        width: '100%'
                                    }}
                                    value={beginHourInitialState}
                                    label={'Início'}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setResaleConfigContactBeginHour({
                                                beginHour: dayjs(newValue).format('HH:mm')
                                            })
                                        }
                                    }}
                                    closeOnSelect
                                    minutesStep={1}
                                    format="HH:mm"
                                    ampm={false}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    sx={{
                                        width: '100%'
                                    }}
                                    value={endHourInitialState}
                                    label={'Fim'}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setResaleConfigContactEndHour({
                                                endHour: dayjs(newValue).format('HH:mm')
                                            })
                                        }
                                    }}
                                    closeOnSelect
                                    minutesStep={1}
                                    format="HH:mm"
                                    ampm={false}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                E-mail / Telefone de Contato
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.contact.email}
                                error={resaleConfig?.contact.email === '' || validateEmail(resaleConfig?.contact.email ?? '') ? true : false}
                                helperText={resaleConfig?.contact.email === '' || validateEmail(resaleConfig?.contact.email ?? '') ? 'Digite um e-mail de contato válido' : null}
                                onChange={(e) => {
                                    setResaleConfigContactEmail({
                                        email: e.target.value
                                    })
                                }}
                                label="E-mail de Contato"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={formatTelefoneBrazil(resaleConfig?.contact.phone ?? '')}
                                error={resaleConfig?.contact.phone === '' ? true : false}
                                helperText={resaleConfig?.contact.phone === '' ? 'É obrigatório inserir um telefone para contato' : null}
                                onChange={(e) => {
                                    if (e.target.value.length === 16) {
                                        return
                                    }

                                    setResaleConfigContactPhone({
                                        phone: onlyNumbers(e.target.value)
                                    })
                                }}
                                label="Telefone de Contato"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="phone"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Provedor de E-mail
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.emailProviderSettings.user}
                                error={resaleConfig?.emailProviderSettings.user === '' || validateEmail(resaleConfig?.emailProviderSettings.user ?? '') ? true : false}
                                helperText={resaleConfig?.emailProviderSettings.user === '' || validateEmail(resaleConfig?.emailProviderSettings.user ?? '') ? 'Digite um e-mail válido para configurações do provedor' : null}
                                onChange={(e) => {
                                    setResaleConfigEmailProviderSettingUser({
                                        user: e.target.value
                                    })
                                }}
                                label="E-mail"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                label="Senha"
                                value={resaleConfig?.emailProviderSettings.password}
                                error={resaleConfig?.emailProviderSettings.password === '' ? true : false}
                                helperText={resaleConfig?.emailProviderSettings.password === '' ? 'A senha é obrigatória para configurações do provedor' : null}
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Button onClick={handleClickShowPassword}>
                                            {showPassword ? (
                                                <Visibility />
                                            ) :
                                                <VisibilityOff />
                                            }
                                        </Button>
                                    </InputAdornment>,
                                }}
                                onChange={(e) => {
                                    setResaleConfigEmailProviderSettingPassword({
                                        password: e.target.value
                                    })
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.emailProviderSettings.host}
                                error={resaleConfig?.emailProviderSettings.host === '' ? true : false}
                                helperText={resaleConfig?.emailProviderSettings.host === '' ? 'É obrigatório inserir o "host" para configurações do provedor' : null}
                                onChange={(e) => {
                                    setResaleConfigEmailProviderSettingHost({
                                        host: e.target.value
                                    })
                                }}
                                label="Host"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="url"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.emailProviderSettings.port}
                                error={resaleConfig?.emailProviderSettings.port === '' ? true : false}
                                helperText={resaleConfig?.emailProviderSettings.port === '' ? 'É obrigatório inserir a porta para configurações do provedor' : null}
                                onChange={(e) => {
                                    setResaleConfigEmailProviderSettingPort({
                                        port: e.target.value
                                    })
                                }}
                                label="Porta"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Tema - Background / Paper
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.background.body.main}
                                error={resaleConfig?.theme.colors.background.body.main === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.background.body.main === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsBackgroundBodyMain({
                                        mainColor: e.target.value
                                    })
                                }}
                                label="Cor de fundo"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.background.paper.main}
                                error={resaleConfig?.theme.colors.background.paper.main === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.background.paper.main === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsBackgroundPaperMain({
                                        mainColor: e.target.value
                                    })
                                }}
                                label="Cor terciária"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Tema - Cor Primária / Cor de Contraste
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.primary.main}
                                error={resaleConfig?.theme.colors.primary.main === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.primary.main === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsPrimaryMain({
                                        mainColor: e.target.value
                                    })
                                }}
                                label="Cor primária"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.primary.contrastColor}
                                error={resaleConfig?.theme.colors.primary.contrastColor === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.primary.contrastColor === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsPrimaryContrastColor({
                                        contrastColor: e.target.value
                                    })
                                }}
                                label="Cor de contraste"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Tema - Cor Secundária / Cor de Contraste
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.secondary.main}
                                error={resaleConfig?.theme.colors.secondary.main === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.secondary.main === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsSecondaryMain({
                                        mainColor: e.target.value
                                    })
                                }}
                                label="Cor secundária"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} my={2}>
                            <TextField
                                disabled={userType === 'SELLER'}
                                value={resaleConfig?.theme.colors.secondary.contrastColor}
                                error={resaleConfig?.theme.colors.secondary.contrastColor === '' ? true : false}
                                helperText={resaleConfig?.theme.colors.secondary.contrastColor === '' ? 'Selecione uma cor' : null}
                                onChange={(e) => {
                                    setResaleConfigThemeColorsSecondaryContrastColor({
                                        contrastColor: e.target.value
                                    })
                                }}
                                label="Cor de contraste"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                type="color"
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                }}
                                color="primary"
                                align="center"
                                variant="h6" component="div"
                            >
                                Tema - Fonte
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <Divider
                                sx={{
                                    backgroundColor: 'primary.main',
                                    height: '2px',
                                    width: '50%',
                                    margin: '0 auto'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">Fonte</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={resaleConfig?.theme.fontFamily}
                                    label="Fonte"
                                    onChange={(e) => {
                                        setResaleConfigThemeFontFamily({
                                            fontFamily: e.target.value
                                        })
                                    }}
                                    style={{
                                        fontFamily: resaleConfig?.theme.fontFamily
                                    }}
                                >
                                    {
                                        fontOptionsJSON.fontOptions.map((font) => {
                                            return (
                                                <MenuItem value={font.fontFamily} style={{ fontFamily: font.fontFamily }}>{font.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                                <FormHelperText>Toda a aplicação seguirá a fonte escolhida</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}