export const changeFavicon = (src: string) => {
    const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]')
    if (favicon !== null) {
        favicon.href = src
    } else {
        const newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = src;
        document.head.appendChild(newFavicon);
    }
}