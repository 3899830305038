import { View, Text, Link, Image } from "@react-pdf/renderer"
import { BoxShadowView } from "./BoxShadowView"
import { ICurrentPj } from "./ICurrentPJ"
import { styles } from "./styles"
import { SectionTitle } from "./SectionTitle"
import companyDataIcon from './images/companyDataIcon.png'
import { formatBrazilPhone, formatCnpjCpf } from "../card-pessoa-juridica"
import { format } from "date-fns"
import lurikLogo from './images/lurikLogo.jpg'

export const CompanySection = (props: {
    currentPj?: ICurrentPj,
    image?: string
}) => {
    return <>
        <SectionTitle icon={companyDataIcon} title='Dados Cadastrais' />
        <View style={styles.titleLine} />
        {
            props.image === ''
            ? null
            : <Image src={lurikLogo} style={styles.backgroundLogo} />
        }
        <BoxShadowView>
            <View style={styles.content}>
                <Text style={styles.label}>CNPJ:</Text>
                <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.cnpj ? formatCnpjCpf(props.currentPj?.pj.cnpj) : ` `}</Text>
                <Text style={styles.label}>Razão Social:</Text>
                <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.razaosocial ? props.currentPj?.pj.razaosocial : ` `}</Text>
                <Text style={styles.label}>Nome Fantasia:</Text>
                <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.nomefantasia ? props.currentPj?.pj.nomefantasia : ` `}</Text>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Telefones:</Text>
                        {props.currentPj?.pjPhones ? props.currentPj?.pjPhones?.map((telefone) => (
                            <Link src={`tel:${telefone}`}
                                style={[styles.link, styles.value, styles.highlightedValue]}
                            >
                                {formatBrazilPhone(telefone)}
                            </Link>))
                            : <Text style={[styles.value, styles.highlightedValue]}> </Text>
                        }
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>E-mails:</Text>
                        {props.currentPj?.pjMails ? props.currentPj?.pjMails?.map((email) => (
                            <Link src={`mailto:${email}`}
                                style={[styles.link, styles.value, styles.highlightedValue]}
                            >
                                {email}
                            </Link>
                        ))
                            : <Text style={[styles.value, styles.highlightedValue]}> </Text>
                        }
                    </View>
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Data de Abertura:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.dataabertura ? format(new Date(props.currentPj?.pj.dataabertura), 'dd/MM/yyyy') : 'Sem registro'}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>Situação Cadastral:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.situacaocadastral ? props.currentPj?.pj.situacaocadastral : ` `}</Text>
                    </View>
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Porte:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.porte ? props.currentPj?.pj.porte : ` `}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>Natureza Jurídica:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.naturezajuricadescricao ? props.currentPj?.pj.naturezajuricadescricao : ` `}</Text>
                    </View>
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Capital Social:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}> {props.currentPj?.pj.capitalsocial ? `R$ ${props.currentPj.pj.capitalsocial.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2
                        })}` : ` `
                        }</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>Faturamento Anual:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.faturamentoanual ? `R$ ${props.currentPj.pj.faturamentoanual.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2
                        })}` : ` `
                        }</Text>
                    </View>
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Regime Tributário:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.regimetributario ? props.currentPj?.pj.regimetributario : ` `}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>Número de Funcionários:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.numerofuncionario ? props.currentPj?.pj.numerofuncionario.toLocaleString('pt-BR') : ` `}</Text>
                    </View>
                </View>
                <View style={styles.columnContainer}>
                    <View style={styles.column}>
                        <Text style={styles.label}>Setor:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.setor ? props.currentPj?.pj.setor : ` `}</Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.label}>Matriz / Filial:</Text>
                        <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.tipoempresa ? props.currentPj?.pj.tipoempresa : ` `}</Text>
                    </View>
                </View>
            </View>
        </BoxShadowView>
    </>
}