import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { BusinessSizeKeys, IBusinessSize } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const businessSizeKeyToLabel = (businessSizeKey: IBusinessSize) => {
  switch (businessSizeKey) {
    case 'MEI':
      return 'MEI - Microempreendedor Individual'
    case 'MICRO':
      return 'Microempresa'
    case 'PEQUENA':
      return 'Pequena empresa'
    case 'MEDIA':
      return 'Média empresa'
    case 'GRANDE':
      return 'Grande empresa'
    default:
      return 'Não definido'
  }
}

export const BusinessSizeFilter = () => {
  const businessSizeFilter = useAppSelector((state) => state.project?.selectedFilter?.businessSize)
  const { addBusinessSizeFilter, removeBusinessSizeFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Porte" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Esta opção permite filtrar empresas através do porte em que ela se encontra na Receita Federal. Podendo ser marcado somente um único ou vários portes ao mesmo tempo.
        </Typography>
        <Typography>
          Exemplo: MEI / Pequena / Média
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão considerados todos os portes na contagem.
        </Typography>
        <Grid item xs={12} py={3}>
          <Divider />
        </Grid>
      </>} />

      <Box p={3}>
        <Grid container spacing={3}>
          {
            BusinessSizeKeys.map((businessSize) => {
              return <>
                <Grid item xs={6}>
                  <FormControlLabel control={
                    <Switch
                      checked={businessSizeFilter?.includes(businessSize) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addBusinessSizeFilter({
                            businessSize: businessSize
                          })
                        }
                        else {
                          removeBusinessSizeFilter({
                            businessSize: businessSize
                          })
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={businessSizeKeyToLabel(businessSize).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>
  )
}