import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAggsCnae, IAggsSector, IAggsSize } from '../../models/ICnpjDZero'

interface IDayZeroCountState {
  total: number
  totalWithFilter: number
  aggsCnae: any[]
  aggsPorte: any[]
  aggsSetor: any[]
}

const initialState: IDayZeroCountState = {
  total: 0,
  totalWithFilter: 0,
  aggsCnae: [],
  aggsPorte: [],
  aggsSetor: []
};

const DayZeroCountSlice = createSlice({
  name: 'dayZeroCount',
  initialState,
  reducers: {
    setDayZeroByUserCountState(state, action: PayloadAction<{
      totalWithFilter: number
    }>) {
      state.totalWithFilter = action.payload.totalWithFilter;
    },
    setDayZeroTotalCountState(state, action: PayloadAction<{
      total: number
      aggsCnae: IAggsCnae[]
      aggsPorte: IAggsSize[]
      aggsSetor: IAggsSector[]
    }>) {
      state.total = action.payload.total
      state.aggsCnae = action.payload.aggsCnae
      state.aggsPorte = action.payload.aggsPorte
      state.aggsSetor = action.payload.aggsSetor
    }
  }
});

export const {
  setDayZeroByUserCountState,
  setDayZeroTotalCountState
} = DayZeroCountSlice.actions;
export const dayZeroCountReducer = DayZeroCountSlice.reducer;