import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeCompany, IBackofficeSeparatedCredits, IBackofficeSubscription, IResaleConfig } from "../../../application/models"

export const useBackofficeCompanyApi = () => {
    const { axiosAuthInstance } = useAxiosInstances()

    const findCompanyById = useCallback(async (data: {
        companyId: string
    }): Promise<IBackofficeCompany | undefined> => {

        return axiosAuthInstance?.get(`/company/${data.companyId}`).then((res: any) => {
            return {
                id: res.data.result.id,
                active: res.data.result.active,
                address: res.data.result.address,
                email: res.data.result.email,
                cnpj: res.data.result.cnpj,
                phone: res.data.result.phone,
                razaoSocial: res.data.result.razaoSocial,
                createdAt: res.data.result.createdAt,
                updatedAt: res.data.result.updatedAt,
                master: res.data.result.master,
                resale: res.data.result.resale,
                resaleId: res.data.result.resaleId
            }
        }).catch((error: any) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const getCompaniesList = useCallback(async (data: {
        userId: string
    }): Promise<{
        company: IBackofficeCompany
        subscription: IBackofficeSubscription
        separatedCredits: IBackofficeSeparatedCredits[]
        resaleConfig?: IResaleConfig
    }[]> => {
        return axiosAuthInstance?.get(`/find-companies/${data.userId}`).then((response: any) => {
            return response.data.result.companiesData
        }).catch((error: any) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const findCompaniesByResaleIdApi = useCallback((data: {
        resaleId: string
    }): Promise<IBackofficeCompany[]> => {
        return axiosAuthInstance.get(`/backoffice/${data.resaleId}`)
            .then((res) => {
                const result = res.data.result.companies as IBackofficeCompany[]
                if (result) {
                    return result
                } else {
                    throw new Error('Companies not found')
                }
            })
    }, [axiosAuthInstance])

    const createCompanyApi = useCallback(async (data: {
        companyData: {
            cnpj: string,
            email: string,
            phone: string,
            razaoSocial: string,
            nomeFantasia?: string,
            address: {
                postalCode: string,
                houseNumber: string,
                street: string,
                city: string,
                state: string,
                neighbourhood: string
            },
            img?: string,
            master: boolean,
            resale: boolean,
            resaleId?: string
        },
        subscriptionData: {
            createdBy: string,
            name: string,
            sellerId?: string,
            partnerCols?: boolean,
            ranges: {
                min: number,
                max?: number,
                fixedPrice?: number,
                pricePerRegister?: number
            }[]
        },
        resaleConfig?: IResaleConfig
    }): Promise<IBackofficeCompany> => {
        return axiosAuthInstance.post('/company', data).then((res: any) => {
            return {
                id: res.data.result.id,
                active: res.data.result.active,
                address: res.data.result.address,
                email: res.data.result.email,
                cnpj: res.data.result.cnpj,
                phone: res.data.result.phone,
                razaoSocial: res.data.result.razaoSocial,
                nomeFantasia: res.data.result.nomeFantasia,
                createdAt: res.data.result.createdAt,
                updatedAt: res.data.result.updatedAt,
                img: res.data.result.img,
                master: res.data.result.master,
                resale: res.data.result.resale,
                resaleId: res.data.result.resaleId
            }
        }).catch((error: any) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const updateCompanyApi = useCallback(async (data: {
        company: Partial<IBackofficeCompany>
        resaleConfig?: IResaleConfig
    }): Promise<IBackofficeCompany> => {
        let companyQuery: any = {}
        if (data.company.active !== undefined) {
            companyQuery.active = data.company.active
        }
        if (data.company.address) {
            companyQuery.address = data.company.address
        }
        if (data.company.cnpj) {
            companyQuery.cnpj = data.company.cnpj
        }
        if (data.company.email) {
            companyQuery.email = data.company.email
        }
        if (data.company.phone) {
            companyQuery.phone = data.company.phone
        }
        if (data.company.razaoSocial) {
            companyQuery.razaoSocial = data.company.razaoSocial
        }
        if (data.company.img) {
            companyQuery.img = data.company.img
        }
        if (data.company.resale) {
            companyQuery.resale = data.company.resale
        }
        if (data.company.resaleId) {
            companyQuery.resaleId = data.company.resaleId
        }
        if (data.company.nomeFantasia) {
            companyQuery.nomeFantasia = data.company.nomeFantasia
        }

        return axiosAuthInstance.put(`/company`, {
            companyData: {
                ...companyQuery,
                id: data.company.id
            },
            resaleConfig: data.resaleConfig
        }).then((res: any) => {
            return {
                id: res.data.result.id,
                active: res.data.result.active,
                address: res.data.result.address,
                email: res.data.result.email,
                cnpj: res.data.result.cnpj,
                phone: res.data.result.phone,
                razaoSocial: res.data.result.razaoSocial,
                nomeFantasia: res.data.result.nomeFantasia,
                createdAt: res.data.result.createdAt,
                updatedAt: res.data.result.updatedAt,
                img: res.data.result.img,
                master: res.data.result.master,
                resale: res.data.result.resale,
                resaleId: res.data.result.resaleId
            }
        }).catch((error: any) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    return {
        findCompanyById,
        getCompaniesList,
        createCompanyApi,
        updateCompanyApi,
        findCompaniesByResaleId: findCompaniesByResaleIdApi
    }
}