
import { Dialog, AppBar, Toolbar, Typography, Button, Slide, Autocomplete, Box, Grid, TextField, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useAppSelector } from '../../../../../application/states/hooks';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useExportLayoutService } from '../../../../../use-cases/export-layout';
import { IExportLayoutOption } from '../../../../../application/models';
import { IFileExtensionOption } from '../../../../../application/states/project-download';
import { useExportDownloadService } from '../../../../../use-cases/project-download/useProjectDownloadService';
import { columnValueToLabel, ExportLayoutModal } from '../export-layout-modal';
import { darkenColor } from '../../../../../utils';
import { theme } from '../../../../styles';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});





export const ExportDownloadModal = () => {
  /*  ExportLayout imports */
  const { selectExportLayout, resetExportLayout, setExportLayouts, openModal, deleteExportLayout } = useExportLayoutService()
  const exportLayoutState = useAppSelector((state) => state.exportLayout)
  const { company } = useAppSelector((state) => state.company)

  useEffect(() => {
    setExportLayouts()
    return () => {
      resetExportLayout()
    }
  }, [setExportLayouts, resetExportLayout])

  const exportDownload = useAppSelector((state) => state.exportDownload)

  const { closeModal, selectFileExtension, downloadProjectExport } = useExportDownloadService()
  return <>
    <ExportLayoutModal />
    <Dialog
      fullScreen
      open={exportDownload.modalOpen ?? false}
      onClose={() => {
        closeModal()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
            Download de extração
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            closeModal()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <form onSubmit={(e) => {
        e.preventDefault()
        downloadProjectExport()
        closeModal()
      }}>

        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                options={exportDownload.fileExtensions}
                value={exportDownload.selectedFileExtension}
                onChange={(event: any, newValue: IFileExtensionOption | null) => {
                  if (newValue) {
                    selectFileExtension({
                      selectedFileExtension: newValue
                    })
                  }
                }}
                renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar a extensão" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={exportLayoutState.layouts.map((item) => {
                  return {
                    label: item.name,
                    id: item.id
                  }
                })}
                value={{
                  label: exportLayoutState.layouts.find((layout) => layout.id === exportLayoutState.selectedLayout?.id)?.name ?? '',
                  id: exportLayoutState.layouts.find((layout) => layout.id === exportLayoutState.selectedLayout?.id)?.id ?? ''
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: IExportLayoutOption | null) => {
                  if (newValue) {
                    const layoutToSelect = exportLayoutState.layouts.find((item) => item.id === newValue.id)
                    if (layoutToSelect) {
                      selectExportLayout({
                        selectedLayout: layoutToSelect
                      })
                    }
                  }
                }}
                renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar o layout" />} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={6}>
                <Grid item>
                  <Button
                    onClick={() => {
                      openModal({
                        action: 'create'
                      })
                    }}
                    endIcon={<AddIcon />}
                  >
                    Criar novo layout

                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      openModal({
                        action: 'edit'
                      })
                    }}
                    disabled={!exportLayoutState.selectedLayout?.id}
                    endIcon={<EditIcon />}
                  >
                    Editar layout
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!exportLayoutState.selectedLayout?.id || exportLayoutState.layouts.length <= 1}
                    onClick={() => {
                      deleteExportLayout()
                    }}
                    endIcon={<DeleteIcon />}
                  >
                    Excluir layout
                  </Button>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item>
                  <List >
                    <ListItem>
                      <ListItemText primary="Nome do layout" secondary={exportLayoutState.selectedLayout?.name} />
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item>
                  <List >
                    <ListItem>
                      <ListItemText primary="Colunas" secondary={<>
                        {exportLayoutState.selectedLayout?.layoutColumns.map((item, index) => {
                          return <div key={index}> <strong style={{ fontWeight: 'bold' }}>{columnValueToLabel(item.value)}</strong> -  {item.label}</div>
                        })}
                      </>} />
                      <Divider variant="inset" component="li" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  mt: 2,
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 15)
                  },
                  color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                }}
                disabled={!exportLayoutState.selectedLayout?.id || exportDownload.selectedFileExtension.value === ''}
              >
                Baixar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

    </Dialog>
  </>
}