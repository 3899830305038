import { useCallback } from "react"
import { useAppDispatch } from "../../application/states/hooks"
import { setSegmentosListState, setSegmentosSearchTermState } from "../../application/states/segmentos"
import { executeAlert } from "../../application/states/toast-alert"
import { useSegmentosApi } from "../../infra/segmentos-api"

export const useSegmentosService = () => {
  const dispatch = useAppDispatch()
  const { findAllSegmentos } = useSegmentosApi()
  const setSegmentosList = useCallback(() => {
    findAllSegmentos().then((segmentosList) => {
  
      dispatch(setSegmentosListState({
        segmentosList: segmentosList
      }))
    }).catch(() => {
      dispatch(executeAlert({
        message: 'Erro ao buscar segmentos',
        type: 'error'
      }))
    })
  }, [dispatch, findAllSegmentos])

  const setSearchTerm = useCallback((data: {
    searchTerm: string
  }) => {
    dispatch(setSegmentosSearchTermState({
      searchTerm: data.searchTerm
    }))
  }, [dispatch])


  return {
    setSegmentosList,
    setSearchTerm
  }
}