import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { ActiveDebtTypeKeys, CreditScoreDescriptionsKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const activeDebtKeyToLabel = (activeDebtKey: string) => {
    switch (activeDebtKey) {
        case 'CIDE':
            return 'CIDE'
        case 'COFINS':
            return 'COFINS'
        case 'CSLL':
            return 'CSLL'
        case 'DEMAIS':
            return 'DEMAIS'
        case 'FGTS':
            return 'FGTS'
        case 'ICMS':
            return 'ICMS'
        case 'INSS':
            return 'INSS'
        case 'IOF':
            return 'IOF'
        case 'IPI':
            return 'IPI'
        case 'IRPJ':
            return 'IRPJ'
        case 'ISS':
            return 'ISS'
        case 'ITR':
            return 'ITR'
        case 'MEI':
            return 'MEI'
        case 'PASEP':
            return 'PASEP'
        case 'PIS':
            return 'PIS'
        default:
            return 'Não definido'
    }
}

const creditScoreDescriptionToLabel = (creditScoreDescriptionKey: string) => {
    switch (creditScoreDescriptionKey) {
        case 'ALTISSIMO RISCO':
            return 'ALTÍSSIMO RISCO'
        case 'ALTO RISCO':
            return 'ALTO RISCO'
        case 'MEDIO RISCO':
            return 'MÉDIO RISCO'
        case 'BAIXISSIMO RISCO':
            return 'BAIXÍSSIMO RISCO'
        case 'BAIXO RISCO':
            return 'BAIXO RISCO'
        default:
            return 'Não definido'
    }
}

export const FinancialIssuesFilter = () => {
    const activeDebtsFilter = useAppSelector((state) => state.project?.selectedFilter?.debts?.debt)
    const includeDebts = useAppSelector((state) => state.project?.selectedFilter?.includeActiveDebts)
    const creditScoreDescriptionsFilter = useAppSelector((state) => state.project.selectedFilter?.creditScoreDescriptions)
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
    const { addActiveDebtsFilter, removeActiveDebtsFilter, setIncludeDebtsFilter, addCreditScoreDescriptionFilter, removeCreditScoreDescriptionFilter } = useProjectService()

    return (
        <>
            <FilterHeader title="Pendências Financeiras"
                description={
                    <>
                        <Typography>
                            As opções abaixo possuem o intuito de filtrar empresas a partir do risco de inadimplência, ou que tenham algum tipo de dívida ativa.
                        </Typography>
                        <Typography>
                            A <strong>saúde empresarial</strong> é resultado de um algoritmo criado pela <strong>{resaleConfig?.id !== '' ? resaleConfig!.name : 'Lurik'}</strong>, partindo de diversos dados, para que, à partir de uma pontuação, seja possível mensurar o risco de inadimplência de cada empresa.
                            Você pode selecionar mais de um nível de risco diferente.
                        </Typography>
                        <Typography>
                            Já a seção de <strong>dívida ativa</strong> pode ser definida de duas formas: empresas que <strong>tenham</strong> algum tipo de dívida, 
                            ou empresas que <strong>não tenham</strong>.
                        </Typography>
                        <Typography>
                            Caso estas opções não sejam definidas, os filtros abaixo não serão levados em consideração durante a pesquisa.
                        </Typography>
                    </>
                }
            />
            <Box p={3} style={{ marginTop: '10px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary" fontWeight="bold" textTransform={"uppercase"}>
                            Saúde empresarial:
                        </Typography>
                    </Grid>
                    {
                        CreditScoreDescriptionsKeys.map((creditScoreDescription) => {
                            return <>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={creditScoreDescriptionsFilter?.length === 0 ? false : creditScoreDescriptionsFilter?.includes(creditScoreDescription) ?? false}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        addCreditScoreDescriptionFilter({
                                                            creditScoreDescription: creditScoreDescription
                                                        })
                                                    }

                                                    else {
                                                        removeCreditScoreDescriptionFilter({
                                                            creditScoreDescription: creditScoreDescription
                                                        })
                                                    }
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={creditScoreDescriptionToLabel(creditScoreDescription).toUpperCase()}
                                    />
                                </Grid>
                            </>
                        })
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="primary" fontWeight="bold" textTransform={"uppercase"}>
                            Dívida Ativa:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={
                            <Switch
                                checked={includeDebts ?? false}
                                onChange={(e) => {
                                    setIncludeDebtsFilter({
                                        includeDebts: e.target.checked
                                    })
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label={includeDebts ? 'Incluir dívidas' : 'Não incluir dívidas'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {
                        ActiveDebtTypeKeys.map((activeDebtType) => {
                            return <>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={activeDebtsFilter?.length === 0 ? false : activeDebtsFilter?.includes(activeDebtType) ?? false}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        addActiveDebtsFilter({
                                                            activeDebt: activeDebtType
                                                        })
                                                    }

                                                    else {
                                                        removeActiveDebtsFilter({
                                                            activeDebt: activeDebtType
                                                        })
                                                    }
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={activeDebtKeyToLabel(activeDebtType).toUpperCase()}
                                    />
                                </Grid>
                            </>
                        })
                    }
                </Grid>
            </Box>
        </>
    )
}