import { useCallback } from "react"
import { ISegmento } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"

export const useSegmentosApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findAllSegmentos = useCallback((): Promise<ISegmento[]> => {
    return axiosAuthInstance.get('/segmentos/all').then((response) => {
      const result = response.data?.result?.segmentosList
      if(result) {
        return result
      } else {
        throw new Error('Erro ao buscar segmentos')
      }
    }).catch((error) => {
      throw error
    })
  }, [axiosAuthInstance])
  return {
    findAllSegmentos
  }
}