
export const userTypeOptions: Array<{
  label: string,
  value: string
}> = [
    {
      label: 'ADMINISTRADOR',
      value: 'ADMIN'
    },
    {
      label: 'VENDEDOR',
      value: 'SELLER'
    },
    {
      label: 'OPERADOR',
      value: 'OPERATOR'
    }
  ]