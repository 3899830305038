import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as jose from 'jose'

interface IAuthState {
  /* Undefined deve ser o estado onde o usuário ainda não foi validado */
  isValid?: boolean
  token: string
}

const initialState: IAuthState = {
  isValid: undefined,
  token: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<{
      token: string
    }>) {
      state.token = action.payload.token
      const decodedToken = jose.decodeJwt(action.payload.token)
      if (decodedToken) {
        state.isValid = true
      } else {
        state.isValid = false
      }
    },
    resetAuthState(state) {
      state.isValid = false
      state.token = ''
    }
  },
});

export const { setToken, resetAuthState } = authSlice.actions;
export const authReducer = authSlice.reducer;