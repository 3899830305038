import { Button, Grid, TextField } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useUnitSearchService } from "../../../../../use-cases/unit-search/useUnitSearchService"

export const UnitSearchFilterForm = () => {
  const { search, setSearchTerm } = useUnitSearchService()
  const unitSearch = useAppSelector((state) => state.unitSearch)
  return (
    <>
      <form onSubmit={(event) => {
        event.preventDefault()
        search()
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="CNPJ, Razão Social ou Nome Fantasia"
              variant="outlined"
              value={unitSearch.searchTerm}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: <>
                  <Button type="submit" >
                    Pesquisar
                  </Button>
                </>
              }}
              onChange={(event) => {
                setSearchTerm({
                  searchTerm: event.target.value
                })
              }}
            />
          </Grid>
        </Grid>

      </form>
    </>
  )
}