import { Avatar, Box, Button, Chip, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { useState } from "react";
import { darkenColor } from "../../../../../../utils";
import { theme } from "../../../../../styles";


export const TermsFilter = () => {
  const termsFilterState = useAppSelector((state) => state.project?.selectedFilter?.termsFilter)
  const notIncludeTermsFilterState = useAppSelector((state) => state.project?.selectedFilter?.notIncludeTermsFilter)

  const { addTermsFilter, removeTermFilter, addNotIncludeTermsFilter, removeNotIncludeTermFilter } = useProjectService()
  const [termValue, setTermValue] = useState('')
  const [notInclude, setNotInclude] = useState(false)
  return (
    <>
      <FilterHeader title="Palavras Chave" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>

          Nesta opção é possível pesquisar empresas por nome, utilizando palavras chaves ex: (veículos), também é possível utilizar a combinação de mais de uma palavra ex: (veículos agência).
        </Typography>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Também é possível utilizar a busca de modo inverso, ou seja, desconsiderando palavras chaves no nome da empresa conforme indicado acima.
        </Typography>
      </>} />


      <Box p={3}>
        <form
          onSubmit={(e) => {
            e.preventDefault()

            if (termValue === '') return
            if (notInclude) {
              addNotIncludeTermsFilter({
                terms: termValue
              })
            } else {
              addTermsFilter({
                terms: termValue
              })
            }
            setTermValue('')
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                value={termValue}
                onChange={(e) => {
                  setTermValue(e.target.value)
                }}
                label="Digite aqui o termo que deseja buscar" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch
                  checked={notInclude}
                  onChange={(e) => {
                    setNotInclude(e.target.checked)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label={notInclude ? "Não incluir" : "Incluir"} />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                onClick={() => {
                  if (termValue === '') return
                  if (notInclude) {
                    addNotIncludeTermsFilter({
                      terms: termValue
                    })
                  } else {
                    addTermsFilter({
                      terms: termValue
                    })
                  }
                  setTermValue('')
                }}
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                  }
                }}
              >
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {
                  termsFilterState?.map((term, index) => {
                    return <Grid item key={index}>
                      <Chip sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          transition: '0.1s all',
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '10px 15px',
                          fontWeight: 'bold'
                        },
                      }} color="primary" onDelete={() => {
                        removeTermFilter({
                          terms: term
                        })
                      }} avatar={
                        <Avatar style={{
                          cursor: 'pointer'
                        }}>
                          <TextFieldsIcon sx={{
                            "&": {
                              transition: '0.1s all'
                            },
                            "&:hover": {
                              color: "secondary.main"
                            },
                            backgroundColor: theme.palette.primary.main
                          }} />
                        </Avatar>}
                        label={term} />
                    </Grid>
                  })
                }
              </Grid>


            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {
                  notIncludeTermsFilterState?.map((term, index) => {
                    return <Grid item key={index}>
                      <Chip sx={{
                        height: 'auto',
                        '& .MuiChip-label': {
                          color: 'white',
                          transition: '0.1s all',
                          display: 'block',
                          whiteSpace: 'normal',
                          padding: '10px 15px',
                          fontWeight: 'bold'
                        },
                      }} color="secondary" onDelete={() => {
                        removeNotIncludeTermFilter({
                          terms: term
                        })
                      }} avatar={
                        <Avatar style={{
                          cursor: 'pointer'
                        }}>
                          <TextFieldsIcon sx={{
                            "&": {
                              transition: '0.1s all',
                              color: 'white',
                              "& *": {
                                color: 'white'
                              }
                            },
                            "&:hover": {
                              color: "primary.main"
                            },
                            backgroundColor: theme.palette.secondary.main
                          }} />
                        </Avatar>}
                        label={term} />
                    </Grid>
                  })
                }
              </Grid>


            </Grid>

          </Grid>
        </form>
      </Box >
    </>

  )
}