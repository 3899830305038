import { Accordion, AccordionSummary, ListItem, ListItemAvatar, Avatar, ListItemText, Box, LinearProgress, Typography, AccordionDetails, Grid, TextField, Button } from "@mui/material"
import { userTypeOptions } from "../../../../assets/helper-assets/userTypeOptions"
import { IUser } from "../../../../../application/models"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Person2Icon from '@mui/icons-material/Person2';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useConsumptionService } from "../../../../../use-cases/consumption";
import { useState } from "react";
import { darkenColor, onlyNumbers } from "../../../../../utils";
import { theme } from "../../../../styles";

export const UserConsumption = (user: IUser) => {
  const { company } = useAppSelector((state) => state.company)
  const consumptionState = useAppSelector((state) => state.consumption)
  const { createConsumptionLock } = useConsumptionService()
  const [inputValue, setInputValue] = useState<number | undefined>(
    consumptionState.userListWithConsumption.find((item) => {
      return item.userId === user.id
    }
    )?.consumptionLimit
  )

  return <>
    <Accordion style={{
      marginBottom: '20px'
    }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={user.img}>
              <Person2Icon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<>
              {
                userTypeOptions.find((item) => {
                  return item.value === user.userType
                })?.label ?? 'DONO'
              }
              {
                ' - ' + user.name
              }
            </>}
            secondary={<>

              {
                consumptionState.userListWithConsumption.find((item) => {
                  return item.userId === user.id
                })?.consumptionLimit ? (
                  <>
                    <Box mt={2} mb={1}>
                      <LinearProgress variant="determinate" value={
                        (consumptionState.userListWithConsumption.find((item) => {
                          return item.userId === user.id
                        })?.consumption ?? 0) / (consumptionState.userListWithConsumption.find((item) => {
                          return item.userId === user.id
                        }
                        )?.consumptionLimit ?? 1) * 100
                      } />
                    </Box>
                    <Typography>
                      {
                        consumptionState.userListWithConsumption.find((item) => {
                          return item.userId === user.id
                        })?.consumption.toLocaleString('pt-BR')
                      } / {consumptionState.userListWithConsumption.find((item) => {
                        return item.userId === user.id
                      })?.consumptionLimit?.toLocaleString('pt-BR')} - ({((consumptionState.userListWithConsumption.find((item) => {
                        return item.userId === user.id
                      })?.consumption ?? 0) / (consumptionState.userListWithConsumption.find((item) => {
                        return item.userId === user.id
                      }
                      )?.consumptionLimit ?? 1) * 100).toFixed(2)} % do limite atingido)
                    </Typography>
                  </>

                ) : (
                  <>
                    <Typography>
                      {
                        consumptionState.userListWithConsumption.find((item) => {
                          return item.userId === user.id
                        })?.consumption.toLocaleString('pt-BR') + ' créditos consumidos'
                      }
                    </Typography>
                  </>
                )
              }
            </>}
          />
        </ListItem>,
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Limite de consumo"
              type="text"
              value={inputValue?.toLocaleString('pt-BR')}
              onChange={(e) => {
                const formattedNumber = onlyNumbers(e.target.value)
                if (e.target.value) {
                  setInputValue(Number(formattedNumber))
                } else {
                  setInputValue(undefined)
                }
              }}
              fullWidth />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                '&:hover': {
                  backgroundColor: darkenColor(theme.palette.primary.main, 15)
                },
                color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
              }}
              onClick={() => {
                createConsumptionLock({
                  limit: inputValue ?? 0,
                  userId: user.id
                })
              }}
            >
              Atualizar limite
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  </>
}