import { View, Image, Text } from "@react-pdf/renderer"
import { BoxShadowView } from "./BoxShadowView"
import { ICurrentPj } from "./ICurrentPJ"
import { SectionTitle } from "./SectionTitle"
import { styles } from "./styles"
import warningIcon from './images/warningIcon.png'
import lurikLogo from './images/lurikLogo.jpg'

export const WarningPage = (props: {
    currentPj?: ICurrentPj,
    image?: string
}) => {
    return <>
        <SectionTitle icon={warningIcon} title='CONSIDERAÇÕES IMPORTANTES' />
        {
            props.image === ''
                ? null
                : <Image src={props.image ? '' : lurikLogo} style={[styles.backgroundLogo]} />
        }
        <BoxShadowView>
            <View style={styles.section}>
                <Text style={[styles.value, styles.highlightedValue]}>
                    Este documento é confidencial e protegido nos termos da
                    {<Text style={styles.bold}> Lei 13.709, de 14 de agosto de 2018. NÃO </Text>}
                    deve ser compartilhado e tem como objetivo
                    a proteção e os direitos fundamentais de liberdade e de privacidade.
                </Text>
            </View>
        </BoxShadowView>
    </>
}