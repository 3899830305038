import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeSeparatedCredits } from "../../../application/models"

export const useBackofficeSeparatedCreditsApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const getSeparatedCredits = useCallback(async (data: {
    companyId: string
  }): Promise<IBackofficeSeparatedCredits[]> => {
    return axiosAuthInstance?.get('/separated-credits', {
      params: {
        companyId: data.companyId
      }
    }).then((response: any) => {
      const result = response.data?.result
      if (result) {
        return result.separatedCreditsList
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Subscription não encontrada'
        }
      }
    })
  }, [
    axiosAuthInstance
  ])

  const createSeparatedCredits = useCallback(async (data: {
    "separatedCreditsData": {
      "companyId": string
      "value": number
      "price": number
      "expiresAt": Date
      "sellerId": string
    }
  }): Promise<IBackofficeSeparatedCredits> => {
    return axiosAuthInstance?.post('/separated-credits', data).then((response: any) => {
      const result = response.data?.result
      if (result) {
        return result.separatedCredits
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Subscription não encontrada'
        }
      }
    })
  }, [
    axiosAuthInstance
  ])

  return {
    getSeparatedCredits,
    createSeparatedCredits
  }
}