import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user-slice'
import { passwordRecoveryReducer } from './password-recovery';
import { toastAlertReducer } from './toast-alert';
import { modalContainerReducer } from './modal-container';
import { loginReducer } from './login';
import { authReducer } from './auth';
import { navigationReducer } from './navigation';
import { unitSearchReducer } from './unit-search';
import { loadingReducer } from './loading';
import { settingsReducer } from './settings';
import { dataEnrichmentReducer } from './data-enrichment';
import { exportDownloadReducer } from './project-download';
import { exportLayoutReducer } from './export-layout';
import { subscriptionReducer } from './subscription';
import { UFCountReducer } from './uf-count';
import { projectReducer } from './project';
import { locationReducer } from './location';
import { segmentosReducer } from './segmentos';
import { economicActivityReducer } from './economic-activity';
import { projectExportReducer } from './project-export/project-export-slice';
import { companyReducer } from './company';
import { blackListReducer } from './black-list';
import { cepListReducer } from './cep-list';
import { consumptionReducer } from './consumption';
import { notificationReducer } from './notification';
import { dayZeroCountReducer } from './day-zero';
import { backofficeReducer } from './backoffice';
import { backofficeCompaniesReducer } from './backoffice/company';
import { backofficeConsumptionReducer } from './backoffice/consumption';
import { backofficeNotificationsReducer } from './backoffice/notification';
import { backOfficeRevenueReportReducer } from './backoffice/revenue-report';
import { resaleConfigReducer } from './resale-config';
import { geolocationReducer } from './geolocation';

export const store = configureStore({
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  },
  reducer: {
    login: loginReducer,
    user: userReducer,
    auth: authReducer,
    passwordRecovery: passwordRecoveryReducer,
    toastAlert: toastAlertReducer,
    modalContainer: modalContainerReducer,
    navigation: navigationReducer,
    unitSearch: unitSearchReducer,
    loading: loadingReducer,
    settings: settingsReducer,
    dataEnrichment: dataEnrichmentReducer,
    exportDownload: exportDownloadReducer,
    exportLayout: exportLayoutReducer,
    subscription: subscriptionReducer,
    ufCount: UFCountReducer,
    project: projectReducer,
    location: locationReducer,
    segmentos: segmentosReducer,
    economicActivity: economicActivityReducer,
    projectExport: projectExportReducer,
    company: companyReducer,
    blackList: blackListReducer,
    cepList: cepListReducer,
    consumption: consumptionReducer,
    notification: notificationReducer,
    dayZero: dayZeroCountReducer,
    backoffice: backofficeReducer,
    backofficeCompany: backofficeCompaniesReducer,
    backofficeNotification: backofficeNotificationsReducer,
    backofficeConsumption: backofficeConsumptionReducer,
    backofficeRevenueReport: backOfficeRevenueReportReducer,
    resaleConfig: resaleConfigReducer,
    geolocation: geolocationReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;