import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeLocation } from "../../../application/models"

export const useBackofficeLocationApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findLocationByCepApi = useCallback((data: {
    cep: string
  }): Promise<IBackofficeLocation> => {
    return axiosAuthInstance.get('/location/find-by-cep', {
      params: {
        cep: data.cep
      }
    }).then((response) => {
      const result = response.data?.result?.location
      if (result) {
        return result
      } else {
        throw new Error('Não foi possível encontrar o endereço')
      }
    })

  }, [axiosAuthInstance])

  return {
    findLocationByCepApi
  }
}