import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { INotification } from "../../application/models"

export const useNotificationApi = () => {
    const { axiosAuthInstance } = useAxiosInstances()

    const findNotificationByRecipientApi = useCallback((data: {
        userId: string
    }): Promise<INotification[]> => {
        return axiosAuthInstance.get('/notification/list', { params: data }).then((result) => {
            const notificationList: INotification[] = result.data.result.notification
            if (!notificationList) throw new Error('Notification list not found')
            return notificationList
        })
    }, [axiosAuthInstance])

    const viewNotificationApi = useCallback((data: {
        notificationId: string,
        userId: string
    }): Promise<INotification> => {
        return axiosAuthInstance.put('/notification', {
            notificationId: data.notificationId,
            userId: data.userId
        }).then((result) => {
            const notification: INotification = result.data.result.notification
            if (!notification) throw new Error('Notification not found')
            return notification
        })
    }, [axiosAuthInstance])

    return {
        findNotificationByRecipientApi,
        viewNotificationApi
    }
}