import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { ContactTypeKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const contactKeyToLabel = (contactKey: string) => {
  switch (contactKey) {
    case 'TELEFONE':
      return 'Telefone'
    case 'WHATSAPP':
      return 'WhatsApp'
    case 'EMAIL':
      return 'E-mail'
    case 'ENDERECO':
      return 'Endereço'
    default:
      return 'Não definido'
  }
}

export const ContactFilter = () => {
  const businessContactFilter = useAppSelector((state) => state.project?.selectedFilter?.contacts?.business)
  const partnerContactFilter = useAppSelector((state) => state.project?.selectedFilter?.contacts?.partner)
  const { addBusinessContactFilter, removeBusinessContactFilter, addPartnetContactFilter, removePartnerContactFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Contatos" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Marcando as três opções (e-mail, telefone e endereço), os filtros serão considerados como obrigatórios na contagem. Também é possível marcar apenas uma opção. Caso nenhuma das opções seja marcada, não serão considerados a obrigatoriedade desses filtros na contagem.

        </Typography>

      </>} />

      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" fontWeight="bold" textTransform={"uppercase"}>
              Contatos da empresa:
            </Typography>
          </Grid>
          {
            ContactTypeKeys.map((contactType) => {
              return <>
                <Grid item xs={6}>
                  <FormControlLabel control={
                    <Switch
                      checked={businessContactFilter?.includes(contactType) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addBusinessContactFilter({
                            businessContact: contactType
                          })

                        }
                        else {
                          removeBusinessContactFilter({
                            businessContact: contactType
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={contactKeyToLabel(contactType).toUpperCase()}
                  />
                </Grid>
              </>
            })
          }
          <Grid item xs={6}>
            <FormControlLabel control={
              <Switch
                checked={businessContactFilter?.includes('SITE') ?? false}
                onChange={(e) => {
                  if (e.target.checked) {
                    addBusinessContactFilter({
                      businessContact: 'SITE'
                    })

                  }
                  else {
                    removeBusinessContactFilter({
                      businessContact: 'SITE'
                    })
                  }
                }}

                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label={'SITE'} />
          </Grid>

          <Grid item xs={12} py={3}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="primary" fontWeight="bold" textTransform={"uppercase"}>
              Contatos dos sócios:
            </Typography>
          </Grid>
          {
            ContactTypeKeys.map((contactType) => {
              return <>
                <Grid item xs={6}>
                  <FormControlLabel control={
                    <Switch
                      checked={partnerContactFilter?.includes(contactType) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addPartnetContactFilter({
                            partnerContact: contactType
                          })

                        }
                        else {
                          removePartnerContactFilter({
                            partnerContact: contactType
                          })

                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={contactKeyToLabel(contactType).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}