import { Grid, Autocomplete, TextField, Divider, Paper, Typography, Button, Box, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, Avatar, DialogActions } from "@mui/material"
import { useState, useEffect } from "react"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useBackofficeService } from "../../../../../use-cases/backoffice"
import { formatCnpj, numericCnpj, darkenColor } from "../../../../../utils"
import { theme } from "../../../../styles"
import { useUserService } from "../../../../../use-cases/user"
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EditIcon from '@mui/icons-material/Edit'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { userTypeOptions } from "../../../../assets/helper-assets/userTypeOptions"
import { MuiFileInput } from "mui-file-input"

export const BackofficeUsersPage = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const { selectedCompany } = useAppSelector((state) => state.backofficeCompany)
    const { selectedUser } = useAppSelector((state) => state.user)
    const { user, company, backofficeCompany, resaleConfig } = useAppSelector((state) => state)
    const { findCompanyBySearchTerm, setResetSelectedCompany } = useBackofficeService()
    const { selectUser, updateUser, createUserForCompanies, setSelectedUserFileImage } = useUserService()
    const file = useAppSelector((state) => state.user.selectedUser?.img)
    const [newFile, setNewFile] = useState<File | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [nameInputError, setNameInputError] = useState(false)
    const [emailInputError, setEmailInputError] = useState(false)
    const [companyOptions, setCompanyOptions] = useState<{
        label: string,
        value: string
    }[]>([])

    useEffect(() => {
        document.title = `${resaleConfig?.resaleConfig?.id !== '' ? `${resaleConfig!.resaleConfig?.name}` : 'Lurik'} - Backoffice / Usuários`
    }, [resaleConfig])

    useEffect(() => {
        const companyOptionsLabel: {
            label: string
            value: string
        }[] = []

        const orderedCompanies = [...backofficeCompany.companyList].sort((a, b) => {
            if (a.company.razaoSocial < b.company.razaoSocial) {
                return -1
            } else {
                return 1
            }
        })

        for (let i = 0; i < orderedCompanies.length; i++) {
            const company = orderedCompanies[i].company

            if (company.nomeFantasia) {
                companyOptionsLabel.push({
                    label: `${formatCnpj(company.cnpj)} - ${company.razaoSocial.toUpperCase()} - ${company.nomeFantasia?.toUpperCase()}`,
                    value: numericCnpj(company.cnpj)
                })
            } else {
                companyOptionsLabel.push({
                    label: `${formatCnpj(company.cnpj)} - ${company.razaoSocial.toUpperCase()}`,
                    value: numericCnpj(company.cnpj)
                })
            }
        }

        setCompanyOptions(companyOptionsLabel)
    }, [backofficeCompany.companyList, user.id, user.userType])

    useEffect(() => {
        setResetSelectedCompany()
    }, [setResetSelectedCompany])

    return (
        <>
            <Grid container display={"flex"} justifyContent={"center"}>
                <Grid item xs={12}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        findCompanyBySearchTerm({
                            searchTerm: searchTerm
                        })
                    }}>
                        <Grid item xs={12} pb={3}>
                            <Autocomplete
                                options={companyOptions}
                                fullWidth
                                renderInput={(props) =>
                                    <TextField
                                        {...props}
                                        label={`Digite o CNPJ, Razão Social ou Nome Fantasia da empresa para ${user.userType === "OPERATOR" || user.userType === "SELLER" ? 'visualizar' : 'visualizar/editar os usuários'}`}
                                    />
                                }
                                value={companyOptions.find((company) => {
                                    return company.value === selectedCompany?.company.razaoSocial
                                })}
                                onChange={(event, newValue) => {
                                    setResetSelectedCompany()
                                    if (typeof newValue === "object") {
                                        setSearchTerm(newValue?.value ?? '')
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon={<PersonSearchIcon />}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                    },
                                    color: company.company?.resale === true || company.company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                }}
                                type="submit"
                            >
                                Pesquisar usuários por empresa
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                {
                    selectedCompany !== undefined && (
                        <>
                            <Grid item xs={12} my={3}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} mb={3}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        py: 2,
                                        pl: 2
                                    }}
                                >
                                    <Typography mb={2} variant="h6" color="primary" textTransform="uppercase" fontWeight="bold">
                                        Empresa selecionada
                                    </Typography>
                                    <Grid item xs={12} display="flex" flexDirection="row" my={0.5}>
                                        <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
                                            marginRight: '10px'
                                        }}>
                                            CNPJ:
                                        </Typography>
                                        <Typography textTransform={"uppercase"}
                                            color="secondary"
                                            fontWeight={"bold"}
                                        >
                                            {formatCnpj(selectedCompany.company.cnpj)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" flexDirection="row" my={0.5}>
                                        <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
                                            marginRight: '10px'
                                        }}>
                                            Razão Social:
                                        </Typography>
                                        <Typography textTransform={"uppercase"}
                                            color="secondary"
                                            fontWeight={"bold"}
                                        >
                                            {selectedCompany.company.razaoSocial.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} mt={1} mb={3}>
                                <Divider />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PersonAddIcon />}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                            },
                                            color: company?.company?.resale === true || company?.company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                        }}
                                        onClick={() => {
                                            setOpen(true)
                                            selectUser({
                                                user: {
                                                    name: '',
                                                    email: '',
                                                    companyId: '',
                                                    createdAt: new Date(),
                                                    updatedAt: new Date(),
                                                    id: '',
                                                    userType: 'OPERATOR',
                                                    status: true
                                                }
                                            })
                                        }}
                                    >
                                        Adicionar usuário
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        {
                                            selectedCompany.users?.map((user, index) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={index}
                                                            button
                                                            secondaryAction={<>
                                                                <Button style={{
                                                                    marginRight: '10px'
                                                                }} onClick={() => {
                                                                    setOpen(true)
                                                                    selectUser({
                                                                        user: user
                                                                    })
                                                                }} endIcon={
                                                                    <EditIcon />
                                                                }>
                                                                    Editar
                                                                </Button>
                                                            </>}
                                                        >
                                                            <ListItemText primary={user.name} secondary={`E-mail: ${user.email}`} />
                                                            <Divider variant="inset" component="li" />

                                                        </ListItem>
                                                    </>
                                                )
                                            })
                                        }
                                    </List>
                                </Grid>
                                <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => {
                                    setOpen(false)
                                }}>
                                    <DialogTitle>
                                        {
                                            selectedUser?.id ? (
                                                <>
                                                    Atualizar usuário
                                                </>
                                            ) : <>
                                                Criar usuário
                                            </>
                                        }
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box my={3}>
                                            <Grid container spacing={3}>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        error={nameInputError}
                                                        helperText={nameInputError ? 'Campo obrigatório' : ''}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={selectedUser?.name}
                                                        onChange={(e) => {
                                                            if (!selectedUser) return
                                                            selectUser({
                                                                user: {
                                                                    ...selectedUser,
                                                                    name: e.target.value
                                                                }
                                                            })
                                                        }}
                                                        autoFocus
                                                        id="name"
                                                        label="Nome"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        error={emailInputError}
                                                        helperText={emailInputError ? 'Campo obrigatório' : ''}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        value={selectedUser?.email}
                                                        onChange={(e) => {
                                                            if (!selectedUser) return
                                                            selectUser({
                                                                user: {
                                                                    ...selectedUser,
                                                                    email: e.target.value
                                                                }
                                                            })
                                                        }}
                                                        autoFocus
                                                        id="email"
                                                        label="E-mail"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {
                                                    selectedUser?.userType !== 'OWNER' ? (
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                options={userTypeOptions.filter((option) => {
                                                                    if (selectedCompany.company?.master === true || selectedCompany.company?.resale === true) {
                                                                        return option
                                                                    } else {
                                                                        return option.value !== 'SELLER'
                                                                    }
                                                                })}
                                                                value={userTypeOptions.find((option) => {
                                                                    return option.value === selectedUser?.userType
                                                                })
                                                                }
                                                                onChange={(event: any, newValue: { label: string, value: string } | null) => {
                                                                    if (newValue) {
                                                                        if (!selectedUser) return

                                                                        selectUser({
                                                                            user: {
                                                                                ...selectedUser,
                                                                                userType: newValue.value as any
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                renderInput={(props) =>
                                                                    <TextField
                                                                        {...props}
                                                                        label="Digite ou clique para selecionar o tipo de usuário"
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ) : null
                                                }
                                                {
                                                    selectedUser?.id ? (
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                options={[
                                                                    {
                                                                        label: 'Ativo',
                                                                        value: 'true'
                                                                    }, {
                                                                        label: 'Inativo',
                                                                        value: 'false'
                                                                    }
                                                                ]}
                                                                value={
                                                                    selectedUser?.status ? {
                                                                        label: 'Ativo',
                                                                        value: 'true'
                                                                    } : {
                                                                        label: 'Inativo',
                                                                        value: 'false'
                                                                    }
                                                                }
                                                                onChange={(event: any, newValue: { label: string, value: string } | null) => {
                                                                    if (newValue !== null) {
                                                                        if (!selectedUser) return
                                                                        selectUser({
                                                                            user: {
                                                                                ...selectedUser,
                                                                                status: newValue.value === 'true' ? true : false
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar a extensão" />}
                                                            />
                                                        </Grid>
                                                    ) : null
                                                }
                                                <Grid item xs={12}>
                                                    <MuiFileInput
                                                        id="outlined-static"
                                                        value={file}
                                                        fullWidth
                                                        variant="outlined"
                                                        onChange={(e) => {
                                                            setSelectedUserFileImage(e)
                                                            setNewFile(e)
                                                        }}
                                                        label="Imagem"
                                                        placeholder="Selecione uma imagem"
                                                        getInputText={(file) => newFile ? newFile.name : 'Selecione uma imagem'}
                                                        InputProps={{
                                                            inputProps: {
                                                                accept: '.png, .jpeg, .jpg'
                                                            }
                                                        }}
                                                    />
                                                    {file !== null ?
                                                        <Grid container p={1} justifyContent={'center'}>
                                                            <Grid item pl={2} pr={2} pt={2} justifyContent={'center'} >
                                                                <Avatar src={selectedUser?.img} alt="preview" sx={{ width: 115, height: 115 }} />
                                                            </Grid>
                                                        </Grid>
                                                        : null}
                                                </Grid>

                                            </Grid>
                                        </Box>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (!selectedUser?.name) {
                                                    setNameInputError(true)
                                                    return
                                                } else {
                                                    setNameInputError(false)
                                                }
                                                if (!selectedUser?.email) {
                                                    setEmailInputError(true)
                                                    return
                                                } else {
                                                    setEmailInputError(false)
                                                }
                                                setOpen(false)
                                                if (selectedUser?.id) {
                                                    updateUser()
                                                } else {
                                                    createUserForCompanies()
                                                }
                                            }}
                                        >
                                            {
                                                selectedUser?.id ? (
                                                    <>
                                                        Atualizar
                                                    </>
                                                ) : <>
                                                    Criar
                                                </>
                                            }
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </>
    )
}