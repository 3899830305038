import { useAppSelector } from "../../../application/states/hooks"
import { PreLoaderStylesProvider } from "./styles/PreLoaderStylesProvider"

export const PreLoader = () => {
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
    const colors = {
        primary: {
            main: resaleConfig?.id !== '' ?
                resaleConfig?.theme.colors.primary.main !== '' ?
                    resaleConfig?.theme.colors.primary.main
                    : '#723989'
                : '#723989',
            contrastColor: resaleConfig?.id !== '' ?
                resaleConfig?.theme.colors.primary.contrastColor ?
                    resaleConfig?.theme.colors.primary.contrastColor !== '' ?
                        resaleConfig?.theme.colors.primary.contrastColor
                        : '#FFF'
                    : '#FFF'
                : '#FFF'
        },
        secondary: {
            main: resaleConfig?.id !== '' ?
                resaleConfig?.theme.colors.secondary.main ?
                    resaleConfig?.theme.colors.secondary.main !== '' ?
                        resaleConfig?.theme.colors.secondary.main
                        : '#ee9338CC'
                    : '#ee9338CC'
                : '#ee9338CC',
            contrastColor: resaleConfig?.id !== '' ?
                resaleConfig?.theme.colors.secondary.contrastColor ?
                    resaleConfig?.theme.colors.secondary.contrastColor !== '' ?
                        resaleConfig?.theme.colors.secondary.contrastColor
                        : '#FFF'
                    : '#FFF'
                : '#FFF'
        },
        background: {
            paper: resaleConfig?.id !== '' ?
                resaleConfig?.theme.colors.background.paper.main ?
                    resaleConfig?.theme.colors.background.paper.main !== '' ?
                        resaleConfig?.theme.colors.background.paper.main
                        : '#ee5338cc'
                    : '#ee5338cc'
                : '#ee5338cc'
        }
    }

    return (
        <>
            <PreLoaderStylesProvider
                primaryColor={colors.primary.main}
                secondaryColor={colors.secondary.main}
                tertiaryColor={colors.background.paper}
            >
                <div></div>
            </PreLoaderStylesProvider>
        </>
    )
}
