import { useCallback } from "react"
import { ISubscription } from "../../application/models/ISubscription"
import { useAxiosInstances } from "../axios-instance"

export const useSubscriptionApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findCompanySubscription = useCallback((data: {
    companyId: string
  }): Promise<ISubscription> => {
    return axiosAuthInstance.get('/subscription/find-company-subscription', {
      params: {
        companyId: data.companyId
      }
    }).then((response) => {
      const subscription = response.data?.result?.subscription
      if (subscription) {
        return subscription
      }
    }).catch((error) => {
      throw {
        type: 'error',
        message: error.message
      }
    })
  }, [axiosAuthInstance])

  const getSubscriptionUsage = useCallback((data: {
    companyId: string
  }): Promise<{
    totalBySeparated: number,
    usedBySeparated: number,
    usedBySubscription: number,
    type: string,
    limit?: number,
    consumptionLock?: number
  }> => {
    return axiosAuthInstance.get('/subscription/usage', {
      params: {
        companyId: data.companyId
      }
    }).then((response) => {
      const usage = response.data?.result
      if (usage) {
        return {
          totalBySeparated: usage.totalBySeparated,
          usedBySeparated: usage.usedBySeparated,
          usedBySubscription: usage.usedBySubscription,
          type: usage.type,
          limit: usage.limit,
          consumptionLock: usage.consumptionLock,

        }
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Plano não encontrado'
        }
      }
    }).catch((error) => {
      throw {
        type: 'NotFoundError',
        message: error.message
      }
    })
  }, [axiosAuthInstance])





  return {
    findCompanySubscription,
    getSubscriptionUsage
  }
}