import { Snackbar, Alert } from "@mui/material"
import * as React from 'react'
import { useAppDispatch, useAppSelector } from "../../application/states/hooks";
import { setToastAlertOpen } from "../../application/states/toast-alert";
export const ToastAlert = () => {
  const toastAlert = useAppSelector(state => state.toastAlert)
  const dispatch = useAppDispatch()

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setToastAlertOpen(false))
  };

  return (
    <>
      <Snackbar
        open={toastAlert.open}
        color="primary"
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toastAlert.type} variant="filled" sx={{ width: '100%' }}>
          {toastAlert.message}
        </Alert>
      </Snackbar>
    </>
  )
}