import React, { useRef, useState } from 'react';
import { useLoadingService } from '../../../../../../use-cases/loading';
import './fileUploadStyle.css';
import readExcelFile from 'read-excel-file'
import { useDataEnrichmentService } from '../../../../../../use-cases/data-enrichment';
import { theme } from '../../../../../styles';
type FileUploadProps = {
  label: string;
};



const excelToJson = (file: any[]) => {
  const columns = file[0]
  const data = file.slice(1).map((item) => {
    const obj: any = {}
    columns.forEach((column: any, index: number) => {
      obj[column] = item[index]
    })
    return obj
  })
  return data
}

export const FileUpload: React.FC<FileUploadProps> = ({ label }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const { startLoading, stopLoading } = useLoadingService()
  const { setFile, setName } = useDataEnrichmentService()

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {

    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      startLoading()

      const file = e.dataTransfer.files[0];
      /* Check if is CSV or XLSX */

      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileType = file.type;
        const fileName = file.name;
        const fileSize = `${Math.round(file.size / 1024)} KB`;
        const fileDataString = event.target?.result as string;
        let fileData: any[] = [];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          fileData = await readExcelFile(file, { sheet: 1 })
          setFile({
            data: excelToJson(fileData),
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileData[0]
          })
          setName({
            name: fileName,
          })
        }
        if (file.type === 'text/csv' || file.type === 'text/plain') {
          const delimiter = guessDelimiter(fileDataString);
          fileData = csvToJson(fileDataString, delimiter);
          setFile({
            data: fileData,
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileDataString.split('\n')[0].split(delimiter)
          })
          setName({
            name: fileName
          })
        }

        stopLoading()
      };
      reader.readAsText(file);
    }
  };
  const guessDelimiter = (fileDataString: string) => {
    const delimiters = [',', ';', '\t', '|'];
    let maxCount = 0;
    let maxDelimiter = ',';
    delimiters.forEach((delimiter) => {
      const count = fileDataString.split(delimiter).length;
      if (count > maxCount) {
        maxCount = count;
        maxDelimiter = delimiter;
      }
    });
    return maxDelimiter;
  };

  const csvToJson = (csvString: string, delimiter: string) => {
    const rows = csvString.split('\n');
    const result: any[] = []
    rows.slice(1, rows.length).forEach((item: any) => {
      if (item !== "") {
        const fields = item.split(delimiter)
        let obj: any = {}
        rows[0]?.split(delimiter).forEach((column: any, index: number) => {
          obj[column] = fields[index]
        })

        result.push(obj)
      }
    })
    return result;
  };




  return (
    <div>

      <div
        className={`file-upload ${dragging ? 'dragging' : ''}`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => {
          if (inputFileRef.current) {
            inputFileRef.current.click()

          }
        }}

      >
        <div className="file-upload__label" style={{ color: theme.palette.primary.main }}>{label}</div>
        <input hidden type="file" ref={inputFileRef} onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            startLoading()
            const reader = new FileReader();
            reader.onload = async (event) => {
              const fileType = file.type;
              const fileName = file.name;
              const fileSize = `${Math.round(file.size / 1024)} KB`;
              const fileDataString = event.target?.result as string;
              let fileData: any[] = [];
              if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileData = await readExcelFile(file, { sheet: 1 })
                setFile({
                  data: excelToJson(fileData),
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileData[0]
                })
                setName({
                  name: fileName,
                })
              }
              if (file.type === 'text/csv' || file.type === 'text/plain') {
                const delimiter = guessDelimiter(fileDataString);
                fileData = csvToJson(fileDataString, delimiter);
                setFile({
                  data: fileData,
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileDataString.split('\n')[0].split(delimiter)
                })
                setName({
                  name: fileName
                })
              }
            }
            reader.readAsText(file);
          }
        }} />


      </div>

    </div>

  );
};


