import { useCallback } from "react"
import { FNPadronizarCNPJ, getDistinctValues } from "../data-enrichment"
import { useCnpjService } from "../../veles-validations/cnpj"
import { useLoadingService } from "../loading"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { addItemBlackListList, editItemBlackListList, removeItemBlackListList, resetBlackListFileData, resetBlackListForm, resetSelectedBlackListState, setBlackListDistinctValues, setBlackListFileData, setBlackListFileDataList, setBlackListFileInvalidDataList, setBlackListFileRepeatedDataList, setBlackListFileValidDataList, setBlackListFormDialogOpenState, setBlackListFormErrors, setBlackListList, setBlackListName, setBlackListSelectedColumn, setBlackListStatus, setBlackListValidValues, setBlackLists, setSelectedBlackList, validateBlackListForm } from "../../application/states/black-list"
import { useModalContainerService } from "../modal-container"
import { Box, Grid, Typography, Button } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { ISelectedBlackList } from "../../application/models"
import { executeAlert } from "../../application/states/toast-alert"
import { useBlackListApi } from "../../infra/black-list-api"
import { darkenColor } from "../../utils"
import { theme } from "../../presentation/styles"

export const useBlackListService = () => {
  const { validateCnpj } = useCnpjService()
  const {
    createBlackListApi,
    findBlackListByCompanyId,
    updateBlackListApi,
    changeBlackListStatusApi,
    findBlackListByIdApi
  } = useBlackListApi()
  const dispatch = useAppDispatch()
  const { openModal, closeModal } = useModalContainerService()
  const userState = useAppSelector((state) => state.user)
  const blackListState = useAppSelector((state) => state.blackList)
  const { startLoading, stopLoading } = useLoadingService()

  const findCnpjKeyInArray = useCallback((array: any[]) => {
    for (let i = 0; i < array.length; i++) {
      const keys = Object.keys(array[i])
      for (let k = 0; k < keys.length; k++) {
        if (validateCnpj(FNPadronizarCNPJ(`${array[i][keys[k]]?.toString()}`))) {
          return keys[k]
        }
      }
    }
    return null
  }, [validateCnpj])

  const resetFileData = useCallback(() => {
    dispatch(resetBlackListFileData())
  }, [dispatch])

  const selectColumn = useCallback((data: {
    selectedColumn: string,
    data?: any[]
  }) => {
    startLoading()
    dispatch(setBlackListSelectedColumn({
      selectedColumn: data.selectedColumn
    }))
    /* performatic code to get distinct values */
    let dataToUse = blackListState.fileData.data

    if (data.data) {
      dataToUse = data.data
    }

    const distinctValues = getDistinctValues(dataToUse.map((item: any) => item[data.selectedColumn].replace('\r', '')))

    dispatch(setBlackListDistinctValues({
      distinctValues: distinctValues.length
    }))

    const filteredList = distinctValues.filter((item) => {
      const replacedItem = FNPadronizarCNPJ(String(item.replace('\r', '')))
      return !blackListState.selectedBlackList?.list?.includes(replacedItem)
    })

    const distinctValidValues = distinctValues.filter((value: any) => {
      let isValid = validateCnpj(FNPadronizarCNPJ(value.replace('\r', '')))

      if (isValid) {
        return true
      } else {
        return false
      }
    })

    const repeatedList = distinctValues.filter((item) => {
      const replacedItem = FNPadronizarCNPJ(item.replace('\r', ''))

      return blackListState.selectedBlackList?.list?.includes(replacedItem)
    })

    const validValues = filteredList.filter((value: any) => {
      let isValid = validateCnpj(FNPadronizarCNPJ(value.replace('\r', '')))

      if (isValid) {
        return true
      } else {
        return false
      }
    })

    const invalidValues = filteredList.filter((value: any) => {
      let isValid = validateCnpj(FNPadronizarCNPJ(value.replace('\r', '')))

      if (!isValid) {
        return true
      } else {
        return false
      }
    })

    dispatch(setBlackListValidValues({
      validValues: distinctValidValues.length
    }))
    dispatch(setBlackListFileDataList({
      value: validValues
    }))
    dispatch(setBlackListFileValidDataList({
      value: validValues
    }))
    dispatch(setBlackListFileInvalidDataList({
      value: invalidValues
    }))
    dispatch(setBlackListFileRepeatedDataList({
      value: repeatedList
    }))

    stopLoading()
  }, [startLoading, dispatch, blackListState.fileData.data, blackListState.selectedBlackList?.list, stopLoading, validateCnpj])

  const setFile = useCallback((data: {
    name: string,
    size: string,
    columns: string[],
    type: string,
    data: any[]
  }) => {
    startLoading()
    dispatch(setBlackListFileData({
      fileData: {
        columns: data.columns,
        data: data.data,
        name: data.name,
        size: data.size,
        type: data.type
      }
    }))

    const cnpjKey = findCnpjKeyInArray(data.data)

    if (cnpjKey) {
      const replacedFileData = []
      for (let i = 0; i < data.data.length; i++) {
        const cnpj = data.data[i]

        if (typeof cnpj[cnpjKey] === 'number') {
          const replacedCnpj = FNPadronizarCNPJ(String(cnpj[cnpjKey]) as string)

          replacedFileData.push(replacedCnpj)
        }
        if (typeof cnpj[cnpjKey] === 'string') {
          const replacedCnpj = FNPadronizarCNPJ(String(cnpj[cnpjKey]))

          replacedFileData.push(replacedCnpj)
        }
      }

      const filteredList = replacedFileData.filter((item) => {
        return !blackListState.selectedBlackList?.list?.includes(item)
      })

      const repeatedList = replacedFileData.filter((item) => {
        const replacedItem = FNPadronizarCNPJ(item)
        return blackListState.selectedBlackList?.list?.includes(replacedItem)
      })

      const validValues = filteredList.filter((value: string) => {
        const formatNumericCnpj = FNPadronizarCNPJ(value)

        let isValid = validateCnpj(formatNumericCnpj)
        if (isValid) {
          return formatNumericCnpj
        } else {
          return false
        }
      })
      if (validValues.length < 1) {
        if (repeatedList.length > 0) {
          stopLoading()
          openModal({
            content: <>
              <Box p={3}>
                <Grid container alignItems={"center"} flexDirection="column">
                  <Grid item>
                    <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                      Encontramos apenas CNPJs já inseridos na sua lista atual.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" sx={{ mt: 2 }} align="center">
                      Não encontramos nenhuma coluna com CNPJs válidos e novos no arquivo. Selecione um arquivo com valores válidos.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box my={3}>
                      <Button variant="contained"
                        sx={{
                          mt: 2,
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          }
                        }}
                        onClick={() => {
                          closeModal()
                          resetFileData()
                        }}
                      >
                        OK
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          })
        } else {
          stopLoading()
          openModal({
            content: <>
              <Box p={3}>
                <Grid container alignItems={"center"} flexDirection="column">
                  <Grid item>
                    <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                      Não encontramos CNPJs válidos no seu arquivo!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" sx={{ mt: 2 }} align="center">
                      Não encontramos nenhuma coluna com CNPJs válidos e novos. Selecione outro arquivo.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box my={3}>
                      <Button variant="contained" sx={{
                        mt: 2,
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        }
                      }}
                        onClick={() => {
                          closeModal()
                          resetFileData()
                        }}
                      >
                        OK
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          })
        }
      } else {
        selectColumn({
          selectedColumn: cnpjKey,
          data: data.data
        })
        openModal({
          content: <>
            <Box p={3}>
              <Grid container alignItems={"center"} flexDirection="column">
                <Grid item>
                  <CheckCircleIcon color="primary" sx={{ fontSize: 100 }} />
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                    Arquivo <strong>{data.name}</strong> carregado com sucesso!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ mt: 2 }} align="center">
                    Selecionamos a coluna <strong>{cnpjKey}</strong> como a coluna que contém os CNPJs. Caso esta não seja a coluna correta, selecione a correta no menu indicado.
                  </Typography>
                </Grid>
                <Grid item>
                  <Box my={3}>
                    <Button variant="contained"
                      sx={{
                        mt: 2,
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        }
                      }}
                      onClick={() => {
                        closeModal()
                      }}
                    >
                      OK
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        })
      }
    } else {
      stopLoading()
      openModal({
        content: <>
          <Box p={3}>
            <Grid container alignItems={"center"} flexDirection="column">
              <Grid item>
                <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                  Não encontramos CNPJs válidos no seu arquivo!
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                  Não encontramos nenhuma coluna com CNPJs válidos. Selecione outro arquivo.
                </Typography>
              </Grid>
              <Grid item>
                <Box my={3}>
                  <Button variant="contained"
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      }
                    }}
                    onClick={() => {
                      closeModal()
                      resetFileData()
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      })
    }
  }, [startLoading, dispatch, findCnpjKeyInArray, blackListState.selectedBlackList?.list, validateCnpj, stopLoading, openModal, closeModal, resetFileData, selectColumn])

  const setName = useCallback((data: {
    name: string
  }) => {
    dispatch(setBlackListName({
      value: data.name
    }))
  }, [dispatch])

  const reset = useCallback(() => {
    dispatch(resetBlackListForm())
  }, [dispatch])

  const addFileListToBlackList = useCallback(() => {
    /* Do not insert if there is no list selected */
    if (!blackListState.selectedBlackList?.list) {
      return
    }
    /* Do not insert repeated values */
    const filteredList = blackListState.fileListData.fileListData.filter((item) => {
      return !blackListState.selectedBlackList?.list?.includes(item)
    })

    if (filteredList.length > 0) {
      startLoading()
      dispatch(setBlackListList({
        value: [
          ...blackListState.selectedBlackList?.list,
          ...blackListState.fileListData.fileListTableValues.fileListValidData,
        ]
      }))

      dispatch(executeAlert({
        message: 'Lista adicionada com sucesso!',
        type: 'success'
      }))
      stopLoading()
    }
  }, [startLoading, stopLoading, blackListState.fileListData, blackListState.selectedBlackList?.list, dispatch])

  const selectBlackList = useCallback((data: {
    blackList?: ISelectedBlackList
  }) => {
    dispatch(setSelectedBlackList({
      blackList: data.blackList
    }))
  }, [dispatch])

  const selectBlackListToEdit = useCallback((data: {
    blackListId: string
  }) => {
    startLoading()
    findBlackListByIdApi({
      blackListId: data.blackListId
    }).then((blackList) => {
      selectBlackList({
        blackList: blackList
      })
      stopLoading()
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })

  }, [dispatch, findBlackListByIdApi, selectBlackList, startLoading, stopLoading])

  const addItemToList = useCallback((data: {
    item: string
  }) => {
    const cnpjValidate = validateCnpj(FNPadronizarCNPJ(data.item))
    const cnpjPadronizado = FNPadronizarCNPJ(data.item)

    if (!cnpjValidate) {
      dispatch(executeAlert({
        message: 'CNPJ inválido',
        type: 'error'
      }))
    }

    if (cnpjValidate) {
      if (blackListState.selectedBlackList!.list.includes(cnpjPadronizado)) {
        dispatch(executeAlert({
          message: 'Este CNPJ já se encontra na lista de deduplicação.',
          type: 'error'
        }))
      }

      if (blackListState.selectedBlackList!.list.includes(cnpjPadronizado) === false) {
        dispatch(
          addItemBlackListList({
            value: cnpjPadronizado
          })
        )

        dispatch(executeAlert({
          message: 'CNPJ adicionado com sucesso.',
          type: 'success'
        }))
      }
    }
  }, [dispatch, blackListState.selectedBlackList, validateCnpj])

  const editItemList = useCallback((data: {
    value: string,
    newValue: string
  }) => {
    const newCnpjValidate = validateCnpj(FNPadronizarCNPJ(data.newValue))
    const novoCnpjPadronizado = FNPadronizarCNPJ(data.newValue)

    if (!newCnpjValidate) {
      dispatch(executeAlert({
        message: 'CNPJ inválido',
        type: 'error'
      }))
    }

    if (newCnpjValidate) {
      if (blackListState.selectedBlackList!.list.includes(novoCnpjPadronizado)) {
        dispatch(executeAlert({
          message: 'Este CNPJ já se encontra na lista de deduplicação.',
          type: 'error'
        }))
      }

      if (blackListState.selectedBlackList!.list.includes(novoCnpjPadronizado) === false) {
        dispatch(
          editItemBlackListList({
            value: data.value,
            newValue: novoCnpjPadronizado
          })
        )
        dispatch(executeAlert({
          message: 'CNPJ atualizado com sucesso.',
          type: 'success'
        }))
      }
    }

  }, [dispatch, validateCnpj, blackListState.selectedBlackList])

  const removeItemList = useCallback((data: {
    value: string
  }) => {
    dispatch(removeItemBlackListList({
      value: data.value
    }))
    dispatch(executeAlert({
      message: 'CNPJ removido com sucesso.',
      type: 'success'
    }))
  }, [dispatch])

  const setupBlackList = useCallback(() => {
    startLoading()
    findBlackListByCompanyId({
      companyId: userState.companyId
    }).then((blackLists) => {
      dispatch(setBlackLists({
        blackLists: blackLists
      }))
      stopLoading()
    }).catch((error) => {
      dispatch(executeAlert({
        message: error.message,
        type: 'error'
      }))
      stopLoading()
    })
  }, [findBlackListByCompanyId, userState.companyId, dispatch, startLoading, stopLoading])

  const closeModalDeleteBlackList = useCallback(() => {
    dispatch(setBlackListFormDialogOpenState({
      openFormDialog: false
    }))
    /* reset form */
    reset()
  }, [dispatch, reset])

  const createBlackList = useCallback(() => {
    closeModalDeleteBlackList()

    const validateBlackList = validateBlackListForm({
      name: blackListState.selectedBlackList?.name ?? '',
    })

    if (validateBlackList.length > 0) {
      dispatch(executeAlert({
        message: validateBlackList[0].fieldError.message,
        type: 'error'
      }))

      dispatch(setBlackListFormErrors({
        errors: validateBlackList
      }))

      return
    } else {
      startLoading()
      createBlackListApi({
        createdBy: userState.id,
        name: blackListState.selectedBlackList?.name ?? '',
      }).then((blackList) => {
        selectBlackList({
          blackList: blackList
        })
        setupBlackList()
      }).finally(() => {
        stopLoading()
      })
    }

  }, [closeModalDeleteBlackList, blackListState.selectedBlackList?.name, dispatch, startLoading, createBlackListApi, userState.id, selectBlackList, setupBlackList, stopLoading])

  const updateBlackList = useCallback(() => {
    const validateBlackList = validateBlackListForm({
      name: blackListState.selectedBlackList?.name ?? '',
    })

    if (validateBlackList.length > 0) {
      dispatch(executeAlert({
        message: validateBlackList[0].fieldError.message,
        type: 'error'
      }))

      dispatch(setBlackListFormErrors({
        errors: validateBlackList
      }))

      return
    } else {
      startLoading()
      updateBlackListApi({
        id: blackListState.selectedBlackList?.id ?? '',
        name: blackListState.selectedBlackList?.name ?? '',
        list: blackListState.selectedBlackList?.list ?? [],
      }).then((blackList) => {
        dispatch(executeAlert({
          message: 'Alterações salvas.',
          type: 'success'
        }))

        selectBlackList({
          blackList: blackList
        })

        setupBlackList()
      }).finally(() => {
        stopLoading()
      })
    }

  }, [blackListState.selectedBlackList?.id, blackListState.selectedBlackList?.list, blackListState.selectedBlackList?.name, dispatch, selectBlackList, setupBlackList, startLoading, stopLoading, updateBlackListApi])

  const activeBlackListStatus = useCallback((data: {
    status: boolean
  }) => {
    dispatch(setBlackListStatus({
      status: data.status
    }))
  }, [dispatch])

  const disableBlackListStatus = useCallback((data: {
    status: boolean
  }) => {
    dispatch(setBlackListStatus({
      status: data.status
    }))
  }, [dispatch])

  const changeBlackListStatus = useCallback(() => {
    const validateBlackList = validateBlackListForm({
      name: blackListState.selectedBlackList?.name ?? '',
    })

    if (validateBlackList.length > 0) {
      dispatch(executeAlert({
        message: validateBlackList[0].fieldError.message,
        type: 'error'
      }))

      dispatch(setBlackListFormErrors({
        errors: validateBlackList
      }))

      return
    } else {
      startLoading()
      changeBlackListStatusApi({
        id: blackListState.selectedBlackList?.id ?? '',
        status: blackListState.selectedBlackList?.status ?? true
      }).then((blackList) => {
        if (blackList.status === true) {
          dispatch(executeAlert({
            message: 'Lista ativa.',
            type: 'success'
          }))

          selectBlackList({
            blackList: blackList
          })

          setupBlackList()
        }

        if (blackList.status === false) {
          dispatch(executeAlert({
            message: 'Lista desativada.',
            type: 'success'
          }))

          selectBlackList({
            blackList: blackList
          })

          setupBlackList()
        }
      }).finally(() => {
        stopLoading()
      })
    }
  }, [blackListState.selectedBlackList?.name, blackListState.selectedBlackList?.id, blackListState.selectedBlackList?.status, dispatch, startLoading, changeBlackListStatusApi, selectBlackList, setupBlackList, stopLoading])

  const openModalDeleteBlackList = useCallback(() => {
    selectBlackList({
      blackList: {
        createdAt: new Date(),
        createdBy: '',
        id: '',
        list: [],
        name: '',
        updatedAt: new Date(),
        status: true
      }
    })

    dispatch(setBlackListFormDialogOpenState({
      openFormDialog: true
    }))

  }, [dispatch, selectBlackList])

  const cleanFileData = useCallback(() => {
    dispatch(setBlackListFileData({
      fileData: {
        columns: [],
        data: [],
        name: '',
        size: '',
        type: ''
      }
    }))

    dispatch(setBlackListFileDataList({
      value: []
    }))

    dispatch(setBlackListDistinctValues({
      distinctValues: 0
    }))

    dispatch(setBlackListValidValues({
      validValues: 0
    }))

    dispatch(setBlackListSelectedColumn({
      selectedColumn: ''
    }))

  }, [dispatch])

  const verifyBlackListFileData = useCallback(() => {
    startLoading()
    const validCnpjs = blackListState.fileListData.fileListTableValues.fileListValidData
    const invalidCnpjs = blackListState.fileListData.fileListTableValues.fileListInvalidData

    if (validCnpjs.length < 1 && invalidCnpjs.length > 0) {
      stopLoading()
      openModal({
        content: <>
          <Box p={3}>
            <Grid container alignItems={"center"} flexDirection="column">
              <Grid item>
                <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                  A coluna <strong>{blackListState.matchCode.selectedColumn}</strong> não possui CNPJs válidos.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                  Por favor, selecione uma coluna com CNPJs válidos, ou outro arquivo.
                </Typography>
              </Grid>
              <Grid item>
                <Box my={3}>
                  <Button variant="contained" sx={{ mt: 2 }} onClick={() => {
                    closeModal()
                  }}>
                    OK
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      })
      return false
    } else {
      stopLoading()
      return true
    }
  }, [startLoading, stopLoading, blackListState.fileListData.fileListTableValues.fileListInvalidData, blackListState.fileListData.fileListTableValues.fileListValidData, blackListState.matchCode.selectedColumn, closeModal, openModal])

  const setResetSelectedBlackList = useCallback(() => {
    dispatch(resetSelectedBlackListState())
  }, [dispatch])

  return {
    findCnpjKeyInArray,
    selectColumn,
    setFile,
    setName,
    reset,
    addFileListToBlackList,
    selectBlackList,
    addItemToList,
    selectBlackListToEdit,
    createBlackList,
    setupBlackList,
    updateBlackList,
    editItemList,
    removeItemList,
    activeBlackListStatus,
    disableBlackListStatus,
    changeBlackListStatus,
    openModalDeleteBlackList,
    closeModalDeleteBlackList,
    cleanFileData,
    verifyBlackListFileData,
    setResetSelectedBlackList
  }
}