import { ListItemButton, styled } from "@mui/material";

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  '&': {
    background: 'rgba(114, 57, 137, 0.04)',
    minHeight: '200px',
    transition: 'all 0.1s',
    '& *': {
      transition: 'all 0.1s',
    }
  },
  '&:hover': {
    color: theme.palette.primary.main,
    '&': {
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    }
  },

}));