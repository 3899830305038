import { MainRouter } from "./router/main";
import { ThemeProvider } from '@mui/material/styles'
import { theme } from "./styles";
import { CssBaseline } from '@mui/material'
import './assets/css/image.css'
import { ToastAlert } from "./toast-alert";
import { LoadingContainer } from "./loading-container/LoadingContainer";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";
import { useAppSelector } from "../application/states/hooks";

function FallBackError() {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  useEffect(() => {
    window.location.href = '/unknown-error'

  }, [])
  return (
    <>
    </>
  )
}

function App() {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const { company } = useAppSelector((state) => state.company)

  if (company?.resale === true || company?.resaleId) {
    if (resaleConfig) {
      // Theme colors
      // Primary colors
      if (resaleConfig.theme.colors.primary.main !== '' && resaleConfig.theme.colors.primary.main !== undefined) {
        theme.palette.primary.main = resaleConfig.theme.colors.primary.main
        if (theme.components?.MuiCssBaseline?.styleOverrides) {
          theme.components!.MuiCssBaseline!.styleOverrides = {
            '&::-webkit-scrollbar-track': {
              background: theme.palette.background.default,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: theme.palette.secondary.main
            }
          }
        }
      }
      if (resaleConfig.theme.colors.primary.contrastColor !== '' && resaleConfig.theme.colors.primary.contrastColor !== undefined) {
        theme.palette.primary.contrastText = resaleConfig.theme.colors.primary.contrastColor!
      }

      // Secondary colors
      if (resaleConfig.theme.colors.secondary.main !== '' && resaleConfig.theme.colors.secondary.main !== undefined) {
        theme.palette.secondary.main = resaleConfig.theme.colors.secondary.main
      }
      if (resaleConfig.theme.colors.secondary.contrastColor !== '' && resaleConfig.theme.colors.secondary.contrastColor !== undefined) {
        theme.palette.secondary.contrastText = resaleConfig.theme.colors.secondary.contrastColor!
      }

      // Background colors
      if (resaleConfig.theme.colors.background.body.main !== '' && resaleConfig.theme.colors.background.body.main !== undefined) {
        theme.palette.background.default = resaleConfig.theme.colors.background.body.main
      }
      if (resaleConfig.theme.colors.background.paper.main !== '' && resaleConfig.theme.colors.background.paper.main !== undefined) {
        theme.palette.background.paper = resaleConfig.theme.colors.background.body.main
      }

      // Font Family
      if (resaleConfig.theme.fontFamily !== '' && resaleConfig.theme.fontFamily !== undefined) {
        theme.typography.body1.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.body2.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.button.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.caption.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h1.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h2.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h3.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h4.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h5.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.h6.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.subtitle1.fontFamily = resaleConfig.theme.fontFamily
        theme.typography.subtitle2.fontFamily = resaleConfig.theme.fontFamily
      }
    }
  }

  return (
    <>
      <ErrorBoundary fallback={<FallBackError />}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastAlert />
          <LoadingContainer />
          <MainRouter />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;