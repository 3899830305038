import { useCallback } from "react"
import { IEconomicActivity } from "../../application/models/IEconomicActivity"
import { useAxiosInstances } from "../axios-instance"

export const useEconomicActivityApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findEconomicActivityBySearchTerm = useCallback((data: {
    searchTerm: string
  }): Promise<IEconomicActivity[]> => {
    return axiosAuthInstance.get('/economic-activity', {
      params: {
        searchTerm: data.searchTerm
      }
    }).then((response) => {
      const result = response.data?.result?.economicActivityList
      if (result) {
        return result
      } else {
        throw new Error('Erro ao buscar atividade econômica')
      }
    }).catch((error) => {
      throw error
    })
  }, [axiosAuthInstance])

  const findEconomicActivityByValue = useCallback((data: {
    value: string
  }): Promise<IEconomicActivity> => {
    return axiosAuthInstance.get('/economic-activity/value', {
      params: {
        value: data.value
      }
    }).then((response) => {
      const result = response.data?.result?.economicActivity
      if (result) {
        return result
      } else {
        throw new Error('Erro ao buscar atividade econômica')
      }
    }).catch((error) => {
      throw error
    })
  }, [axiosAuthInstance])

  return {
    findEconomicActivityBySearchTerm,
    findEconomicActivityByValue
  }
}