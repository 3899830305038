import { tooltipClasses, Tooltip, styled, TooltipProps } from "@mui/material";

export const CompanyTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid #dadde9',
        borderRadius: 20,
        width: 700,
        maxWidth: '100%',
        backgroundColor: 'rgba(255,255,255,1)',
        color: 'black'
    },
}));