import { Box, Grid } from "@mui/material"
import { ExportDownloadModal } from "./project-download/ProjectDownload"
import { ProjectExportsTable } from "./project-exports-table"
import { useEffect } from 'react'
import { useAppSelector } from "../../../../application/states/hooks"

export const ProjectExports = () => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Extrações`
  }, [resaleConfig])

  return (
    <>
      <Box p={3}>
        <Grid container spacing={5}>
          {/* <Grid item xs={12}>
            <ProjectExportFilterForm />
          </Grid> */}
          <Grid item xs={12}>
            <ProjectExportsTable />
          </Grid>
        </Grid>
      </Box>
      <ExportDownloadModal />
    </>
  )
}


