export const formatTelefoneBrazil = (telefone: string): string => {
  return telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
}

export const anonPhone = (telefone: string): string => {
  const regexSelectNumbers = /\d/g;
  return telefone.replaceAll(regexSelectNumbers, '*');
}

export const onlyNumbers = (value: string): string => {
  return value.replace(/\D/g, '')
}