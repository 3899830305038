import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeSubscription } from "../../../application/models"
import { IBill } from "../../../application/models/IBill"

export const useBackofficeSubscriptionApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findCompanySubscriptionApi = useCallback((data: {
    companyId: string
  }): Promise<IBackofficeSubscription> => {
    return axiosAuthInstance.get('/subscription/find-company-subscription', {
      params: {
        companyId: data.companyId
      }
    }).then((response) => {
      const subscription = response.data?.result?.subscription
      if (subscription) {
        return subscription
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Subscription não encontrada'
        }
      }
    })
  }, [axiosAuthInstance])

  const getSubscriptionUsage = useCallback((data: {
    subscriptionId: string,
    date?: Date
  }): Promise<{
    "total": number,
    "usedCredits": number,
    "bill": IBill
  }> => {
    return axiosAuthInstance.get('/subscription/usage', {
      params: {
        subscriptionId: data.subscriptionId,
        date: data.date
      }
    }).then((response) => {
      const usage = response.data?.result
      if (usage) {
        return {
          total: usage.total,
          usedCredits: usage.usedCredits,
          bill: usage.bill
        }
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Subscription não encontrada'
        }
      }
    })
  }, [axiosAuthInstance])

  const updateSubscriptionApi = useCallback((data: {
    subscriptionId: string
    sellerId?: string
    name?: string,
    partnerCols?: boolean
    ranges?: {
      min: number
      max?: number
      fixedPrice?: number
      pricePerRegister?: number
    }[]
  }): Promise<IBackofficeSubscription> => {
    return axiosAuthInstance.put('/subscription', data).then((response) => {
      const subscription = response.data?.result?.subscription
      if (subscription) {
        return subscription
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Subscription não encontrada'
        }
      }
    })
  }, [axiosAuthInstance])

  const updateBillApi = useCallback((data: {
    id: string
    bonus: number,
    subscriptionId: string
  }): Promise<IBill> => {
    return axiosAuthInstance.put('/subscription/bill', data).then((response) => {
      const bill = response.data?.result?.bill
      if (bill) {
        return bill
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Bill não encontrada'
        }
      }
    })
  }, [axiosAuthInstance])

  return {
    findCompanySubscriptionApi,
    getSubscriptionUsage,
    updateSubscriptionApi,
    updateBillApi
  }
}