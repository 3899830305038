import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Slide, TextField, Toolbar, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header/FilterHeader"
import { SegmentosFilterPreview } from "./segmentos-filter-preview"
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApartmentIcon from '@mui/icons-material/Apartment'
import { forwardRef, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { TransitionProps } from "@mui/material/transitions";
import { useProjectService } from "../../../../../../use-cases/project";
import { useSegmentosService } from "../../../../../../use-cases/segmentos";
import { SegmentosTooltip } from "./segmento-tooltip";
import Chip from '@mui/material/Chip';
import InfoIcon from '@mui/icons-material/Info';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const SegmentosFilter = () => {
  const [open, setOpen] = useState(false)
  const segmentosState = useAppSelector((state) => state.segmentos)
  const segmentosFilterState = useAppSelector((state) => state.project.selectedFilter?.segmentos)
  const { addSegmentoFilter } = useProjectService()
  const { setSearchTerm, setSegmentosList } = useSegmentosService()
  const projectState = useAppSelector((state) => state.project)

  useEffect(() => {
    setSegmentosList()
  }, [setSegmentosList])

  const handleSegmentosFilterClose = () => {
    setOpen(false)
    setSearchTerm({
      searchTerm: ''
    })
  }

  return (
    <>
      <FilterHeader title="Segmentos" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Nesta opção nós temos o agrupamento de todas as empresas em um único segmento. Facilita a busca de todas as empresas do mesmo ramo com um único clique!
        </Typography>
        <Typography>
          Exemplo: Alimentação: É o agrupamento de restaurantes / lanchonetes / cantinas etc.
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão considerados todos os sub grupos na contagem.
        </Typography>
      </>} />
      <Box my={3} mt={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button onClick={() => {
              setOpen(true)
            }} fullWidth endIcon={<>
              <AddIcon />
            </>}>
              <Typography variant="h6" fontWeight="bold">

                Clique para adicionar um novo segmento
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <SegmentosFilterPreview />
          </Grid>
        </Grid>
      </Box >
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleSegmentosFilterClose()
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform="uppercase">
              Selecionar segmento
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              handleSegmentosFilterClose()
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                value={segmentosState.searchTerm}
                onChange={(e) => {
                  setSearchTerm({
                    searchTerm: e.target.value
                  })
                }}
                fullWidth
                label="Digite o nome do segmento"
                placeholder="Digite o nome do segmento"
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <List >
              {
                segmentosState.segmentosList.map((segmento, index) => {
                  if (!segmento.segmento?.toUpperCase().includes(segmentosState?.searchTerm?.toUpperCase())) {
                    return false
                  }

                  if (projectState.selectedFilter?.segmentos?.includes(segmento)) {
                    return false
                  }

                  return (<>
                    <div key={index}>
                      <ListItem
                        style={{
                          padding: 0
                        }}
                      >
                        <ListItemButton onClick={(e) => {
                          addSegmentoFilter({
                            segmento: segmento
                          })
                        }}>
                          <ListItemText primary={segmento.segmento?.toUpperCase()} />
                          <SegmentosTooltip
                            title={<Box onClick={(e) => {
                              e.stopPropagation()
                            }} style={{
                              maxHeight: '500px',
                              overflowY: 'scroll',
                            }} p={4}>
                              <div>
                                <Typography variant="h5" color="primary" fontWeight={"bold"}>
                                  Segmento: {segmento.segmento?.toUpperCase()}
                                </Typography>
                              </div>
                              <Grid container spacing={1}>
                                {
                                  segmento.cnaesWithDescription?.map((subSegmento) => {
                                    return (<>
                                      <Grid item>
                                        <Chip
                                          sx={{
                                            height: 'auto',
                                            '& .MuiChip-label': {
                                              display: 'block',
                                              whiteSpace: 'normal',
                                              padding: '10px 15px',
                                              lineHeight: '1.5'
                                            },
                                          }}
                                          avatar={<ApartmentIcon />}
                                          label={subSegmento}
                                        />
                                      </Grid>
                                    </>)
                                  })
                                }
                              </Grid>
                            </Box>
                            }
                            placement="right"
                          >
                            <Box>
                              <InfoIcon />
                            </Box>
                          </SegmentosTooltip>
                        </ ListItemButton>
                      </ListItem>
                      <Divider />
                    </div>
                  </>)
                })
              }

            </List>
          </Grid>
          {
            (segmentosFilterState?.length ?? 0) > 0 && <Grid xs={6}>
              <Box p={3} my={3}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                      Segmentos selecionados
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SegmentosFilterPreview />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>

          }


        </Grid>



      </Dialog>
    </>
  )
}