import { BubbleChart } from "@mui/icons-material"
import { Avatar, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { IProjectResume } from "../../../../../application/states/project"
import { useProjectService } from "../../../../../use-cases/project"
import { ListItemButtonStyled } from "./ListItemButtonStyled"
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { useUserApi } from "../../../../../infra"
import MoreVertIcon from '@mui/icons-material/MoreVert';
ChartJS.register(ArcElement, ChartTooltip, Legend);
export const data = {

};

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     border: '1px solid #dadde9',
//     borderRadius: 20,
//     width: 700,
//     maxWidth: '100%',
//     backgroundColor: 'rgba(255,255,255,0.9)'
//   },
// }));


export const ProjectCard = (projectResume: IProjectResume) => {
  const { openProject, projectIsExporting, getCurrentProjectReport, updateProjectStatus, openCreateProjectForm, setProjectName } = useProjectService()
  const { findById } = useUserApi()
  const [isExporting, setIsExporting] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null);
  };

  useEffect(() => {
    projectIsExporting({
      projectId: projectResume.id,
    }).then((result) => {
      if (result) {
        setIsExporting(true)
        let keepGoing = true
        const interval = setInterval(() => {
          projectIsExporting({
            projectId: projectResume.id,
          }).then((result) => {
            if (!result && keepGoing) {
              setIsExporting(false)
              keepGoing = false
              clearInterval(interval)
            } else {
              setIsExporting(true)
            }
          })
        }, 3000)
      } else {
        getCurrentProjectReport({
          projectId: projectResume.id,
        }).then((report) => {

        })
      }
    })
  }, [projectResume.id, projectIsExporting, getCurrentProjectReport])


  const [createdByName, setCreatedByName] = useState('')

  useEffect(() => {
    if (projectResume.createdBy) {
      findById({
        userId: projectResume.createdBy
      }).then((user) => {
        setCreatedByName(user?.name ?? '')
      })
    }
  }, [projectResume.createdBy, findById])

  const theme = useTheme()

  return (
    <>
      {/* <HtmlTooltip
        placement="left"
        title={
          <>
            {
              isExporting ? (
                <>
                  <Box p={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary" fontWeight="bold">
                          Por que o projeto está bloqueado?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="black" fontWeight="bold">
                          O projeto está sendo exportado, aguarde alguns instantes.
                        </Typography>
                      </Grid>
                    </Grid>

                  </Box>
                </>
              ) : <Box p={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box my={2}>
                      <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} variant="h5">
                        Informações gerais do projeto:
                      </Typography>
                    </Box>
                  </Grid >
                  <Grid xs={12}>
                    <Grid container>
                      {
                        data ? (
                          <Grid container spacing={2}>

                            <Grid item xs={12}>
                              <Typography align="center" fontWeight="bold" variant="h5" color="primary">
                                Exportados
                              </Typography>
                              <Box p={2}>
                                <Grid container justifyContent="center">
                                  <Grid item>
                                    <Pie color="white" data={data} />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Grid container justifyContent={"center"} spacing={2}>
                                <Grid item xs={12}>
                                  <Typography align="center" fontWeight="bold">
                                    <span style={{
                                      color: 'rgba(255, 99, 132, 1)',
                                    }}>Exportados:</span>  <span style={{
                                      color: 'black',
                                      fontWeight: 'bold'
                                    }}>{data.datasets[0].data[0].toLocaleString('pt-BR')}</span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography align="center" fontWeight="bold">
                                    <span style={{
                                      color: 'rgba(54, 162, 235, 1)'
                                    }}>Disponíveis:</span>  <span style={{
                                      fontWeight: 'bold',
                                      color: 'black'
                                    }}>
                                      {data.datasets[0].data[1].toLocaleString('pt-BR')}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                            <Grid item xs={12}>
                              <Divider variant="middle" />
                            </Grid>

                          </Grid>
                        ) : null
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={5}>
                      <Button onClick={() => {
                        openProject({
                          projectId: projectResume.id
                        })
                      }} fullWidth variant="contained">
                        <Typography fontWeight={"bold"}>
                          Ver mais
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid >
              </Box >
            }

          </>
        }
      > */}
      <ListItem

      // secondaryAction={
      //   <IconButton edge="end" aria-label="delete">
      //     <DeleteIcon />
      //   </IconButton>
      // }
      >
        <ListItemButtonStyled
          disabled={isExporting}
          onClick={() => {
            openProject({
              projectId: projectResume.id
            })
          }}>
          <ListItemAvatar>
            <Avatar>
              <BubbleChart />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<>
              <Typography style={{
                marginBottom: '15px'
              }} fontWeight={"bold"}>
                {projectResume.name} {projectResume.active ? null : <span style={{
                  color: theme.palette.secondary.main,
                  fontWeight: 'bold'
                }}>- (Arquivado)</span>

                }
              </Typography>
            </>}
            secondary={<>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Criado por: <strong>{createdByName}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Criado em: <strong>{new Date(projectResume.createdAt).toLocaleDateString('pt-BR')} </strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Clique para abrir o projeto
                  </Typography>
                </Grid>
                <div>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '3%',
                      right: '3%'
                    }}
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >

                    <MenuItem onClick={(e) => {
                      e.stopPropagation()
                      setProjectName({
                        name: projectResume.name + ' - Cópia '
                      })
                      openCreateProjectForm({
                        action: 'CLONE',
                        projectToCLone: projectResume.id
                      })
                      handleClose(e)
                    }}>Duplicar projeto</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation()
                      setProjectName({
                        name: projectResume.name
                      })
                      openCreateProjectForm({
                        action: 'EDIT',
                        projectToCLone: projectResume.id
                      })
                      handleClose(e)
                    }}>Renomear projeto</MenuItem>
                    <MenuItem onClick={(e) => {
                      e.stopPropagation()
                      updateProjectStatus({
                        projectId: projectResume.id,
                        status: !projectResume.active
                      })
                      handleClose(e)
                    }}>
                      {projectResume.active ? (
                        <>
                          Arquivar projeto
                        </>
                      ) : (
                        <>
                          Restaurar projeto
                        </>
                      )}
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </>}
          />
        </ListItemButtonStyled>
      </ListItem>
      {/* </HtmlTooltip > */}

    </>
  )
}