import { Box, Grid, Typography } from "@mui/material"


import { useAppSelector } from "../../../../../../application/states/hooks";
import { HeatMap } from "./HeatMap";

// import Mock from './mock-1.json'


export const GeoLocationSection = () => {
  const geoHashes = useAppSelector((state) => state.project.projectReport?.generalData.heatmap.buckets)

  return <>
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary" fontWeight="bold">
                Mapa de calor
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                (geoHashes?.length ?? 0) === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    Não há dados para exibir
                  </Typography>
                ) : <>
                  <HeatMap geoHashes={geoHashes} />
                </>

              }

            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Box>

  </>
}