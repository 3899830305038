import { FormControlLabel, Switch } from "@mui/material"
import { useBackofficeService } from "../../../../../../../../../use-cases/backoffice"
import { useAppSelector } from "../../../../../../../../../application/states/hooks"

export const PopupCreateNotification = () => {

    const { selectNotification } = useBackofficeService()
    const notificationState = useAppSelector((state) => state.backofficeNotification)
    const userState = useAppSelector((state) => state.user)

    return <>
        <FormControlLabel control={
            <Switch
                checked={notificationState.selectedNotification?.popup ?? false}
                onChange={(e) => {
                    if (notificationState.selectedNotification) {
                        selectNotification({
                            notification: {
                                ...notificationState.selectedNotification,
                                popup: e.target.checked
                            }
                        })
                    }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                value={notificationState.selectedNotification?.popup}
            />
        }
            label={'Popup'.toUpperCase()}
            disabled={userState.userType === 'SELLER'}
        />
    </>
}