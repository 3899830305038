import { Download } from "@mui/icons-material"
import { AppBar, Box, Button, Dialog, Fab, Slide, Toolbar, Typography } from "@mui/material"
import { CardPessoaJuridicaWithIcons } from "../card-pessoa-juridica"
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as React from 'react'
import { TransitionProps } from "@mui/material/transitions";
import { usePDF } from '@react-pdf/renderer';
import { useAppSelector } from "../../../../../application/states/hooks";
import { PDFGenerator } from "../pdf-generator";
import { useUnitSearchService } from "../../../../../use-cases/unit-search";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const UnitSearchPjDetailsPage = () => {
  const { setSearchTerm } = useUnitSearchService()
  const currentPj = useAppSelector((state) => state.unitSearch.currentPj)
  // eslint-disable-next-line
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const [instance, updateInstance] = usePDF({ document: <PDFGenerator currentPj={currentPj} image={resaleConfig?.img !== '' ? '' : undefined} /> });

  React.useEffect(() => {
    return () => {
      setSearchTerm({
        searchTerm: '',
      })
    }
  }, [
    setSearchTerm
  ])

  const cardRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  React.useEffect(() => {
    updateInstance(<PDFGenerator currentPj={currentPj} image={resaleConfig?.id !== '' ? '' : undefined}/>)
  }, [currentPj, resaleConfig?.id, updateInstance])

  const handleDownloadPdf = React.useCallback(() => {
    if (instance.error) return <div>Something went wrong: {instance.error}</div>;
    if (instance.url) {
      const link = document.createElement('a');
      link.href = instance.url;
      link.setAttribute('download', `${currentPj!.pj.cnpj} - ${currentPj!.pj.razaosocial} `);
      document.body.appendChild(link);
      link.click();
    } else {
      return <div>Something went wrong: {instance.error}</div>;
    }

  }, [instance.error, instance.url, currentPj])
  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={() => {
          navigate(-2)
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              CONSULTA UNITÁRIA
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              navigate(-2)
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Fab
          sx={{
            '&:hover': {
              backgroundColor: darkenColor(theme.palette.primary.main, 10)
            }
          }}
          onClick={() => {
            handleDownloadPdf()
          }} style={{
            position: 'fixed',
            bottom: '5%',
            right: '5%'
          }} color="primary" aria-label="add">
          <Download
            style={{
              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
            }}
          />
        </Fab>
        <Box p={3}>
          <CardPessoaJuridicaWithIcons ref={cardRef} />
        </Box>
      </Dialog>

    </>
  )
}