import { View, Text, Image } from "@react-pdf/renderer"
import { BoxShadowView } from "./BoxShadowView"
import { ICurrentPj } from "./ICurrentPJ"
import { styles } from "./styles"
import { SectionTitle } from "./SectionTitle"
import locationIcon from './images/locationIcon.png'
import { formatCep } from "../card-pessoa-juridica"
import lurikLogo from './images/lurikLogo.jpg'

export const LocationSection = (props: {
    currentPj?: ICurrentPj,
    image?: string
}) => {
    return <>
        <SectionTitle icon={locationIcon} title='Localização' />
        {
            props.image === ''
                ? null
                : <Image src={lurikLogo} style={styles.backgroundLogo} />
        }
        <BoxShadowView>
            <View style={styles.columnContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Macrorregião:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.macrorregiao ? props.currentPj?.pj.macrorregiao : ` `}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.label}>UF:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.uf ? props.currentPj?.pj.uf : ` `}</Text>
                </View>
            </View>
            <View style={styles.columnContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Mesorregião:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.mesorregiao ? props.currentPj?.pj.mesorregiao : ` `}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.label}>Microrregião:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.microrregiao ? props.currentPj?.pj.microrregiao : ` `}</Text>
                </View>
            </View>
            <View style={styles.columnContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Cidade:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.cidade ? props.currentPj?.pj.cidade : ` `}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.label}>Bairro:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.bairro ? props.currentPj?.pj.bairro : ` `}</Text>
                </View>
            </View>
            <View style={styles.columnContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Endereço:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{`${props.currentPj?.pj.enderecotipo ?? ''} ${props.currentPj?.pj.enderecotitulo ?? ''} ${props.currentPj?.pj.enderecologradouro ?? ''} ${props.currentPj?.pj.endereconumero ?? ''}`}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.label}>Número:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.endereconumero ? props.currentPj?.pj.endereconumero : ` `}</Text>
                </View>
            </View>
            <View style={styles.columnContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Complemento:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.enderecocomplemento ? props.currentPj?.pj.enderecocomplemento : ` `}</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.label}>CEP:</Text>
                    <Text style={[styles.value, styles.highlightedValue]}>{props.currentPj?.pj.cep ? formatCep(props.currentPj?.pj.cep) : ` `}</Text>
                </View>
            </View>
        </BoxShadowView>
    </>
}