import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  0%{
    transform: rotate(0)
  }
  100%{
    transform: rotate(360deg)
  }
`

const tooltipAnimation = keyframes`
  0%{
    transform: translateY(-70%)
  }
  100%{
    transform: translateY(-120%)
  }
`

export const BottomNavigationStylesProvider = styled.div<{
  action: 'refresh' | 'export'
}>`
  position: fixed;
  bottom: 0;
  z-index: 49;

  & .wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  & .navbar {
  position: relative;
  height: 80px;
  width: 100vw;
  padding: 0 35px 0 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
      background-color: white;
  box-shadow: 0 10px 20px rgba(82, 74, 74, 0.3);
  }

  @media(max-width: 991px){
    & .navbar{
        justify-content: space-between
    }
  }

  & .circle {
  transition: 0.3s all;
  cursor: pointer;
  position: absolute;
  left: 50%;;
  transform: translateX(-50%);
  bottom: calc(80px - (60px / 2));
  margin-bottom: 0;
  height: 60px;
  width: 60px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  & .veles-tooltip{
    animation: ${tooltipAnimation} 0.2s;
    display: none;
    position: absolute;
    top: 0;
    z-index: 30000;
    transform: translateY(-120%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    background: #000000;
  }
}
& .circle:hover .veles-tooltip{
  display: block;
}


  & .circle .plus-icon {
  color: white;
  font-size: 27px;
  transition: opacity 0.3s, transform 0.2s;
  }

  & .circle:hover .plus-icon {
  transform: rotate(360deg);
  opacity: 0;
  }

  & .circle .social {
  position: absolute;
  top: 195px;
  color: white;
  background-color: rgba(255, 212, 212, 0.308);
  height: 60px;
  width: 60px;
  margin-bottom: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
  }

  & .circle .social:nth-child(2) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s ease;
  }

  & .circle .social:nth-child(3) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s 0.1s ease;
  }

  & .circle .social:nth-child(4) {
  opacity: 0;
  transition: opacity 0.3s, top 0.5s 0.22s ease;
  margin-bottom: 0px;
  }

  & .circle:hover .social:nth-child(2) {
  opacity: 1;
  top: 5px;
  }

  & .circle:hover .social:nth-child(3) {
  opacity: 1;
  top: calc(5px + 60px + 5px);
  }

  & .circle:hover .social:nth-child(4) {
  opacity: 1;
  top: calc(5px + 60px + 5px + 60px + 5px);
  }

  & .circleBackground {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(80px - (60px + 20) / 2);
  margin-bottom: 0;
  height: calc(80px + 20px);
  width: calc(80px + 20px);
  border-radius: calc((60px + 20) / 2);
  }

  & .bottom-navigation-item{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 120px;
    max-width: 25vw;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  @media(max-width: 380px){
    & .bottom-navigation-item{
        padding: 5px;
    }
  }

  & .icon-circle{
    animation: ${rotateAnimation} 0.3s;
  }
  & .icon-circle *{
    animation: ${rotateAnimation} 0.3s;
  }

`