import { Autocomplete, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemText, TextField } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import EditIcon from '@mui/icons-material/Edit';
import { useUserService } from "../../../../../use-cases/user";
import { useEffect, useState } from "react";
import React from "react";
import { userTypeOptions } from "../../../../assets/helper-assets/userTypeOptions";
import AddIcon from '@mui/icons-material/Add';
import { MuiFileInput } from "mui-file-input";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";

export const UserSection = () => {
  // Service
  const { selectUser, updateUser, createUser, setSelectedUserFileImage } = useUserService()
// States
  const userList = useAppSelector((state) => state.user.userList)
  const selectedUser = useAppSelector((state) => state.user.selectedUser)
  const file = useAppSelector((state) => state.user.selectedUser?.img)
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
// Use States
  const [open, setOpen] = React.useState(false);
  const [newFile, setNewFile] = useState<File | null>(null)
  const [nameInputError, setNameInputError] = React.useState(false)
  const [emailInputError, setEmailInputError] = React.useState(false)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações / Usuários`
  }, [resaleConfig])

  return (
    <>
      <Box>
        <Box m={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{
              '&:hover': {
                backgroundColor: darkenColor(theme.palette.primary.main, 15)
              },
              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
            }}
            onClick={() => {
              setOpen(true)
              selectUser({
                user: {
                  name: '',
                  email: '',
                  companyId: '',
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  id: '',
                  userType: 'OPERATOR',
                  status: true
                }
              })
            }}
          >
            Adicionar usuário
          </Button>
        </Box>
        <List>
          {
            userList.map((user) => {
              return (
                <>
                  <ListItem
                    button
                    secondaryAction={<>
                      <Button style={{
                        marginRight: '10px'
                      }} onClick={() => {
                        setOpen(true)
                        selectUser({
                          user: user
                        })
                      }} endIcon={
                        <EditIcon />
                      }>
                        Editar
                      </Button>
                      {/* <Button onClick={() => {
                        selectExportLayout({
                          selectedLayout: {
                            ...selectedExportLayout,
                            layoutColumns: selectedExportLayout.layoutColumns.filter((column) => {
                              return column.value !== item.value
                            })
                          }
                        })
                      }} endIcon={<>
                        <CloseIcon />
                      </>
                      }>
                        Excluir
                      </Button> */}
                    </>}


                  >
                    <ListItemText primary={user.name} secondary={`E-mail: ${user.email}`} />
                    <Divider variant="inset" component="li" />

                  </ListItem>
                </>
              )
            })
          }
        </List>
      </Box>
      <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => {
        setOpen(false)
      }}>
        <DialogTitle>
          {
            selectedUser?.id ? (
              <>
                Atualizar usuário
              </>
            ) : <>
              Criar usuário
            </>
          }
        </DialogTitle>
        <DialogContent>
          <Box my={3}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <TextField
                  error={nameInputError}
                  helperText={nameInputError ? 'Campo obrigatório' : ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={selectedUser?.name}
                  onChange={(e) => {
                    if (!selectedUser) return
                    selectUser({
                      user: {
                        ...selectedUser,
                        name: e.target.value
                      }
                    })
                  }}
                  autoFocus
                  id="name"
                  label="Nome"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={emailInputError}
                  helperText={emailInputError ? 'Campo obrigatório' : ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={selectedUser?.email}
                  onChange={(e) => {
                    if (!selectedUser) return
                    selectUser({
                      user: {
                        ...selectedUser,
                        email: e.target.value
                      }
                    })
                  }}
                  autoFocus
                  id="email"
                  label="E-mail"
                  fullWidth
                />
              </Grid>
              {
                selectedUser?.userType !== 'OWNER' ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={userTypeOptions.filter((option) => {
                        if (company?.master === true || company?.resale === true) {
                          return option
                        } else {
                          return option.value !== 'SELLER'
                        }
                      })}
                      value={userTypeOptions.find((option) => {
                        return option.value === selectedUser?.userType
                      })
                      }
                      onChange={(event: any, newValue: { label: string, value: string } | null) => {
                        if (newValue) {
                          if (!selectedUser) return

                          selectUser({
                            user: {
                              ...selectedUser,
                              userType: newValue.value as any
                            }
                          })
                        }
                      }}
                      renderInput={(props) =>
                        <TextField
                          {...props}
                          label="Digite ou clique para selecionar a extensão"
                        />
                      }
                    />
                  </Grid>
                ) : null
              }
              {
                selectedUser?.id ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={[
                        {
                          label: 'Ativo',
                          value: 'true'
                        }, {
                          label: 'Inativo',
                          value: 'false'
                        }
                      ]}
                      value={
                        selectedUser?.status ? {
                          label: 'Ativo',
                          value: 'true'
                        } : {
                          label: 'Inativo',
                          value: 'false'
                        }
                      }
                      onChange={(event: any, newValue: { label: string, value: string } | null) => {
                        if (newValue !== null) {
                          if (!selectedUser) return
                          selectUser({
                            user: {
                              ...selectedUser,
                              status: newValue.value === 'true' ? true : false
                            }
                          })
                        }
                      }}
                      renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar a extensão" />}
                    />
                  </Grid>
                ) : null
              }
              <Grid item xs={12}>
                <MuiFileInput
                  id="outlined-static"
                  value={file}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {
                    setSelectedUserFileImage(e)
                    setNewFile(e)
                  }}
                  label="Imagem"
                  placeholder="Selecione uma imagem"
                  getInputText={(file) => newFile ? newFile.name : 'Selecione uma imagem'}
                  InputProps={{
                    inputProps: {
                      accept: '.png, .jpeg, .jpg'
                    }
                  }}
                />
                {file !== null ?
                  <Grid container p={1} justifyContent={'center'}>
                    <Grid item pl={2} pr={2} pt={2} justifyContent={'center'} >
                      <Avatar src={selectedUser?.img} alt="preview" sx={{ width: 115, height: 115 }} />
                    </Grid>
                  </Grid>
                  : null}
              </Grid>

            </Grid>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          }}>Cancelar</Button>
          <Button onClick={() => {
            if (!selectedUser?.name) {
              setNameInputError(true)
              return
            } else {
              setNameInputError(false)
            }
            if (!selectedUser?.email) {
              setEmailInputError(true)
              return
            } else {
              setEmailInputError(false)
            }
            setOpen(false)
            if (selectedUser?.id) {
              updateUser()
            } else {
              createUser()
            }
          }}>
            {
              selectedUser?.id ? (
                <>
                  Atualizar
                </>
              ) : <>
                Criar
              </>
            }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}