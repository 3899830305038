import { Box, Divider, Grid } from "@mui/material"
import { ProjectReportResume } from "../../project-report-resume"
import { BusinessCharts } from "./business-charts"
import { PartnerCharts } from "./partner-charts"

export const GeneralDataSection = () => {

  return <>
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProjectReportResume />
        </Grid>
        <Grid item xs={12}>
          <BusinessCharts />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <PartnerCharts />
        </Grid>
      </Grid>
    </Box>
  </>
}