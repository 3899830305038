export const formatCnpj = (cnpj: string) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export const anonCnpj = (cnpj: string): string => {
  /* Show only 3 firsts and 3 lasts characters */
  /* Replace the others to '*' */
  const firstThree = cnpj.slice(0, 4);
  const lastThree = cnpj.slice(-3);
  const middle = cnpj.slice(4, -3);
  const regexSelectNumbers = /\d/g;
  const anonMiddle = middle.replaceAll(regexSelectNumbers, '*');
  return firstThree + anonMiddle + lastThree;

}

export const numericCnpj = (cnpj: string): string => {
  const numericCnpj = cnpj.replace(/\D/g, '')

  return numericCnpj
}