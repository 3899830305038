import { Paper, Grid, Box } from '@mui/material'
import LurikLogo from '../../../../assets/img/lurik-logo-nome.png'
import { useAppSelector } from '../../../../../application/states/hooks'

export const MainContainer = (props: { children: any }) => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  return (
    <Box p={5} style={{
      height: '100vh'
    }}>
      <Grid container justifyContent="center" alignItems="center" height={'100%'}>
        <Grid item xs={12} lg={8}>
          <Paper style={{
            borderRadius: '30px'
          }} elevation={3}>
            <Box p={5} style={{
              height: '100%'
            }}>
              <Grid container>
                <Grid item xs={12} textAlign={'center'}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={6}>
                      <img
                        src={resaleConfig?.id !== '' ? resaleConfig?.loginPageImg : LurikLogo}
                        alt={`${resaleConfig?.id !== '' ? resaleConfig?.name : 'Lurik'} Logo`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box my={3}>
                    {props.children}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )

} 