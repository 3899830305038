import { View } from "@react-pdf/renderer"
import { styles } from "./styles"

export const BoxShadowView = (props: { children: any }) => {
    return <>
        <View style={{
            position: 'relative'
        }}>
            <View style={styles.boxShadow}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, indexItem) => {
                    return (
                        <View style={{
                            position: 'absolute',
                            top: `${indexItem * 0.18}px`,
                            right: `${indexItem * 0.18}px`,
                            left: `${indexItem * 0.18}px`,
                            bottom: `${indexItem * 0.18}px`,
                            borderTop: `0.7px solid f${indexItem}f${indexItem}f${indexItem}`,
                            borderRight: `2.3px solid f${indexItem}f${indexItem}f${indexItem}`,
                            borderBottom: `2.3px solid f${indexItem}f${indexItem}f${indexItem}`,
                            borderLeft: `0.7px solid f${indexItem}f${indexItem}f${indexItem}`,
                            opacity: indexItem * 0.010,
                            zIndex: indexItem,
                        }}>
                        </View>
                    )
                })}
            </View>
            <View style={styles.boxShadowChildren}>
                {props.children}
            </View>
        </View>
    </>
}