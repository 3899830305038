import { useCallback } from "react"
import { resetAuthState, setToken } from "../../application/states/auth"
import { executeAlert } from "../../application/states/toast-alert"
import { useLoginApi } from "../../infra/login-api"
import { useLocalStorageService } from "../local-storage"
import { useNavigationService } from "../navigation"
import { useAppSelector, useAppDispatch } from "../../application/states/hooks"
import { setLoginEmail, setLoginPassword, resetLoginErrorsState, validateLoginForm, setLoginErrors, setLoginErrorForm, resetLoginState } from "../../application/states/login"
export const useLoginService = () => {
  const { signIn: signInApi } = useLoginApi()
  const login = useAppSelector((state) => state.login)
  const dispatch = useAppDispatch()
  const { setItem, removeItem } = useLocalStorageService()
  const { navigateTo } = useNavigationService()
  /* Setters */
  const setEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setLoginEmail({
      value: data.email
    }))
  }, [dispatch])

  const setPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setLoginPassword({
      value: data.password
    }))
  }, [dispatch])

  const signIn = useCallback(() => {
    dispatch(resetLoginErrorsState())
    const errors = validateLoginForm({
      email: login.loginModel.email,
      password: login.loginModel.password
    })
    if (errors.length > 0) {
      dispatch(setLoginErrors({
        errors: errors
      }))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
      dispatch(setLoginErrorForm({
        error: errors[0].fieldError
      }))
    } else {
      signInApi({
        email: login.loginModel.email,
        password: login.loginModel.password,
        domain: window.location.host
      }).then((response) => {
        if (response) {
          dispatch(resetLoginState())

          dispatch(setToken({
            token: response.token
          }))
          setItem({
            key: 'ACCESS_TOKEN',
            value: response.token
          })

          navigateTo({
            route: '/workspace'
          })
        }
      }).catch((error) => {
        dispatch(setLoginErrorForm({
          error: {
            message: error.message ?? 'Erro inesperado',
            type: 'error'
          }
        }))
        dispatch(executeAlert({
          message: error.message ?? 'Erro inesperado',
          type: 'error'
        }))
        removeItem({
          key: "ACCESS_TOKEN"
        })
      })
    }



  }, [
    dispatch,
    signInApi,
    setItem,
    removeItem,
    navigateTo,
    login.loginModel.email,
    login.loginModel.password,
  ])

  const signOut = useCallback(() => {
    removeItem({
      key: 'ACCESS_TOKEN'
    })
    dispatch(resetAuthState())
    navigateTo({
      route: '/authentication/login'
    })
  }, [removeItem, dispatch, navigateTo])

  return {
    signIn,
    signOut,
    setEmail,
    setPassword,
  }
}