import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, List, ListItem, ListItemText, Paper, Slide, TextField, Toolbar, Typography, emphasize } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { forwardRef, useEffect } from "react"
import AddIcon from '@mui/icons-material/Add';
import { TransitionProps } from "@mui/material/transitions";
import { ListItemButtonStyled } from "../../../project-list/project-card/ListItemButtonStyled";
import React, { useRef, useState } from 'react';
import { useLoadingService } from '../../../../../../use-cases/loading';
import './fileUploadStyle.css';
import readExcelFile from 'read-excel-file'
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useBlackListService } from "../../../../../../use-cases/black-list";
import FileIcon from '@mui/icons-material/FileUpload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { ColumnsDialog } from "./columns-dialog";
import { darkenColor, formatCnpj, onlyNumbers } from "../../../../../../utils";
import { useProjectService } from "../../../../../../use-cases/project";
import { BlacklistFilterPreview } from "./blacklist-filter-preview";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FNPadronizarCNPJ } from "../../../../../../use-cases/data-enrichment";
import { theme } from "../../../../../styles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

type FileUploadProps = {
  label: string;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RenderActualCNPJs(props: ListChildComponentProps) {
  const { index, style } = props;
  const blackListState = useAppSelector((state) => state.blackList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 !== 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCnpj(blackListState.selectedBlackList?.list[index] ?? '')} />
    </ListItem>
  );
}

function RenderNewCnpjs(props: ListChildComponentProps) {
  const { index, style } = props;
  const blackListState = useAppSelector((state) => state.blackList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 === 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCnpj(blackListState.fileListData.fileListTableValues.fileListValidData[index] ?? '')} />
    </ListItem>
  );
}

function RenderRepeatedCnpjs(props: ListChildComponentProps) {
  const { index, style } = props;
  const blackListState = useAppSelector((state) => state.blackList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 !== 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCnpj(blackListState.fileListData.fileListTableValues.fileListRepeatedData[index] ?? '')} />
    </ListItem>
  );
}

function RenderInvalidCnpjs(props: ListChildComponentProps) {
  const { index, style } = props;
  const blackListState = useAppSelector((state) => state.blackList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 === 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCnpj(blackListState.fileListData.fileListTableValues.fileListInvalidData[index] ?? '')} />
    </ListItem>
  );
}

export const BlackListFilter = () => {
  const [blackListGroupsFilterOpen, setBlackListGroupsFilterOpen] = useState(false)
  const [blackListImportFileOpen, setBlackListImportFileOpen] = useState(false)
  const [columnDialogOpen, setColumnDialogOpen] = useState(false)
  const [editListOpen, setEditListOpen] = useState(false)
  const blackListState = useAppSelector((state) => state.blackList)
  const {
    selectColumn,
    addFileListToBlackList,
    setName,
    addItemToList,
    selectBlackListToEdit,
    createBlackList,
    updateBlackList,
    removeItemList,
    editItemList,
    openModalDeleteBlackList,
    closeModalDeleteBlackList,
    cleanFileData,
    verifyBlackListFileData,
    setResetSelectedBlackList,
    setupBlackList
  } = useBlackListService()
  const { addBlackListFilter } = useProjectService()
  const projectState = useAppSelector((state) => state.project)
  const { company } = useAppSelector((state) => state.company)
  const [searchTerm, setSearchTerm] = useState('')
  const [cnpjSearchTerm, setCnpjSearchTerm] = useState('')
  const [addCnpjValue, setAddCnpjValue] = useState('');
  const [open, setOpen] = useState(false);
  const [oldCnpj, setOldCnpj] = useState('')
  const [newCnpj, setNewCnpj] = useState('')
  const [openBlackListPreview, setOpenBlackListPreview] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEconomicActivityFilterClose = () => {
    setBlackListGroupsFilterOpen(false)
    setSearchTerm('')
  }

  useEffect(() => {
    setupBlackList()
  }, [setupBlackList])

  return (<>
    <Dialog
      open={open}
      onClose={handleClose}
      hideBackdrop
    >
      <DialogTitle >Editar CNPJ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Digite abaixo o novo CNPJ atualizado.
        </DialogContentText>
        <Box mt={3}>
          <TextField
            InputLabelProps={{
              shrink: true
            }}
            autoFocus
            value={newCnpj}
            margin="dense"
            label="Digite o novo CNPJ"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setNewCnpj(e.target.value)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={() => {
          handleClose()
          editItemList({
            value: oldCnpj,
            newValue: FNPadronizarCNPJ(newCnpj)
          })
        }} autoFocus>Ok</Button>
      </DialogActions>
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={
      blackListState.openFormDialog
    } onClose={() => {
      closeModalDeleteBlackList()
    }}>
      <DialogTitle>
        Criar nova lista de deduplicação
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Escolha um nome para a lista de deduplicação
        </DialogContentText>
        <Box my={3}>
          <TextField
            error={!blackListState.blackListForm.fields.name.isValid}
            helperText={!blackListState.blackListForm.fields.name.isValid ? blackListState.blackListForm.fields.name.error?.message : ''}
            value={blackListState.selectedBlackList?.name}
            onChange={(e) => {
              setName({
                name: e.target.value
              })
            }}
            autoFocus
            id="nomeproj"
            label="Digite o nome da lista"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          closeModalDeleteBlackList()
        }}>Cancelar</Button>
        <Button onClick={() => {
          if (blackListState.blackListForm.action === 'create') {
            createBlackList()
            setEditListOpen(true)
          }
        }}>
          Criar
        </Button>
      </DialogActions>
    </Dialog>
    <FilterHeader title="Lista de deduplicação" description={<>
      <Typography style={{
        marginBottom: '10px'
      }}>
      </Typography>
      Essa opção permite que você exclua do levantamento quantitativo os CNPJs do projeto criado.
      <Typography>
      </Typography>
      <Typography>
        Clicando na opção "adicionar listas de deduplicação", é possível criar listas para inserir CNPJs em lotes ou inserir manualmente um único CNPJ em uma determinada lista, para que o mesmo seja desconsiderado no seu levantamento quantitativo.
      </Typography>
      <Typography>
        É possível selecionar listas com CNPJs a serem excluídos ou todas as listas, clicando na opção "incluir todas as listas".
      </Typography>
      <Typography>
        Caso nenhuma das opções seja marcada, não será excluído nenhum CNPJ das listas para deduplicação no levantamento quantitativo.
      </Typography>
    </>} />

    <Box my={3} mt={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={() => {
            setBlackListGroupsFilterOpen(true)
          }} fullWidth endIcon={<>
            <AddIcon />
          </>}>
            <Typography variant="h6" fontWeight="bold">
              Clique para adicionar listas de deduplicação
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <BlacklistFilterPreview />
        </Grid>
      </Grid>
    </Box >
    <Dialog
      fullScreen
      open={blackListGroupsFilterOpen}
      onClose={() => {
        handleEconomicActivityFilterClose()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>

          <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
            Selecionar listas de deduplicação
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            handleEconomicActivityFilterClose()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>

      <Box p={3}>
        <form onSubmit={(e) => {
          e.preventDefault()
          // searchLocations()
        }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Button onClick={(e) => {
                e.preventDefault()
                openModalDeleteBlackList()

              }} fullWidth startIcon={<>
                <AddIcon />
              </>}>
                Criar nova lista
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                  }
                }}
                onClick={(e) => {
                  for (let i = 0; i < blackListState.blackLists.length; i++) {
                    addBlackListFilter({
                      blackList: blackListState.blackLists[i].id
                    })
                  }
                }}
              >
                Incluir todas as listas
              </Button>
            </Grid>
            <Grid item xs={7}>
              <TextField
                // value={locationState.searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                }}
                InputProps={{
                  endAdornment: <>
                    <Button type="submit" onClick={() => {
                    }}>
                      Buscar
                    </Button>
                  </>
                }}
                fullWidth
                label="Digite o nome da lista"
                placeholder="Digite o nome da lista"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <List>
            {
              blackListState.blackLists.filter((blackList) => {
                if (searchTerm === '') {
                  return true
                }
                if (blackList.name.toUpperCase().includes(searchTerm.toLocaleUpperCase())) {
                  return true
                } else {
                  return false
                }
              }).map((blackList) => {
                return (<>
                  <ListItem secondaryAction={<>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        selectBlackListToEdit({
                          blackListId: blackList.id
                        })
                        setEditListOpen(true)
                      }}
                      startIcon={<>
                        <EditIcon />
                      </>}
                    >
                      Editar
                    </Button>
                  </>} button onClick={() => {
                    addBlackListFilter({
                      blackList: blackList.id
                    })

                  }}>
                    <ListItemText primary={blackList.name?.toUpperCase()} secondary={blackList.createdBy?.toUpperCase()} />
                  </ListItem>
                  <Divider />
                </>)
              })
            }
          </List>
        </Grid>
        {
          (((projectState?.selectedFilter?.blackLists?.length ?? 0) > 0) && <Grid xs={6}>
            <Box p={3} my={3}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                    Listas de deduplicação selecionadas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BlacklistFilterPreview />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          )}
      </Grid>
    </Dialog>
    <Dialog
      fullScreen
      open={editListOpen}
      onClose={() => {
        setResetSelectedBlackList()
        setEditListOpen(false)
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
            Editar lista de deduplicação
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            setResetSelectedBlackList()
            setEditListOpen(false)
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={3} style={{
        height: '100%',
        overflowY: 'scroll',
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome da lista"
              placeholder="Digite o nome da lista"
              value={blackListState.selectedBlackList?.name}
              onChange={(e) => {
                setName({
                  name: e.target.value
                })
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="uppercase"
                      color="primary"
                    >
                      Adicionar itens
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Digite o CNPJ que deseja adicionar"
                      placeholder="Digite o CNPJ que deseja adicionar"
                      value={addCnpjValue.length > 13 ? formatCnpj(addCnpjValue) : addCnpjValue}
                      onChange={(e) => {
                        let onlyCnpjNumbers = onlyNumbers(e.target.value)

                        if (onlyCnpjNumbers.length > 14) {
                          onlyCnpjNumbers = onlyCnpjNumbers.substring(0, 14)
                        }

                        setAddCnpjValue(onlyCnpjNumbers)
                      }}
                      InputProps={{
                        endAdornment: <>
                          <Button type="submit" onClick={() => {
                            addItemToList({
                              item: addCnpjValue
                            })
                          }}>
                            Adicionar
                          </Button>
                        </>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="uppercase"
                      align="center"
                      color="primary"
                    >
                      OU
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}>

                      <Button
                        startIcon={<FileIcon />}
                        variant="contained"
                        onClick={(e) => {
                          e.preventDefault()
                          setBlackListImportFileOpen(true)
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          },
                          color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                        }}
                      >
                        Importar arquivo
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Pesquisar CNPJ"
              placeholder="Digite o CNPJ que deseja pesquisar"
              fullWidth
              value={cnpjSearchTerm.length > 13 ? formatCnpj(cnpjSearchTerm) : cnpjSearchTerm}
              onChange={(e) => {
                let onlyCnpjNumbers = onlyNumbers(e.target.value)

                if (onlyCnpjNumbers.length > 14) {
                  onlyCnpjNumbers = onlyCnpjNumbers.substring(0, 14)
                }
                setCnpjSearchTerm(onlyCnpjNumbers)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6">
              Tamanho: {blackListState.selectedBlackList?.list.length.toLocaleString('pt-BR')}
            </Typography>
          </Grid>
          <Grid item xs={12} height={(blackListState.selectedBlackList?.list.length ?? 0) <= 3 ? 175 : 250} maxHeight={400}>
            <AutoSizer >
              {(data: any) => (
                <FixedSizeList
                  height={(blackListState.selectedBlackList?.list.length ?? 0) <= 3 ? 175 : 250}
                  style={{
                    maxHeight: 400
                  }}
                  width={data.width}
                  itemSize={46}
                  itemCount={blackListState.selectedBlackList?.list.filter((cnpj) => {
                    if (
                      cnpjSearchTerm === '' ||
                      cnpjSearchTerm === undefined ||
                      cnpj.includes(cnpjSearchTerm)
                    ) {
                      return true
                    } else {
                      return false
                    }
                  }).length ?? 0}
                  overscanCount={15}
                >
                  {({ index, style }) => {
                    const item = blackListState.selectedBlackList?.list.filter((cnpj) => {
                      if (
                        cnpjSearchTerm === '' ||
                        cnpjSearchTerm === undefined ||
                        cnpj.includes(cnpjSearchTerm)
                      ) {
                        return true
                      } else {
                        return false
                      }
                    })[index];
                    return (
                      <ListItem
                        style={style}
                        key={index}
                        secondaryAction={<>
                          <Button style={{
                            marginRight: '10px'
                          }} startIcon={<>
                            <EditIcon />
                          </>} onClick={() => {
                            handleClickOpen()
                            setOldCnpj(item ?? '')
                            setNewCnpj(item ?? '')
                          }
                          }>
                            Editar
                          </Button>
                          <Button
                            onClick={() => {
                              removeItemList({
                                value: item ?? ''
                              })
                            }}
                            startIcon={<>
                              <DeleteIcon />
                            </>}>
                            Excluir
                          </Button>
                        </>}
                      >
                        <ListItemText primary={formatCnpj(item ?? '')} />
                      </ListItem>
                    );
                  }}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Grid>
          <Grid item xs={12}>
          </Grid>
        </Grid>

      </Box>
      <Box p={1}>

        <Button
          fullWidth
          onClick={() => {
            updateBlackList()
          }}
          variant="contained"
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: darkenColor(theme.palette.primary.main, 10)
            },
            color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
          }}
        >
          Salvar alterações
        </Button>
      </Box>
    </Dialog>
    <Dialog
      fullWidth
      maxWidth="xl"
      open={blackListImportFileOpen}
      onClose={() => {
        setBlackListImportFileOpen(false)
        cleanFileData()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
            Importar arquivo de lista de deduplicação
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            setBlackListImportFileOpen(false)
            cleanFileData()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (verifyBlackListFileData()) {
          setOpenBlackListPreview(true)
        }
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* Drag and drop component*/}
            <FileUpload label={"Arraste e solte o arquivo aqui ou clique para selecionar"} />
          </Grid>
          {
            blackListState.matchCode.selectedColumn !== '' ? (
              <>
                <Grid item xs={6}>
                  <List component="div" role="group">
                    <ListItem
                      key={0}
                      divider
                      aria-haspopup="true"
                      aria-controls="ringtone-menu"
                      aria-label="phone ringtone"
                      onClick={(() => {
                        setColumnDialogOpen(true)
                      })}
                    >
                      <ListItemText primary="Clique para selecionar a coluna"
                        secondary={blackListState.matchCode.selectedColumn}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List component="div" role="group">
                    <ListItemButtonStyled
                      key={0}
                      divider
                    >
                      <ListItemText primary="Dados do arquivo" secondary={<>
                        <Typography >
                          <strong> Nome: </strong>
                          {blackListState.fileData.name}
                        </Typography>
                        <Typography>
                          <strong> Tamanho: </strong>
                          {blackListState.fileData.size}
                        </Typography>
                        <Typography >
                          <strong> Tipo: </strong>
                          {blackListState.fileData.type}
                        </Typography>
                        <Typography >
                          <strong> Linhas: </strong>
                          {blackListState.fileData.data.length.toLocaleString('pt-BR')}
                        </Typography>
                        <Typography>
                          <strong> Match codes distintos: </strong>
                          {blackListState.matchCode.distinctValues.toLocaleString('pt-BR')}
                        </Typography>
                        <Typography>
                          <strong> Match codes válidos e distintos: </strong>
                          {blackListState.matchCode.validValues.toLocaleString('pt-BR')}
                        </Typography>
                      </>} />
                    </ListItemButtonStyled>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      }
                    }}
                  >
                    Enviar
                  </Button>
                </Grid>
              </>
            ) : null
          }
        </Grid>
        <ColumnsDialog
          id="ringtone-menu"
          keepMounted
          open={columnDialogOpen}
          onClose={(newValue?: string) => {
            setColumnDialogOpen(false)

            if (newValue) {
              selectColumn({
                selectedColumn: newValue,
              });
            }
          }}
          value={blackListState.matchCode.selectedColumn}
        />
      </form>

      <Dialog fullWidth maxWidth={'lg'} open={openBlackListPreview} onClose={() => {
        setOpenBlackListPreview(false)
        cleanFileData()
      }}>
        <DialogTitle>
          <Typography fontWeight={'bold'} textTransform="uppercase" variant="h5" color='primary'>
            Pré visualização da lista - <strong>{blackListState.selectedBlackList?.name}</strong>
          </Typography>
        </DialogTitle>
        <Box px={2}>
          <Divider />
        </Box>
        <DialogContent>
          <Grid container height={300} sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            overflowY: 'hidden'
          }}>
            <Grid item xs={12} md={3}>
              <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                <PlaylistAddCheckIcon sx={{ width: '8%', height: '8%' }} /> CNPJs atuais:
              </Typography>
              <AutoSizer >
                {(data: any) => (
                  <FixedSizeList
                    height={data.height}
                    width={data.width}
                    itemSize={46}
                    itemCount={blackListState.selectedBlackList?.list.length ?? 0}
                    overscanCount={5}
                    style={{
                      marginTop: 3.5
                    }}
                  >
                    {RenderActualCNPJs}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                <PlaylistAddIcon sx={{ width: '8%', height: '8%' }} /> CNPJs novos:
              </Typography>
              <AutoSizer >
                {(data: any) => (
                  <FixedSizeList
                    height={data.height}
                    width={data.width}
                    itemSize={46}
                    itemCount={blackListState.fileListData.fileListTableValues.fileListValidData.length ?? 0}
                    overscanCount={5}
                    style={{
                      marginTop: 3.5
                    }}
                  >
                    {RenderNewCnpjs}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                <SyncProblemIcon sx={{ width: '8%', height: '8%' }} /> CNPJs repetidos:
              </Typography>
              <AutoSizer >
                {(data: any) => (
                  <FixedSizeList
                    height={data.height}
                    width={data.width}
                    itemSize={46}
                    itemCount={blackListState.fileListData.fileListTableValues.fileListRepeatedData.length ?? 0}
                    overscanCount={5}
                    style={{
                      marginTop: 3.5
                    }}
                  >
                    {RenderRepeatedCnpjs}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                <PlaylistRemoveIcon sx={{ width: '8%', height: '8%' }} /> CNPJs inválidos:
              </Typography>
              <AutoSizer >
                {(data: any) => (
                  <FixedSizeList
                    height={data.height}
                    width={data.width}
                    itemSize={46}
                    itemCount={blackListState.fileListData.fileListTableValues.fileListInvalidData.length ?? 0}
                    overscanCount={5}
                    style={{
                      marginTop: 3.5
                    }}
                  >
                    {RenderInvalidCnpjs}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Grid>
          </Grid>
        </DialogContent>
        <Box px={2}>
          <Divider />
        </Box>
        <DialogActions>
          <Button onClick={() => {
            setOpenBlackListPreview(false)
          }}>
            Cancelar
          </Button>
          <Button onClick={() => {
            addFileListToBlackList()
            setOpenBlackListPreview(false)
            setBlackListImportFileOpen(false)
            cleanFileData()
          }}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  </>)
}

const excelToJson = (file: any[]) => {
  const columns = file[0]
  const data = file.slice(1).map((item) => {
    const obj: any = {}
    columns.forEach((column: any, index: number) => {
      obj[column] = item[index]
    })
    return obj
  })
  return data
}

const FileUpload: React.FC<FileUploadProps> = ({ label }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const { startLoading, stopLoading } = useLoadingService()
  const { setFile } = useBlackListService()
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {

    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      startLoading()

      const file = e.dataTransfer.files[0];
      /* Check if is CSV or XLSX */
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileType = file.type;
        const fileName = file.name;
        const fileSize = `${Math.round(file.size / 1024)} KB`;
        const fileDataString = event.target?.result as string;
        let fileData: any[] = [];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          fileData = await readExcelFile(file, { sheet: 1 })
          setFile({
            data: excelToJson(fileData),
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileData[0]
          })
        }
        if (file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel') {
          const delimiter = guessDelimiter(fileDataString);
          fileData = csvToJson(fileDataString, delimiter);
          setFile({
            data: fileData,
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileDataString.split('\n')[0].split(delimiter)
          })
        }

        stopLoading()
      };
      reader.readAsText(file);
    }
  };
  const guessDelimiter = (fileDataString: string) => {
    const delimiters = [',', ';', '\t', '|'];
    let maxCount = 0;
    let maxDelimiter = ',';
    delimiters.forEach((delimiter) => {
      const count = fileDataString.split(delimiter).length;
      if (count > maxCount) {
        maxCount = count;
        maxDelimiter = delimiter;
      }
    });
    return maxDelimiter;
  };

  const csvToJson = (csvString: string, delimiter: string) => {
    const rows = csvString.split('\n');
    const result: any[] = []
    rows.slice(1, rows.length).forEach((item: any) => {
      if (item !== "") {
        const fields = item.split(delimiter)
        let obj: any = {}
        rows[0]?.split(delimiter).forEach((column: any, index: number) => {
          obj[column] = fields[index]
        })

        result.push(obj)
      }
    })
    return result;
  };




  return (
    <div>

      <div
        className={`file-upload ${dragging ? 'dragging' : ''}`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => {
          if (inputFileRef.current) {
            inputFileRef.current.click()

          }
        }}

      >
        <div className="file-upload__label">{label}</div>
        <input hidden type="file" ref={inputFileRef} onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            startLoading()
            const reader = new FileReader();
            reader.onload = async (event) => {
              const fileType = file.type;
              const fileName = file.name;
              const fileSize = `${Math.round(file.size / 1024)} KB`;
              const fileDataString = event.target?.result as string;
              let fileData: any[] = [];
              if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileData = await readExcelFile(file, { sheet: 1 })
                setFile({
                  data: excelToJson(fileData),
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileData[0]
                })
              }
              if (file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel') {
                const delimiter = guessDelimiter(fileDataString);
                fileData = csvToJson(fileDataString, delimiter);
                setFile({
                  data: fileData,
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileDataString.split('\n')[0].split(delimiter)
                })

              }
            }
            reader.readAsText(file);
          }
        }} />


      </div>

    </div>

  );
};


