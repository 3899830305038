import { Grid, List, Avatar, Chip } from "@mui/material"
import { useAppSelector } from "../../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../../use-cases/project";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { theme } from "../../../../../../styles";

export const BlacklistFilterPreview = () => {
  const projectState = useAppSelector((state) => state.project)
  const { removeBlackListFilter } = useProjectService()
  const blackListState = useAppSelector((state) => state.blackList)

  return (
    <>
      <List>
        <Grid container spacing={3}>
          {projectState.selectedFilter?.blackLists?.map((blackList, index) => {
            return (
              <Grid item key={index}>
                <Chip sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    transition: '0.1s all',
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px 15px',
                    fontWeight: 'bold',
                    color: theme.palette.primary.contrastText
                  }
                }}
                  color="primary"
                  onDelete={() => {
                    removeBlackListFilter({
                      blackList: blackList
                    })
                  }}
                  avatar={
                    <Avatar
                      style={{
                        cursor: 'pointer'
                      }}>
                      <ContentPasteOffIcon sx={{
                        "&": {
                          transition: '0.1s all'
                        },
                        "&:hover": {
                          color: "secondary.main"
                        },
                        backgroundColor: theme.palette.primary.main
                      }} />
                    </Avatar>}
                  label={blackListState.blackLists.find((blackListState) => blackListState.id === blackList)?.name.toUpperCase()} />
              </Grid>
            )

          })}
        </Grid>
      </List >
    </>
  )
}