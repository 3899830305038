import { StyleSheet, Font } from '@react-pdf/renderer';
import RobotoBold from '../../../../assets/fonts/Roboto-Bold.ttf'
import RobotoMedium from '../../../../assets/fonts/Roboto-Medium.ttf'
import RobotoRegular from '../../../../assets/fonts/Roboto-Regular.ttf'

Font.register({
    family: "roboto",
    fonts: [
        {
            src: RobotoRegular,
            fontWeight: 400,
        },
        {
            src: RobotoBold,
            fontWeight: 700,
        },
        {
            src: RobotoMedium,
            fontWeight: 500
        }
    ]
});
export const styles = StyleSheet.create({
    
    page: {
        backgroundColor: '#FFF',
        padding: 20,
    },
    backgroundLogo: {
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0.07,
        width: '100%',
        height: 'auto',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'top',
        backgroundSize: '50%'
    },
    section: {
        marginTop: 10,
        marginBottom: 10,
    },
    content: {
        margin: 5,
    },
    heading: {
        fontFamily: 'roboto',
        fontSize: 20,
        textAlign: 'center',
        fontWeight: 500
    },
    title: {
        fontFamily: 'roboto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    bold: {
        fontFamily: 'roboto',
        fontWeight: 700
    },
    label: {
        fontFamily: 'roboto',
        fontWeight: 500,
        fontSize: 13,
        marginBottom: 5,
        marginTop: 5,
    },
    value: {
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 12,
        marginBottom: 10,
    },
    logo: {
        width: 'auto',
        height: 100,
        alignSelf: 'center',
    },
    highlightedValue: {
        backgroundColor: '#7239891f',
        padding: 5,
        borderRadius: 5,
    },
    titleLine: {
        position: 'absolute',
        top: 30,
        width: 400,
        height: 2,
        backgroundColor: '#000000',
        display: 'flex',
        alignSelf: 'center',
        marginBottom: 15,
    },
    subTitleLine: {
        position: 'absolute',
        top: 30,
        width: 400,
        height: 2,
        backgroundColor: '#000000',
        display: 'flex',
        alignSelf: 'center',
    },
    columnContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    column: {
        flex: 1,
        marginRight: 5,
    },
    line: {
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        marginVertical: 10,
    },
    link: {
        color: '#000000',
        textDecoration: 'none'
    },
    boxShadow: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 1,
        margin: '10px'
    },
    boxShadowChildren: {
        zIndex: 30,
        padding: '10px',
        margin: '10px',
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    sociosContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: '100vw',
    },
    sociosItem: {
        flexBasis: '50%'
    }
});