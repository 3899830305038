import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../../models'
import { ISubscription } from '../../../models/ISubscription'

interface IBackofficeConsumptionState {
  report?: {
    consumptionsList: {
      user: Omit<IUser, 'password'>
      date: Date
      total: number
      type: string
      ip: string
      createdAt: Date
      createdBy: Omit<IUser, 'password'>
      origins: {
        separatedCredits: {
          total: number
        }
        subscription: {
          total: number
        }
      }[]
      resourceId: string
      ipAddress: string
    }[]
    subscriptionData?: {
      subscription: ISubscription
      usage?: {
        totalBySeparated: number,
        usedBySeparated: number,
        usedBySubscription: number,
        type: string,
        limit?: number,
        consumptionLock?: number
      }
    }
  }
}

const initialState: IBackofficeConsumptionState = {

};

const backofficeConsumptionSlice = createSlice({
  name: 'backofficeConsumptionSlice',
  initialState,
  reducers: {
    setConsumptionReportState: (state, action: PayloadAction<
      {
        consumptionsList: {
          user: Omit<IUser, 'password'>
          date: Date
          total: number
          type: string
          ip: string
          createdAt: Date
          createdBy: Omit<IUser, 'password'>
          origins: {
            separatedCredits: {
              total: number
            }
            subscription: {
              total: number
            }
          }[]
          resourceId: string
          ipAddress: string
        }[]
        subscriptionData?: {
          subscription: ISubscription
          usage?: {
            totalBySeparated: number,
            usedBySeparated: number,
            usedBySubscription: number,
            type: string,
            limit?: number,
            consumptionLock?: number
          }
        }
      }>) => {
      state.report = action.payload
    }

  },
});

export const { setConsumptionReportState } = backofficeConsumptionSlice.actions;
export const backofficeConsumptionReducer = backofficeConsumptionSlice.reducer;