import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface IConsumptionState {
  /* Undefined deve ser o estado onde o usuário ainda não foi validado */
  userListWithConsumption: {
    userId: string,
    consumption: number,
    consumptionLimit?: number
  }[]
}

const initialState: IConsumptionState = {
  userListWithConsumption: []
};

const consumptionSlice = createSlice({
  name: 'consumption',
  initialState,
  reducers: {
    setUserListWithConsumptionState(state, action: PayloadAction<{
      userListWithConsumption: {
        userId: string,
        consumption: number,
        consumptionLimit?: number
      }[]
    }>) {
      state.userListWithConsumption = action.payload.userListWithConsumption
    }
  },
});

export const { setUserListWithConsumptionState } = consumptionSlice.actions;
export const consumptionReducer = consumptionSlice.reducer;