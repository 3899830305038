import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Box, Fab } from "@mui/material"
import { dataGridTexts } from "./dataGridText"
import { CreateNotificationForm } from "../form"
import { IBackofficeNotification } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"

export const NotificationTable = () => {
    const notificationsState = useAppSelector((state) => state.backofficeNotification)

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Data',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell(params) {
                return (
                    <div>
                        {new Date(params.value as string).toLocaleString('pt-BR', {
                            timeZone: 'America/Sao_Paulo'
                        })}
                    </div>
                )
            }
        },
        {
            field: 'title',
            headerName: 'Título',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell(params) {
                return (
                    <div>
                        {params.value}
                    </div>
                )
            }
        },
        {
            field: 'message',
            headerName: 'Mensagem',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell(params) {
                return (
                    <div>
                        {params.value}
                    </div>
                )
            }
        },
        {
            field: 'img',
            headerName: 'Imagem',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell(params) {
                return (
                    <div>
                        {
                            params.value ?
                                <img style={{
                                    maxWidth: 85,
                                    maxHeight: 85
                                }} src={params.value} alt="" />
                                :
                                "Não possui imagem"
                        }
                    </div>
                )
            }
        },
        {
            field: 'viewedBy',
            headerName: 'Visualizações',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell(params) {
                return (
                    <div>
                        {params.value.toLocaleString('pt-BR')}
                    </div>
                )
            }
        },
    ]

    return (
        <>
            <Box>
                <Fab
                    style={{
                        position: 'fixed',
                        bottom: '5%',
                        right: '5%'
                    }}
                    color="primary"
                    aria-label="add"
                >
                    <CreateNotificationForm />
                </Fab>
                <DataGrid
                    showCellVerticalBorder
                    disableColumnFilter
                    disableColumnMenu
                    rowSelection={false}
                    autoHeight={true}
                    pagination={true}
                    pageSizeOptions={[5, 10, 20]}
                    localeText={dataGridTexts}
                    rows={notificationsState.notificationList.map((notification: IBackofficeNotification) => {
                        return {
                            id: notification.id,
                            title: notification.title,
                            message: notification.message,
                            img: notification.img === '' ? 'Sem imagem' : notification.img,
                            viewedBy: notification.viewedBy?.length,
                            createdAt: notification.createdAt
                        }
                    })}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            }
                        }
                    }}
                    scrollbarSize={10}
                    columns={columns}
                />
            </Box>
        </>
    )
}