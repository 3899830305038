export const formatCep = (cep: string) => {
    let cepFormatado: string = ''
    let tamanhoFormatado = cep.replace(/\D/g, '')?.toString() ?? ''

    if (tamanhoFormatado.length < 8) {
        const zeros = '0'.repeat(8 - tamanhoFormatado.length)
        tamanhoFormatado = (zeros + tamanhoFormatado)
        return tamanhoFormatado
    }

    for (let i = 0; i < tamanhoFormatado.length; i++) {
        if (i === 4) {
            cepFormatado = cepFormatado + tamanhoFormatado[i] + '-'
        } else {
            cepFormatado = cepFormatado + tamanhoFormatado[i]
        }
    }
    return cepFormatado
}