import { AppBar, Box, Button, Dialog, IconButton, Menu, MenuItem, Slide, Toolbar, Typography } from "@mui/material"
import { useAppSelector } from "../../../../application/states/hooks"
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import { BottomNavigation } from "./bottom-navigation";
import { FiltersSection } from "./filters-section/FiltersSection";
import { DataSection } from "./data-section";
import { ExportDialog } from "./export-dialog";
import { useProjectService } from "../../../../use-cases/project";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ProjectDetailsPage = () => {
  const projectState = useAppSelector((state) => state.project)
  const { closeProject, resetFilters } = useProjectService()
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Projeto - ${projectState?.selectedProject?.name}`
  }, [projectState?.selectedProject?.name, resaleConfig])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e.stopPropagation()
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!projectState?.selectedProject) {
      closeProject()
    }
  }, [projectState.selectedProject, closeProject])

  return (
    <>
      <ExportDialog />
      <div>
        <Dialog
          fullScreen
          open={true}
          TransitionComponent={Transition}
          disableEscapeKeyDown
          onClose={() => {
            closeProject()
          }}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>

                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <div>
                    <IconButton
                      id="demo-positioned-button"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon style={{
                        color: 'white'
                      }} />
                    </IconButton>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation()
                          resetFilters()
                          handleClose(e)
                        }}
                      >
                        <Box mr={3}>
                          <FilterAltOffIcon />
                        </Box>
                        Redefinir filtros
                      </MenuItem>
                      {/* <MenuItem onClick={(e) => {
                        e.stopPropagation()
                        
                        handleClose(e)
                      }}>
                        Arquivar projeto
                      </MenuItem> */}
                    </Menu>
                  </div>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {projectState?.selectedProject?.name}
                  </Typography>

                </div>

                <div>
                  <Button autoFocus color="inherit" onClick={() => {
                    closeProject()
                  }}>
                    Fechar
                  </Button>
                </div>
              </div>

            </Toolbar>
          </AppBar>
          {
            projectState.activeSection === 'filter' ? (
              <FiltersSection />
            ) : <DataSection />
          }
          <BottomNavigation />

        </Dialog>

      </div >
    </>
  )
}