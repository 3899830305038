import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { SpecialStatus, SpecialStatusKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const specialStatusKeyToLabel = (specialStatus: SpecialStatus) => {
  switch (specialStatus) {
    case 'ESPOLIO EV 407':
      return 'Espólio EV 407'
    case 'FALIDO':
      return 'Falido'
    case 'INTERVENCAO':
      return 'Intervenção'
    case 'LIQUIDACAO EXTRA-JUDICIAL':
      return 'Liquidacão Extrajudicial'
    case 'RECUPERACAO JUDICIAL':
      return 'Recuperação Judicial'
    case 'EM LIQUIDACAO':
      return 'Em Liquidação'
    case 'NOT_INCLUDE':
      return 'Não incluir'

    default:
      return 'Não definido'

  }
}


export const SpecialStatusFilter = () => {
  const specialStatusFilter = useAppSelector((state) => state.project?.selectedFilter?.specialStatus)
  const { addSpecialStatusFilter, removeSpecialStatusFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Situação Especial" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          A Situação Especial de uma empresa é uma classificação atribuída pela Receita Federal quando a empresa está passando por algum tipo de problema jurídico, tributário, administrativo ou financeiro.
        </Typography>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Caso esta opção não seja marcada serão consideradas todas as situações cadastrais na contagem.
        </Typography>
        <Grid item xs={12} py={3}>
          <Divider />
        </Grid>
      </>}
      />

      <Box p={3}>
        <Grid container spacing={3}>
          {
            SpecialStatusKeys.map((specialStatus) => {
              return <>
                <Grid item xs={6}>

                  <FormControlLabel control={
                    <Switch
                      checked={specialStatusFilter?.includes(specialStatus) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addSpecialStatusFilter({
                            specialStatus: specialStatus
                          })
                        }
                        else {
                          removeSpecialStatusFilter({
                            specialStatus: specialStatus
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={specialStatusKeyToLabel(specialStatus).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}