import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { ICompany } from "../../application/models/ICompany"

export const useCompanyApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  
  const findCompanyById = useCallback((data: {
    companyId: string
  }): Promise<ICompany> => {
    return axiosAuthInstance.get(`/company/${data.companyId}`)
      .then((res) => {
        const result = res.data.result.company as ICompany
        if (result) {
          return result
        } else {
          throw new Error('Company not found')
        }
      })
  }, [axiosAuthInstance])

  return {
    findCompanyById
  }
}