import { AppBar, Box, Button, Dialog, Grid, Slide, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useBackofficeService } from "../../../../../use-cases/backoffice";
import { darkenColor, dataGridTexts, formatCnpj } from "../../../../../utils";
import { useAppSelector } from "../../../../../application/states/hooks";
import { theme } from "../../../../styles";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BackofficeRevenueReportPage = () => {
  const { getRevenueReport, setRevenueReportDate, downloadRevenueReport, setRevenueReportInitialState } = useBackofficeService()
  const revenueReportState = useAppSelector(state => state.backofficeRevenueReport)
  const [openRevenueDialog, setOpenRevenueDialog] = useState(false)
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Backoffice / Relatório de Receita`
  }, [resaleConfig])

  useEffect(() => {
    setRevenueReportInitialState()
  }, [setRevenueReportInitialState])

  const revenueReportColumns: GridColDef[] = [
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      headerClassName: 'headerStyles',
      flex: 0.6,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return formatCnpj(params.value as string)
      }
    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      headerClassName: 'headerStyles',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'tipoPlano',
      headerName: 'Tipo de Plano',
      headerClassName: 'headerStyles',
      minWidth: 140,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'headerStyles',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return (
          <div style={{
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            background: params.value === "ATIVO" ? theme.palette.success.main : theme.palette.error.main,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
          }
          } />
        )
      }
    },
    {
      field: 'subscriptionConsumption',
      headerName: 'Consumo de Assinatura',
      headerClassName: 'headerStyles',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return params.value?.toLocaleString('pt-BR')
      }
    },
    {
      field: 'separatedCreditValue',
      headerName: 'Qtd. Crédito Separado',
      headerClassName: 'headerStyles',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return params.value?.toLocaleString('pt-BR')
      }
    },
    {
      field: 'precoUnitario',
      headerName: 'Preço Unitário',
      headerClassName: 'headerStyles',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value) return `R$ ${params.value?.toLocaleString('pt-BR')}`
      }
    },
    {
      field: 'valorAPagar',
      headerName: 'Valor a Pagar',
      headerClassName: 'headerStyles',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return 'R$ ' + params.value?.toLocaleString('pt-BR')
      }
    }
  ]
  return <>
    <Box px={3} pb={3}>
      <Grid container justifyContent={'center'}>
        <Grid item pb={2}>
          <LocalizationProvider
            dateFormats={{
              fullDate: 'MM/yyyy',
            }}
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}>
            <DatePicker
              views={['month', 'year']}
              value={revenueReportState.date ? new Date(revenueReportState.date) : null}
              onChange={(newValue) => {
                if (newValue) {
                  setRevenueReportDate({
                    date: newValue
                  })
                } else {
                  setRevenueReportDate({
                    date: undefined
                  })
                }
              }}
              label="Data de referência"
              slotProps={{
                day: {
                  sx: {
                    "&.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      }
                    }
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} py={3}>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Button
                sx={{
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                  },
                  color: theme.palette.primary.contrastText
                }}
                variant="contained"
                onClick={() => {
                  getRevenueReport()
                }}
              >
                Gerar Relatório
              </Button>
            </Grid>
            {
              revenueReportState.result.length > 0 && (
                <>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        },
                        color: theme.palette.primary.contrastText
                      }}
                      onClick={() => {
                        downloadRevenueReport()
                      }}
                    >
                      Exportar XLSX
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        },
                        color: theme.palette.primary.contrastText
                      }}
                      onClick={() => {
                        setOpenRevenueDialog(true)
                      }}
                    >
                      Abrir relatório de receita
                    </Button>
                  </Grid>
                </>
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            revenueReportState.result && (
              <Dialog
                fullScreen
                open={openRevenueDialog}
                onClose={() => {
                  setOpenRevenueDialog(false)
                }}
                TransitionComponent={Transition}
              >
                <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Relatório de Receita
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {
                      setOpenRevenueDialog(false)
                    }
                    }>
                      FECHAR
                    </Button>
                  </Toolbar>
                </AppBar>

                <Box pt={2.5}>
                  <Grid container justifyContent={'center'}
                    sx={{
                      '& .MuiDataGrid-columnHeader': {
                        backgroundColor: theme.palette.primary.main,
                        color: company?.resale === true || company?.resaleId
                          ? theme.palette.primary.contrastText
                          : '#FFF'
                      },
                      '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 0
                      },
                      minWidth: '100%'
                    }}
                  >
                    <DataGrid
                      showCellVerticalBorder
                      disableColumnFilter
                      rowSelection={false}
                      autoHeight={true}
                      localeText={dataGridTexts}
                      pagination={undefined}
                      disableColumnMenu
                      rows={revenueReportState.result.map((item, index) => {
                        return {
                          ...item,
                          id: index
                        }
                      })
                      }
                      columns={revenueReportColumns}
                      sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        minWidth: '100%'
                      }}
                    />
                  </Grid>
                </Box>
              </Dialog>
            )
          }
        </Grid>
      </Grid>
    </Box>
  </>
}