import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material"
import { useAppSelector } from "../../../../application/states/hooks"
import { useSettingsService } from "../../../../use-cases/settings"
import { theme } from "../../../styles"
import ProfileIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { ProfileSection } from "./profile-section";
import { SecuritySection } from "./security-section";
import { UserSection } from "./users-section";
import { useEffect } from "react";
import SyncLockIcon from '@mui/icons-material/SyncLock';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { ConsumptionLockSection } from "./consumption-lock-section";
import { WhiteLabelSection } from "./white-label-section";
import { useUserService } from "../../../../use-cases/user";

export const SettingsPage = () => {
  // States
  const activeTab = useAppSelector((state) => state.settings.activeTab)
  const userType = useAppSelector((state) => state.user.userType)
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  // Service
  const { setActiveTab } = useSettingsService()
  const { setUserList } = useUserService()

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações`
  }, [resaleConfig])

  useEffect(() => {
    if (userType === 'ADMIN' || userType === 'OWNER') {
      setUserList()
    }
  }, [setUserList, userType])

  return <>
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <List>
            <ListItem>
              <Paper elevation={3} style={{
                width: '100%'
              }}>
                <ListItemButton onClick={() => {
                  setActiveTab({
                    tab: 'profile'
                  })
                }} style={{
                  background: activeTab === 'profile' ? theme.palette.primary.main : '',
                  color: activeTab === 'profile' ? 'white' : 'black',
                }}>
                  <ListItemIcon>
                    <ProfileIcon style={{
                      color: activeTab === 'profile' ? 'white' : 'black',
                    }} />
                  </ListItemIcon>
                  <ListItemText primary="PERFIL" />
                </ListItemButton>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper elevation={3} style={{
                width: '100%'
              }}>
                <ListItemButton onClick={() => {
                  setActiveTab({
                    tab: 'security'
                  })
                }} style={{
                  background: activeTab === 'security' ? theme.palette.primary.main : '',
                  color: activeTab === 'security' ? 'white' : 'black',
                }}>
                  <ListItemIcon>
                    <SecurityIcon style={{
                      color: activeTab === 'security' ? 'white' : 'black',
                    }} />
                  </ListItemIcon>
                  <ListItemText primary="SEGURANÇA" />
                </ListItemButton>
              </Paper>
            </ListItem>
            {
              (userType === 'ADMIN' || userType === 'OWNER') ? (
                <>
                  <ListItem>
                    <Paper elevation={3} style={{
                      width: '100%'
                    }}>
                      <ListItemButton onClick={() => {
                        setActiveTab({
                          tab: 'users'
                        })
                      }} style={{
                        background: activeTab === 'users' ? theme.palette.primary.main : '',
                        color: activeTab === 'users' ? 'white' : 'black',
                      }}>
                        <ListItemIcon>
                          <GroupAddIcon style={{
                            color: activeTab === 'users' ? 'white' : 'black',
                          }} />
                        </ListItemIcon>
                        <ListItemText primary="USUÁRIOS" />
                      </ListItemButton>
                    </Paper>
                  </ListItem>
                  <ListItem>
                    <Paper elevation={3} style={{
                      width: '100%'
                    }}>
                      <ListItemButton onClick={() => {
                        setActiveTab({
                          tab: 'consumptionLock'
                        })
                      }} style={{
                        background: activeTab === 'consumptionLock' ? theme.palette.primary.main : '',
                        color: activeTab === 'consumptionLock' ? 'white' : 'black',
                      }}>
                        <ListItemIcon>
                          <SyncLockIcon style={{
                            color: activeTab === 'consumptionLock' ? 'white' : 'black',
                          }} />
                        </ListItemIcon>
                        <ListItemText primary="LIMITAÇÃO DE CONSUMO" />
                      </ListItemButton>
                    </Paper>
                  </ListItem>
                  {
                    company?.resale === true ?
                      <>
                        <ListItem>
                          <Paper elevation={3} style={{
                            width: '100%'
                          }}>
                            <ListItemButton onClick={() => {
                              setActiveTab({
                                tab: 'whiteLabel'
                              })
                            }} style={{
                              background: activeTab === 'whiteLabel' ? theme.palette.primary.main : '',
                              color: activeTab === 'whiteLabel' ? 'white' : 'black',
                            }}>
                              <ListItemIcon>
                                <AutoFixHighIcon style={{
                                  color: activeTab === 'whiteLabel' ? 'white' : 'black',
                                }} />
                              </ListItemIcon>
                              <ListItemText primary={"White Label".toUpperCase()} />
                            </ListItemButton>
                          </Paper>
                        </ListItem>
                      </>
                      : null
                  }
                </>
              ) : null
            }

          </List>
        </Grid>
        <Grid item xs={9}>
          <Box p={2}>

            {
              activeTab === 'profile' ? (
                <>
                  <ProfileSection />
                </>
              ) : null
            }
            {
              activeTab === 'security' ? (
                <>
                  <SecuritySection />
                </>
              ) : null
            }
            {
              activeTab === 'users' && (userType === 'ADMIN' || userType === 'OWNER') ? (
                <>
                  <UserSection />
                </>
              ) : null
            }
            {
              activeTab === 'consumptionLock' && (userType === 'ADMIN' || userType === 'OWNER') ? (
                <>
                  <ConsumptionLockSection />
                </>
              ) : null
            }
            {
              activeTab === 'whiteLabel' && (userType === 'ADMIN' || userType === 'OWNER') ? (
                <>
                  <WhiteLabelSection />
                </>
              ) : null
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
}