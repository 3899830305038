
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';

import { dataGridTexts } from '../../../../assets/helper-assets';
import { Visibility } from '@mui/icons-material';

import { useUnitSearchService } from '../../../../../use-cases/unit-search/useUnitSearchService';
import { useAppSelector } from '../../../../../application/states/hooks';
import { anonAddress, anonCnpj, anonPhone, formatCnpj, formatTelefoneBrazil } from '../../../../../utils';
import { theme } from '../../../../styles';

export function UnitSearchTable() {
  const { viewPj } = useUnitSearchService()
  const pjList = useAppSelector((state) => state.unitSearch.pjList)
  const { company } = useAppSelector((state) => state.company)

  const columns: GridColDef[] = [
    {
      field: 'view',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Visualizar',
      minWidth: 140,
      renderCell: (params) => {
        return <Button onClick={() => {
          viewPj({
            pjId: params.row.id
          })
        }}>
          <Visibility />
        </Button>
      }
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return <>{anonCnpj(formatCnpj(params.value ?? ''))}</>
      },

    },
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      headerAlign: 'center',
      align: 'center',
      minWidth: 330,
      flex: 1
    },
    {
      field: 'nomeFantasia',
      headerName: 'Nome Fantasia',
      headerAlign: 'center',
      align: 'center',
      minWidth: 250,
      flex: 0.7
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      type: 'string',
      renderCell: (params) => {
        const telefoneFormatado = formatTelefoneBrazil(params.value ?? '');
        const telefoneAnonimizado = anonPhone(telefoneFormatado)
        return <>
          {telefoneAnonimizado}
        </>
      }
    },
    {
      field: 'endereco',
      headerName: 'Endereço',
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      flex: 0.5,
      type: 'string',
      renderCell: (params) => {
        return <>
          {anonAddress(params.value ?? '')}
        </>
      }
    }
  ]

  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: theme.palette.primary.main,
          color: company?.resale === true || company?.resaleId
            ? theme.palette.primary.contrastText
            : '#FFF'
        },
        '& .MuiDataGrid-cell': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 0
        },
        minWidth: '100%'
      }}
    >
      <DataGrid
        showCellVerticalBorder
        disableColumnFilter
        disableColumnMenu
        rowSelection={false}
        autoHeight={true}
        localeText={dataGridTexts}
        pagination={true}
        pageSizeOptions={[5, 10, 20]}
        rows={pjList}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        scrollbarSize={10}
        columns={columns}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          minWidth: '100%'
        }}
      />
    </Box>
  );
}