import { useCallback } from "react"
import { IPessoaJuridica } from "../../application/models/IPessoaJuridica"
import { ISocio } from "../../application/models/ISocio"
import { IUFCount } from "../../application/states/uf-count"
import { PjResume } from "../../application/states/unit-search"
import { useAxiosInstances } from "../axios-instance"

export const usePJApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findBySearchTerm = useCallback((data: {
    searchTerm: string
  }): Promise<PjResume[]> => {
    return axiosAuthInstance.get('/pessoa-juridica', {
      params: {
        searchTerm: data.searchTerm
      }
    }).then((response) => {
      const pjList = response.data?.result?.pessoaJuridicaList
      let pjResumeList: PjResume[] = pjList.map((item: any) => {
        return {
          id: item.pessoajuridicaid,
          cnpj: item.cnpj,
          razaoSocial: item.razaosocial,
          telefone: item?.telefones?.[0] ?? '',
          endereco: item.enderecocompleto,
          nomeFantasia: item.nomefantasia,
        }
      })
      if (pjList) {
        return pjResumeList
      } else {
        throw {
          type: 'NotFoundError',
          message: 'PJ não encontrada'
        }
      }
    })
  }, [axiosAuthInstance])


  const findUfCount = useCallback((): Promise<IUFCount[]> => {
    return axiosAuthInstance.get('/pessoa-juridica/uf-count')
      .then((response) => {
        const ufCount = response.data?.result?.ufCount
        if (ufCount) {
          return ufCount
        } else {
        }
      }).catch((err) => {
        if (err?.response?.data?.result) {
          
        } else {
          throw {
            message: 'Erro desconhecido',
            type: 'unknown'
          }
        }
      })
  }, [axiosAuthInstance])

  const findPjById = useCallback((data: {
    id: string,
    userId: string
  }): Promise<{
    pj: IPessoaJuridica,
    socios: ISocio[]
  }> => {
    return axiosAuthInstance.get(`/pessoa-juridica/${data.id}`, {
      params: {
        userId: data.userId
      }
    })
      .then((response) => {
        const pj = response.data?.result?.pessoaJuridica
        const socios = response.data?.result?.socios
        if (pj) {
          return {
            pj: pj,
            socios: socios
          }
        } else {
          throw {
            type: 'NotFoundError',
            message: 'PJ não encontrada'
          }
        }
      })
  }, [axiosAuthInstance])

  const findByCnpj = useCallback((data: {
    cnpj: string,
    userId: string
  }): Promise<{
    pj: IPessoaJuridica,
    socios: ISocio[]
  }> => {
    return axiosAuthInstance.get(`/pessoa-juridica/find-by-cnpj`, {
      params: {
        userId: data.userId,
        cnpj: data.cnpj
      }
    })
      .then((response) => {
        const pj = response.data?.result?.pessoaJuridica
        const socios = response.data?.result?.socios
        if (pj) {
          return {
            pj: pj,
            socios: socios
          }
        } else {
          throw {
            type: 'NotFoundError',
            message: 'PJ não encontrada'
          }
        }
      }).catch((err) => {
        if (err?.response?.data?.result) {
          throw {
            message: err.response.data.result.message,
            type: err.response.data.result.type
          }
        } else {
          throw {
            message: 'Erro desconhecido',
            type: 'unknown'
          }
        }
      })
  }, [axiosAuthInstance])

  return {
    findBySearchTerm,
    findUfCount,
    findPjById,
    findByCnpj
  }
}