import { Outlet } from "react-router-dom"
import { BackgroundGradientAnimation } from "./background-gradient-animation"
import { MainContainer } from "./main-container"
import { useResaleConfigService } from "../../../../use-cases/resale-config"
import { useEffect, useState } from "react"
import { changeFavicon, getSleep } from "../../../../utils"
import { useAppSelector } from "../../../../application/states/hooks"
import LurikFavicon from '../../../assets/img/lurik-logo.png'

export const AuthenticationLayout = () => {
  const resaleConfigFavicon = useAppSelector((state) => state.resaleConfig.resaleConfig?.favicon)
  const { getResaleConfigByDomain } = useResaleConfigService()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTheme = async () => {
      try {
        getResaleConfigByDomain({
          domain: window.location.host
        })
      } catch (error) {

      } finally {
        await getSleep(500)

        setLoading(false)
      }
    }

    loadTheme()
  }, [getResaleConfigByDomain])

  useEffect(() => {
    if (loading === false) {
      if (resaleConfigFavicon === '') changeFavicon(LurikFavicon)
    }
  })

  return (
    <>
      {
        loading === true ? <></>
          :
          <>
            <BackgroundGradientAnimation />
            <MainContainer>
              <Outlet />
            </MainContainer>
          </>
      }
    </>
  )
}