import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ILoadingState {
  isLoading?: boolean
}

const initialState: ILoadingState = {
  isLoading: false
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<{
      isLoading: boolean
    }>) {
      state.isLoading = action.payload.isLoading
    }

  },
});

export const { setIsLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;