import { Typography, Box, Grid, TextField, Slider } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { useProjectService } from "../../../../../../use-cases/project";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { NumberFormatCustom, ValueLabelComponent, valuetext } from "../../../../../../utils";

export const ShareCapitalFilter = () => {
    const { setShareCapitalMinFilter, setShareCapitalMaxFilter } = useProjectService()
    const shareCapitalFilterState = useAppSelector((state) => state.project?.selectedFilter?.shareCapital)
    const exportDialog = useAppSelector((state) => state.project.exportDialog)

    return (<>
        <FilterHeader title="Capital social" description={<>
            <Typography style={{
                marginBottom: '10px'
            }}>
                Esta opção permite filtrar empresas através do seu capital social. Ela permite a busca através do capital social mínimo e máximo.
            </Typography>
            <Typography>
                Caso esta opção não seja definida, será considerado todos os níveis de capital social na contagem.

            </Typography>
        </>} />
        <Box p={3}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        value={shareCapitalFilterState?.min}
                        InputProps={{
                            startAdornment: <span style={{
                                marginRight: '5px'
                            }}>R$</span>,
                            inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) => {
                            setShareCapitalMinFilter({
                                shareCapitalMin: Number(e.target.value)
                            })
                        }}
                        label="Capital social mínimo"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        InputProps={{
                            startAdornment: <span style={{
                                marginRight: '5px'
                            }}>R$</span>,
                            inputComponent: NumberFormatCustom,
                        }}
                        value={shareCapitalFilterState?.max}
                        onChange={(e) => {
                            setShareCapitalMaxFilter({
                                shareCapitalMax: Number(e.target.value)
                            })
                        }}
                        label="Capital social máximo"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%', marginTop: '60px' }}>
                        <Slider
                            defaultValue={999999999999.99}
                            getAriaValueText={valuetext}
                            step={10}
                            valueLabelDisplay={exportDialog.open ? 'off' : 'on'}
                            marks={[
                                {
                                    value: 100000000000.00,
                                    label: 'R$ 100.000.000.000,00',
                                },
                                {
                                    value: 500000000000.00,
                                    label: 'R$ 500.000.000.000,00',
                                },
                                {
                                    value: 900000000000.00,
                                    label: 'R$ 900.000.000.000,00',
                                }
                            ]}
                            min={0.00}
                            max={999999999999.99}
                            value={[shareCapitalFilterState?.min ?? 0, shareCapitalFilterState?.max ?? 999999999999.99]}
                            onChange={(event, value: any) => {

                                setShareCapitalMinFilter({
                                    shareCapitalMin: Number(value[0] ?? 0)
                                })
                                setShareCapitalMaxFilter({
                                    shareCapitalMax: Number(value[1] ?? 999999999999.00)
                                })
                            }}
                            slots={{
                                valueLabel: ValueLabelComponent,
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>)
}