import { Box, Button, Grid, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import { useModalContainerService } from "../../../../../use-cases/modal-container";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";
export const PasswordChangedModal = () => {
  const navigate = useNavigate()
  const { closeModal } = useModalContainerService()
  return (
    <>
      <Box p={3}>
        <Grid container alignItems={"center"} flexDirection="column">
          <Grid item>
            <CheckCircleIcon color="primary" sx={{ fontSize: 100 }} />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ mt: 2 }} align="center">
              Senha alterada com sucesso!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ mt: 2 }} align="center">
              Você já pode fazer login com sua nova senha.
            </Typography>
          </Grid>
          <Grid item>
            <Box my={3}>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                  },
                  color: theme.palette.primary.contrastText
                }}
                onClick={() => {
                  navigate('/authentication/login')
                  closeModal()
                }}
              >
                Ir para login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}