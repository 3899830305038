import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useLoadingService } from "../loading"
import {
    resetResaleConfigState,
    setResaleConfigContactBeginHourState,
    setResaleConfigContactEmailState,
    setResaleConfigContactEndHourState,
    setResaleConfigContactPhoneState,
    setResaleConfigDomainState,
    setResaleConfigEmailProviderSettingHostState,
    setResaleConfigEmailProviderSettingPasswordState,
    setResaleConfigEmailProviderSettingPortState,
    setResaleConfigEmailProviderSettingUserState,
    setResaleConfigFaviconFileState,
    setResaleConfigFaviconState,
    setResaleConfigImageFileState,
    setResaleConfigImgState,
    setResaleConfigInitialState,
    setResaleConfigLoginPageImgFileState,
    setResaleConfigLoginPageImgState,
    setResaleConfigNameState,
    setResaleConfigState,
    setResaleConfigThemeColorsBackgroundBodyMainState,
    setResaleConfigThemeColorsBackgroundPaperMainState,
    setResaleConfigThemeColorsPrimaryContrastColorState,
    setResaleConfigThemeColorsPrimaryMainState,
    setResaleConfigThemeColorsSecondaryContrastColorState,
    setResaleConfigThemeColorsSecondaryMainState,
    setResaleConfigThemeFontFamilyState
} from "../../application/states/resale-config"
import { useResaleConfigApi } from "../../infra"
import { executeAlert } from "../../application/states/toast-alert"
import { changeFavicon, validateEmail } from "../../utils"
import { IResaleConfig } from "../../application/models"
import { theme } from "../../presentation/styles"
import LurikFavicon from '../../presentation/assets/img/lurik-logo.png'

export const useResaleConfigService = () => {
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
    const { createResaleConfigApi, updateResaleConfigApi, findResaleConfigByDomainApi } = useResaleConfigApi()
    const { startLoading, stopLoading } = useLoadingService()
    const { company } = useAppSelector((state) => state.company)
    const dispatch = useAppDispatch()

    const resetResaleConfig = useCallback(() => {
        startLoading()
        dispatch(resetResaleConfigState())
        stopLoading()
    }, [dispatch, startLoading, stopLoading])

    const setResaleConfigName = useCallback((data: {
        name: string
    }) => {
        dispatch(setResaleConfigNameState({
            name: data.name
        }))
    }, [dispatch])

    const setResaleConfigImg = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setResaleConfigImageFileState({
                imageFile: undefined,
            }))
            dispatch(setResaleConfigImgState({
                img: ''
            }))
            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setResaleConfigImgState({
                        img: result as string
                    }))
                }

                dispatch(setResaleConfigImageFileState({
                    imageFile: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }

    }, [dispatch])

    const setResaleConfigFavicon = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setResaleConfigFaviconFileState({
                faviconFile: undefined
            }))
            dispatch(setResaleConfigFaviconState({
                favicon: ''
            }))

            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setResaleConfigFaviconState({
                        favicon: result as string
                    }))
                }

                dispatch(setResaleConfigFaviconFileState({
                    faviconFile: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }
    }, [dispatch])

    const setResaleConfigDomain = useCallback((data: {
        domain: string
    }) => {
        dispatch(setResaleConfigDomainState({
            domain: data.domain
        }))
    }, [dispatch])

    const setResaleConfigContactEmail = useCallback((data: {
        email: string
    }) => {
        dispatch(setResaleConfigContactEmailState({
            email: data.email
        }))
    }, [dispatch])

    const setResaleConfigContactPhone = useCallback((data: {
        phone: string
    }) => {
        dispatch(setResaleConfigContactPhoneState({
            phone: data.phone
        }))
    }, [dispatch])

    const setResaleConfigContactBeginHour = useCallback((data: {
        beginHour: string
    }) => {
        dispatch(setResaleConfigContactBeginHourState({
            beginHour: data.beginHour
        }))
    }, [dispatch])

    const setResaleConfigContactEndHour = useCallback((data: {
        endHour: string
    }) => {
        dispatch(setResaleConfigContactEndHourState({
            endHour: data.endHour
        }))
    }, [dispatch])

    const setResaleConfigEmailProviderSettingUser = useCallback((data: {
        user: string
    }) => {
        dispatch(setResaleConfigEmailProviderSettingUserState({
            user: data.user
        }))
    }, [dispatch])

    const setResaleConfigEmailProviderSettingPassword = useCallback((data: {
        password: string
    }) => {
        dispatch(setResaleConfigEmailProviderSettingPasswordState({
            password: data.password
        }))
    }, [dispatch])

    const setResaleConfigEmailProviderSettingHost = useCallback((data: {
        host: string
    }) => {
        dispatch(setResaleConfigEmailProviderSettingHostState({
            host: data.host
        }))
    }, [dispatch])

    const setResaleConfigEmailProviderSettingPort = useCallback((data: {
        port: string
    }) => {
        dispatch(setResaleConfigEmailProviderSettingPortState({
            port: data.port
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsPrimaryMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsPrimaryMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsPrimaryContrastColor = useCallback((data: {
        contrastColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsPrimaryContrastColorState({
            contrastColor: data.contrastColor
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsSecondaryMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsSecondaryMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsSecondaryContrastColor = useCallback((data: {
        contrastColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsSecondaryContrastColorState({
            contrastColor: data.contrastColor
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsBackgroundPaperMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsBackgroundPaperMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setResaleConfigThemeColorsBackgroundBodyMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setResaleConfigThemeColorsBackgroundBodyMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setResaleConfigThemeFontFamily = useCallback((data: {
        fontFamily: string
    }) => {
        dispatch(setResaleConfigThemeFontFamilyState({
            fontFamily: data.fontFamily
        }))
    }, [dispatch])

    const createResaleConfig = useCallback(() => {
        if (!resaleConfig) {
            return
        }

        if (validateEmail(resaleConfig.contact.email)) {
            dispatch(executeAlert({
                message: 'Insira um e-mail válido para contato',
                type: 'error'
            }))
            return
        }

        if (validateEmail(resaleConfig.emailProviderSettings.user)) {
            dispatch(executeAlert({
                message: 'Insira um e-mail válido para o provedor de e-mail',
                type: 'error'
            }))
            return
        }
        startLoading()
        createResaleConfigApi({
            contact: {
                beginHour: resaleConfig?.contact.beginHour ?? '',
                email: resaleConfig?.contact.email ?? '',
                endHour: resaleConfig?.contact.endHour ?? '',
                phone: resaleConfig?.contact.phone ?? ''
            },
            domain: resaleConfig?.domain ?? '',
            emailProviderSettings: {
                host: resaleConfig?.emailProviderSettings.host ?? '',
                password: resaleConfig?.emailProviderSettings.password ?? '',
                port: resaleConfig?.emailProviderSettings.port ?? '',
                user: resaleConfig?.emailProviderSettings.user ?? ''
            },
            favicon: resaleConfig?.favicon ?? '',
            img: resaleConfig?.img ?? '',
            loginPageImg: resaleConfig?.loginPageImg ?? '',
            name: resaleConfig?.name ?? '',
            theme: {
                colors: {
                    background: {
                        body: {
                            main: resaleConfig?.theme.colors.background.body.main ?? ''
                        },
                        paper: {
                            main: resaleConfig?.theme.colors.background.paper.main ?? ''
                        }
                    },
                    primary: {
                        main: resaleConfig?.theme.colors.primary.main ?? '',
                        contrastColor: resaleConfig?.theme.colors.primary.contrastColor ?? ''
                    },
                    secondary: {
                        main: resaleConfig?.theme.colors.secondary.main ?? '',
                        contrastColor: resaleConfig?.theme.colors.secondary.contrastColor ?? ''
                    }
                },
                fontFamily: resaleConfig?.theme.fontFamily ?? ''
            },
            companyId: company?.id ?? ''
        }).then((resaleConfig) => {
            if (resaleConfig) {
                stopLoading()
                dispatch(setResaleConfigState({
                    resaleConfig: resaleConfig
                }))
                dispatch(executeAlert({
                    message: 'Configurações do white label criadas com sucesso!',
                    type: 'success'
                }))
            }
        }).catch((error) => {
            stopLoading()
            dispatch(executeAlert({
                message: error.message,
                type: 'error'
            }))
        })
    }, [company?.id, createResaleConfigApi, dispatch, resaleConfig, startLoading, stopLoading])

    const updateResaleConfig = useCallback(() => {
        if (!resaleConfig) {
            return
        }

        if (validateEmail(resaleConfig.contact.email)) {
            dispatch(executeAlert({
                message: 'Insira um e-mail válido para contato',
                type: 'error'
            }))
            return
        }

        if (validateEmail(resaleConfig.emailProviderSettings.user)) {
            dispatch(executeAlert({
                message: 'Insira um e-mail válido para o provedor de e-mail',
                type: 'error'
            }))
            return
        }

        startLoading()
        updateResaleConfigApi({
            resaleConfig: {
                id: resaleConfig.id,
                contact: {
                    beginHour: resaleConfig?.contact.beginHour ?? '',
                    email: resaleConfig?.contact.email ?? '',
                    endHour: resaleConfig?.contact.endHour ?? '',
                    phone: resaleConfig?.contact.phone ?? ''
                },
                domain: resaleConfig?.domain ?? '',
                emailProviderSettings: {
                    host: resaleConfig?.emailProviderSettings.host ?? '',
                    password: resaleConfig?.emailProviderSettings.password ?? '',
                    port: resaleConfig?.emailProviderSettings.port ?? '',
                    user: resaleConfig?.emailProviderSettings.user ?? ''
                },
                favicon: resaleConfig?.favicon ?? '',
                img: resaleConfig?.img ?? '',
                loginPageImg: resaleConfig?.loginPageImg ?? '',
                name: resaleConfig?.name ?? '',
                theme: {
                    colors: {
                        background: {
                            body: {
                                main: resaleConfig?.theme.colors.background.body.main ?? ''
                            },
                            paper: {
                                main: resaleConfig?.theme.colors.background.paper.main ?? ''
                            }
                        },
                        primary: {
                            main: resaleConfig?.theme.colors.primary.main ?? '',
                            contrastColor: resaleConfig?.theme.colors.primary.contrastColor ?? ''
                        },
                        secondary: {
                            main: resaleConfig?.theme.colors.secondary.main ?? '',
                            contrastColor: resaleConfig?.theme.colors.secondary.contrastColor ?? ''
                        }
                    },
                    fontFamily: resaleConfig?.theme.fontFamily ?? ''
                },
                companyId: company?.id ?? ''
            }
        }).then((resaleConfig) => {
            if (resaleConfig) {
                stopLoading()
                dispatch(setResaleConfigState({
                    resaleConfig: resaleConfig
                }))
                dispatch(executeAlert({
                    message: 'Configurações do white label atualizadas com sucesso!',
                    type: 'success'
                }))
            }
        }).catch((error) => {
            stopLoading()
            dispatch(executeAlert({
                message: error.message,
                type: 'error'
            }))
        })
    }, [company?.id, dispatch, resaleConfig, startLoading, stopLoading, updateResaleConfigApi])

    const setResaleConfigLoginPageImg = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setResaleConfigLoginPageImgFileState({
                loginPageImg: undefined
            }))
            dispatch(setResaleConfigLoginPageImgState({
                loginPageImg: ''
            }))
            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setResaleConfigLoginPageImgState({
                        loginPageImg: result as string
                    }))
                }

                dispatch(setResaleConfigLoginPageImgFileState({
                    loginPageImg: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }

    }, [dispatch])

    const getResaleConfigByDomain = useCallback((data: {
        domain: string
    }) => {
        findResaleConfigByDomainApi({
            domain: data.domain
        }).then((resaleConfig: IResaleConfig) => {
            if (resaleConfig) {
                // Theme colors
                // Primary colors
                if (resaleConfig.theme.colors.primary.main !== '' && resaleConfig.theme.colors.primary.main !== undefined) {
                    theme.palette.primary.main = resaleConfig.theme.colors.primary.main
                    if (theme.components?.MuiCssBaseline?.styleOverrides) {
                        theme.components!.MuiCssBaseline!.styleOverrides = {
                            '&::-webkit-scrollbar-track': {
                                background: theme.palette.background.default,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: theme.palette.secondary.main
                            }
                        }
                    }
                }
                if (resaleConfig.theme.colors.primary.contrastColor !== '' && resaleConfig.theme.colors.primary.contrastColor !== undefined) {
                    theme.palette.primary.contrastText = resaleConfig.theme.colors.primary.contrastColor!
                }

                // Secondary colors
                if (resaleConfig.theme.colors.secondary.main !== '' && resaleConfig.theme.colors.secondary.main !== undefined) {
                    theme.palette.secondary.main = resaleConfig.theme.colors.secondary.main
                }
                if (resaleConfig.theme.colors.secondary.contrastColor !== '' && resaleConfig.theme.colors.secondary.contrastColor !== undefined) {
                    theme.palette.secondary.contrastText = resaleConfig.theme.colors.secondary.contrastColor!
                }

                // Background colors
                if (resaleConfig.theme.colors.background.body.main !== '' && resaleConfig.theme.colors.background.body.main !== undefined) {
                    theme.palette.background.default = resaleConfig.theme.colors.background.body.main
                }
                if (resaleConfig.theme.colors.background.paper.main !== '' && resaleConfig.theme.colors.background.paper.main !== undefined) {
                    theme.palette.background.paper = resaleConfig.theme.colors.background.body.main
                }

                // Font Family
                if (resaleConfig.theme.fontFamily !== '' && resaleConfig.theme.fontFamily !== undefined) {
                    theme.typography.body1.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.body2.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.button.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.caption.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h1.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h2.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h3.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h4.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h5.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.h6.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.subtitle1.fontFamily = resaleConfig.theme.fontFamily
                    theme.typography.subtitle2.fontFamily = resaleConfig.theme.fontFamily
                }
                dispatch(setResaleConfigState({
                    resaleConfig: resaleConfig
                }))
                changeFavicon(resaleConfig.favicon)
            } else {
                changeFavicon(LurikFavicon)
            }
        }).catch(() => {
            dispatch(setResaleConfigInitialState())
            changeFavicon(LurikFavicon)
            theme.palette.primary.main = '#723989'
            theme.palette.secondary.main = '#ee9338'
            theme.components!.MuiCssBaseline!.styleOverrides = {
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#723989'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#ee9338'
                }
            }

        })
    }, [dispatch, findResaleConfigByDomainApi])

    return {
        resetResaleConfig,
        setResaleConfigName,
        setResaleConfigImg,
        setResaleConfigFavicon,
        setResaleConfigDomain,
        setResaleConfigContactEmail,
        setResaleConfigContactPhone,
        setResaleConfigContactBeginHour,
        setResaleConfigContactEndHour,
        setResaleConfigEmailProviderSettingUser,
        setResaleConfigEmailProviderSettingPassword,
        setResaleConfigEmailProviderSettingHost,
        setResaleConfigEmailProviderSettingPort,
        setResaleConfigThemeColorsPrimaryMain,
        setResaleConfigThemeColorsPrimaryContrastColor,
        setResaleConfigThemeColorsSecondaryMain,
        setResaleConfigThemeColorsSecondaryContrastColor,
        setResaleConfigThemeColorsBackgroundPaperMain,
        setResaleConfigThemeColorsBackgroundBodyMain,
        setResaleConfigThemeFontFamily,
        createResaleConfig,
        updateResaleConfig,
        setResaleConfigLoginPageImg,
        getResaleConfigByDomain
    }
}