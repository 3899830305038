import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IModalContainerState {
  open: boolean;
  content: JSX.Element
  closeButton?: boolean
  okButton?: boolean,
  onOk?: () => void
}

const initialState: IModalContainerState = {
  open: false,
  content: <></>,
  closeButton: false,
  okButton: false,
  onOk: () => {}
};

const modalContainerSlice = createSlice({
  name: 'modalContainer',
  initialState,
  reducers: {
    setModalContainerOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setModalContainerContent: (state, action: PayloadAction<JSX.Element>) => {
      state.content = action.payload;
    },
    setModalCloseButton: (state, action: PayloadAction<boolean>) => {
      state.closeButton = action.payload;
    },
    setModalOkButton: (state, action: PayloadAction<boolean>) => {
      state.okButton = action.payload;
    },
    setModalOnOk: (state, action: PayloadAction<() => void>) => {
      state.onOk = action.payload;
    }
  },
});

export const {
  setModalContainerContent,
  setModalContainerOpen,
  setModalCloseButton,
  setModalOkButton,
  setModalOnOk

} = modalContainerSlice.actions;


export const modalContainerReducer = modalContainerSlice.reducer;