import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, Slide, Switch, TextField, Toolbar, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import AddIcon from '@mui/icons-material/Add';
import { forwardRef, useState } from "react";
import { EconomicActivityFilterPreview } from "./economic-activity-filter-preview";
import { TransitionProps } from "@mui/material/transitions";
import { useEconomicActivityService } from "../../../../../../use-cases/economic-activity";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../use-cases/project";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const EconomicActivityFilter = () => {
  const [open, setOpen] = useState(false)
  const { setSearchTerm, searchEconomicActivity, resetEconomicActivityList } = useEconomicActivityService()
  const economicActivityState = useAppSelector((state) => state.economicActivity)
  const justPrimaryActivityFilterState = useAppSelector((state) => state.project?.selectedFilter?.justPrimaryActivity)
  const { addEconomicActivityFilter, setJustPrimaryActivityFilter } = useProjectService()
  const economicActivityFilterState = useAppSelector((state) => state.project?.selectedFilter?.economicActivity)
  const projectState = useAppSelector((state) => state.project.selectedFilter)

  const handleEconomicActivityFilterClose = () => {
    setOpen(false)
    setSearchTerm({
      searchTerm: ''
    })
    resetEconomicActivityList()

  }


  return (
    <>
      <FilterHeader title="Atividade econômica" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Nesta opção nós temos os agrupamentos das empresas pela atividade em que atua. Esta opção também permite fazer buscas mais detalhadas, como exemplo um único número de CNAE (Classificação Nacional de Atividades Econômicas).
        </Typography>
        <Typography>
          Exemplo de grupo: Educação é o agrupamento de: Ensino médio / Ensino superior / outras atividades de ensino
        </Typography>
        <Typography>
          Exemplo de busca específica através do CNAE 8531-7/00, que corresponde a: Educação superior - graduação
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão consideradas todas as atividades econômicas na contagem.
        </Typography>
      </>} />

      <Box my={3} mt={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel control={
              <Switch
                checked={justPrimaryActivityFilterState ?? false}
                onChange={(e) => {
                  setJustPrimaryActivityFilter({
                    justPrimaryActivity: e.target.checked
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label={"Incluir apenas atividade primária"} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => {
              setOpen(true)
            }} fullWidth endIcon={<>
              <AddIcon />
            </>}>
              <Typography variant="h6" fontWeight="bold">
                Clique para adicionar uma atividade econômica (CNAE)
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <EconomicActivityFilterPreview />
          </Grid>
        </Grid>
      </Box >

      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleEconomicActivityFilterClose()
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
              Selecionar atividade econômica
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              handleEconomicActivityFilterClose()
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <form onSubmit={(e) => {
            e.preventDefault()
            searchEconomicActivity()
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={economicActivityState.searchTerm}
                  onChange={(e) => {
                    setSearchTerm({
                      searchTerm: e.target.value
                    })
                  }}

                  InputProps={{
                    endAdornment: <>
                      <Button type="submit" onClick={() => {
                      }}>
                        Buscar
                      </Button>
                    </>
                  }}
                  fullWidth
                  label="Digite o nome ou código da atividade econômica"
                  placeholder="Digite o nome ou código da atividade econômica"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <List>
              {
                economicActivityState.economicActivityList.map((economicActivity, index) => {
                  if (projectState?.economicActivity?.includes(economicActivity)) {
                    return false
                  }

                  return (<>
                    <ListItem
                      key={index}
                      style={{
                        padding: 0
                      }}>
                      <ListItemButton onClick={(e) => {
                        addEconomicActivityFilter({
                          economicActivity: economicActivity
                        })
                      }}>
                        <ListItemText primary={economicActivity.cnaeDescricao?.toUpperCase()} secondary={economicActivity.cnaeCodigo?.toUpperCase()} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>)
                })
              }
            </List>
          </Grid>
          {
            (economicActivityFilterState?.length ?? 0) > 0 && <Grid xs={6}>
              <Box p={3} my={3}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                      Atividades econômicas selecionadas
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EconomicActivityFilterPreview />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          }
        </Grid>
      </Dialog>
    </>
  )
}