import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubscription } from '../../models/ISubscription';

interface ISubscriptionState {
  subscription?: ISubscription
  usage?: {
    totalBySeparated: number
    usedBySeparated: number
    usedBySubscription: number
    type: string
    limit?: number
    consumptionLock?: number
  }
}

const initialState: ISubscriptionState = {

};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionState(state, action: PayloadAction<{
      subscription: ISubscription
    }>) {
      state.subscription = action.payload.subscription
    },
    setUsageState(state, action: PayloadAction<{
      usage: {
        totalBySeparated: number
        usedBySeparated: number
        usedBySubscription: number
        type: string
        limit?: number
        consumptionLock?: number
      }
    }>) {
      state.usage = action.payload.usage
    }


  },
});

export const {
  setSubscriptionState,
  setUsageState
} = subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;