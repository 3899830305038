import { Slide, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Box, List, ListItem, ListItemText, DialogActions, DialogContent, DialogTitle, TextField, Grid, FormControlLabel, Radio, RadioGroup, Divider, Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControl, FormGroup, FormLabel, InputAdornment, LinearProgress } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../use-cases/project";
import EditIcon from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { dataGridTexts } from "../../../../../assets/helper-assets";
import { CountReportFields, countReportFieldToLabel } from "../../../../../assets/helper-assets/CountReportFields";
import ClearIcon from '@mui/icons-material/Clear';
import { useCountReportApi } from "../../../../../../infra/count-report/useCountReportApi";
import { theme } from "../../../../../styles";
import { darkenColor, objectValuesToUpperCase } from "../../../../../../utils";
import { Reorder } from 'framer-motion'
const options = [
  'XLSX - (Excel)',
  'CSV - (Texto)',
]

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CountReportSection = () => {
  const [columnOpen, setColumnOpen] = React.useState(false);
  const selectedCountReport = useAppSelector((state) => state.project.selectedCountReport)
  const { closeCountReportModal, selectCountReport, updateCountReport, createCountReport, openCountReportModal, setCountReportModalAction, setCountReportList, setAction, downloadCountReport, deleteCountReport } = useProjectService()
  const countReportModalOpen = useAppSelector((state) => state.project.countReportModalOpen)

  const radioGroupRef = React.useRef<HTMLElement>(null);
  const radioGroupRef2 = React.useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };


  const handleEntering2 = () => {
    if (radioGroupRef2.current != null) {
      radioGroupRef2.current.focus();
    }
  };

  const handleCancel = () => {
    setColumnOpen(false)
    setColumnsToSelect({
      ...CountReportFields.reduce((acc, item) => {
        return {
          ...acc,
          [item]: false
        }
      }, {})
    })

  };
  const handleCancel2 = () => {
    setOpen(false)
  };

  const handleOk = () => {
    setColumnOpen(false)
    selectCountReport({
      countReport: {
        ...selectedCountReport,
        columns: [
          ...selectedCountReport.columns,
          ...Object.keys(columnsToSelect).filter((key) => columnsToSelect[key])
        ]
      }
    })
    setColumnsToSelect({
      ...CountReportFields.reduce((acc, item) => {
        return {
          ...acc,
          [item]: false
        }
      }, {})
    })
  };

  const { company } = useAppSelector((state) => state.company)
  const action = useAppSelector((state) => state.project.action)
  const modalAction = useAppSelector((state) => state.project.countReportModalAction)
  const [countReportLoaded, setCountReportLoaded] = useState<string[]>([])
  const [countReportLoading, setCountReportLoading] = useState<{
    resourceId: string,
    progress: number
  }[]>([])
  const { checkCountReport, proccessCountReportApi } = useCountReportApi()
  useEffect(() => {
    /* Check every second if the count report is loaded */
    const interval = setInterval(() => {
      if (countReportLoading.length > 0) {
        checkCountReport({
          countReportId: countReportLoading[0].resourceId
        }).then((res) => {
          if (res.status === 'completed') {
            setCountReportLoaded((prev) => {
              return [
                ...prev,
                res.resourceId
              ]
            })
            setCountReportLoading(countReportLoading.filter((item) => item.resourceId !== res.resourceId))
            setCountReportList()
          } else {
            setCountReportLoading((prev) => {
              let filered = prev.filter((item) => item.resourceId !== res.resourceId)
              return [
                ...filered,
                {
                  resourceId: res.resourceId ?? '',
                  progress: (res.progress ?? 0) * 100
                }
              ]

            })
          }
        })
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countReportLoading, checkCountReport, setCountReportList])


  const countReportList = useAppSelector((state) => state.project.countReportList)
  useEffect(() => {
    if (modalAction === 'create') {
      selectCountReport({
        countReport: {
          columns: [],
          companyId: '',
          createdAt: new Date(),
          createdBy: '',
          id: '',
          name: '',
          projectId: '',
          updatedAt: new Date(),
          rows: [],
        }
      })
    }
  }, [modalAction, selectCountReport])


  const [columnsToSelect, setColumnsToSelect] = React.useState<any>({
    ...CountReportFields.reduce((acc, item) => {
      return {
        ...acc,
        [item]: false
      }
    }, {})
  });



  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id)
  useEffect(() => {
    if (selectedProjectId) {
      setCountReportList()
    }
  }, [selectedProjectId, setCountReportList])

  const countReportFields = useAppSelector((state) => state.project.countReportForm.fields)
  const [value, setValue] = React.useState(options[0]);
  const [open, setOpen] = React.useState(false);
  const [inputSearchValue, setSearchInputValue] = useState('')

  const total: GridColDef = {
    field: 'total',
    headerName: 'TOTAL',
    minWidth: 250,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return params.value.toLocaleString('pt-BR')
    }
  }

  useEffect(() => {
    setCountReportLoaded([])
  }, [action])

  return <>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            '&:hover': {
              backgroundColor: darkenColor(theme.palette.primary.main, 15)
            },
            color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
          }}
          onClick={() => {
            setCountReportModalAction({
              countReportModalAction: 'create'
            })
            openCountReportModal()
          }}
          startIcon={
            <AddIcon style={{
              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
            }}
            />}
        >
          Criar relatório de contagem
        </Button>
      </Grid>
      {
        countReportList.map((countReport) => {
          return <>
            <Grid item xs={12}>
              <Accordion >
                <AccordionSummary
                  aria-controls="panel1a-content"
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h5" fontWeight={"bold"} color="primary">{countReport.name}</Typography>

                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <Grid container display="flex">
                            <Grid item xs={12}>
                              <Typography variant="h6" color={company?.resale === true || company?.resaleId ? theme.palette.primary.main : ''}>Colunas:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {countReport.columns.map((column, index) => {
                                return <Grid container>
                                  <Grid item xs={12}>
                                    <Typography color={company?.resale === true || company?.resaleId ? theme.palette.primary.main : ''}>{countReportFieldToLabel(column)}</Typography>
                                  </Grid>
                                </Grid>
                              })}
                            </Grid>
                          </Grid>

                        </Grid>
                        <Grid item>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            <ExpandMore />
                            <Typography variant="caption" display={"block"} color={company?.resale === true || company?.resaleId ? theme.palette.primary.main : ''}>
                              Clique para expandir
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box m={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <Button
                              sx={{
                                '&:hover': {
                                  backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                },
                                color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                              }}
                              onClick={() => {
                                setCountReportModalAction({
                                  countReportModalAction: 'edit'
                                })
                                selectCountReport({
                                  countReport
                                })
                                openCountReportModal()
                              }}
                              startIcon={<EditIcon />}
                              fullWidth
                              variant="contained"
                            >
                              Editar
                            </Button>
                          </Grid>

                          <Grid item xs={4}>
                            <Button
                              startIcon={<DownloadIcon />}
                              fullWidth
                              variant="contained"
                              disabled={(action === 'refresh') || (!countReportLoaded.includes(countReport.id))}
                              style={{
                                backgroundColor: (action === 'refresh') || (!countReportLoaded.includes(countReport.id)) ? '#808080' : company?.resale === true || company?.resaleId ? theme.palette.secondary.main : '#808080',
                                color: (action === 'refresh') || (!countReportLoaded.includes(countReport.id)) ? '#535554' : company?.resale === true || company?.resaleId ? theme.palette.secondary.contrastText : 'white'
                              }}
                              onClick={(e) => {
                                selectCountReport({
                                  countReport
                                })
                                setOpen(true)
                              }}
                            >
                              Baixar Relatório
                            </Button>
                          </Grid>

                          <Grid item xs={4}>
                            <Button
                              startIcon={<DeleteIcon />}
                              fullWidth
                              variant="contained"
                              color="error"
                              onClick={() => {
                                deleteCountReport({
                                  countReportId: countReport.id
                                })
                              }}
                            >
                              Excluir
                            </Button>
                          </Grid>

                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {
                        action === 'refresh' ? (
                          <>
                            <Box m={3}>
                              <Typography color={company?.resale === true || company?.resaleId ? theme.palette.primary.main : ''} variant="h6">Para exibir as informações, clique em "Atualizar"</Typography>
                            </Box>

                          </>
                        ) : (
                          <>
                            {
                              countReportLoaded.includes(countReport.id) ? (
                                <Box sx={{
                                  '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: company?.resale === true || company?.resaleId
                                      ? theme.palette.primary.contrastText
                                      : '#FFF'
                                  },
                                  '& .MuiDataGrid-cell': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  },
                                  '& .MuiDataGrid-cell:focus': {
                                    outline: 0
                                  },
                                  minWidth: '100%'
                                }}>
                                  <DataGrid
                                    showCellVerticalBorder
                                    disableColumnFilter
                                    disableColumnMenu
                                    rowSelection={false}
                                    autoHeight={true}
                                    localeText={dataGridTexts}
                                    pageSizeOptions={[5, 10, 20]}
                                    initialState={{
                                      pagination: {
                                        paginationModel: {
                                          pageSize: 10
                                        }
                                      }
                                    }}
                                    scrollbarSize={10}
                                    rows={countReport.rows.filter((item) => {
                                      if (item.Outros === 'Outros') {
                                        if (item.count > 0) {
                                          return true
                                        } else {
                                          return false
                                        }
                                      } else {
                                        return true
                                      }
                                    }).map((row, index) => {
                                      const rowOrdened: any = {}

                                      countReport.columns.forEach((column, index) => {
                                        rowOrdened[column] = row[column]
                                      })
                                      if (row.Outros) {
                                        rowOrdened['cnae'] = 'OUTROS'
                                      }
                                      rowOrdened["total"] = `${row.Outros ? ` (CNAES SECUNDÁRIOS) - ` : ''}${row.count.toLocaleString('pt-BR')}`

                                      const rowOrdenedToUppercase = objectValuesToUpperCase(rowOrdened)

                                      return {
                                        ...rowOrdenedToUppercase,
                                        id: index
                                      }
                                    })
                                    }
                                    columns={
                                      [...countReport.columns.map((column) => {
                                        const formattedColumn: GridColDef = {
                                          field: column,
                                          headerName: countReportFieldToLabel(column).toUpperCase(),
                                          flex: 1,
                                          headerAlign: 'center',
                                          align: 'center'
                                        }
                                        return formattedColumn
                                      }),
                                        total
                                      ]
                                    }
                                    sx={{
                                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                        outline: "none !important",
                                      },
                                      minWidth: '100%'
                                    }}
                                  />
                                </Box>
                              ) : <>
                                <Box my={3} p={3}>
                                  <Grid container spacing={3}>


                                    <Grid item xs={12}>
                                      {
                                        countReportLoading.find((item) => {
                                          return item.resourceId === countReport.id
                                        }) ? (
                                          <>
                                            <Box mb={3}>
                                              <LinearProgress variant={countReportLoading.find((item) => {
                                                return item.resourceId === countReport.id
                                              }) ? 'determinate' : 'indeterminate'} value={
                                                (countReportLoading.find((item) => {
                                                  return item.resourceId === countReport.id
                                                }
                                                )?.progress ?? undefined
                                                )
                                              } />
                                            </Box>
                                            {
                                              countReportLoading.find((item) => {
                                                return item.resourceId === countReport.id
                                              }
                                              )?.progress === 100 ? (
                                                <>
                                                  <Typography color="primary" fontWeight="bold" align="center">
                                                    Finalizando processamento...
                                                  </Typography>
                                                </>
                                              ) : <Typography color="primary" fontWeight="bold" align="center">
                                                Processando relatório...
                                              </Typography>
                                            }
                                          </>
                                        ) : <Grid item xs={12}>

                                          <Button
                                            sx={{
                                              '&:hover': {
                                                backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                              },
                                              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                            }}
                                            disabled={countReportLoading.length > 0}
                                            onClick={() => {
                                              proccessCountReportApi({
                                                countReportId: countReport.id,
                                                createdBy: '123'
                                              }).then(() => {
                                                setCountReportLoading([...countReportLoading, {
                                                  progress: 0,
                                                  resourceId: countReport.id
                                                }])
                                              })
                                            }}
                                            variant='contained'
                                            fullWidth
                                          >
                                            Processar relatório
                                          </Button>
                                        </Grid>
                                      }
                                    </Grid>



                                  </Grid>
                                </Box>

                              </>
                            }
                          </>
                        )
                      }

                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        })
      }
    </Grid>
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={columnOpen}
    >
      <DialogTitle>Adicionar coluna</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 3, my: 1 }}>
          <TextField
            fullWidth
            value={inputSearchValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value)
            }}
            label="Pesquisar"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchInputValue('')
                    }}
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

        </Box>
        <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <FormLabel component="legend" style={{ justifyContent: 'center' }}>Colunas</FormLabel>
          <FormGroup style={{ justifyContent: 'center' }}>
            {
              CountReportFields.filter((item) => {
                return !selectedCountReport.columns.includes(item)
              }).filter((item) => {
                return item.toLowerCase().includes(inputSearchValue.toLowerCase())
              }).map((field) => {
                return <FormControlLabel
                  control={<Checkbox checked={columnsToSelect[field]} onChange={(e) => {
                    setColumnsToSelect({
                      ...columnsToSelect,
                      [field]: e.target.checked
                    })
                  }} name={field} />}
                  label={countReportFieldToLabel(field)}
                />
              })
            }
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          disabled={Object.keys(columnsToSelect).filter((column) => {
            return columnsToSelect[column]
          }).length === 0}
          onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      fullScreen
      open={countReportModalOpen ?? false}
      onClose={() => {
        closeCountReportModal()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
            Layout de relatório de contagem
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            closeCountReportModal()
          }}>
            Fechar
          </Button>

        </Toolbar>
      </AppBar>
      <Box p={3}>
        <Typography color="primary" fontWeight="bold">
          Escolha as colunas que deseja exportar. A ordem das colunas é a mesma em que aparecerão no arquivo de exportação. Clique, segure e arraste para reordenar as colunas.
        </Typography>
        <Box my={3}>

          <TextField
            fullWidth
            error={!countReportFields.name.isValid}
            helperText={!countReportFields.name.isValid && `${countReportFields.name.error?.message}`}
            label="Digite o nome do relatório de contagem"
            value={selectedCountReport.name}
            onChange={(e) => {
              selectCountReport({
                countReport: {
                  ...selectedCountReport,
                  name: e.target.value
                }
              })
            }}
          />
        </Box>
        <List>
          <Reorder.Group
            key={99}
            style={{
              listStyle: 'none',
              margin: 0,
              padding: 0,
              alignItems: 'start'
            }}
            axis="y"
            values={selectedCountReport.columns}
            onReorder={(newOrder) => {
              selectCountReport({
                countReport: {
                  ...selectedCountReport,
                  columns: newOrder
                }
              })
            }}
          >
            {selectedCountReport.columns.map((item) => (
              <Reorder.Item style={{ listStyle: 'none' }} value={item} key={item}>
                <ListItem
                  button
                  secondaryAction={<>
                    <Button onClick={() => {
                      selectCountReport({
                        countReport: {
                          ...selectedCountReport,
                          columns: selectedCountReport.columns.filter((column) => {
                            return column !== item
                          })
                        }
                      })
                    }} endIcon={<>
                      <CloseIcon />
                    </>
                    }>
                      Excluir
                    </Button>
                  </>}
                  style={{
                    padding: '10px 0px',
                    margin: '5px',
                    listStyle: 'none',
                    cursor: 'grab'
                  }}
                >
                  <ListItemText primary={countReportFieldToLabel(item)} />
                  <Divider variant="inset" component="li" />

                </ListItem>
              </Reorder.Item>
            ))}
          </Reorder.Group>
        </List>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Button
              onClick={() => {
                setColumnOpen(true)
              }}
              endIcon={
                <>
                  <AddIcon />
                </>
              }>
              Clique para adicionar uma nova coluna
            </Button>

          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                mt: 2,
                '&:hover': {
                  backgroundColor: darkenColor(theme.palette.primary.main, 10)
                },
                color: theme.palette.primary.contrastText
              }}
              style={{
                marginTop: '30px'
              }}
              fullWidth
              onClick={() => {
                setAction({
                  action: 'refresh'
                })
                if (modalAction === 'create') {
                  createCountReport()
                } else if (modalAction === 'edit') {
                  updateCountReport()
                }
              }}
              variant="contained"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering2 }}
      open={open}
    >
      <DialogTitle> Baixar contagem </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={(e) => {
            setValue((e.target as HTMLInputElement).value);
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel2}>
          Cancelar
        </Button>
        <Button onClick={() => {

          setOpen(false)
          if (value.toUpperCase().includes('XLSX')) {
            downloadCountReport({
              type: 'xlsx'
            })
          } else {
            downloadCountReport({
              type: 'csv'
            })
          }

        }}>Ok</Button>
      </DialogActions>
    </Dialog>
  </>
}