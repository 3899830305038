import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { setLocationCategoriaState, setLocationSearchTermState, setLocationsState } from "../../application/states/location"
import { useLocationApi } from "../../infra/location"

export const useLocationService = () => {
  const dispatch = useAppDispatch()
  const { findLocationBySearchTerm } = useLocationApi()
  const locationState = useAppSelector((state) => state.location)
  const setSearchTerm = useCallback((data: {
    searchTerm: string
  }) => {
    dispatch(setLocationSearchTermState({
      searchTerm: data.searchTerm
    }))
  }, [dispatch])

  const setCategoria = useCallback((data: {
    categoria: string
  }) => {
    dispatch(setLocationCategoriaState({
      categoria: data.categoria
    }))
  }, [dispatch])


  const searchLocations: () => void = useCallback(() => {
    findLocationBySearchTerm({
      searchTerm: locationState.searchTerm,
      categoria: locationState.categoria
    }).then((locations) => {
      dispatch(setLocationsState({
        locations: locations
      }))
    }).catch((error) => {
    })
  }, [locationState.searchTerm, findLocationBySearchTerm, dispatch, locationState.categoria])

  const resetLocationsList = useCallback(() => {
    dispatch(setLocationsState({
      locations: []
    }))
  }, [dispatch])


  return {
    setSearchTerm,
    searchLocations,
    setCategoria,
    resetLocationsList
  }
}