import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
// import { Pie } from 'react-chartjs-2';
// import { Tooltip } from '@mui/material'
import { useProjectService } from "../../../../use-cases/project";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../application/states/hooks";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { ProjectListTable } from "./project-card";
import { theme } from "../../../styles";
import { darkenColor } from "../../../../utils";

export const ProjectListPage = () => {
  // States
  const createProjectFormOpen = useAppSelector((state) => state.project.createProjectForm.open)
  const projectList = useAppSelector((state) => state.project.projectList)
  const { active } = useAppSelector((state) => state.project)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const projectName = useAppSelector((state) => state.project.createProjectForm.name)
  const projectNameFieldProps = useAppSelector((state) => state.project.createProjectForm.fields.name)
  const [searchTerm, setSearchTerm] = useState('')
  const formAction = useAppSelector((state => state.project.createFormAction))
  const projectToClone = useAppSelector((state => state.project.projectToClone))
  const { company } = useAppSelector((state) => state.company)
  // Services
  const {
    createProject,
    setProjectName,
    openCreateProjectForm,
    closeCreateProjectForm,
    cloneProject,
    updateProjectName,
    setProjectFilterActive,
    setProjectList
  } = useProjectService()

  useEffect(() => {
    setProjectList()
  }, [setProjectList])

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Projetos`
  }, [resaleConfig])

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={
        createProjectFormOpen
      } onClose={() => {
        closeCreateProjectForm()
      }}>
        <DialogTitle>
          {
            formAction === 'CREATE' ? 'Criar projeto' : ''
          }
          {
            formAction === 'CLONE' ? 'Duplicar projeto' : ''
          }
          {
            formAction === 'EDIT' ? 'Editar projeto' : ''
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escolha um nome para o projeto
          </DialogContentText>
          <Box my={3}>
            <TextField
              error={!projectNameFieldProps.isValid}
              helperText={!projectNameFieldProps.isValid && `${projectNameFieldProps.error?.message}`}
              value={projectName}
              onChange={(e) => {
                setProjectName({
                  name: e.target.value
                })
              }}
              autoFocus
              id="nomeproj"
              label="Digite o nome do projeto"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            closeCreateProjectForm()
          }}>Cancelar</Button>
          <Button onClick={() => {
            if (formAction === 'CREATE') {
              createProject()
            } else if (formAction === 'CLONE') {
              cloneProject({
                projectId: projectToClone ?? ''
              })
            } else if (formAction === 'EDIT') {
              updateProjectName({
                name: projectName,
                projectId: projectToClone ?? ''
              })
            }
          }}>
            {
              formAction === 'CREATE' ? 'Criar' : ''
            }
            {
              formAction === 'CLONE' ? 'Duplicar' : ''
            }
            {
              formAction === 'EDIT' ? 'Editar' : ''
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Fab
        onClick={() => {
          openCreateProjectForm({
            action: 'CREATE'
          })
          setProjectName({
            name: ''
          })
        }}
        style={{
          position: 'fixed',
          bottom: '5%',
          right: '5%'
        }}
        color="primary"
        aria-label="add"
        sx={{
          '&:hover': {
            backgroundColor: darkenColor(theme.palette.primary.main, 10)
          }
        }}
      >
        <AddIcon style={{
          color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
        }} />
      </Fab>
      <Box p={3}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <ProjectsFilterForm />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
              fullWidth
              id="search"
              label="Pesquisar"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <SearchIcon />
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={
              <Switch
                checked={!active}
                onChange={(e) => {
                  setProjectFilterActive({
                    active: e.target.checked
                  })
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label={'Mostrar projetos arquivados'} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProjectListTable {...{
              projectList: projectList,
              searchTerm: searchTerm
            }} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}