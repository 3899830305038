import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { setExportListState, setProjectExportPageState } from "../../application/states/project-export/project-export-slice"
import { useProjectExportApi } from "../../infra/project-export"
import { useLoadingService } from "../loading"

export const useProjectExportService = () => {
  const { findProjectExportByUserId, recreateExportFileApi, getExportStatusApi } = useProjectExportApi()
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const page = useAppSelector((state) => state.projectExport.page)
  const { startLoading, stopLoading } = useLoadingService()

  const setProjectExportList = useCallback(async () => {
    if (user.id === '' || !user.id) {
      return
    }

    startLoading()
    const projectExportList = await findProjectExportByUserId({
      userId: user.id,
      limit: 10,
      page: page
    })

    dispatch(setExportListState({
      exportList: projectExportList.projectExportList,
      page: projectExportList.page,
      total: projectExportList.total
    }))
    
    stopLoading()

  }, [dispatch, findProjectExportByUserId, user.id, page, startLoading, stopLoading])

  const recreateProjectExport = useCallback((data: {
    projectExportId: string
  }) => {
    recreateExportFileApi({
      projectExportId: data.projectExportId
    }).then(() => {
      setProjectExportList()
    }).catch((err) => {
    })
  }, [recreateExportFileApi, setProjectExportList])

  const getExportStatus = useCallback((data: {
    projectExportId: string
  }): Promise<{
    status: 'pending' | 'completed' | 'failed' | 'processing',
    progress?: number
  }> => {
    return getExportStatusApi({
      projectExportId: data.projectExportId
    })
  }, [getExportStatusApi])

  const setPage = useCallback((data: {
    page: number
  }) => {
    dispatch(setProjectExportPageState(data.page))
  }, [
    dispatch,
  ])

  return {
    setProjectExportList,
    recreateProjectExport,
    getExportStatus,
    setPage

  }
}
