import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IProjectResume } from "../../../../../application/states/project";
import { dataGridTexts } from "../../../../assets/helper-assets";
import { useAppSelector } from "../../../../../application/states/hooks";
import { useProjectService } from "../../../../../use-cases/project";
import { Box, Grid, Button } from "@mui/material";
import { format } from "date-fns";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { theme } from "../../../../styles";

export const ProjectListTable = (data: { projectList: IProjectResume[], searchTerm: string }) => {

    const { page, total, active, pageSize } = useAppSelector((state) => state.project)
    const { company } = useAppSelector((state) => state.company)
    const {
        setPage,
        openProject,
        openCreateProjectForm,
        setProjectName,
        updateProjectStatus,
        setPageSize
    } = useProjectService()

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: 270,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <>
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            openProject({
                                projectId: params.value.id
                            })
                        }}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        {params.value.name}
                    </Grid>
                </>
            )
        },
        {
            field: 'createdBy',
            headerName: 'Criado por',
            minWidth: 250,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            zIndex: 999
                        }}
                    >
                        {params.value.userName}
                    </Grid>
                </>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Data de Criação',
            minWidth: 200,

            type: 'date',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return format(new Date(params.value as string), 'dd/MM/yyyy - HH:mm ')
            }
        },
        {
            field: 'duplicate',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Duplicar Projeto',
            minWidth: 150,
            renderCell: (params) => (
                <>
                    <Button onClick={() => {
                        openCreateProjectForm({
                            action: 'CLONE',
                            projectToCLone: params.value.id
                        })
                        setProjectName({
                            name: params.value.name + ' - Cópia'
                        })
                    }}>
                        <ContentCopyIcon />
                    </Button>
                </>
            )
        },
        {
            field: 'rename',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Renomear Projeto',
            minWidth: 180,
            renderCell: (params) => (
                <>
                    <Button
                        onClick={() => {
                            openCreateProjectForm({
                                action: 'EDIT',
                                projectToCLone: params.value.id
                            })

                            setProjectName({
                                name: params.value.name
                            })
                        }}>
                        <EditIcon />
                    </Button>
                </>
            )
        },
        {
            field: 'active',
            headerAlign: 'center',
            align: 'center',
            headerName: active ? 'Arquivar Projeto' : 'Desarquivar Projeto',
            minWidth: 180,
            renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            updateProjectStatus({
                                projectId: params.value.id,
                                status: !params.value.active
                            })
                        }
                        } >
                        {
                            active ?
                                <VisibilityOffIcon />
                                :
                                <VisibilityIcon />
                        }
                    </Button >
                )
            }
        },
    ]

    return (
        <Box sx={{
            '& .MuiDataGrid-columnHeader': {
                backgroundColor: theme.palette.primary.main,
                color: company?.resale === true || company?.resaleId
                    ? theme.palette.primary.contrastText
                    : '#FFF'
            },
            '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .MuiDataGrid-cell:focus': {
                outline: 0
            },
            minWidth: '100%'
        }}
        >
            <DataGrid
                showCellVerticalBorder
                disableColumnMenu
                disableColumnFilter
                disableRowSelectionOnClick
                autoHeight={true}
                localeText={dataGridTexts}
                pagination={true}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={{
                    page: page - 1,
                    pageSize: pageSize
                }}
                onPaginationModelChange={(params) => {
                    setPage({
                        page: params.page + 1
                    })
                    setPageSize({
                        pageSize: params.pageSize
                    })
                }}
                paginationMode='server'
                rowCount={total}
                rows={data.projectList.filter((project) => {
                    if (data.searchTerm === '' || data.searchTerm === null || data.searchTerm === undefined) {
                        return true
                    } else {
                        return project.name.toLowerCase().includes(data.searchTerm.toLowerCase()) || project.userName?.toLowerCase().includes(data.searchTerm.toLowerCase())
                    }
                }).map((project) => {
                    return {
                        id: project.id,
                        name: project,
                        createdBy: project,
                        createdAt: new Date(project.createdAt),
                        duplicate: project,
                        rename: project,
                        active: project
                    }
                })
                }
                columns={columns}
                slotProps={{
                    pagination: {
                        style: {
                            marginRight: '35%'
                        }
                    }
                }}
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: 0
                    },
                    minWidth: '100%'
                }}
            />
        </Box>
    )
}