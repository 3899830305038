export const calculateContrastColor = (backgroundColor: string): string => {
    const hexToRgb = (hex: string): [number, number, number] => {
        const bigint = parseInt(hex.slice(1), 16)
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255]
    }

    const [r, g, b] = hexToRgb(backgroundColor)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 125 ? '#000000' : '#ffffff'
}