// DUCKS pattern
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../models/IUser';
import { IBackofficeFindUserQueryObject } from '../../models';


interface IUserState {
  id: string,
  name: string,
  email: string,
  companyId: string,
  createdAt: Date,
  updatedAt: Date,
  userType: 'SELLER' | 'ADMIN' | 'OWNER' | 'OPERATOR',
  img?: any,
  userImageFileInputValue?: any,
  changePasswordValue: string,
  changePasswordConfirmValue: string,
  selectedUser?: IUser
  userList: IUser[]
  selectedSeller?: IUser
  searchTerm: string
  filters: IBackofficeFindUserQueryObject
}

const initialState: IUserState = {
  id: '',
  name: '',
  email: '',
  companyId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  userType: 'SELLER',
  changePasswordValue: '',
  changePasswordConfirmValue: '',
  userList: [],
  searchTerm: '',
  filters: {
    searchTerm: '',
    companyId: [],
    email: [],
    name: [],
    userType: []
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setChangePasswordValueState(state, action: PayloadAction<{
      value: string
    }>) {
      state.changePasswordValue = action.payload.value;
    },
    setChangePasswordConfirmValueState(state, action: PayloadAction<{
      value: string
    }>) {
      state.changePasswordConfirmValue = action.payload.value;
    },

    setUserEmailState(state, action: PayloadAction<{
      email: string
    }>) {
      state.email = action.payload.email;
    },
    setUserNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.name = action.payload.name;
    },

    setUserState(state, action: PayloadAction<{
      user: IUser
    }>) {
      state.id = action.payload.user.id;
      state.name = action.payload.user.name;
      state.email = action.payload.user.email;
      state.companyId = action.payload.user.companyId;
      state.createdAt = action.payload.user.createdAt;
      state.updatedAt = action.payload.user.updatedAt;
      state.userType = action.payload.user.userType;
      state.img = action.payload.user.img;
    },
    resetUserState(state) {
      state.id = '';
      state.name = '';
      state.email = '';
      state.companyId = '';
      state.createdAt = new Date();
      state.updatedAt = new Date();
      state.userType = 'SELLER';
    },
    selectUserState(state, action: PayloadAction<{
      user: IUser
    }>) {
      state.selectedUser = action.payload.user;
    },
    selectSellerState(state, action: PayloadAction<{
      seller: IUser
    }>) {
      state.selectedSeller = action.payload.seller;
    },
    setUserListState(state, action: PayloadAction<{
      userList: IUser[]
    }>) {
      state.userList = action.payload.userList;
    },
    setSelectedUserImageFileState(state, action: PayloadAction<{
      image?: File
    }>) {
      if (!state.selectedUser) {
        return
      }
      state.selectedUser!.img = action.payload.image
    },
    setUserImageFileInputValueState(state, action: PayloadAction<{
      image?: File
    }>) {
      state.userImageFileInputValue = action.payload.image
    },
    setUserSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    },
    addEmailFilterState(state, action: PayloadAction<{
      email: string
    }>) {
      const { email } = action.payload
      const { filters } = state
      if (filters) {
        if (filters.email) {
          const findEmail = filters.email.find(findedEmail => findedEmail === email)
          if (!findEmail) {
            filters.email.push(email)
          }
        } else {
          filters.email = []
          filters.email.push(email)
        }
      }
    },
    removeEmailFilterState(state, action: PayloadAction<{
      email: string
    }>) {
      const { email } = action.payload
      const { filters } = state
      if (filters) {
        if (filters.email) {
          const findEmail = filters.email.find(findedEmail => findedEmail === email)
          if (findEmail) {
            const index = filters.email.indexOf(findEmail)
            filters.email.splice(index, 1)
          }
        }
      }
    }
  }
});

export const {
  setUserState,
  resetUserState,
  setUserEmailState,
  setUserNameState,
  setChangePasswordValueState,
  setChangePasswordConfirmValueState,
  selectUserState,
  setUserListState,
  setSelectedUserImageFileState,
  setUserImageFileInputValueState,
  selectSellerState,
  setUserSearchTermState,
  addEmailFilterState,
  removeEmailFilterState
} = userSlice.actions;
export default userSlice.reducer;