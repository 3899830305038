import { List, Grid, ListItem, Button, Typography, useTheme, Box, ListItemText } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { CompanyTooltip } from "../CompanyTooltip";
import { useBackofficeService } from "../../../../../../../../../../use-cases/backoffice";
import { useAppSelector } from "../../../../../../../../../../application/states/hooks";

export const CompanyRecipientFormFilterPreview = () => {

    const theme = useTheme()
    const companiesState = useAppSelector((state) => state.backofficeCompany)
    const { removeRazaoSocialFilter } = useBackofficeService()

    return (
        <>
            <List>
                <Grid container spacing={3}>
                    {companiesState?.filters?.razaoSocial?.map((company, index) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <CompanyTooltip
                                    title={
                                        <Box
                                            style={{
                                                height: '300px',
                                                overflow: 'scroll'
                                            }}
                                            p={4}
                                        >
                                            <div>
                                                <Typography variant="h5" color="primary" fontWeight={"bold"}>
                                                    Razão Social: {company}
                                                </Typography>
                                            </div>
                                            <Box my={3}>
                                                <Typography variant="h6" fontWeight={"bold"}>
                                                    CNPJ: {companiesState.companyList.map((companies) => {
                                                        if (companies.company.razaoSocial.includes(company)) {
                                                            return companies.company.cnpj
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                </Typography>
                                            </Box>
                                            <List>
                                                <Grid container key={index}>
                                                    <Grid item xs={6} key={index}>
                                                        <ListItem key={index}>
                                                            <ListItemText primary={companiesState.companyList.find((companies) => {
                                                                if (companies.company.razaoSocial.includes(company)) {
                                                                    return companies.company.id
                                                                } else {
                                                                    return null
                                                                }
                                                            })!.company.email.toLocaleUpperCase()
                                                            } />
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>


                                            </List>
                                        </Box>
                                    }
                                    placement="top"
                                >
                                    <div>
                                        <ListItem
                                            style={{
                                                backgroundColor: theme.palette.primary.main,
                                                borderRadius: '50px',
                                                padding: '15px'
                                            }}
                                            key={index}
                                            secondaryAction={<>
                                                <Button
                                                    onClick={() => {
                                                        removeRazaoSocialFilter({
                                                            razaoSocial: company,
                                                            companyId: companiesState.companyList.find((companies) => {
                                                                if (companies.company.razaoSocial.includes(company)) {
                                                                    return companies.company.id
                                                                } else {
                                                                    return null
                                                                }
                                                            })!.company.id
                                                        })
                                                    }}
                                                    style={{
                                                        color: 'white'
                                                    }} endIcon={<>
                                                        <DeleteIcon style={{
                                                            color: 'white'
                                                        }} />
                                                    </>}>
                                                    Remover
                                                </Button>
                                            </>
                                            }>
                                            <Box mr={5} style={{
                                                maxWidth: '80%'
                                            }}>
                                                <Typography color="white" fontWeight="bold">
                                                    {company}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    </div>
                                </CompanyTooltip>
                            </Grid>
                        )
                    })}
                </Grid>
            </List >
        </>
    )
}