import { IFileExtensionOption } from "../../../application/states/project-download";

export const fileExtensionsOptions: IFileExtensionOption[] = [
  {
    label: 'EXCEL - (.xlsx)',
    value: 'xlsx'
  },
  {
    label: 'CSV - (.csv)',
    value: 'csv'
  },
  {
    label: 'TEXTO - (.txt)',
    value: 'txt'
  }
  // {
  //   label: 'TEXTO - (.txt)',
  //   value: 'txt'
  // }
]