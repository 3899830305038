import { List, Grid, Typography, Box, Chip, Avatar } from "@mui/material"
import { useAppSelector } from "../../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../../use-cases/project";
import { SegmentosTooltip } from "../segmento-tooltip";
import ApartmentIcon from '@mui/icons-material/Apartment'
import GridOnIcon from '@mui/icons-material/GridOn';
import { theme } from "../../../../../../styles";

export const SegmentosFilterPreview = () => {
  const projectState = useAppSelector((state) => state.project)
  const { removeSegmentoFilter } = useProjectService()

  return (
    <>
      <List>
        <Grid container spacing={3}>
          {projectState?.selectedFilter?.segmentos?.map((segmento, index) => {
            return (
              <Grid item key={index}>
                <Chip sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    transition: '0.1s all',
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px 15px',
                    fontWeight: 'bold'
                  }
                }}
                  color="primary"
                  onDelete={() => {
                    removeSegmentoFilter({
                      segmento: segmento
                    })
                  }}
                  avatar={
                    <Avatar
                      style={{
                        cursor: 'pointer',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                      }}>
                      <SegmentosTooltip
                        title={
                          <Box
                            style={{
                              height: '300px',
                              overflow: 'scroll'
                            }}
                            p={4}
                          >
                            <div>
                              <Typography variant="h5" color="primary" fontWeight={"bold"}>
                                Segmento: {segmento.segmento?.toUpperCase()}
                              </Typography>
                            </div>
                            <List>
                              <Grid container spacing={1}>
                                {
                                  segmento.cnaesWithDescription?.map((subSegmento) => {
                                    return (<>
                                      <Grid item>
                                        <Chip
                                          avatar={<ApartmentIcon />}
                                          label={subSegmento}
                                        />
                                      </Grid>
                                    </>)
                                  })
                                }
                              </Grid>
                            </List>
                          </Box>}
                        placement="top"
                      >
                        <GridOnIcon sx={{
                          "&": {
                            transition: '0.1s all'
                          },
                          "&:hover": {
                            color: "secondary.main"
                          }
                        }} />
                      </SegmentosTooltip>
                    </Avatar>
                  } label={segmento.segmento} />
              </Grid>
            )
          })}
        </Grid>
      </List >
    </>
  )
}