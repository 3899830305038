import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../../application/states/hooks"
import { IProps } from "./types/IProps"

export const PrivateRoute = (props: IProps) => {
  const isValid = useAppSelector((state) => state.auth.isValid)

  return (
    <>
      {
        isValid || isValid === undefined ? (
          <>
            {props.children}
          </>
        ) : <Navigate to="/authentication/login" />
      }
    </>
  )
}