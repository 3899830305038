import L from "leaflet";
import 'leaflet.heat';
import 'leaflet/dist/leaflet.css';
import GeoHash from 'latlon-geohash'

import { useRef, useEffect } from "react";
export const HeatMap = (props: {
  geoHashes: any
}) => {
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (!mapRef.current) {
      // Cria o mapa e armazena a referência no elemento do mapa
      mapRef.current = L.map('map').setView([-14.2350, -51.9253], 4.2);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapRef.current);

      let maxDocCount = 0

      props.geoHashes?.forEach((item: any) => {
        if (item.doc_count > maxDocCount) {
          maxDocCount = item.doc_count
        }
      })

      L.heatLayer(props.geoHashes?.map((item: any) => {
        const decodedGeoHash = GeoHash.decode(item.key);

        return [
          decodedGeoHash.lat,
          decodedGeoHash.lon,
          item.doc_count / maxDocCount
        ]
      }) ?? [], {
        radius: 25,
        minOpacity: 0.3,
      }).addTo(
        mapRef.current
      );

      // for (let i = 0; i < (Mock as any).length; i++) {

      //   let item = (Mock as any)[i]
      //   L.circleMarker([item.lat, item.lon], {
      //     radius: 5,
      //     fillColor: "#723989",
      //     color: "#fff",
      //     weight: 1,
      //     opacity: 1,
      //     fillOpacity: 0.8
      //   }).addTo(mapRef.current);
      // }


    }
  }, [props.geoHashes])
  return <>
    <div>
      <div id="map" style={{ width: '100%', height: '600px' }}></div>
    </div>
  </>
}