import { Grid, Typography, Box, TextField } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { usePasswordRecoveryService } from "../../../../../use-cases/password-recovery"

export const ChangePasswordForm = () => {
  const passwordFieldProps = useAppSelector(state => state.passwordRecovery.fields.password)
  const confirmPasswordFieldProps = useAppSelector(state => state.passwordRecovery.fields.confirmPassword)
  const password = useAppSelector(state => state.passwordRecovery.passwordRecoveryModel.password)
  const passwordConfirmation = useAppSelector(state => state.passwordRecovery.passwordRecoveryModel.confirmPassword)
  const { setPassword, setPasswordConfirmation } = usePasswordRecoveryService()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" align="center" color="primary" fontWeight="bold">
            Recuperar senha
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="p" align="center">
            Insira a nova senha que deseja usar para acessar sua conta no campo de texto abaixo e clique em "Finalizar". Em seguida, você será redirecionado para a página de login.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="body1" component="p" align="center" fontWeight="bold">
              Lembre-se de seguir todas as instruções fornecidas pelo sistema para garantir a segurança da sua conta. Se você tiver problemas para recuperar sua senha, entre em contato com o suporte ao cliente para obter ajuda adicional.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nova senha"
            type="password"
            value={password}
            error={!passwordFieldProps.isValid}
            helperText={!passwordFieldProps.isValid && `${passwordFieldProps.error?.message}`}
            onChange={(e) => {
              setPassword({
                password: e.target.value
              })
            }}
            fullWidth />

        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Confirme a nova senha"
            type={"password"}
            value={passwordConfirmation}
            error={!confirmPasswordFieldProps.isValid}
            helperText={!confirmPasswordFieldProps.isValid && `${confirmPasswordFieldProps.error?.message}`}
            onChange={(e) => {
              setPasswordConfirmation({
                passwordConfirmation: e.target.value
              })
            }}
            fullWidth />
        </Grid>
      </Grid>
    </>
  )
}