import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fileExtensionsOptions } from '../../../presentation/assets/helper-assets/fileExtensions';
import { IProjectExport } from '../../models';

export interface IFileExtensionOption {
  label: string,
  value: string
}

interface IExportDownloadState {
  modalOpen?: boolean,
  selectedFileExtension: IFileExtensionOption,
  fileExtensions: IFileExtensionOption[],
  fileExtensionInputValue?: string,
  selectedProject: string,
  selectedProjectExport?: IProjectExport
}

const initialState: IExportDownloadState = {
  modalOpen: false,
  fileExtensions: [
    ...fileExtensionsOptions
  ],
  selectedFileExtension: fileExtensionsOptions[0],
  selectedProject: ''
};

const exportDownloadSlice = createSlice({
  name: 'project-download',
  initialState,
  reducers: {
    setExportDownloadModalOpen(state, action: PayloadAction<{
      modalOpen: boolean
    }>) {
      state.modalOpen = action.payload.modalOpen
    },
    selectFileExtensionState(state, action: PayloadAction<{
      selectedFileExtension: IFileExtensionOption
    }>) {
      state.selectedFileExtension = action.payload.selectedFileExtension
    },
    setFileExtensionInputValueState(state, action: PayloadAction<{
      fileExtensionInputValue: string
    }>) {
      state.fileExtensionInputValue = action.payload.fileExtensionInputValue

    },
    setSelectedProjectExportState(state, action: PayloadAction<{
      selectedProjectExport: IProjectExport
    }>) {
      state.selectedProjectExport = action.payload.selectedProjectExport
    }

  },
});

export const { 
  setExportDownloadModalOpen,
  selectFileExtensionState,
  setFileExtensionInputValueState,
  setSelectedProjectExportState
 } = exportDownloadSlice.actions;
export const exportDownloadReducer = exportDownloadSlice.reducer;