export const dataGridTexts = {
  actionsCellMore: 'Mais',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Desordenar',
  columnMenuSortAsc: 'Ordenar crescente',
  columnMenuSortDesc: 'Ordenar decrescente',
  filterOperatorAfter: 'depois',
  filterOperatorBefore: 'antes',
  filterOperatorContains: 'contém',
  filterOperatorEquals: 'é igual a',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'não é',
  columnMenuManageColumns: 'Gerenciar colunas',
  columnMenuShowColumns: 'Mostrar colunas',
  columnsPanelShowAllButton: 'Mostrar tudo',
  columnsPanelHideAllButton: 'Esconder tudo',
  filterPanelInputPlaceholder: 'Filtrar valor',
  filterPanelInputLabel: 'Filtrar',
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Excluir',
  aggregationFunctionLabelAvg: 'Média',
  aggregationFunctionLabelMax: 'Máximo',
  aggregationFunctionLabelMin: 'Mínimo',
  aggregationFunctionLabelSize: 'Tamanho',
  aggregationMenuItemHeader: 'Agregação',
  columnHeaderFiltersLabel: 'Filtros',
  columnHeaderSortIconLabel: 'Ordenar',
  columnMenuLabel: 'Menu',
  columnsPanelDragIconLabel: 'Reordenar coluna',
  columnsPanelTextFieldLabel: 'Encontrar coluna',
  columnsPanelTextFieldPlaceholder: 'Título da coluna',
  collapseDetailPanel: 'Recolher',
  filterPanelColumns: 'Colunas',
  footerTotalRows: 'Total de linhas:',
  filterOperatorOnOrAfter: 'em ou depois',
  filterOperatorOnOrBefore: 'em ou antes',
  filterPanelLogicOperator: 'Operador lógico',
  filterOperatorIsAnyOf: 'é qualquer um de',
  detailPanelToggle: 'Expandir',
  aggregationFunctionLabelSum: 'Soma',
  booleanCellFalseLabel: 'falso',
  booleanCellTrueLabel: 'verdadeiro',
  checkboxSelectionHeaderName: 'Seleção',
  checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
  checkboxSelectionSelectRow: 'Selecionar linha',
  checkboxSelectionUnselectAllRows: 'Desmarcar todas as linhas',
  checkboxSelectionUnselectRow: 'Desmarcar linha',
  expandDetailPanel: 'Expandir',
  noRowsLabel: 'Nenhuma linha',
  footerTotalVisibleRows: (visibleCount: any, totalCount: any) => {
    return `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`
  },
  MuiTablePagination: {
    labelRowsPerPage: 'Linhas por página:',
    labelDisplayedRows: ({ from, to, count }: any) => {
      return `${from.toLocaleString()}-${to.toLocaleString()} de ${count.toLocaleString()}`
    },
  }

}
