import { Button } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

export const CreateNotificationButton = () => {

    return <>
        <Button
            variant="contained"
            fullWidth
            type="submit"
            endIcon={<AddIcon />}
            sx={{ borderRadius: '5px', fontWeight: 'bold' }}
        >
            Salvar
        </Button>
    </>
}