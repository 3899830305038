import { TextField } from "@mui/material"
import { useBackofficeService } from "../../../../../../../../../use-cases/backoffice"
import { useAppSelector } from "../../../../../../../../../application/states/hooks"

export const TitleCreateNotification = () => {

    const { selectNotification } = useBackofficeService()
    const userState = useAppSelector((state) => state.user)
    const notificationState = useAppSelector((state) => state.backofficeNotification)

    return <>
        <TextField
            disabled={userState.userType === 'SELLER'}
            error={!notificationState.notificationForm.formFields.title.isValid}
            helperText={!notificationState.notificationForm.formFields.title.isValid ? notificationState.notificationForm.formFields.title.error?.message : ''}
            id="outlined-basic"
            label="Título"
            variant="outlined"
            type="text"
            fullWidth
            InputLabelProps={{
                shrink: true
            }}
            onChange={(e) => {
                if (notificationState.selectedNotification) {
                    selectNotification({
                        notification: {
                            ...notificationState.selectedNotification,
                            title: e.target.value
                        }
                    })
                }
            }}
        />
    </>
}