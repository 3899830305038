import { Slide, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Box, List, ListItem, ListItemText, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, FormControlLabel, Divider, Checkbox, FormControl, FormGroup, InputAdornment } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../application/states/hooks";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import { useExportLayoutService } from "../../../../../use-cases/export-layout";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { ILayoutColumn } from "../../../../../application/models";
import extraCols from "../../../../assets/helper-assets/ExtraColsFields";
import ClearIcon from '@mui/icons-material/Clear';
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";
import { Reorder } from "framer-motion";

export const columnValueToLabel = (value: string) => {
  return extraCols.find((item) => item.value === value)?.label
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ExportLayoutModal = () => {
  const [open, setOpen] = React.useState(false);
  const [columnOpen, setColumnOpen] = React.useState(false);
  const selectedExportLayout = useAppSelector((state) => state.exportLayout.selectedLayout)

  const handleClose = () => {
    setOpen(false);
  };

  const { closeModal, selectExportLayout, updateExportLayout, createExportLayout } = useExportLayoutService()
  const exportLayoutModalOpen = useAppSelector((state) => state.exportLayout.modalOpen)

  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setColumnsToSelect({
        ...extraCols.reduce((acc, item) => {
          return {
            ...acc,
            [item.value]: false
          }
        }, {})
      })

    }
  }, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    setColumnOpen(false)
    setColumnsToSelect({
      ...extraCols.reduce((acc, item) => {
        return {
          ...acc,
          [item.value]: false
        }
      }, {})
    })
    setSearchInputValue('')
  };

  const handleOk = () => {
    setColumnOpen(false)

    const newColumns = Object.keys(columnsToSelect).filter((key) => {
      return columnsToSelect[key]
    }).map((key) => {
      return {
        label: columnValueToLabel(key) ?? '',
        value: key
      }
    })

    if (selectedExportLayout) {
      selectExportLayout({
        selectedLayout: {
          ...selectedExportLayout,
          layoutColumns: [
            ...selectedExportLayout.layoutColumns,
            ...newColumns
          ]
        }
      })
    }

    setColumnsToSelect({
      ...extraCols.reduce((acc, item) => {
        return {
          ...acc,
          [item.value]: false
        }
      }, {})
    })

    setSearchInputValue('')

  };



  const modalAction = useAppSelector((state) => state.exportLayout.action)

  useEffect(() => {
    if (modalAction === 'create') {
      selectExportLayout({
        selectedLayout: {
          id: '',
          companyId: '',
          createdAt: new Date(),
          createdBy: '',
          layoutColumns: [],
          name: '',
          updatedAt: new Date(),
        }
      })
    }
  }, [modalAction, selectExportLayout])



  const [selectedColumnToEdit, setSelectedColumnToEdit] = useState<ILayoutColumn>({
    label: '',
    value: ''
  })

  const [columnsToSelect, setColumnsToSelect] = React.useState<any>({
    ...extraCols.reduce((acc, item) => {
      return {
        ...acc,
        [item.value]: false
      }
    }, {})
  });

  const [searchInputValue, setSearchInputValue] = useState('')

  const [lauoutNameError, setLayoutNameError] = useState(false)

  return <>

    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={columnOpen}
    >
      <DialogTitle>Adicionar coluna</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 3, my: 1 }}>
          <TextField
            fullWidth
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value)
            }}
            label="Digite o nome da coluna"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchInputValue('')
                    }}
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

        </Box>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {extraCols.filter((item) => {
              return !selectedExportLayout?.layoutColumns.find((column) => column.value === item.value)
            }).filter((item) => {
              if (searchInputValue === '') return true
              return item.label.toLowerCase().includes(searchInputValue.toLowerCase())
            }).map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={columnsToSelect[option.value]}
                      onChange={(e) => {
                        setColumnsToSelect({
                          ...columnsToSelect,
                          [option.value]: e.target.checked
                        })
                      }}
                      name={option.value} />
                  }
                  label={option.label}
                />
              )
            })}
          </FormGroup>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          disabled={Object.keys(columnsToSelect).filter((key) => {
            return columnsToSelect[key]
          }).length === 0
          }
          onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edição de coluna</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escolha um nome para a coluna, esse nome será o valor da coluna no arquivo de exportação.
          </DialogContentText>
          <Box mt={3}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              defaultValue={selectedColumnToEdit.label}
              onChange={(e) => {
                setSelectedColumnToEdit({
                  ...selectedColumnToEdit,
                  label: e.target.value
                })
              }}

              autoFocus
              margin="dense"
              id="name"
              label="Digite o nome da coluna"
              type="text"
              fullWidth
              variant="standard"
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {
            handleClose()
            if (selectedExportLayout) {
              selectExportLayout({
                selectedLayout: {
                  ...selectedExportLayout,
                  layoutColumns: selectedExportLayout.layoutColumns.map((column) => {
                    if (column.value === selectedColumnToEdit.value) {
                      return {
                        ...column,
                        label: selectedColumnToEdit.label
                      }
                    }
                    return column
                  })
                }
              })
            }
          }}>OK</Button>

        </DialogActions>
      </Dialog>
    </div>
    <Dialog
      fullScreen
      open={exportLayoutModalOpen ?? false}
      onClose={() => {
        closeModal()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
            Layout de exportação
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            closeModal()
          }}>
            Fechar
          </Button>

        </Toolbar>
      </AppBar>
      <Box p={3}>
        <Typography color="primary" fontWeight="bold">
          Escolha as colunas que deseja exportar, a ordem das colunas é a mesma em que elas aparecerão no arquivo de exportação. Clique, segure e arraste para reordenar as colunas.
        </Typography>
        <Box my={3}>
          <TextField
            fullWidth
            label="Digite o nome do layout"
            value={selectedExportLayout?.name}
            error={lauoutNameError}
            helperText={lauoutNameError ? 'Nome do layout é obrigatório' : ''}
            onChange={(e) => {
              if (selectedExportLayout) {
                selectExportLayout({
                  selectedLayout: {
                    ...selectedExportLayout,
                    name: e.target.value
                  }
                })
              }
            }}
          />
        </Box>
        <List>
          <Reorder.Group
            style={{
              listStyle: 'none',
              margin: 0,
              padding: 0,
              alignItems: 'start'
            }}
            axis="y"
            values={selectedExportLayout?.layoutColumns ? selectedExportLayout.layoutColumns : []}
            onReorder={(newOrder) => {
              if (selectedExportLayout) {
                selectExportLayout({
                  selectedLayout: {
                    ...selectedExportLayout,
                    layoutColumns: newOrder
                  }
                })
              }
            }}
          >
            {selectedExportLayout?.layoutColumns.map((item) => (
              <Reorder.Item style={{ listStyle: 'none' }} value={item} key={item.value}>
                <ListItem
                  button
                  secondaryAction={<>
                    <Button style={{
                      marginRight: '10px'
                    }} onClick={() => {
                      setOpen(true)
                      setSelectedColumnToEdit(item)
                    }} endIcon={
                      <EditIcon />
                    }>
                      Editar
                    </Button>
                    <Button onClick={() => {
                      if (selectedExportLayout) {
                        selectExportLayout({
                          selectedLayout: {
                            ...selectedExportLayout,
                            layoutColumns: selectedExportLayout?.layoutColumns.filter((column) => {
                              return column.value !== item.value
                            })
                          }
                        })
                      }
                    }} endIcon={<>
                      <CloseIcon />
                    </>
                    }>
                      Excluir
                    </Button>
                  </>}
                  style={{
                    padding: '10px 0px',
                    margin: '5px',
                    listStyle: 'none',
                    cursor: 'grab'
                  }}
                >
                  <ListItemText primary={columnValueToLabel(item.value)} secondary={`Apelido: ${item.label}`} />
                  <Divider variant="inset" component="li" />

                </ListItem>
              </Reorder.Item>
            )
            )}
          </Reorder.Group>
        </List>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Button
              onClick={() => {
                setColumnOpen(true)
              }}
              endIcon={
                <>
                  <AddIcon />
                </>
              }>
              Clique para adicionar uma nova coluna
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                mt: 2,
                '&:hover': {
                  backgroundColor: darkenColor(theme.palette.primary.main, 10)
                },
                color: theme.palette.primary.contrastText
              }}
              style={{
                marginTop: '30px'
              }}
              fullWidth
              onClick={() => {
                setLayoutNameError(false)
                if (selectedExportLayout?.name === undefined || selectedExportLayout?.name === '') {
                  setLayoutNameError(true)
                  return
                }
                closeModal()
                if (modalAction === 'create') {
                  createExportLayout()
                } else if (modalAction === 'edit') {
                  updateExportLayout()
                }
              }}
              variant="contained"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  </>
}