import readExcelFile from "read-excel-file";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, Grid, List, ListItem, ListItemText, Paper, Slide, Slider, Switch, TextField, Toolbar, Typography, emphasize } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useLocationService } from "../../../../../../use-cases/location";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useProjectService } from "../../../../../../use-cases/project";
import { LocationFilterPreview } from "./location-filter-preview";
import { useCepListService } from "../../../../../../use-cases/cep-list";
import { useLoadingService } from "../../../../../../use-cases/loading";
import { ListItemButtonStyled } from "../../../project-list/project-card/ListItemButtonStyled";
import { ColumnsDialog } from "./columns-dialog";
import { darkenColor, formatCep } from "../../../../../../utils";
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit'
import FileIcon from '@mui/icons-material/FileUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { ListChildComponentProps, FixedSizeList } from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer";
import { theme } from "../../../../../styles";


type FileUploadProps = {
  label: string;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RenderActualCeps(props: ListChildComponentProps) {
  const { index, style } = props;
  const cepListState = useAppSelector((state) => state.cepList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 !== 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCep(cepListState.selectedCepList?.list[index] ?? '')} />
    </ListItem>
  );
}

function RenderNewCeps(props: ListChildComponentProps) {
  const { index, style } = props;
  const cepListState = useAppSelector((state) => state.cepList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 === 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCep(cepListState.fileListData.fileListTableValues.fileListValidData[index] ?? '')} />
    </ListItem>
  );
}

function RenderRepeatedCeps(props: ListChildComponentProps) {
  const { index, style } = props;
  const cepListState = useAppSelector((state) => state.cepList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 !== 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCep(cepListState.fileListData.fileListTableValues.fileListRepeatedData[index] ?? '')} />
    </ListItem>
  );
}

function RenderInvalidCeps(props: ListChildComponentProps) {
  const { index, style } = props;
  const cepListState = useAppSelector((state) => state.cepList)
  const { company } = useAppSelector((state) => state.company)

  return (
    <ListItem
      style={style}
      key={index}
      sx={{
        textAlign: 'center',
        background: (props.index % 2 === 0) ?
          company?.resale === true || company?.resaleId ?
            emphasize(theme.palette.primary.main, 0.95)
            : '#f6f6f6'
          : ''
      }}
    >
      <ListItemText primary={formatCep(cepListState.fileListData.fileListTableValues.fileListInvalidData[index] ?? '')} />
    </ListItem>
  );
}


const categoriaValueToLabel = (value: string) => {
  switch (value) {
    case 'macrorregiao':
      return 'Macrorregião'
    case 'mesorregiao':
      return 'Mesorregião'
    case 'uf':
      return 'Estado'
    case 'cidade':
      return 'Cidade'
    case 'regiao':
      return 'Região Municipal'
    case 'bairro':
      return 'Bairro'
    case 'cep':
      return 'CEP'

    default:
      return 'Macrorregião'

  }
}

const categoriaLocationOptions = [
  {
    label: 'Macrorregião',
    value: 'macrorregiao'
  },
  {
    label: 'Mesorregião',
    value: 'mesorregiao'
  },
  {
    label: 'Estado',
    value: 'uf'
  },
  {
    label: 'Cidade',
    value: 'cidade'
  },
  // {
  //   label: 'Região Municipal',
  //   value: 'regiao'
  // },
  {
    label: 'Bairro',
    value: 'bairro'
  },
  {
    label: 'CEP',
    value: 'cep'
  }
]

export const LocationFilter = () => {
  const { searchLocations, setSearchTerm, setCategoria, resetLocationsList } = useLocationService()
  const { addLocationFilter, addPurgeLocationFilter, addCepListFilter, addPurgeCepListFilter, setFilterByLatAndLngRadius, setFilterByLatAndLngCep } = useProjectService()
  const { selectCepListToEdit, setupCepList, selectCepList, setName, createCepList, addItemToList, removeItemList, updateCepList, addFileListToCepList, selectedColumn, editItemList, resetFileData, verifyCepListFileData } = useCepListService()
  const locationState = useAppSelector((state) => state.location)
  const cepListState = useAppSelector((state) => state.cepList)
  const userState = useAppSelector((state) => state.user)
  const [open, setOpen] = useState(false)
  const [purge, setPurge] = useState(false)
  const projectState = useAppSelector((state) => state.project)
  const [createCepListOpen, setCreateCepListOpen] = useState(false)
  const [editListOpen, setEditListOpen] = useState(false)
  const [cepListImportFileOpen, setCepListImportFileOpen] = useState(false)
  const [columnDialogOpen, setColumnDialogOpen] = useState(false)
  const [openEditCepList, setOpenEditCepList] = useState(false)
  const [openCepListPreview, setOpenCepListPreview] = useState(false)
  const [addCepValue, setAddCepValue] = useState('')
  const [cepSearchTerm, setCepSearchTerm] = useState('')
  const [oldCep, setOldCep] = useState('')
  const [newCep, setNewCep] = useState('')
  const { company } = useAppSelector((state) => state.company)

  const handleLocationFilterClose = () => {
    setOpen(false)
    setSearchTerm({
      searchTerm: ''
    })
    setCategoria({
      categoria: 'macrorregiao'
    })
    setPurge(false)
    resetLocationsList()


  }

  const handleClickOpen = () => {
    setOpenEditCepList(true);
  };

  const handleClose = () => {
    setOpenEditCepList(false);
  };

  // const RenderActualCepList = (props: ListChildComponentProps) => {
  //   const { index, style } = props

  //   if (cepSearchTerm === '' ||
  //     cepSearchTerm === undefined ||
  //     cepListState.selectedCepList?.list[index].includes(cepSearchTerm)) {
  //     return (
  //       <ListItem
  //         style={style}
  //         
  //         secondaryAction={<>
  //           <Button style={{
  //             marginRight: '10px'
  //           }} startIcon={<>
  //             <EditIcon />
  //           </>} onClick={() => {
  //             handleClickOpen()
  //             setOldCep(cepListState.selectedCepList?.list[index] ?? '')
  //             setNewCep(cepListState.selectedCepList?.list[index] ?? '')
  //           }
  //           }>
  //             Editar
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               removeItemList({
  //                 value: cepListState.selectedCepList?.list[index] ?? ''
  //               })
  //             }}
  //             startIcon={<>
  //               <DeleteIcon />
  //             </>}>
  //             Excluir
  //           </Button>
  //         </>}
  //       >
  //         <ListItemText primary={formatCep(cepListState.selectedCepList?.list[index] ?? '')} />
  //         {/* <ListItemText primary={formatCep(cepListState.selectedCepList?.list?.filter((item) => {
  //           if (cepSearchTerm === '' || cepSearchTerm === undefined) return true
  //           if (item === cepSearchTerm) {
  //             return item
  //           } else {
  //             return false
  //           }
  //         })[index] ?? '')} /> */}
  //       </ListItem>
  //     )
  //   } else {
  //     return null
  //   }
  // }

  useEffect(() => {
    setupCepList()
  }, [setupCepList])

  useEffect(() => {
    if (locationState.searchTerm !== '') {
      searchLocations()
    }
  }, [locationState.searchTerm, searchLocations])


  return (
    <>
      <Dialog
        open={openEditCepList}
        onClose={handleClose}
        hideBackdrop
      >
        <DialogTitle >Editar CEP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite abaixo o novo CEP atualizado.
          </DialogContentText>
          <Box mt={3}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              autoFocus
              value={newCep}
              margin="dense"
              label="Digite o novo CEP"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setNewCep(e.target.value)
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {
            handleClose()
            editItemList({
              value: oldCep,
              newValue: formatCep(newCep)
            })
          }} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>

      <FilterHeader title="Localização" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          É a localização das empresas através de endereços. Nesta opção é possível localizar a empresa no atual endereço em que está localizada. Essa busca pode ser feita de uma forma mais ampla, como exemplo (Macrorregião – Norte / Nordeste), ou até mesmo de uma forma mais simples e precisa (CEP, logradouro etc.)
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão considerados todos os endereços na contagem.
        </Typography>
      </>} />
      {/* <Box my={3} mt={5}>
        <FormControlLabel control={<Switch
          checked={
            projectState?.selectedFilter?.filterByLatLong
          }
          onChange={(e) => {
            setFilterByLatAndLng({
              flag: e.target.checked
            })
          }}
        />} label="Filtrar por coordenadas geograficas" />

      </Box> */}
      {
        projectState?.selectedFilter?.filterByLatLong ? (
          <>
            <Box>
              <Grid container spacing={4}>

                <Grid item xs={12}>
                  <Typography>
                    Selecione um CEP e um raio(KM) para filtrar as empresas que estão dentro do raio selecionado.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="CEP" value={
                    projectState?.selectedFilter?.cepFilterByLatLong
                  }
                    onChange={(e) => {
                      setFilterByLatAndLngCep({
                        cep: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Slider
                    marks={[
                      {
                        value: 5,
                        label: '5km'
                      },
                      {
                        value: 10,
                        label: '10km'
                      },
                      {
                        value: 20,
                        label: '20km'

                      },
                      {
                        value: 30,
                        label: '30km'

                      },
                      {
                        value: 50,
                        label: '50km'

                      },
                      {
                        value: 100,
                        label: '100km'
                      },
                    ]}

                    aria-label="Always visible"
                    defaultValue={2}
                    value={
                      projectState?.selectedFilter?.radius
                    }
                    onChange={(e, value) => {
                      setFilterByLatAndLngRadius({
                        radius: Number(value)
                      })
                    }}
                    max={100}
                    min={2}
                    step={1}
                    valueLabelDisplay="on"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>

            <Box my={3} mt={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button onClick={() => {
                    setOpen(true)
                  }} fullWidth endIcon={<>
                    <AddIcon />
                  </>}>
                    <Typography variant="h6" fontWeight="bold">
                      Clique para adicionar uma nova localização
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <LocationFilterPreview />
                </Grid>



              </Grid>
            </Box >
          </>
        )
      }


      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleLocationFilterClose()
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
              Selecionar localização
            </Typography>
            <Button autoFocus color="inherit" onClick={() => {
              handleLocationFilterClose()
            }}>
              Fechar
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <form onSubmit={(e) => {
            e.preventDefault()
            searchLocations()
          }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Autocomplete
                  options={categoriaLocationOptions}
                  value={
                    categoriaLocationOptions.find((option) => option.value === locationState.categoria)
                  }
                  onChange={(event: any, newValue: { label: string, value: string } | null) => {
                    if (newValue === null) return
                    setCategoria({
                      categoria: newValue?.value
                    })
                  }}
                  renderInput={(props) => <TextField {...props} label="Digite ou clique para selecionar a extensão" />}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel control={
                  <Switch
                    checked={purge}
                    onChange={(e) => {
                      setPurge(e.target.checked)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } label={purge ? 'Não incluir' : 'Incluir'} />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  value={locationState.categoria !== 'cep' ? locationState.searchTerm : cepSearchTerm}
                  onChange={(e) => {
                    if (locationState.categoria !== 'cep') {
                      setSearchTerm({
                        searchTerm: e.target.value
                      })
                    } else {
                      setCepSearchTerm(e.target.value)
                    }
                  }}

                  InputProps={{
                    endAdornment: <>
                      <Button type="submit" onClick={() => {
                      }}>
                        Buscar
                      </Button>
                    </>
                  }}
                  fullWidth
                  label={locationState.categoria !== 'cep' ? "Digite o nome da localização" : 'Digite o nome da lista'}
                  placeholder={locationState.categoria === 'cep' ? "Digite o nome da localização" : 'Digite o nome da lista'}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
        {
          locationState.categoria === 'cep' ?
            <>
              <Box pl={3}>
                <form onSubmit={(e) => {
                  e.preventDefault()
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Button onClick={(e) => {
                        e.preventDefault()
                        setCreateCepListOpen(true)
                        selectCepList({
                          cepList: {
                            companyId: userState.companyId,
                            createdAt: new Date(),
                            createdBy: '',
                            id: '',
                            list: [],
                            name: '',
                            updatedAt: new Date(),
                            status: true
                          }
                        })
                      }} fullWidth startIcon={<>
                        <AddIcon />
                      </>}>
                        Criar nova lista de CEPs
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button variant="contained"
                        onClick={(e) => {
                          if (purge) {
                            for (let i = 0; i < cepListState.cepLists.length; i++) {
                              addPurgeCepListFilter({
                                cepList: cepListState.cepLists[i].id
                              })
                            }
                          } else {
                            for (let i = 0; i < cepListState.cepLists.length; i++) {
                              addCepListFilter({
                                cepList: cepListState.cepLists[i].id
                              })
                            }
                          }
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          },
                          color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                        }}
                      >
                        Incluir todas as listas
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <List>
                    {
                      cepListState.cepLists.filter((cepList) => {
                        if (cepSearchTerm === '') {
                          return true
                        }
                        if (cepList.name.toUpperCase().includes(cepSearchTerm.toLocaleUpperCase())) {
                          return true
                        } else {
                          return false
                        }
                      }).map((cepList, index) => {
                        return (<>
                          <ListItem
                            key={cepList.id}
                            secondaryAction={<>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation()

                                  selectCepListToEdit({
                                    cepListId: cepList.id
                                  })

                                  setEditListOpen(true)
                                }}
                                startIcon={<EditIcon />}
                              >
                                Editar
                              </Button>
                            </>}
                            button
                            onClick={() => {
                              if (purge) {
                                addPurgeCepListFilter({
                                  cepList: cepList.id
                                })
                              } else {
                                addCepListFilter({
                                  cepList: cepList.id
                                })
                              }
                            }}
                          >
                            <ListItemText
                              primary={cepList.name?.toUpperCase()}
                              secondary={cepList.createdBy?.toUpperCase()} />
                          </ListItem>
                          <Divider />
                        </>)
                      })
                    }
                  </List>
                </Grid>
                {
                  (((projectState?.selectedFilter?.location?.length ?? 0) > 0) || (projectState?.selectedFilter?.purgeLocation?.length ?? 0) > 0 || (projectState?.selectedFilter?.cepLists?.length ?? 0) > 0 || (projectState?.selectedFilter?.purgeCepLists?.length ?? 0) > 0) &&
                  <Grid item xs={6}>
                    <Box px={3}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                            Localizações selecionadas
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <LocationFilterPreview />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                }
              </Grid>
            </>
            :
            <Grid container>
              <Grid item xs={6}>
                <List>
                  {
                    locationState.locations.map((location, index) => {
                      if (
                        projectState?.selectedFilter?.location?.includes(location)
                        || projectState?.selectedFilter?.purgeLocation?.includes(location)
                      ) {
                        return false
                      }

                      if (location.categoria === 'bairro') {
                        if (
                          projectState?.selectedFilter?.location?.find((selectedLocation) =>
                            selectedLocation.bairro === location.bairro
                            && selectedLocation.cidade === location.cidade
                          )
                          || projectState?.selectedFilter?.purgeLocation?.find((purgeLocation) =>
                            purgeLocation.bairro === location.bairro
                            && purgeLocation.cidade === location.cidade
                          )
                        ) {
                          return false
                        }
                      }

                      if (location.categoria === 'macrorregiao') {
                        if (
                          projectState?.selectedFilter?.location?.find((selectedLocation) =>
                            selectedLocation.categoria === 'macrorregiao'
                            && selectedLocation.macrorregiao === location.macrorregiao
                          )
                          || projectState?.selectedFilter?.purgeLocation?.find((purgeLocation) =>
                            purgeLocation.categoria === 'macrorregiao'
                            && purgeLocation.macrorregiao === location.macrorregiao
                          )
                        ) {
                          return false
                        }
                      }

                      if (location.categoria === 'mesorregiao') {
                        if (
                          projectState?.selectedFilter?.location?.find((selectedLocation) =>
                            selectedLocation.categoria === 'mesorregiao'
                            && selectedLocation.mesorregiao === location.mesorregiao
                          )
                          || projectState?.selectedFilter?.purgeLocation?.find((purgeLocation) =>
                            purgeLocation.categoria === 'mesorregiao'
                            && purgeLocation.mesorregiao === location.mesorregiao
                          )
                        ) {
                          return false
                        }
                      }

                      if (location.categoria === 'uf') {
                        if (
                          projectState?.selectedFilter?.location?.find((selectedLocation) =>
                            selectedLocation.categoria === 'uf'
                            && selectedLocation.uf === location.uf
                          )
                          || projectState?.selectedFilter?.purgeLocation?.find((purgeLocation) =>
                            purgeLocation.categoria === 'uf'
                            && purgeLocation.uf === location.uf
                          )
                        ) {
                          return false
                        }
                      }

                      if (location.categoria === 'cidade') {
                        if (
                          projectState?.selectedFilter?.location?.find((selectedLocation) =>
                            selectedLocation.cidade === location.cidade
                            && selectedLocation.uf === location.uf
                          )
                          || projectState?.selectedFilter?.purgeLocation?.find((purgeLocation) =>
                            purgeLocation.cidade === location.cidade
                            && purgeLocation.uf === location.uf
                          )
                        ) {
                          return false
                        }
                      }

                      return (<>
                        <ListItem
                          button
                          key={location.value}
                          onClick={() => {
                            if (purge) {
                              addPurgeLocationFilter({
                                location: location
                              })
                            } else {
                              addLocationFilter({
                                location: location
                              })
                            }
                          }}>
                          <ListItemText primary={location.value?.toUpperCase()} secondary={
                            categoriaValueToLabel(location.categoria ?? '').toUpperCase()
                          } />
                        </ListItem>
                        <Divider />
                      </>)
                    })
                  }

                </List>
              </Grid>
              {
                (((projectState?.selectedFilter?.location?.length ?? 0) > 0) || (projectState?.selectedFilter?.purgeLocation?.length ?? 0) > 0 || (projectState?.selectedFilter?.cepLists?.length ?? 0) > 0 || (projectState?.selectedFilter?.purgeCepLists?.length ?? 0) > 0) &&
                <Grid item xs={6}>
                  <Box p={3} my={3}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                          Localizações selecionadas
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <LocationFilterPreview />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              }
            </Grid>
        }

        <Dialog fullWidth maxWidth="md" open={
          createCepListOpen
        } onClose={() => {
          setCreateCepListOpen(false)
        }}>
          <DialogTitle>
            Criar nova lista de CEPs
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Escolha um nome para a lista de CEPs
            </DialogContentText>
            <Box my={3}>
              <TextField
                error={!cepListState.cepListForm.fields.name.isValid}
                helperText={!cepListState.cepListForm.fields.name.isValid ? cepListState.cepListForm.fields.name.error?.message : ''}
                value={cepListState.selectedCepList?.name}
                onChange={(e) => {
                  setName({
                    name: e.target.value
                  })
                }}
                autoFocus
                id="nomeproj"
                label="Digite o nome da lista de CEPs"
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setCreateCepListOpen(false)
            }}>Cancelar</Button>
            <Button onClick={() => {
              if (cepListState.cepListForm.action === 'create') {
                createCepList()
                setEditListOpen(true)
                setCreateCepListOpen(false)
              }
            }}>
              Criar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen
          open={editListOpen}
          onClose={() => {
            setEditListOpen(false)
          }}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
                Editar lista de CEPs
              </Typography>
              <Button autoFocus color="inherit" onClick={() => {
                setEditListOpen(false)
              }}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nome da lista"
                  placeholder="Digite o nome da lista"
                  value={cepListState.selectedCepList?.name}
                  onChange={(e) => {
                    setName({
                      name: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          textTransform="uppercase"
                          color="primary"
                        >
                          Adicionar CEP
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Digite o CEP que deseja adicionar"
                          value={addCepValue}
                          onChange={(e) => {
                            setAddCepValue(e.target.value)
                          }}
                          InputProps={{
                            endAdornment: <>
                              <Button type="submit" onClick={() => {
                                addItemToList({
                                  item: addCepValue
                                })
                              }}>
                                Adicionar
                              </Button>
                            </>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          textTransform="uppercase"
                          align="center"
                          color="primary"
                        >
                          OU
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>

                          <Button
                            startIcon={<FileIcon />}
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault()
                              setCepListImportFileOpen(true)
                              resetFileData()
                            }}
                            sx={{
                              '&:hover': {
                                backgroundColor: darkenColor(theme.palette.primary.main, 10)
                              },
                              color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                            }}
                          >
                            Importar arquivo
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Pesquisar CEP"
                  placeholder="Digite o CEP que deseja pesquisar"
                  fullWidth
                  value={cepSearchTerm}
                  onChange={(e) => {
                    setCepSearchTerm(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="primary" variant="h6">
                  Tamanho: {cepListState.selectedCepList?.list.filter((cep) => cep.includes(cepSearchTerm)).length.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12} height={(cepListState.selectedCepList?.list.length ?? 0) <= 3 ? 175 : 250} maxHeight={400}>
                <AutoSizer >
                  {(data: any) => (
                    <FixedSizeList
                      height={(cepListState.selectedCepList?.list.length ?? 0) <= 3 ? 175 : 250}
                      style={{
                        maxHeight: 400
                      }}
                      width={data.width}
                      itemSize={46}
                      itemCount={cepListState.selectedCepList?.list.filter((cep) => {
                        if (
                          cepSearchTerm === '' ||
                          cepSearchTerm === undefined ||
                          cep.includes(cepSearchTerm)
                        ) {
                          return true
                        } else {
                          return false
                        }
                      }).length ?? 0}
                      overscanCount={15}
                    >
                      {({ index, style }) => {
                        const item = cepListState.selectedCepList?.list.filter((cep) => {
                          if (
                            cepSearchTerm === '' ||
                            cepSearchTerm === undefined ||
                            cep.includes(cepSearchTerm)
                          ) {
                            return true
                          } else {
                            return false
                          }
                        })[index];
                        return (
                          <ListItem
                            style={style}

                            secondaryAction={<>
                              <Button
                                style={{
                                  marginRight: '10px'
                                }}
                                startIcon={<EditIcon />}
                                onClick={() => {
                                  handleClickOpen()
                                  setOldCep(item ?? '')
                                  setNewCep(item ?? '')
                                }}
                              >
                                Editar
                              </Button>
                              <Button
                                onClick={() => {
                                  removeItemList({
                                    value: item ?? ''
                                  })
                                }}
                                startIcon={<DeleteIcon />}
                              >
                                Excluir
                              </Button>
                            </>
                            }
                          >
                            <ListItemText primary={formatCep(item ?? '')} />
                          </ListItem>
                        )
                      }}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation()
                    updateCepList()
                  }}
                  variant="contained"
                  sx={{
                    '&:hover': {
                      backgroundColor: darkenColor(theme.palette.primary.main, 10)
                    },
                    color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : '#FFF'
                  }}
                >
                  Salvar alterações
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="xl"
          open={cepListImportFileOpen}
          onClose={() => {
            setCepListImportFileOpen(false)
          }}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} textTransform="uppercase" variant="h6" component="div">
                Importar arquivo de lista de CEPs
              </Typography>
              <Button autoFocus color="inherit" onClick={() => {
                setCepListImportFileOpen(false)
              }}>
                Fechar
              </Button>
            </Toolbar>
          </AppBar>
          <form onSubmit={(e) => {
            e.preventDefault()
            setCepListImportFileOpen(false)
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {/* Drag and drop compoennte*/}
                <FileUpload label={"Arraste e solte o arquivo aqui ou clique para selecionar"} />
              </Grid>
              {
                cepListState.matchCode.selectedColumn !== '' ? (
                  <>
                    <Grid item xs={6}>
                      <List component="div" role="group">
                        <ListItem
                          key={1}
                          divider
                          aria-haspopup="true"
                          aria-controls="ringtone-menu"
                          aria-label="phone ringtone"
                          onClick={(() => {
                            setColumnDialogOpen(true)
                          })}
                        >
                          <ListItemText key={1} primary="Clique para selecionar a coluna"
                            secondary={cepListState.matchCode.selectedColumn}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List component="div" role="group">
                        <ListItemButtonStyled
                          divider
                          key={1}
                        >
                          <ListItemText primary="Dados do arquivo" secondary={<>
                            <Typography >
                              <strong> Nome: </strong>
                              {cepListState.fileData.name}
                            </Typography>
                            <Typography >
                              <strong> Tamanho: </strong>
                              {cepListState.fileData.size}
                            </Typography>
                            <Typography >
                              <strong> Tipo: </strong>
                              {cepListState.fileData.type}
                            </Typography>
                            <Typography >
                              <strong> Linhas: </strong>
                              {cepListState.fileData.data.length.toLocaleString('pt-BR')}
                            </Typography>
                            <Typography >
                              <strong> Match codes distintos: </strong>
                              {cepListState.matchCode.distinctValues.toLocaleString('pt-BR')}
                            </Typography>
                            <Typography >
                              <strong> Match codes válidos e distintos: </strong>
                              {cepListState.matchCode.validValues.toLocaleString('pt-BR')}
                            </Typography>
                          </>} />
                        </ListItemButtonStyled>
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (verifyCepListFileData()) {
                            setOpenCepListPreview(true)
                          }
                        }}
                        sx={{
                          '&:hover': {
                            backgroundColor: darkenColor(theme.palette.primary.main, 10)
                          }
                        }}
                      >
                        Enviar
                      </Button>
                    </Grid>
                  </>
                ) : null
              }
            </Grid>
            <ColumnsDialog
              id="ringtone-menu"
              keepMounted
              open={columnDialogOpen}
              onClose={(newValue?: string) => {
                if (newValue) {
                  selectedColumn({
                    selectedColumn: newValue
                  });
                }
                setColumnDialogOpen(false)
              }}
              value={cepListState.matchCode.selectedColumn}
            />
          </form>
        </Dialog>

        <Dialog fullWidth maxWidth={'lg'} open={openCepListPreview} onClose={() => {
          setOpenCepListPreview(false)
        }}>
          <DialogTitle>
            <Typography fontWeight={'bold'} textTransform="uppercase" variant="h5" color='primary'>
              Pré visualização da lista - <strong>{cepListState.selectedCepList?.name}</strong>
            </Typography>
          </DialogTitle>
          <Box px={2}>
            <Divider />
          </Box>
          <DialogContent>
            <Grid container height={300} sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              overflowY: 'hidden'
            }}>
              <Grid item xs={12} md={3}>
                <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                  <PlaylistAddCheckIcon sx={{ width: '8%', height: '8%' }} /> CEPs atuais:
                </Typography>
                <AutoSizer >
                  {(data: any) => (
                    <FixedSizeList
                      height={data.height}
                      width={data.width}
                      itemSize={46}
                      itemCount={cepListState.selectedCepList?.list.length ?? 0}
                      overscanCount={5}
                      style={{
                        marginTop: 3.5
                      }}
                    >
                      {RenderActualCeps}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                  <PlaylistAddIcon sx={{ width: '8%', height: '8%' }} /> CEPs novos:
                </Typography>
                <AutoSizer >
                  {(data: any) => (
                    <FixedSizeList
                      height={data.height}
                      width={data.width}
                      itemSize={46}
                      itemCount={cepListState.fileListData.fileListTableValues.fileListValidData.length ?? 0}
                      overscanCount={5}
                      style={{
                        marginTop: 3.5
                      }}
                    >
                      {RenderNewCeps}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                  <SyncProblemIcon sx={{ width: '8%', height: '8%' }} /> CEPs repetidos:
                </Typography>
                <AutoSizer >
                  {(data: any) => (
                    <FixedSizeList
                      height={data.height}
                      width={data.width}
                      itemSize={46}
                      itemCount={cepListState.fileListData.fileListTableValues.fileListRepeatedData.length ?? 0}
                      overscanCount={5}
                      style={{
                        marginTop: 3.5
                      }}
                    >
                      {RenderRepeatedCeps}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography color="primary" variant="h6" align={'center'} fontWeight={'bold'}>
                  <PlaylistRemoveIcon sx={{ width: '8%', height: '8%' }} /> CEPs inválidos:
                </Typography>
                <AutoSizer >
                  {(data: any) => (
                    <FixedSizeList
                      height={data.height}
                      width={data.width}
                      itemSize={46}
                      itemCount={cepListState.fileListData.fileListTableValues.fileListInvalidData.length ?? 0}
                      overscanCount={5}
                      style={{
                        marginTop: 3.5
                      }}
                    >
                      {RenderInvalidCeps}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </Grid>
            </Grid>
          </DialogContent>
          <Box px={2}>
            <Divider />
          </Box>
          <DialogActions>
            <Button onClick={() => {
              setOpenCepListPreview(false)
            }}>
              Cancelar
            </Button>
            <Button onClick={() => {
              addFileListToCepList()
              setOpenCepListPreview(false)
              setCepListImportFileOpen(false)
            }}>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog >
    </>
  )
}

const excelToJson = (file: any[]) => {
  const columns = file[0]
  const data = file.slice(1).map((item) => {
    const obj: any = {}
    columns.forEach((column: any, index: number) => {
      obj[column] = item[index]
    })
    return obj
  })
  return data
}

const FileUpload: React.FC<FileUploadProps> = ({ label }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const { startLoading, stopLoading } = useLoadingService()
  const { setFile } = useCepListService()

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {

    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      startLoading()

      const file = e.dataTransfer.files[0];
      /* Check if is CSV or XLSX */
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileType = file.type;
        const fileName = file.name;
        const fileSize = `${Math.round(file.size / 1024)} KB`;
        const fileDataString = event.target?.result as string;
        let fileData: any[] = [];
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          fileData = await readExcelFile(file, { sheet: 1 })
          setFile({
            data: excelToJson(fileData),
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileData[0]
          })
        }
        if (file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel') {
          const delimiter = guessDelimiter(fileDataString);
          fileData = csvToJson(fileDataString, delimiter);
          setFile({
            data: fileData,
            name: fileName,
            type: fileType,
            size: fileSize,
            columns: fileDataString.split('\n')[0].split(delimiter)
          })
        }

        stopLoading()
      };
      reader.readAsText(file);
    }
  };
  const guessDelimiter = (fileDataString: string) => {
    const delimiters = [',', ';', '\t', '|'];
    let maxCount = 0;
    let maxDelimiter = ',';
    delimiters.forEach((delimiter) => {
      const count = fileDataString.split(delimiter).length;
      if (count > maxCount) {
        maxCount = count;
        maxDelimiter = delimiter;
      }
    });
    return maxDelimiter;
  };

  const csvToJson = (csvString: string, delimiter: string) => {
    const rows = csvString.split('\n');
    const result: any[] = []
    rows.slice(1, rows.length).forEach((item: any) => {
      if (item !== "") {
        const fields = item.split(delimiter)
        let obj: any = {}
        rows[0]?.split(delimiter).forEach((column: any, index: number) => {
          obj[column] = fields[index]
        })

        result.push(obj)
      }
    })
    return result;
  };

  return (
    <div>

      <div
        className={`file-upload ${dragging ? 'dragging' : ''}`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => {
          if (inputFileRef.current) {
            inputFileRef.current.click()

          }
        }}
      >

        <div className="file-upload__label">{label}</div>
        <input hidden type="file" ref={inputFileRef} onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            startLoading()
            const reader = new FileReader();
            reader.onload = async (event) => {
              const fileType = file.type;
              const fileName = file.name;
              const fileSize = `${Math.round(file.size / 1024)} KB`;
              const fileDataString = event.target?.result as string;
              let fileData: any[] = [];
              if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileData = await readExcelFile(file, { sheet: 1 })
                setFile({
                  data: excelToJson(fileData),
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileData[0]
                })
              }
              if (file.type === 'text/csv' || file.type === 'text/plain' || file.type === 'application/vnd.ms-excel') {
                const delimiter = guessDelimiter(fileDataString);
                fileData = csvToJson(fileDataString, delimiter);
                setFile({
                  data: fileData,
                  name: fileName,
                  type: fileType,
                  size: fileSize,
                  columns: fileDataString.split('\n')[0].split(delimiter)
                })

              }
            }
            reader.readAsText(file);
          }
        }} />


      </div>

    </div>

  );
};