import { Grid, Typography, Box, TextField } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { usePasswordRecoveryService } from "../../../../../use-cases/password-recovery"

export const RequestPasswordForm = () => {
  const email = useAppSelector(state => state.passwordRecovery.passwordRecoveryModel.email)
  const emailFieldProps = useAppSelector(state => state.passwordRecovery.fields.email)
  const { setRecoveryEmail } = usePasswordRecoveryService()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" align="center" color="primary" fontWeight="bold">
            Recuperar senha
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="p" align="center">
            Para trocar sua senha, insira o endereço de e-mail associado à sua conta no campo de texto abaixo e clique em "Próximo". Em seguida, enviaremos um e-mail com um código de verificação para confirmar sua identidade. Quando receber o código, insira-o no campo fornecido na próxima página e crie uma nova senha para a sua conta.
          </Typography>
          <Box mt={3}>

            <Typography variant="body1" component="p" align="center" fontWeight="bold">
              Lembre-se de seguir todas as instruções fornecidas pelo sistema para garantir a segurança da sua conta. Se você tiver problemas para recuperar sua senha, entre em contato com o suporte ao cliente para obter ajuda adicional.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="E-mail"
            value={email}
            error={!emailFieldProps.isValid}
            helperText={!emailFieldProps.isValid && `${emailFieldProps.error?.message}`}
            onChange={(e) => {
              setRecoveryEmail({
                email: e.target.value
              })
            }}
            fullWidth />
        </Grid>
      </Grid>
    </>
  )
}