import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, useTheme } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useProjectService } from "../../../../../use-cases/project"
import TableChartIcon from '@mui/icons-material/TableChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import CalculateIcon from '@mui/icons-material/Calculate';
import { SamplesSection } from "./samples-section";
import { GeneralDataSection } from "./general-data-section";
import { CountReportSection } from "./count-report-section";
// import PinDropIcon from '@mui/icons-material/PinDrop';
import { GeoLocationSection } from "./geo-location-section";

export const DataSection = () => {
  const { setActiveDataSection } = useProjectService()
  const activeDataSection = useAppSelector((state) => state.project.activeDataSection)
  const theme = useTheme()

  return (
    <>
      <Box
        style={{
          marginBottom: '80px'
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <List>
              <ListItem
                key={0}
              >
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveDataSection({
                      activeDataSection: 0
                    })
                  }} style={{
                    background: activeDataSection === 0 ? theme.palette.primary.main : '',
                    color: activeDataSection === 0 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <TableChartIcon style={{
                        color: activeDataSection === 0 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="AMOSTRAGEM" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              <ListItem
                key={1}
              >
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveDataSection({
                      activeDataSection: 1
                    })
                  }} style={{
                    background: activeDataSection === 1 ? theme.palette.primary.main : '',
                    color: activeDataSection === 1 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <DonutSmallIcon style={{
                        color: activeDataSection === 1 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="GERAL" />
                  </ListItemButton>
                </Paper>
              </ListItem>
              {/* <ListItem
              key={4}
              >
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveDataSection({
                      activeDataSection: 4
                    })
                  }} style={{
                    background: activeDataSection === 4 ? theme.palette.primary.main : '',
                    color: activeDataSection === 4 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <PinDropIcon style={{
                        color: activeDataSection === 4 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="GEO LOCALIZAÇÃO" />
                  </ListItemButton>
                </Paper>
              </ListItem> */}
              <ListItem
                key={2}
              >
                <Paper elevation={3} style={{
                  width: '100%'
                }}>
                  <ListItemButton onClick={() => {
                    setActiveDataSection({
                      activeDataSection: 2
                    })
                  }} style={{
                    background: activeDataSection === 2 ? theme.palette.primary.main : '',
                    color: activeDataSection === 2 ? 'white' : 'black',
                  }}>
                    <ListItemIcon>
                      <CalculateIcon style={{
                        color: activeDataSection === 2 ? 'white' : 'black',
                      }} />
                    </ListItemIcon>
                    <ListItemText primary="LEVANTAMENTO QUANTITATIVO" />
                  </ListItemButton>
                </Paper>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={9}>
            <Box p={2}>

              {
                activeDataSection === 0 ? (
                  <>
                    <SamplesSection />
                  </>
                ) : null
              }

              {
                activeDataSection === 1 ? (
                  <>
                    <GeneralDataSection />
                  </>
                ) : null
              }
              {
                activeDataSection === 2 ? (
                  <>
                    <CountReportSection />
                  </>
                ) : null
              }
              {
                activeDataSection === 4 ? (
                  <>
                    <GeoLocationSection />
                  </>
                ) : null
              }

            </Box>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}