import { Box, Button, CircularProgress, CircularProgressProps, Typography } from "@mui/material"
import { IProjectExport } from "../../../../../application/models"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useProjectExportService } from "../../../../../use-cases/project-export/useProjectExportService"
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import { useExportDownloadService } from "../../../../../use-cases/project-download";
import { useEffect, useState } from "react";
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress color={props.value < 65 ? 'secondary' : 'primary'} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontWeight={"bold"}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}


export const StatusCell = (data: {
  projectExport: IProjectExport,
  fileExists: boolean
}) => {
  const projectExportState = useAppSelector((state) => state.projectExport)
  const { recreateProjectExport, getExportStatus, setProjectExportList } = useProjectExportService()
  const { openProjectExport } = useExportDownloadService()
  const [itWasPending, setItWasPending] = useState(false)
  const [status, setStatus] = useState<
    'pending' | 'processing' | 'completed' | 'failed'
  >('pending');

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      getExportStatus({
        projectExportId: data.projectExport.id ?? ''
      })
        .then((response) => {
          if (response.status === 'completed') {
            setStatus('completed')
            if (itWasPending) {
              setProjectExportList()
              itWasPending && setItWasPending(false)
            }
            clearInterval(interval)
          }
          if (response.status === 'processing') {
            setItWasPending(true)
            setStatus('processing')
            setProgress(response.progress ?? 0)
          }
          if (response.status === 'failed') {
            setStatus('failed')
            if (itWasPending) {
              setItWasPending(false)
              setProjectExportList()
            }
            clearInterval(interval)
          }
          if (response.status === 'pending') {
            setItWasPending(true)
            setStatus('pending')
          }
        }).catch((err) => {
        })
    }, 1000)
    return () => clearInterval(interval)
  }, [
    data.projectExport.id,
    getExportStatus,
    projectExportState,
    recreateProjectExport,
    setProjectExportList,
    itWasPending
  ])

  return <>
    {
      status === 'completed' ? (
        <>
          {
            data.fileExists ? (
              <Button
                key={data.projectExport.id}
                onClick={() => {
                  openProjectExport({
                    projectExport: data.projectExport
                  })
                }}>
                <DownloadIcon key={data.projectExport.id} />
              </Button>
            ) : <>
              {
                !itWasPending ? (
                  <Button
                    key={data.projectExport.id}
                    onClick={() => {
                      recreateProjectExport({
                        projectExportId: data.projectExport.id ?? ''
                      })
                    }}>
                    <RefreshIcon key={data.projectExport.id} />
                  </Button>
                ) : <CircularProgress key={data.projectExport.id} />
              }
            </>
          }
        </>
      ) : null
    }
    {
      status === 'processing' ? (
        <>
          <CircularProgressWithLabel key={data.projectExport.id} value={progress * 100} />
        </>
      ) : null
    }
    {
      status === 'pending' ? (
        <>
          <CircularProgress key={data.projectExport.id} />
        </>
      ) : null
    }
    {
      status === 'failed' ? (
        <>
          <FileDownloadOffIcon key={data.projectExport.id} color="primary" />
        </>
      ) : null
    }
  </>
}