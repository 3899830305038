export const darkenColor = (color: string, percent: number) => {
    color = color.replace(/^#/, '');

    var r = parseInt(color.substring(0, 2), 16);
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);

    var amt = Math.round(2.55 * percent);

    r = Math.max(0, r - amt);
    g = Math.max(0, g - amt);
    b = Math.max(0, b - amt);

    var hex = (r * 0x10000 + g * 0x100 + b).toString(16);

    hex = '#' + ('000000' + hex).slice(-6);

    return hex;
}