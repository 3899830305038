import { createTheme, responsiveFontSizes } from '@mui/material/styles';


let theme = createTheme({
  palette: {
    primary: {
      main: '#723989',
    },
    secondary: {
      main: '#ee9338'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#723989'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#ee9338'
        }
      }
    }
  }  
})

theme = responsiveFontSizes(theme);

export { theme }


