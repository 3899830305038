import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompany } from '../../models/ICompany';

interface ICompanyState {
  /* Undefined deve ser o estado onde o usuário ainda não foi validado */
  company?: ICompany
}

const initialState: ICompanyState = {

};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyState: (state, action: PayloadAction<ICompany>) => {
      state.company = action.payload;
    }
  },
});

export const { setCompanyState } = companySlice.actions;
export const companyReducer = companySlice.reducer;