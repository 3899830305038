import { Accordion, AccordionSummary, ListItem, ListItemAvatar, Avatar, ListItemText, Box, LinearProgress, Typography, AccordionDetails, Grid, TextField, Button } from "@mui/material"
import { userTypeOptions } from "../../../../assets/helper-assets/userTypeOptions"
import { IUser } from "../../../../../application/models"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Person2Icon from '@mui/icons-material/Person2';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useState } from "react";
import { darkenColor, onlyNumbers } from "../../../../../utils";
import { theme } from "../../../../styles";
import { useBackofficeService } from "../../../../../use-cases/backoffice";

export const BackofficeUserConsumptionLockPage = (user: IUser) => {
    const { company } = useAppSelector((state) => state.company)
    const userState = useAppSelector((state) => state.user)
    const { selectedCompany } = useAppSelector((state) => state.backofficeCompany)
    const { createBackofficeConsumptionLock } = useBackofficeService()
    const [inputValue, setInputValue] = useState<number | undefined>(
        selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
            return item.userId === user.id
        }
        )?.consumptionLimit
    )

    return <>
        <Accordion style={{
            marginBottom: '20px'
        }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem key={user.id}>
                    <ListItemAvatar>
                        <Avatar src={user.img}>
                            <Person2Icon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<>
                            {
                                userTypeOptions.find((item) => {
                                    return item.value === user.userType
                                })?.label ?? 'DONO'
                            }
                            {
                                ' - ' + user.name.toUpperCase()
                            }
                        </>}
                        secondary={<>
                            {
                                selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                    return item.userId === user.id
                                })?.consumptionLimit ? (
                                    <>
                                        <Box mt={2} mb={1}>
                                            <LinearProgress variant="determinate" value={
                                                (selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                    return item.userId === user.id
                                                })?.consumption ?? 0) / (selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                    return item.userId === user.id
                                                }
                                                )?.consumptionLimit ?? 1) * 100
                                            } />
                                        </Box>
                                        <Typography>
                                            {
                                                selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                    return item.userId === user.id
                                                })?.consumption.toLocaleString('pt-BR')
                                            } / {selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                return item.userId === user.id
                                            })?.consumptionLimit?.toLocaleString('pt-BR')} - ({((selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                return item.userId === user.id
                                            })?.consumption ?? 0) / (selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                return item.userId === user.id
                                            }
                                            )?.consumptionLimit ?? 1) * 100).toFixed(2)} % do limite atingido)
                                        </Typography>
                                    </>

                                ) : (
                                    <>
                                        <Typography>
                                            {
                                                selectedCompany?.consumptions?.userListWithConsumption.find((item) => {
                                                    return item.userId === user.id
                                                })?.consumption.toLocaleString('pt-BR') + ' créditos consumidos'
                                            }
                                        </Typography>
                                    </>
                                )
                            }
                        </>}
                    />
                </ListItem>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled={userState.userType === "SELLER" || userState.userType === "OPERATOR"}
                            InputLabelProps={{
                                shrink: true
                            }}
                            label="Limite de consumo"
                            type="text"
                            value={inputValue?.toLocaleString('pt-BR')}
                            onChange={(e) => {
                                const formattedNumber = onlyNumbers(e.target.value)
                                if (e.target.value) {
                                    setInputValue(Number(formattedNumber))
                                } else {
                                    setInputValue(undefined)
                                }
                            }}
                            fullWidth
                        />
                    </Grid>
                    {
                        (userState.userType === "ADMIN" || userState.userType === "OWNER") && (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                        },
                                        color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                    }}
                                    onClick={() => {
                                        createBackofficeConsumptionLock({
                                            limit: inputValue ?? 0,
                                            userId: user.id
                                        })
                                    }}
                                >
                                    Atualizar limite
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>
}