import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type BackofficeTabs = 'companies' | 'revenue' | 'notifications' | 'consumptionLock' | 'users'

interface ISettingsState {
    activeTab: BackofficeTabs
}

const initialState: ISettingsState = {
    activeTab: 'companies'
};

const backofficeSlice = createSlice({
    name: 'backoffice',
    initialState,
    reducers: {
        setActiveBackofficeTab(state, action: PayloadAction<{
            tab: BackofficeTabs
        }>) {
            state.activeTab = action.payload.tab
        }
    },
});

export const { setActiveBackofficeTab } = backofficeSlice.actions;
export const backofficeReducer = backofficeSlice.reducer;