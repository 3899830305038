import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { IBlackList, ISelectedBlackList } from "../../application/models"

export const useBlackListApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const createBlackListApi = useCallback((data: {
    name: string,
    createdBy: string
  }): Promise<ISelectedBlackList> => {
    return axiosAuthInstance.post('/blacklist', data).then((result) => {
      const blackList: ISelectedBlackList = result.data.result.blackList
      if (!blackList) throw new Error('Erro ao buscar lista de deduplicação')
      return blackList
    })
  }, [axiosAuthInstance])

  const findBlackListByCompanyId = useCallback((data: {
    companyId: string
  }): Promise<IBlackList[]> => {
    return axiosAuthInstance.get('/blacklist/find-by-company-id', { params: data }).then((result) => {
      const blackLists: IBlackList[] = result.data.result.blackList
      if (!blackLists) throw new Error('Black lists not found')
      return blackLists
    })
  }, [axiosAuthInstance])

  const updateBlackListApi = useCallback((data: {
    id: string,
    name: string,
    list: string[],
  }): Promise<ISelectedBlackList> => {
    return axiosAuthInstance.put('/blacklist', {
      id: data.id,
      name: data.name,
      list: data.list.map((item) => {
        return item.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
      })
    }).then((result) => {
      const blackList: ISelectedBlackList = result.data.result.blackList
      if (!blackList) throw new Error('Erro ao buscar lista de deduplicação')
      return blackList
    })
  }, [axiosAuthInstance])

  const changeBlackListStatusApi = useCallback((data: {
    id: string,
    status: boolean
  }): Promise<ISelectedBlackList> => {
    return axiosAuthInstance.put('/blacklist/change-status', {
      id: data.id,
      status: data.status
    }).then((result) => {
      const blackList: ISelectedBlackList = result.data.result.blackList
      if (!blackList) throw new Error('Erro ao buscar lista de deduplicação')
      return blackList
    })
  }, [axiosAuthInstance])

  const findBlackListByIdApi = useCallback((data: {
    blackListId: string
  }): Promise<ISelectedBlackList> => {
    return axiosAuthInstance.get('/blacklist/find-by-id', { params: data }).then((result) => {
      const blackList: ISelectedBlackList = result.data.result.blackList
      if (!blackList) throw new Error('Erro ao buscar lista de deduplicação')
      return blackList
    })
  }, [axiosAuthInstance])

  return {
    createBlackListApi,
    findBlackListByCompanyId,
    updateBlackListApi,
    changeBlackListStatusApi,
    findBlackListByIdApi
  }
}