import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFieldError } from '../../models';

interface IDataEnrichmentState {
  name: string,
  error?: IFieldError,
  matchCode: {
    selectedColumn: string,
    distinctValues: number,
    validValues: number
  },
  fileData: {
    name: string,
    size: string,
    columns: string[],
    type: string,
    data: any[]
  },
  matchCodeList?: string[]
}

const initialState: IDataEnrichmentState = {
  name: '',
  matchCode: {
    selectedColumn: '',
    distinctValues: 0,
    validValues: 0
  },
  fileData: {
    name: '',
    size: '',
    columns: [],
    type: '',
    data: []
  }
};

const dataEnrichmentSlice = createSlice({
  name: 'data-enrichment',
  initialState,
  reducers: {
    setDataEnrichmentName(state, action: PayloadAction<{
      name: string
    }>) {
      state.name = action.payload.name
    },
    setDataEnrichmentSelectedColumn(state, action: PayloadAction<{
      selectedColumn: string
    }>) {
      state.matchCode.selectedColumn = action.payload.selectedColumn
    },
    setDataEnrichmentDistinctValues(state, action: PayloadAction<{
      distinctValues: number
    }>) {
      state.matchCode.distinctValues = action.payload.distinctValues
    },
    setDataEnrichmentValidValues(state, action: PayloadAction<{
      validValues: number
    }>) {
      state.matchCode.validValues = action.payload.validValues
    },
    setDataEnrichmentFileData(state, action: PayloadAction<{
      fileData: {
        name: string,
        size: string,
        columns: string[],
        type: string,
        data: any[]
      }
    }>) {
      state.fileData = action.payload.fileData
    },
    validateFields(state) {
      if (state.fileData.data.length === 0) {
        state.error = {
          message: 'Arquivo é obrigatório',
          type: 'required'
        }
      }
    },
    setMatchCodeListState(state, action: PayloadAction<{
      matchCodeList?: string[]
    }>) {
      state.matchCodeList = action.payload.matchCodeList
    },
    resetDataEnrichmentState(state) {
      state.name = initialState.name
      state.matchCode = initialState.matchCode
      state.fileData = initialState.fileData
    }
  },
});

export const {
  setDataEnrichmentName,
  setDataEnrichmentSelectedColumn,
  setDataEnrichmentDistinctValues,
  setDataEnrichmentValidValues,
  setDataEnrichmentFileData,
  validateFields,
  resetDataEnrichmentState,
  setMatchCodeListState
} = dataEnrichmentSlice.actions;
export const dataEnrichmentReducer = dataEnrichmentSlice.reducer;