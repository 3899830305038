export const CountReportFields = [
  'cnaeprimario',
  'cnaeprimariodescricao',
  'setor',
  'situacaocadastral',
  'macrorregiao',
  'mesorregiao',
  'microrregiao',
  'uf',
  'cidade',
  'bairro',
  'cep',
  'tipoempresa',
  'segmentos',
  'porte',
  'regimetributario',
  'naturezajuricadescricao'
]

export const countReportFieldToLabel = (countReportField: string) => {
  switch (countReportField) {
    case 'cnaeprimario':
      return 'CNAE Primário'
    case 'cnaeprimariodescricao':
      return 'CNAE Descrição'
    case 'setor':
      return 'Setor'
    case 'situacaocadastral':
      return 'Situação Cadastral'
    case 'macrorregiao':
      return 'Macrorregião'
    case 'mesorregiao':
      return 'Mesorregião'
    case 'microrregiao':
      return 'Microrregião'
    case 'uf':
      return 'UF'
    case 'cidade':
      return 'Cidade'
    case 'bairro':
      return 'Bairro'
    case 'cep':
      return 'CEP'
    case 'tipoempresa':
      return 'Matriz / Filial'
    case 'segmentos':
      return 'Segmento'
    case 'porte':
      return 'Porte'
    case 'regimetributario':
      return 'Regime Tributário'
    case 'naturezajuricadescricao':
      return 'Natureza Jurídica'
    default:
      return ''
  }
}