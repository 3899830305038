import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { EconomicGroupKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const economicGroupKeyToLabel = (economicGroupKey: string) => {
  switch (economicGroupKey) {
    case 'MATRIZ':
      return 'Matriz'
    case 'FILIAL':
      return 'Filial'
    default:
      return 'Não definido'
  }
}



export const EconomicGroupFilter = () => {
  const economicGroupFilterState = useAppSelector((state) => state.project?.selectedFilter?.economicGroup)
  const { addEconomicGroupFilter, removeEconomicGroupFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Matriz / Filial" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Esta opção permite localizar empresas matriz e/ou filial, ou ambas ao mesmo tempo.
        </Typography>
        <Typography>
          Caso esta opção não seja marcada será considerado todo grupo econômico na contagem.
        </Typography>
        <Grid item xs={12} py={3}>
          <Divider />
        </Grid>
      </>}
      />

      <Box p={3}>
        <Grid container spacing={3}>

          {
            EconomicGroupKeys.map((economicGroup) => {
              return <>
                <Grid item xs={12}>

                  <FormControlLabel control={
                    <Switch
                      checked={economicGroupFilterState?.includes(economicGroup) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addEconomicGroupFilter({
                            economicGroup: economicGroup
                          })

                        }
                        else {
                          removeEconomicGroupFilter({
                            economicGroup: economicGroup
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={economicGroupKeyToLabel(economicGroup).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}