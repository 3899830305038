import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUFCount {
  count: number
  uf: string,
  capital: {
    cidade: string,
    count: number
  }
}

interface IUFCountState {
  ufCount: IUFCount[]
}

const initialState: IUFCountState = {
  ufCount: []
};

const UFCountSlice = createSlice({
  name: 'ufCount',
  initialState,
  reducers: {
    setUfCountState(state, action: PayloadAction<{
      ufCount: IUFCount[]
    }>) {
      state.ufCount = action.payload.ufCount
    }

  },
});

export const {
  setUfCountState
} = UFCountSlice.actions;
export const UFCountReducer = UFCountSlice.reducer;