import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISegmento } from '../../models';

interface ISegmentosState {
  searchTerm: string
  segmentosList: ISegmento[]
}

const initialState: ISegmentosState = {
  searchTerm: '',
  segmentosList: []
};

const segmentosSlice = createSlice({
  name: 'segmentos',
  initialState,
  reducers: {
    setSegmentosListState(state, action: PayloadAction<{
      segmentosList: ISegmento[]
    }>) {
      state.segmentosList = action.payload.segmentosList
    },
    setSegmentosSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    }
  },
});

export const {
  setSegmentosListState,
  setSegmentosSearchTermState
} = segmentosSlice.actions;
export const segmentosReducer = segmentosSlice.reducer;