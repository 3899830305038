import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './presentation/App';
import { store } from './application/states/store'
import { Provider } from 'react-redux';
import './presentation/fonts'
import './presentation/assets/css/scroll.css'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);