import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useDayZeroApi } from "../../infra/day-zero-api"
import { setDayZeroByUserCountState, setDayZeroTotalCountState } from "../../application/states/day-zero"

export const useDayZeroService = () => {
  const dispatch = useAppDispatch()
  const { getDayZeroPjApi, getDayZeroByUserPjApi } = useDayZeroApi()
  const userId = useAppSelector((state) => state.user.id)
  const { token } = useAppSelector((state) => state.auth)

  const getDayZeroByUser = useCallback(() => {
    if (token === '' || !token) {
      return
    }

    getDayZeroByUserPjApi({
      userId: userId
    }).then((result) => {
      dispatch(setDayZeroByUserCountState({
        totalWithFilter: result.totalFiltered
      }))
    }).catch((error) => {
    })
  }, [dispatch, getDayZeroByUserPjApi, token, userId])

  const getDayZero = useCallback(() => {
    if (token === '' || !token) {
      return
    }

    getDayZeroPjApi().then((result) => {
      dispatch(setDayZeroTotalCountState({
        total: result.quantity,
        aggsCnae: result.aggsCnae,
        aggsPorte: result.aggsPorte,
        aggsSetor: result.aggsSetor
      }))
    }).catch((error) => {

    })
  }, [dispatch, getDayZeroPjApi, token])

  return {
    getDayZeroByUser,
    getDayZero
  }
}