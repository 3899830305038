import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { IAggsCnae, IAggsSector, IAggsSize } from "../../application/models/ICnpjDZero"

export const useDayZeroApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const getDayZeroByUserPjApi = useCallback((data: {
    userId: string
  }): Promise<{
    totalFiltered: number
  }> => {
    return axiosAuthInstance.get('/pessoa-juridica/get-day-zero', {
      params: {
        userId: data.userId
      }
    }).then((result) => {
      const totalFiltered = result.data.result?.totalFiltered

      return {
        totalFiltered
      }
    })
  }, [axiosAuthInstance])

  const getDayZeroPjApi = useCallback((): Promise<{
    quantity: number
    aggsCnae: IAggsCnae[]
    aggsPorte: IAggsSize[]
    aggsSetor: IAggsSector[]
  }> => {
    return axiosAuthInstance.get('/pessoa-juridica/get-cnpj-d-zero').then((result) => {
      const quantity = result.data.result?.cnpjDZero.quantity
      const aggs = result.data.result?.cnpjDZero.aggsCnae
      const aggsPorte = result.data?.result?.cnpjDZero.aggsPorte
      const aggsSetor = result.data?.result?.cnpjDZero.aggsSetor

      return {
        quantity: quantity,
        aggsCnae: aggs,
        aggsPorte: aggsPorte,
        aggsSetor: aggsSetor
      }
    })
  }, [axiosAuthInstance])

  return {
    getDayZeroByUserPjApi,
    getDayZeroPjApi
  }
}