import { useCallback } from "react"
import { IExportLayout, ILayoutColumn } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"

export const useExportLayoutApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()


  const findExportLayoutByCompanyId = useCallback((data: {
    companyId: string
  }): Promise<IExportLayout[]> => {
    return axiosAuthInstance.get('/export-layout/company-layouts', {
      params: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result.exportLayoutList
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    })
  }, [axiosAuthInstance])


  const updateExportLayoutApi = useCallback((data: {
    id?: string,
    name?: string,
    layoutColumns?: ILayoutColumn[]
  }): Promise<IExportLayout> => {
    return axiosAuthInstance.put('/export-layout', {
      exportLayoutData: {
        ...data
      }
    }).then((response) => {
      const result = response.data.result.exportLayout
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const createExportLayoutApi = useCallback((data: {
    exportLayout: {
      companyId?: string,
      name?: string,
      layoutColumns?: ILayoutColumn[]
      createdBy?: string
    }
  }): Promise<IExportLayout> => {
    return axiosAuthInstance.post('/export-layout', {
      ...data.exportLayout

    }).then((response) => {
      const result = response.data.result.exportLayout
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])

  const deleteExportLayoutApi = useCallback((data: {
    id?: string
  }): Promise<IExportLayout> => {
    return axiosAuthInstance.delete('/export-layout/' + data.id).then((response) => {
      const result = response.data.result.exportLayout
      if (result) {
        return result
      } else {
        throw new Error('No result')
      }
    }).catch((err) => {
    })
  }, [axiosAuthInstance])





  return {
    findExportLayoutByCompanyId,
    updateExportLayoutApi,
    createExportLayoutApi,
    deleteExportLayoutApi
  }
}