import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../application/states/hooks"
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material"
import { darkenColor, formatCnpj, numericCnpj } from "../../../../../utils"
import { theme } from "../../../../styles"
import { useBackofficeService } from "../../../../../use-cases/backoffice"
import BusinessIcon from '@mui/icons-material/Business'
import Person2Icon from '@mui/icons-material/Person2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useConsumptionService } from "../../../../../use-cases/consumption"
import { BackofficeUserConsumptionLockPage } from "./BackofficeUserConsumptionLockPage"

export const BackofficeConsumptionLockPage = () => {
    const [activeTab, setActiveTab] = useState<'company' | 'user'>('company')
    const [searchTerm, setSearchTerm] = useState('')
    const [inputValue, setInputValue] = useState<number | undefined>()
    const { selectedCompany } = useAppSelector((state) => state.backofficeCompany)
    const { user, company, backofficeCompany, resaleConfig } = useAppSelector((state) => state)
    const { findCompanyBySearchTerm, setResetSelectedCompany } = useBackofficeService()
    const { createConsumptionLock } = useConsumptionService()
    const [companyOptions, setCompanyOptions] = useState<{
        label: string,
        value: string
    }[]>([])

    useEffect(() => {
        document.title = `${resaleConfig?.resaleConfig?.id !== '' ? `${resaleConfig!.resaleConfig?.name}` : 'Lurik'} - Backoffice / Limitação de Consumo`
    }, [resaleConfig])

    useEffect(() => {
        const companyOptionsLabel: {
            label: string
            value: string
        }[] = []

        const orderedCompanies = [...backofficeCompany.companyList].sort((a, b) => {
            if (a.company.razaoSocial < b.company.razaoSocial) {
                return -1
            } else {
                return 1
            }
        })

        for (let i = 0; i < orderedCompanies.length; i++) {
            const company = orderedCompanies[i].company

            if (company.nomeFantasia) {
                companyOptionsLabel.push({
                    label: `${formatCnpj(company.cnpj)} - ${company.razaoSocial.toUpperCase()} - ${company.nomeFantasia?.toUpperCase()}`,
                    value: numericCnpj(company.cnpj)
                })
            } else {
                companyOptionsLabel.push({
                    label: `${formatCnpj(company.cnpj)} - ${company.razaoSocial.toUpperCase()}`,
                    value: numericCnpj(company.cnpj)
                })
            }
        }

        setCompanyOptions(companyOptionsLabel)
    }, [backofficeCompany.companyList, user.id, user.userType])

    useEffect(() => {
        if (selectedCompany?.subscription.usage) {
            setInputValue(selectedCompany?.subscription.usage.consumptionLock)
        }
    }, [selectedCompany?.subscription.usage])

    useEffect(() => {
        setResetSelectedCompany()
    }, [setResetSelectedCompany])

    return (
        <>
            <Grid container display={"flex"} justifyContent={"center"}>
                <Grid item xs={12}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        findCompanyBySearchTerm({
                            searchTerm: searchTerm
                        })
                    }}>
                        <Grid item xs={12} pb={3}>
                            <Autocomplete
                                options={companyOptions}
                                fullWidth
                                renderInput={(props) =>
                                    <TextField
                                        {...props}
                                        label={`Digite o CNPJ, Razão Social ou Nome Fantasia da empresa que deseja ${user.userType === "OPERATOR" || user.userType === "SELLER" ? 'visualizar' : 'visualizar ou editar'}`}
                                    />
                                }
                                value={companyOptions.find((company) => {
                                    return company.value === selectedCompany?.company.razaoSocial
                                })}
                                onChange={(event, newValue) => {
                                    setResetSelectedCompany()
                                    if (typeof newValue === "object") {
                                        setSearchTerm(newValue?.value ?? '')
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    '&:hover': {
                                        backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                    },
                                    color: company.company?.resale === true || company.company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                }}
                                type="submit"
                            >
                                Pesquisar empresa
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                {
                    selectedCompany !== undefined && (
                        <>
                            <Grid item xs={12} my={3}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} mb={3}>
                                <Paper
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        py: 2,
                                        pl: 2
                                    }}
                                >
                                    <Typography mb={2} variant="h6" color="primary" textTransform="uppercase" fontWeight="bold">
                                        Empresa selecionada
                                    </Typography>
                                    <Grid item xs={12} display="flex" flexDirection="row" my={0.5}>
                                        <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
                                            marginRight: '10px'
                                        }}>
                                            CNPJ:
                                        </Typography>
                                        <Typography textTransform={"uppercase"}
                                            color="secondary"
                                            fontWeight={"bold"}
                                        >
                                            {formatCnpj(selectedCompany.company.cnpj)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} display="flex" flexDirection="row" my={0.5}>
                                        <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
                                            marginRight: '10px'
                                        }}>
                                            Razão Social:
                                        </Typography>
                                        <Typography textTransform={"uppercase"}
                                            color="secondary"
                                            fontWeight={"bold"}
                                        >
                                            {selectedCompany.company.razaoSocial.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} mt={1} mb={3}>
                                <Divider />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button
                                        onClick={(e) => {
                                            setActiveTab('company')
                                        }}
                                        variant={activeTab === 'company' ? 'contained' : 'outlined'}
                                        startIcon={<BusinessIcon />}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: activeTab === 'company' ? darkenColor(theme.palette.primary.main, 15) : theme.palette.background.default
                                            },
                                            color: activeTab === 'company' ? theme.palette.primary.contrastText : theme.palette.primary.main
                                        }}
                                    >
                                        Limitar consumo por empresa
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={(e) => {
                                            setActiveTab('user')
                                        }}
                                        variant={activeTab === 'user' ? 'contained' : 'outlined'}
                                        startIcon={<Person2Icon />}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: activeTab === 'user' ? darkenColor(theme.palette.primary.main, 15) : theme.palette.background.default
                                            },
                                            color: activeTab === 'user' ? theme.palette.primary.contrastText : theme.palette.primary.main
                                        }}
                                    >
                                        Limitar consumo por usuário
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    activeTab === 'company' ? (
                                        <Grid container my={2}>
                                            <Grid item xs={12}>
                                                <Accordion defaultExpanded>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography fontWeight="bold">
                                                            Limitação por empresa
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    disabled={user.userType === "SELLER" || user.userType === "OPERATOR"}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    label="Limite de consumo"
                                                                    type="text"
                                                                    value={inputValue?.toLocaleString('pt-BR') ?? ''}
                                                                    onChange={(e) => {
                                                                        const formattedNumber = numericCnpj(e.target.value)
                                                                        if (e.target.value) {
                                                                            setInputValue(Number(formattedNumber))
                                                                        } else {
                                                                            setInputValue(undefined)
                                                                        }
                                                                    }}
                                                                    fullWidth />
                                                            </Grid>
                                                            {
                                                                (user.userType === "ADMIN" || user.userType === "OWNER") &&
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        fullWidth
                                                                        sx={{
                                                                            '&:hover': {
                                                                                backgroundColor: darkenColor(theme.palette.primary.main, 15)
                                                                            },
                                                                            color: company.company?.resale === true || company.company?.resaleId ? theme.palette.primary.contrastText : 'white'
                                                                        }}
                                                                        onClick={() => {
                                                                            createConsumptionLock({
                                                                                limit: inputValue ?? 0,
                                                                                companyId: selectedCompany.company.id
                                                                            })
                                                                        }}
                                                                    >
                                                                        Atualizar limite
                                                                    </Button>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid mt={2}>
                                                {
                                                    selectedCompany.users?.map((user) => {
                                                        return <BackofficeUserConsumptionLockPage {...user} />
                                                    })
                                                }
                                            </Grid>
                                        </>
                                    )
                                }

                            </Grid>
                        </>
                    )
                }
            </Grid>
        </>
    )
}