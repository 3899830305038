import { useAppDispatch } from "../../application/states/hooks"
import { setActiveSettingsTab, SettingsTabs } from "../../application/states/settings"
import { useCallback } from 'react'
export const useSettingsService = () => {
  const dispatch = useAppDispatch()
  const setActiveTab = useCallback((data: {
    tab: SettingsTabs
  }) => {
    dispatch(setActiveSettingsTab({
      tab: data.tab
    }))
  }, [dispatch])

  return {
    setActiveTab
  }
}