import { useCallback } from 'react';
import { cnpj as cnpjValidator} from 'cpf-cnpj-validator'

export const useCnpjService = () => {
  const validateCnpj = useCallback((cnpj: string) => {
    return cnpjValidator.isValid(cnpj)
  }, [])
  return {
    validateCnpj
  }
}