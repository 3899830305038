import { Text } from "@react-pdf/renderer"
import { BoxShadowView } from "./BoxShadowView"
import { ICurrentPj } from "./ICurrentPJ"
import { styles } from "./styles"
import { SectionTitle } from "./SectionTitle"
import cnaeIcon from './images/cnaeIcon.png'
import { formatCnae } from "../card-pessoa-juridica"

export const CnaesSection = (props: {
    currentPj?: ICurrentPj
}) => {
    return <>
        <SectionTitle icon={cnaeIcon} title='CNAEs' />
        <BoxShadowView>
            {props.currentPj?.pj.cnaescodigo ? props.currentPj?.pj.cnaescodigo?.map((cnae, index) => (
                <Text style={[styles.value, styles.highlightedValue, {
                    marginTop: '15px'
                }]}>{String.fromCharCode(149)}  {<Text style={styles.bold}>{formatCnae(cnae)}</Text>} - {props.currentPj?.pj.cnaesdescricao?.[index]}</Text>
            ))
                : <Text style={[styles.value, styles.highlightedValue, {
                    marginTop: '15px'
                }]}> </Text>
            }
        </BoxShadowView>
    </>
}