import { useAxiosInstances } from "../axios-instance"

export const useOpenStreetMapGeolocationApi = () => {
    const { axiosInstance } = useAxiosInstances()

    const findGeolocationBySearchTermApi = (data: {
        searchTerm: string
    }) => {
        return axiosInstance.get(`https://nominatim.openstreetmap.org/search?q=${data.searchTerm}&format=json&polygon_geojson=1&addressdetails=1`)
            .then((result) => {
                return {
                    geolocations: result.data
                }
            }).catch((error) => {
                if (error?.response?.data?.result) {
                    throw {
                        message: error.response.data.result.message,
                        type: error.response.data.result.type
                    }
                }
            })
    }

    const findGeolocationDetailsApi = (data: {
        osmType: string
        osmId: number
        class: string
    }) => {
        return axiosInstance.get(`https://nominatim.openstreetmap.org/details.php?osmtype=${data.osmType}&osmid=${data.osmId}&class=${data.class}&addressdetails=1&hierarchy=0&group_hierarchy=1&format=json`)
            .then((result) => {
                return {
                    geolocation: result.data
                }
            }).catch((error) => {
                if (error?.response?.data?.result) {
                    throw {
                        message: error.response.data.result.message,
                        type: error.response.data.result.type
                    }
                }
            })
    }

    const findGeolocationByPlaceIdApi = (data: {
        placeId: number
    }) => {
        return axiosInstance.get(`https://nominatim.openstreetmap.org/details.php?place_id=${data.placeId}&addressdetails=1&hierarchy=0&group_hierarchy=1&format=json`)
            .then((result) => {
                return {
                    geolocation: result.data
                }
            }).catch((error) => {
                if (error?.response?.data?.result) {
                    throw {
                        message: error.response.data.result.message,
                        type: error.response.data.result.type
                    }
                }
            })
    }

    const findGeolocationByLatLonApi = async (data: {
        lat: number
        lon: number
    }) => {
        try {
            const result = await axiosInstance.get(`https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${data.lat}&lon=${data.lon}&addressdetails=1&hierarchy=0&group_hierarchy=1&format=json`)

            if (result) {
                return {
                    geolocation: result.data
                }
            } else {
                throw {
                    message: 'Erro ao buscar localização',
                    type: 'error'
                }
            }
        } catch (error) {
            throw {
                message: 'Erro ao buscar localização',
                type: 'error'
            }
        }
    }

    return {
        findGeolocationBySearchTermApi,
        findGeolocationDetailsApi,
        findGeolocationByPlaceIdApi,
        findGeolocationByLatLonApi
    }
}