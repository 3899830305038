import { Avatar, Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material"
import React, { forwardRef, useEffect } from "react"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArticleIcon from '@mui/icons-material/Article';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { CallSplit, Email } from "@mui/icons-material";
import { useAppSelector } from "../../../../../application/states/hooks";
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import GradingIcon from '@mui/icons-material/Grading'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Diversity3Icon from '@mui/icons-material/Diversity3'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import FactoryIcon from '@mui/icons-material/Factory'
import format from "date-fns/format";

export const formatCnae = (cnae: string) => {
  let cnaeFormatado: string = ''
  for (let i = 0; i < cnae.length; i++) {
    if (i === 3) {
      cnaeFormatado = cnaeFormatado + cnae[i] + '-'
    } else if (i === 4) {
      cnaeFormatado = cnaeFormatado + cnae[i] + '/'
    } else {
      cnaeFormatado = cnaeFormatado + cnae[i]
    }
  }
  return cnaeFormatado
}

export const hideCpf = (cpf: string) => {
  if (cpf.length !== 14)
    return cpf

  let cpfHidden = ''
  for (let i = 0; i < cpf.length; i++) {
    if (i === 0 || i === 2 || i === 1 || i === 12 || i === 13) {
      cpfHidden = cpfHidden + '*'
    } else {
      cpfHidden = cpfHidden + cpf[i]
    }
  }
  return cpfHidden
}

export const formatCep = (cep: string) => {
  let cepFormatado: string = ''
  for (let i = 0; i < cep.length; i++) {
    if (i === 4) {
      cepFormatado = cepFormatado + cep[i] + '-'
    } else {
      cepFormatado = cepFormatado + cep[i]
    }
  }
  return cepFormatado
}

/* eslint-disable no-useless-escape */
export const formatCnpjCpf = (value = '') => {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replaceAll(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  }

  return cnpjCpf.replaceAll(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
}


export const formatBrazilPhone = (phone: string) => {
  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/g, "(\$1) \$2-\$3")
  } else if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/g, "(\$1) \$2-\$3")
  }
  return phone
}


export const CardPessoaJuridicaWithIcons = forwardRef((props, ref) => {

  const currentPj = useAppSelector((state) => state.unitSearch.currentPj)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'}${currentPj?.pj.razaosocial ? ` - ${currentPj?.pj.razaosocial}` : ''}`
  }, [currentPj?.pj.razaosocial, resaleConfig])

  return (
    <>
      <div ref={ref as any}>
        <List sx={{ width: '90vw', bgcolor: 'background.paper' }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <ArticleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        CNPJ
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {formatCnpjCpf(currentPj?.pj.cnpj ?? '')}
                      </Typography>

                    </React.Fragment>
                  }
                />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <DriveFileRenameOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Razão Social
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.razaosocial}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <DriveFileRenameOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Nome Fantasia
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.nomefantasia}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="inset" component="li" />
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <PhoneEnabledIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Telefones:
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      {
                        currentPj?.pjPhones?.map((telefone) => {
                          return (
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {formatBrazilPhone(telefone ?? '')}
                            </Typography>
                          )
                        })
                      }
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        E-mails
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      {
                        currentPj?.pjMails?.map((email) => {
                          return (
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {email}
                            </Typography>
                          )
                        })
                      }
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <CalendarMonthIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Data de Abertura
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.dataabertura ? format(new Date(currentPj?.pj.dataabertura), 'dd/MM/yyyy') : 'Sem registro'}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <GradingIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Situação Cadastral
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.situacaocadastral ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <DomainAddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Porte
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.porte ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <BusinessCenterIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Natureza Jurídica
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.naturezajuricadescricao ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <AttachMoneyIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Capital Social
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.capitalsocial ? `R$ ${currentPj.pj.capitalsocial.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2
                        })}` : ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <AttachMoneyIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Faturamento Anual
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.faturamentoanual ? `R$ ${currentPj.pj.faturamentoanual.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2
                        })}` : ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <AccountBalanceIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Regime Tributário
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.regimetributario ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <Diversity3Icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Nº de Funcionários
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.numerofuncionario ? currentPj?.pj.numerofuncionario.toLocaleString('pt-BR') : ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <DeviceHubIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Matriz / Filial
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.tipoempresa ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <FactoryIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Setor
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {currentPj?.pj.setor ?? ''}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={6}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <CallSplit />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        CNAES:
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      {
                        currentPj?.pj.cnaescodigo?.map((cnae, index) => {
                          return (
                            <Typography
                              variant="body2"
                              color="text.primary"
                            >
                              <strong>{formatCnae(cnae)}</strong>- {currentPj?.pj.cnaesdescricao?.[index]}
                            </Typography>
                          )
                        }
                        )
                      }
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="inset" component="li" />

            </Grid>
            <Grid item xs={12}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar  >
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Sócios
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Grid container spacing={3}>
                        {
                          currentPj?.socios.map((socio) => {
                            /* Anonimizar cnpj ou cpf ex: ***.550.987-** */
                            return (
                              <Grid item xs={12} md={4}>
                                <Paper elevation={3}>
                                  <Box p={2}>
                                    <Typography>
                                      <strong>Nome:</strong> {socio.nome}
                                    </Typography>
                                    <Typography>
                                      <strong>Qualificação:</strong> {socio.qualificacao}
                                    </Typography>
                                    {
                                      socio.datasociedade ? (
                                        <Typography>
                                          <strong>Data sociedade:</strong> {format(new Date(socio.datasociedade), 'dd/MM/yyyy')}
                                        </Typography>
                                      ) : null
                                    }
                                  </Box>
                                </Paper>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
            <Grid item xs={4}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {/* Avatar with icon */}
                  <Avatar  >
                    <LocationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        color="primary"
                        fontWeight={"bold"}>
                        Endereço
                      </Typography>
                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography

                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Macrorregião:</strong> {currentPj?.pj?.macrorregiao}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>UF:</strong> {currentPj?.pj?.uf}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Mesorregião:</strong> {currentPj?.pj?.mesorregiao}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Microrregião:</strong> {currentPj?.pj?.microrregiao}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Cidade:</strong> {currentPj?.pj?.cidade}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Bairro:</strong> {currentPj?.pj?.bairro}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Endereço:</strong> {currentPj?.pj.enderecocompleto}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Número:</strong> {currentPj?.pj.endereconumero}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>Complemento:</strong> {currentPj?.pj.enderecocomplemento}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        <strong>CEP:</strong> {formatCep(currentPj?.pj.cep ?? '')}
                      </Typography>
                    </React.Fragment>
                  } />
              </ListItem>
            </Grid>
          </Grid>
        </List>
      </div>

    </>
  )
})