import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, TextField, Button } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { useUserService } from "../../../../../use-cases/user"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";

export const SecuritySection = () => {
  const { setChangePasswordValue, setChangePasswordConfirmationValue, changeUserPassword } = useUserService()

  const [passswordInputError, setPasswordInputError] = useState(false)
  const [passwordConfirmationInputError, setPasswordConfirmationInputError] = useState(false)

  const user = useAppSelector((state) => state.user)
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações / Segurança`
  }, [resaleConfig])

  return <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight="bold">
              Alterar senha
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} py={1.5}>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                Sua senha deve conter no mínimo: 8 caracteres; 1 letra maiúscula; 1 letra minúscula; 1 número; 1 caractere especial.
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }} pt={0.5}>
                Exemplo de caracteres: !, @, #, $, &, *, %.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={passswordInputError}
                  helperText={passswordInputError ? 'Campo obrigatório' : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Inserir nova senha"
                  type="password"
                  value={user.changePasswordValue}
                  onChange={(e) => {
                    setChangePasswordValue({
                      value: e.target.value
                    })
                  }}
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={passwordConfirmationInputError}
                  helperText={passwordConfirmationInputError ? 'Campo obrigatório' : ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Confirmar nova senha"
                  type="password"
                  value={user.changePasswordConfirmValue}
                  onChange={(e) => {
                    setChangePasswordConfirmationValue({
                      value: e.target.value
                    })
                  }}
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    '&:hover': {
                      backgroundColor: darkenColor(theme.palette.primary.main, 15)
                    },
                    color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                  }}
                  onClick={() => {
                    setPasswordInputError(false)
                    setPasswordConfirmationInputError(false)
                    if (user.changePasswordValue === '') {
                      setPasswordInputError(true)
                      return
                    }
                    if (user.changePasswordConfirmValue === '') {
                      setPasswordConfirmationInputError(true)
                      return
                    }
                    if (user.changePasswordValue !== user.changePasswordConfirmValue) {
                      setPasswordInputError(true)
                      setPasswordConfirmationInputError(true)
                      return
                    }

                    changeUserPassword()
                  }}
                >
                  Mudar senha
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  </>
}