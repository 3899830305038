import { Box, Button, Grid, Typography } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigationService } from "../../../use-cases/navigation";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../application/states/hooks";
import { darkenColor, getSleep } from "../../../utils";
import { theme } from "../../styles";
import { useResaleConfigService } from "../../../use-cases/resale-config";

export const NotFoundPage = () => {
  const { navigateTo } = useNavigationService()
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const { getResaleConfigByDomain } = useResaleConfigService()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTheme = async () => {
      try {
        getResaleConfigByDomain({
          domain: window.location.host
        })
      } catch (error) {

      } finally {
        await getSleep(500)
        setLoading(false)
      }
    }

    loadTheme()
  }, [getResaleConfigByDomain])

  useEffect(() => {
    if (loading === false) document.title = `${resaleConfig?.id !== '' ? resaleConfig?.name : 'Lurik'} - Página não encontrada`

  }, [loading, resaleConfig?.id, resaleConfig?.name])

  return (
    <>
      {
        loading === true ? <></>
          :
          <>
            <Grid
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column'
              }} spacing={3} container flexDirection={"column"} alignItems="center" justifyContent={"center"}>
              <Grid item>
                <Typography variant="h2" align="center" color="primary" fontWeight={"bold"}>
                  Erro 404 - Página não encontrada :(
                </Typography>
              </Grid>

              <Grid item>
                <ErrorIcon sx={{ fontSize: 250 }} color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h4" align="center">
                  Ops! A página que você está procurando não existe.
                </Typography>
                <Box my={3}>
                  <Typography variant="h5" align="center" fontWeight={"bold"}>
                    Por favor, verifique o endereço digitado e tente novamente.
                  </Typography>
                  <Typography variant="h5" align="center" fontWeight={"bold"}>
                    Se o problema persistir, entre em contato com o administrador do sistema.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigateTo({
                      route: '/workspace'
                    })
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: darkenColor(theme.palette.primary.main, 10)
                    },
                    color: theme.palette.primary.contrastText
                  }}
                >
                  Voltar para página inicial
                </Button>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}