import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { validateEmail } from '../../../veles-validations';
import { IFieldError } from '../../models/IFieldError';
import { IFieldProps } from '../../models/IFieldProps';

export const validatePasswordRecoveryFormStep1 = (data: {
  email: string,
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []
  if (!data.email) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (validateEmail(data.email)) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail inválido',
        type: 'InvalidEmailError'
      }
    })
  }

  return errors
}


export const validatePasswordRecoveryFormStep2 = (data: {
  verificationCode: string,
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []
  if (!data.verificationCode) {
    errors.push({
      field: 'verificationCode',
      fieldError: {
        message: 'Código de verificação é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (data.verificationCode.length !== 6) {
    errors.push({
      field: 'verificationCode',
      fieldError: {
        message: 'Código de verificação inválido',
        type: 'InvalidVerificationCodeError'
      }
    })
  }

  return errors
}

export const validatePasswordRecoveryFormStep3 = (data: {
  password: string,
  confirmPassword: string,
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []
  if (!data.password) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'Senha é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (data.password.length < 8) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'Senha deve conter no mínimo 8 caracteres',
        type: 'InvalidPasswordError'
      }
    })
  }
  let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')

  if (!regex.test(data.password)) {
    errors.push({
      field: 'password',
      fieldError: {
        message: 'Senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
        type: 'InvalidPasswordError'
      }
    })
  }

  if (!data.confirmPassword) {
    errors.push({
      field: 'confirmPassword',
      fieldError: {
        message: 'Confirmação de senha é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (data.confirmPassword !== data.password) {
    errors.push({
      field: 'confirmPassword',
      fieldError: {
        message: 'Confirmação de senha não confere',
        type: 'InvalidPasswordError'
      }
    })
  }

  return errors
}



interface IPasswordRecoveryFields {
  email: IFieldProps
  verificationCode: IFieldProps
  password: IFieldProps
  confirmPassword: IFieldProps
}

interface IPasswordRecoveryState {
  passwordRecoveryModel: {
    email: string
    verificationCode: string
    password: string
    confirmPassword: string
  },
  fields: IPasswordRecoveryFields,
  error?: IFieldError,
  activeStep: number
}

const initialState: IPasswordRecoveryState = {
  passwordRecoveryModel: {
    confirmPassword: '',
    email: '',
    password: '',
    verificationCode: ''
  },
  fields: {
    confirmPassword: {
      isValid: true,
      error: undefined,
    },
    email: {
      isValid: true,
      error: undefined,
    },
    password: {
      isValid: true,
      error: undefined,
    },
    verificationCode: {
      isValid: true,
      error: undefined,
    }
  },
  activeStep: 0,
};

const passwordRecoverySlice = createSlice({
  name: 'passwordRecovery',
  initialState,
  reducers: {
    setRecoveryEmail(state, action: PayloadAction<{
      value: string,
    }>) {
      state.passwordRecoveryModel.email = action.payload.value
    },
    setVerificationCode(state, action: PayloadAction<{
      value: string
    }>) {
      state.passwordRecoveryModel.verificationCode = action.payload.value
    },
    setPassword(state, action: PayloadAction<{
      value: string
    }>) {
      state.passwordRecoveryModel.password = action.payload.value
    },
    setPasswordConfirmation(state, action: PayloadAction<{
      value: string
    }>) {
      state.passwordRecoveryModel.confirmPassword = action.payload.value
    },
    setActivePasswordRecoveryStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload
    },
    setError(state, action: PayloadAction<IFieldError | undefined>) {
      state.error = action.payload
    },
    setPasswordRecoveryFieldErrors(state, action: PayloadAction<{
      field: string,
      fieldError: IFieldError
    }[]>) {
      action.payload.forEach((fieldError) => {
        (state.fields as any)[fieldError.field].isValid = false;
        (state.fields as any)[fieldError.field].error = fieldError.fieldError;
      })
    },
    resetPasswordRecoveryFieldErrors(state) {
      Object.keys(state.fields).forEach((field) => {
        (state.fields as any)[field].isValid = true;
        (state.fields as any)[field].error = undefined;
      })
    },
    resetPasswordRecoveryState(state) {
      state = initialState
    },

  },
});

export const {
  setPassword,
  setPasswordConfirmation,
  setRecoveryEmail,
  setVerificationCode,
  setActivePasswordRecoveryStep,
  setError,
  resetPasswordRecoveryState,
  setPasswordRecoveryFieldErrors,
  resetPasswordRecoveryFieldErrors
} = passwordRecoverySlice.actions;


export const passwordRecoveryReducer = passwordRecoverySlice.reducer;