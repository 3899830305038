import { Box, Grid, Typography, Button } from "@mui/material"
import { useCallback } from "react"
import { resetDataEnrichmentState, setDataEnrichmentDistinctValues, setDataEnrichmentFileData, setDataEnrichmentName, setDataEnrichmentSelectedColumn, setDataEnrichmentValidValues, setMatchCodeListState } from "../../application/states/data-enrichment"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useLoadingService } from "../loading"
import { useModalContainerService } from "../modal-container"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useCnpjService } from "../../veles-validations/cnpj"
import { darkenColor } from "../../utils"
import { theme } from "../../presentation/styles"

export function FNPadronizarCNPJ(cnpj: string): string {
  cnpj = cnpj ?? '';

  cnpj = cnpj.replace(/[^\d]/g, '');

  const zeros = '00000000000000';
  cnpj = zeros + cnpj;

  cnpj = cnpj.slice(-14);

  return cnpj;
}
export function getDistinctValues(array: string[]): string[] {
  const hashTable: { [value: string]: boolean } = {};

  for (const value of array) {
    // Adiciona o valor na tabela hash se ele ainda não foi adicionado antes
    if (!hashTable[value]) {
      hashTable[value] = true;
    }
  }
  // Retorna o número de valores distintos na tabela hash
  return Object.keys(hashTable);
}




export const useDataEnrichmentService = () => {
  const { validateCnpj } = useCnpjService()
  const dispatch = useAppDispatch()
  const { startLoading, stopLoading } = useLoadingService()
  const dataEnrichment = useAppSelector((state) => state.dataEnrichment)
  const { openModal, closeModal } = useModalContainerService()
  const findCnpjKeyInArray = useCallback((array: any[]) => {
    for (let i = 0; i < array.length; i++) {
      const keys = Object.keys(array[i])
      for (let k = 0; k < keys.length; k++) {
        if (validateCnpj(FNPadronizarCNPJ(`${array[i][keys[k]]?.toString()}`))) {
          return keys[k]
        }
      }
    }
    return null
  }, [validateCnpj])
  const selectedColumn = useCallback((data: {
    selectedColumn: string,
    data?: any[]
  }) => {
    startLoading()
    dispatch(setDataEnrichmentSelectedColumn({
      selectedColumn: data.selectedColumn
    }))
    /* performatic code to get distinc values */
    let dataToUse = dataEnrichment.fileData.data
    if (data.data) {
      dataToUse = data.data
    }


    const distinctValues = getDistinctValues(dataToUse.map((item: any) => item[data.selectedColumn]))

    dispatch(setDataEnrichmentDistinctValues({
      distinctValues: distinctValues.length
    }))

    const validValues = distinctValues.filter((value: any) => {
      let isValid = validateCnpj(FNPadronizarCNPJ(value))
      if (isValid) {
        return true
      } else {
        return false
      }
    })
    dispatch(setDataEnrichmentValidValues({
      validValues: validValues.length
    }))
    dispatch(setMatchCodeListState({
      matchCodeList: validValues.map((item) => {
        return item.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
      })
    }))

    stopLoading()
  }, [dispatch, startLoading, stopLoading, dataEnrichment.fileData.data, validateCnpj])


  const setFile = useCallback((data: {
    name: string,
    size: string,
    columns: string[],
    type: string,
    data: any[]
  }) => {
    dispatch(setDataEnrichmentFileData({
      fileData: {
        columns: data.columns,
        data: data.data,
        name: data.name,
        size: data.size,
        type: data.type
      }
    }))
    const cnpjKey = findCnpjKeyInArray(data.data)
    if (cnpjKey) {
      selectedColumn({
        selectedColumn: cnpjKey,
        data: data.data
      })
      openModal({
        content: <>
          <Box p={3}>
            <Grid container alignItems={"center"} flexDirection="column">
              <Grid item>
                <CheckCircleIcon color="primary" sx={{ fontSize: 100 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                  Arquivo carregado com sucesso!
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                  Selecionamos a coluna <strong>{cnpjKey}</strong> como a coluna que contém os CNPJs, caso não seja a coluna correta, selecione a coluna correta no menu indicado.
                </Typography>
              </Grid>
              <Grid item>
                <Box my={3}>
                  <Button variant="contained"
                    sx={{
                      mt: 2,
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      }
                    }}
                    onClick={() => {
                      closeModal()
                    }}>
                    OK
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      })
    } else {
      stopLoading()
      openModal({
        content: <>
          <Box p={3}>
            <Grid container alignItems={"center"} flexDirection="column">
              <Grid item>
                <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                  Não encontramos CNPJ's no seu arquivo!
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                  Não encontramos nenhuma coluna com CNPJs válidos, selecione um arquivo com CNPJs válidos.
                </Typography>
              </Grid>
              <Grid item>
                <Box my={3}>
                  <Button variant="contained"
                    sx={{
                      mt: 2,
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                      },
                      color: theme.palette.primary.contrastText
                    }}
                    onClick={() => {
                      closeModal()
                    }}>
                    OK
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      })
    }

  }, [dispatch, selectedColumn, closeModal, openModal, findCnpjKeyInArray, stopLoading])

  const setName = useCallback((data: {
    name: string
  }) => {
    dispatch(setDataEnrichmentName({
      name: data.name
    }))
  }, [dispatch])

  const reset = useCallback(() => {
    dispatch(resetDataEnrichmentState())
  }, [dispatch])

  return {
    setFile,
    selectedColumn,
    setName,
    reset
  }
}