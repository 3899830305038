import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Link, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material"
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAppSelector } from "../../../../application/states/hooks";
import { useEffect, useState } from "react";
import { useDayZeroService } from "../../../../use-cases/day-zero";
import { PieChart } from '@mui/x-charts/PieChart';
import { CircularProgressWithLabel } from "./CircularProgress";

const colorPalette = ['#9b59b6', '#3498db', '#2ecc71', '#f1c40f', '#e74c3c', 'rgba(217, 217, 217, 0.8)'];

export const DayZeroStats = () => {
  const dayZeroCount = useAppSelector(state => state.dayZero)
  const [count, setCount] = useState(0);
  const [openChart, setOpenChart] = useState(false)
  const [filteredTotal, setFilteredTotal] = useState(0); // Added filteredTotal state
  const { getDayZero, getDayZeroByUser } = useDayZeroService()
  const [activeTab, setActiveTab] = useState<'cnae' | 'porte' | 'setor' | 'filteredPj'>('cnae')
  const [progress, setProgress] = useState(10)

  useEffect(() => {
    getDayZero()
  }, [getDayZero])

  useEffect(() => {
    const duration = 60 * 1000
    const interval = 1000

    const increment = (100 / (duration / interval))

    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + increment))

      if (progress >= 100) {
        getDayZero()
        setCount(prevCount => (prevCount - prevCount) + dayZeroCount.total)
      }
    }, interval)

    return () => {
      clearInterval(timer)
    }
  }, [dayZeroCount.total, getDayZero, progress])

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (count < dayZeroCount.total) {
      interval = setInterval(() => {
        setCount(prevCount => prevCount + 50)
      }, 10) // Decreased the interval to make the animation faster
    } else {
      setCount(prevCount => (prevCount - prevCount) + dayZeroCount.total)
    }

    return () => clearInterval(interval)
  }, [count, dayZeroCount.total, getDayZero])

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (filteredTotal < dayZeroCount.totalWithFilter) {
      interval = setInterval(() => {
        setFilteredTotal(prevFilteredTotal => prevFilteredTotal + 50);
      }, 10); // Decreased the interval to make the animation faster
    } else {
      setFilteredTotal(dayZeroCount.totalWithFilter);
    }
    return () => clearInterval(interval);
  }, [filteredTotal, dayZeroCount.totalWithFilter]);

  return <>
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={openChart}
      onClose={() => {
        setOpenChart(false)
      }}
    >
      <DialogTitle>D-ZERO - Distribuição por CNAE</DialogTitle>
      <DialogContent>
        <Box>
          <Tabs value={activeTab} onChange={(e, newValue) => {
            setActiveTab(newValue)
          }} aria-label="basic tabs example">
            <Tab label="CNAES" value={"cnae"} />
            <Tab label="PORTE" value={"porte"} />
            <Tab label="SETOR" value={"setor"} />
            <Tab label="EMPRESAS NO PERFIL QUE VOCÊ BUSCA" value={'filteredPj'} onClick={() => {
              getDayZeroByUser()
            }} />
          </Tabs>
        </Box>

        {
          activeTab === 'cnae' ? (
            <Box display="flex" justifyContent="center">
              <div style={{
                height: 385,
                width: 1100
              }}>
                <PieChart
                  sx={{
                    mt: 1
                  }}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'bottom', horizontal: 'left' },
                      padding: 0
                    }
                  }}
                  series={[
                    {
                      innerRadius: 15,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 4,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 550,
                      cy: 100,
                      data: dayZeroCount.aggsCnae.map((cnae, index) => {
                        return {
                          label: cnae.cnae,
                          value: cnae.quantity,
                          color: colorPalette[index]
                        }
                      })
                    }
                  ]}
                />
              </div>
            </Box>
          ) : null
        }
        {
          activeTab === 'porte' ? (
            <Box display="flex" justifyContent="center">
              <div style={{
                height: 385,
                width: 1100
              }}>
                <PieChart
                  sx={{
                    mt: 1
                  }}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'bottom', horizontal: 'left' },
                      padding: 0,
                    },
                  }}
                  series={[
                    {
                      innerRadius: 15,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 4,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 550,
                      cy: 100,
                      data: dayZeroCount.aggsPorte.map((porte, index) => {
                        return {
                          label: porte.size,
                          value: porte.quantity,
                          color: colorPalette[index]
                        }
                      })
                    }
                  ]}
                />
              </div>
            </Box>
          ) : null
        }

        {
          activeTab === 'setor' ? (
            <Box
              display="flex"
              justifyContent="center"
            >
              <div
                style={{
                  height: 385,
                  width: 1100
                }}>
                <PieChart
                  sx={{
                    mt: 1
                  }}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'bottom', horizontal: 'left' },
                      padding: 0,
                    },
                  }}
                  series={[
                    {
                      innerRadius: 15,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 4,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 550,
                      cy: 100,
                      data: dayZeroCount.aggsSetor.map((setor, index) => {
                        return {
                          label: setor.sector,
                          value: setor.quantity,
                          color: colorPalette[index]
                        }
                      })
                    }
                  ]}
                />
              </div>
            </Box>
          ) : null
        }

        {
          activeTab === 'filteredPj' ? (
            <Box
              display="flex"
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent="space-between"
              style={{
                height: 385,
                width: 1100
              }}
            >
              <Typography color="text.secondary" my={2}>
                {`Selecionamos os CNAEs dos seus `}<b>3 projetos mais recentes</b> e comparamos com as <b>{`${dayZeroCount.total.toLocaleString('pt-BR')}`}</b> empresas abertas no dia. O total encontrado, no momento, é de:
              </Typography>
              <Divider />
              <Typography
                variant="h4"
                color="primary"
                fontWeight="bold"
                textAlign={'center'}
                textTransform={"uppercase"}
                mb={15}
              >
                {
                  filteredTotal === 0
                    ? 'Nenhuma empresa'
                    : `${filteredTotal.toLocaleString('pt-BR')} empresas`
                }
              </Typography>
            </Box>
          ) : null
        }

        <DialogActions>
          <Button onClick={() => {
            setOpenChart(false)
          }}>Fechar</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
    <Grid container spacing={1} style={{
      marginBottom: '20px'
    }}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="primary"
          fontWeight="bold"
          textTransform={"uppercase"}
        >
          D-ZERO -- Empresas Abertas Hoje
        </Typography>
        <Button>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Grid item xs={6}>
            <Box flexGrow={1}>
              <Box display={"flex"} alignItems="center">
                <Typography
                  style={{
                    margin: 0
                  }}
                  display="block"
                  fontWeight="bold"
                  variant="subtitle1"
                  color="primary"
                  textTransform={"uppercase"}
                  gutterBottom
                >
                  Geral
                </Typography>
              </Box>
              <Typography component="p" variant="h4">
                {count.toLocaleString('pt-BR')}
              </Typography>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                {format(new Date(), "dd 'de' MMMM, yyyy", { locale: ptBR })}
              </Typography>

              <div>
                <Tooltip
                  title="Visualizar detalhes"
                  arrow
                  placement="top"
                >
                  <Link color="primary" href="#" onClick={(e) => {
                    e.preventDefault()
                    setOpenChart(true)
                  }}>
                    Detalhes
                  </Link>
                </Tooltip>
              </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <CircularProgressWithLabel value={progress} />
            </Box>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 160,
          }}
        >
          <Box display={"flex"} alignItems="center">
            <Typography style={{
              margin: 0
            }} display="block" fontWeight="bold" variant="subtitle1" color="primary" textTransform={"uppercase"} gutterBottom>
              EMPRESAS COM PERFIL QUE VOCÊ PROCURA
            </Typography>
            <Tooltip
              title="Empresas que possuem o perfil que você procura, de acordo com os filtros aplicados em seus últimos projetos"
              arrow
              placement="top"
            >
              <Box style={{
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <InfoIcon style={{ color: theme.palette.primary.main }} />
              </Box>
            </Tooltip>
          </Box>
          <Typography component="p" variant="h4">
            {filteredTotal.toLocaleString('pt-BR')} {/* Display filteredTotal
          </Typography>
          <Typography color="text.secondary" sx={{ flex: 1 }}>
            {format(new Date(), "dd 'de' MMMM, yyyy", { locale: ptBR })}
          </Typography>

          <div>
            <Tooltip
              title="Aplica os filtros que você utilizou em seus últimos projetos e cria uma extração com as empresas que possuem o perfil que você procura"
              arrow
              placement="top"
            >
              <Link color="primary" href="#" onClick={(e) => {
                e.preventDefault()
              }}>
                Extrair
              </Link>
            </Tooltip>
          </div>
        </Paper>
      </Grid> */}
    </Grid >
  </>
}