import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { useAppSelector } from "../../application/states/hooks"
import { useModalContainerService } from "../../use-cases/modal-container"
export const ModalContainer = () => {
  const modalContainer = useAppSelector((state) => state.modalContainer)
  const { closeModal } = useModalContainerService()
  return (
    <Dialog
      style={{
        zIndex: 230130
      }}
      open={modalContainer.open}
      onClose={() => {
        closeModal()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {modalContainer.content}
      </DialogContent>
      <DialogActions>
        {
          modalContainer.closeButton ? (
            <Button onClick={() => {
              closeModal()
            }}>Cancelar</Button>
          ) : null
        }
        {
          modalContainer.okButton ? (
            <Button onClick={() => {
              if (modalContainer.onOk) {
                modalContainer.onOk()
              }
            }} autoFocus>
              OK
            </Button>
          ) : null
        }

      </DialogActions>
    </Dialog>


  )
}