import { Box, Grid, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { useProjectService } from "../../../../../../use-cases/project";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { DatePicker } from "@mui/x-date-pickers";
import { theme } from "../../../../../styles";
import { darkenColor } from "../../../../../../utils";

export const CreatedAtFilter = () => {
  const { setMaxCreatedAtFilter, setMinCreatedAtFilter } = useProjectService()
  const createdAtFilterState = useAppSelector((state) => state.project?.selectedFilter?.createdAt)

  return (
    <>
      <FilterHeader title="Data de Abertura" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
        </Typography>
        Esta opção permite filtrar o período inicial e o final de abertura das empresas, podendo também ser definido somente um.
        <Typography>
          Exemplo: Empresas abertas a partir da data 05/02/2019 em diante.

        </Typography>
        <Typography>
          Exemplo: Empresas abertas até a data de 05/02/2019.
        </Typography>
        <Typography>
          Caso esta opção não seja marcada será considerado todas as datas de abertura na contagem.
        </Typography>
      </>} />

      <Box p={3}>
        <Grid container>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}>
              <DatePicker
                value={createdAtFilterState?.min ? new Date(createdAtFilterState?.min) : null}
                onChange={(date) => {
                  setMinCreatedAtFilter({
                    minCreatedAt: date === null ? undefined : date
                  })
                }}
                label="Data início"
                disableFuture
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        }
                      }
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}>
              <DatePicker
                value={createdAtFilterState?.max ? new Date(createdAtFilterState?.max) : null}
                onChange={(date) => {
                  setMaxCreatedAtFilter({
                    maxCreatedAt: date === null ? undefined : date
                  })
                }}
                label="Data fim"
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                          backgroundColor: darkenColor(theme.palette.primary.main, 10)
                        }
                      }
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}