import { Box, Grid, Typography } from "@mui/material"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { SamplesTable } from "./samples-table"

export const SamplesSection = () => {
  const samples = useAppSelector((state) => state.project.projectReport?.samples)
  return <>
    {
      samples?.length ?? -1 > 0 ? (
        <>
          <SamplesTable />
        </>
      ) : (
        <>
          <Box>
            <Grid container>
              <Grid item>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Clique no botão "Atualizar" para gerar os dados da amostragem.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )
    }
  </>
}