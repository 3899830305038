import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from "@mui/material"
import BusinessIcon from '@mui/icons-material/Business';
import Person2Icon from '@mui/icons-material/Person2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../application/states/hooks";
import { useConsumptionService } from "../../../../../use-cases/consumption";
import { UserConsumption } from "./UserConsumption";
import { useSubscriptionService } from "../../../../../use-cases/subscription";
import { darkenColor, onlyNumbers } from "../../../../../utils";
import { theme } from "../../../../styles";

export const ConsumptionLockSection = () => {
  const [activeTab, setActiveTab] = useState<'company' | 'user'>('company')
  const userState = useAppSelector((state) => state.user)
  const { setUserListWithConsumption, createConsumptionLock } = useConsumptionService()
  const usage = useAppSelector((state) => state.subscription.usage)
  const { setSubscription } = useSubscriptionService()
  const [inputValue, setInputValue] = useState<number | undefined>()
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações / Limitação de Consumo`
  }, [resaleConfig])

  useEffect(() => {
    setUserListWithConsumption()
  }, [userState.userList, setUserListWithConsumption])

  useEffect(() => {
    setSubscription()
  }, [
    setSubscription
  ])

  useEffect(() => {
    if (usage) {
      setInputValue(usage.consumptionLock)
    }
  }, [usage])

  return <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              onClick={(e) => {
                setActiveTab('company')
              }}
              variant={activeTab === 'company' ? 'contained' : 'outlined'}
              startIcon={<BusinessIcon />}
              sx={{
                '&:hover': {
                  backgroundColor: activeTab === 'company' ? darkenColor(theme.palette.primary.main, 15) : theme.palette.background.default
                },
                color: activeTab === 'company' ? theme.palette.primary.contrastText : theme.palette.primary.main
              }}
            >
              Limitar consumo por empresa
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={(e) => {
                setActiveTab('user')
              }}
              variant={activeTab === 'user' ? 'contained' : 'outlined'}
              startIcon={<Person2Icon />}
              sx={{
                '&:hover': {
                  backgroundColor: activeTab === 'user' ? darkenColor(theme.palette.primary.main, 15) : theme.palette.background.default
                },
                color: activeTab === 'user' ? theme.palette.primary.contrastText : theme.palette.primary.main
              }}
            >
              Limitar consumo por usuário
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {
          activeTab === 'company' ? (
            <Grid container>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight="bold">
                      Limitação por empresa
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label="Limite de consumo"
                          type="text"
                          value={inputValue?.toLocaleString('pt-BR') ?? ''}
                          onChange={(e) => {
                            const formattedValue = onlyNumbers(e.target.value)
                            if (e.target.value) {
                              setInputValue(Number(formattedValue))
                            } else {
                              setInputValue(undefined)
                            }
                          }}
                          fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            '&:hover': {
                              backgroundColor: darkenColor(theme.palette.primary.main, 15)
                            },
                            color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                          }}
                          onClick={() => {
                            createConsumptionLock({
                              limit: inputValue ?? 0,
                              companyId: userState.companyId
                            })
                          }}
                        >
                          Atualizar limite
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          ) : (
            <>
              {
                userState.userList.map((user) => {
                  return <UserConsumption {...user} />
                })
              }


            </>
          )
        }

      </Grid>
    </Grid>
  </>
}