import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { IResaleConfig } from "../../application/models"

export const useResaleConfigApi = () => {
    const { axiosAuthInstance } = useAxiosInstances()

    const createResaleConfigApi = useCallback((data: {
        companyId: string
        name: string
        img: string
        favicon: string
        loginPageImg: string
        domain: string
        contact: {
            email: string
            phone: string
            beginHour: string
            endHour: string
        }
        emailProviderSettings: {
            user: string
            password: string
            host: string
            port: string
        }
        theme: {
            colors: {
                primary: {
                    main: string
                    contrastColor: string
                }
                secondary: {
                    main: string
                    contrastColor: string
                }
                background: {
                    paper: {
                        main: string
                    }
                    body: {
                        main: string
                    }
                }
            },
            fontFamily?: string
        }
    }): Promise<IResaleConfig> => {
        return axiosAuthInstance.post('/resaleConfig', {
            resaleConfig: {
                companyId: data.companyId,
                name: data.name,
                img: data.img,
                favicon: data.favicon,
                loginPageImg: data.loginPageImg,
                domain: data.domain,
                contact: {
                    email: data.contact.email,
                    phone: data.contact.phone,
                    beginHour: data.contact.beginHour,
                    endHour: data.contact.endHour,
                },
                emailProviderSettings: {
                    user: data.emailProviderSettings.user,
                    password: data.emailProviderSettings.password,
                    host: data.emailProviderSettings.host,
                    port: data.emailProviderSettings.port
                },
                theme: {
                    colors: {
                        primary: {
                            main: data.theme.colors.primary.main,
                            contrastColor: data.theme.colors.primary.contrastColor
                        },
                        secondary: {
                            main: data.theme.colors.secondary.main,
                            contrastColor: data.theme.colors.secondary.contrastColor
                        },
                        background: {
                            paper: {
                                main: data.theme.colors.background.paper.main
                            },
                            body: {
                                main: data.theme.colors.background.body.main
                            }
                        }
                    },
                    fontFamily: data.theme.fontFamily
                }
            }
        }).then((result) => {
            const resaleConfig = result.data.result.resaleConfig

            if (resaleConfig) {
                return resaleConfig
            } else {
                throw {
                    type: 'NotFoundError',
                    message: 'Erro ao criar white label'
                }
            }
        }).catch((error) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                alert('calixto 1')
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const findResaleConfigByCompanyIdApi = useCallback((data: {
        companyId: string
    }): Promise<IResaleConfig> => {
        return axiosAuthInstance.get(`/resaleConfig/find/${data.companyId}`).then((result) => {
            const resaleConfig = result.data?.result?.resaleConfig

            if (resaleConfig) {
                return resaleConfig
            } else {
                throw {
                    type: 'NotFoundError',
                    message: 'Configuração de white label não encontrada'
                }
            }
        }).catch((error) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const findResaleConfigByResaleIdApi = useCallback((data: {
        resaleId: string
    }): Promise<IResaleConfig> => {
        return axiosAuthInstance.get(`/resaleConfig/${data.resaleId}`).then((result) => {
            const resaleConfig = result.data?.result?.resaleConfig

            if (resaleConfig) {
                return resaleConfig
            } else {
                throw {
                    type: 'NotFoundError',
                    message: 'Configuração de white label não encontrada'
                }
            }
        }).catch((error) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const updateResaleConfigApi = useCallback((data: {
        resaleConfig: Partial<IResaleConfig>
    }) => {
        return axiosAuthInstance.put('/resaleConfig', {
            resaleConfig: data.resaleConfig
        }).then((result) => {
            const resaleConfig = result.data?.result?.resaleConfig

            if (resaleConfig) {
                return resaleConfig
            } else {
                throw {
                    type: 'NotFoundError',
                    message: 'Erro ao atualizar ambiente'
                }
            }
        }).catch((error) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    const findResaleConfigByDomainApi = useCallback((data: {
        domain: string
    }): Promise<IResaleConfig> => {
        return axiosAuthInstance.get(`/resaleConfig/domain/${data.domain}`).then((result) => {
            const resaleConfig = result.data?.result?.resaleConfig

            if (resaleConfig) {
                return resaleConfig
            } else {
                throw {
                    type: 'NotFoundError',
                    message: 'Configuração de white label não encontrada'
                }
            }
        }).catch((error) => {
            if (error?.response?.data?.result) {
                throw {
                    message: error.response.data.result.message,
                    type: error.response.data.result.type
                }
            } else {
                throw {
                    message: 'Erro desconhecido',
                    type: 'unknown'
                }
            }
        })
    }, [axiosAuthInstance])

    return {
        createResaleConfigApi,
        findResaleConfigByCompanyIdApi,
        findResaleConfigByResaleIdApi,
        updateResaleConfigApi,
        findResaleConfigByDomainApi
    }
}