import { Typography, Box, Grid, TextField, Slider } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { useProjectService } from "../../../../../../use-cases/project";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { NumberFormatCustom, ValueLabelComponent, valuetext } from "../../../../../../utils";

export const RevenuesFilter = () => {
  const { setRevenueMaxFilter, setRevenueMinFilter } = useProjectService()
  const revenueFilterState = useAppSelector((state) => state.project?.selectedFilter?.revenues)
  const exportDialog = useAppSelector((state) => state.project.exportDialog)

  return (<>
    <FilterHeader title="Faturamento anual" description={<>
      <Typography style={{
        marginBottom: '10px'
      }}>
        Esta opção permite filtrar empresas através do seu faturamento presumido. Ela permite a busca através do faturamento mínimo e o faturamento máximo.
      </Typography>
      <Typography>
        Caso esta opção não seja marcada, será considerado todos os níveis de faturamento na contagem.
      </Typography>
    </>} />

    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            value={revenueFilterState?.min}
            InputProps={{
              startAdornment: <span style={{
                marginRight: '5px'
              }}>R$</span>,
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              setRevenueMinFilter({
                revenueMin: Number(e.target.value)
              })
            }}
            label="Faturamento mínimo"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            InputProps={{
              startAdornment: <span style={{
                marginRight: '5px'
              }}>R$</span>,
              inputComponent: NumberFormatCustom,
            }}
            value={revenueFilterState?.max}
            onChange={(e) => {
              setRevenueMaxFilter({
                revenueMax: Number(e.target.value)
              })
            }}
            label="Faturamento máximo"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: '100%', marginTop: '60px' }}>
            <Slider
              defaultValue={2000000000.00}
              getAriaValueText={valuetext}
              step={10}
              valueLabelDisplay={exportDialog.open ? 'off' : 'on'}
              marks={[
                {
                  value: 1000000.00,
                  label: 'R$ 1.000.000,00',
                },
                {
                  value: 1000000000.00,
                  label: 'R$ 1.000.000.000,00',
                },
                {
                  value: 2000000000.00,
                  label: 'R$ 2.000.000.000,00',
                }
              ]}
              min={0.00}
              max={2000000000.00}
              value={[revenueFilterState?.min ?? 0, revenueFilterState?.max ?? 2000000000.00]}
              onChange={(event, value: any) => {

                setRevenueMinFilter({
                  revenueMin: Number(value[0] ?? 0)
                })
                setRevenueMaxFilter({
                  revenueMax: Number(value[1] ?? 2000000000.00)
                })
              }}
              slots={{
                valueLabel: ValueLabelComponent,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>)
}