import { useCallback } from "react"

type ILocalStorageKeys = 'ACCESS_TOKEN'
export const useLocalStorageService = () => {
  const setItem = useCallback((data: {
    key: ILocalStorageKeys,
    value: string
  }) => {
    window.localStorage.setItem(data.key, data.value)
  }, [])

  const getItem = useCallback((data: {
    key: ILocalStorageKeys
  }) => {
    return window.localStorage.getItem(data.key)
  }
  , [])

  const removeItem = useCallback((data: {
    key: ILocalStorageKeys
  }) => {
    window.localStorage.removeItem(data.key)
  }
  , [])

  return {
    setItem,
    getItem,
    removeItem
  }
}