import { useCallback } from "react"
import { ILocation } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"

export const useLocationApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  const findLocationBySearchTerm = useCallback((data: {
    searchTerm: string,
    categoria?: string
  }): Promise<ILocation[]> => {
    let categoriaFilter = undefined
    if(data.categoria && data.categoria !== '') {
      categoriaFilter = data.categoria
    }
    return axiosAuthInstance.get('/location', {
      params: {
        searchTerm: data.searchTerm,
        categoria: categoriaFilter
      }
    }).then((response) => {
      const locations = response.data?.result?.locationList
      if (locations) {
        return locations
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Localizações não encontradas'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
    
  }, [axiosAuthInstance])
  return {
    findLocationBySearchTerm
  }
}