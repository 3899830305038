import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IBackofficeRevenueReport {
  cnpj: string
  status: string
  razaoSocial: string
  tipoPlano: string
  precoUnitario?: number
  valorAPagar: any
  subscriptionConsumption: number
  separatedCreditValue: number
}

interface IBackofficeRevenueReportSlice {
  date?: Date,
  result: IBackofficeRevenueReport[]
}

const initialState: IBackofficeRevenueReportSlice = {
  date: undefined,
  result: [],
};

const backofficeRevenueReportSlice = createSlice({
  name: 'revenueReport',
  initialState,
  reducers: {
    setRevenueReportDateState(state, action: PayloadAction<{
      date?: Date
    }>) {
      state.date = action.payload.date
    },
    setRevenueReportResultState(state, action: PayloadAction<{
      result: IBackofficeRevenueReportSlice['result']
    }>) {
      state.result = action.payload.result
    },
    setRevenueReportState(state) {
      state.date = initialState.date
      state.result = initialState.result
    }
  },
});

export const {
  setRevenueReportDateState,
  setRevenueReportResultState,
  setRevenueReportState
} = backofficeRevenueReportSlice.actions;
export const backOfficeRevenueReportReducer = backofficeRevenueReportSlice.reducer;