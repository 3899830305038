import { Box, Grid } from "@mui/material"
import { useEffect } from "react"
import { UnitSearchFilterForm } from "./unit-search-filter-form"
import { UnitSearchTable } from "./unit-search-table/UnitSearchTable"
import { useAppSelector } from "../../../../application/states/hooks"

export const UnitSearchPage = () => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Busca unitária`
  }, [resaleConfig])

  return (
    <>
      <Box p={3}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <UnitSearchFilterForm />
          </Grid>
          <Grid item xs={12}>
            <UnitSearchTable />
          </Grid>
        </Grid>

      </Box>
    </>
  )
}