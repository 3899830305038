import React from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import { IPDFGeneratorProps } from './IPDFGeneratorProps';
import { SociosSection } from './SociosSection';
import { styles } from './styles';
import { CompanySection } from './CompanySection';
import { CnaesSection } from './CnaesSection';
import { LocationSection } from './LocationSection';
import { WarningPage } from './WarningPage';

export const PDFGenerator: React.FC<IPDFGeneratorProps> = ({ currentPj, image }) => {
    return <>
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                <View style={styles.section}>
                    <CompanySection currentPj={currentPj} image={image} />
                </View>
                <View style={styles.section}>
                    <CnaesSection currentPj={currentPj} />
                </View>
                <View style={styles.section}>
                    <SociosSection currentPj={currentPj} image={image} />
                </View>
                <View style={styles.section}>
                    <LocationSection currentPj={currentPj} image={image} />
                </View>
            </Page >
            <Page size="A4" style={styles.page} wrap={false}>
                <WarningPage currentPj={currentPj} image={image} />
            </Page>
        </Document >
    </>
};