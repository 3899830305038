import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IPessoaJuridica } from "../../../application/models/IPessoaJuridica"
import { ISocio } from "../../../application/models/ISocio"

export const useBackofficePJApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const findPjById = useCallback((data: {
    id: string,
    userId: string
  }): Promise<{
    pj: IPessoaJuridica,
    socios: ISocio[]
  }> => {
    return axiosAuthInstance.get(`/pessoa-juridica/${data.id}`, {
      params: {
        userId: data.userId
      }
    })
      .then((response) => {
        const pj = response.data?.result?.pessoaJuridica
        const socios = response.data?.result?.socios
        if (pj) {
          return {
            pj: pj,
            socios: socios
          }
        } else {
          throw {
            type: 'NotFoundError',
            message: 'PJ não encontrada'
          }
        }
      }).catch((err) => {
        if (err?.response?.data?.result) {
          throw {
            message: err.response.data.result.message,
            type: err.response.data.result.type
          }
        } else {
          throw {
            message: 'Erro desconhecido',
            type: 'unknown'
          }
        }
      })
  }, [axiosAuthInstance])

  const findByCnpj = useCallback((data: {
    cnpj: string,
    userId: string
  }): Promise<{
    pj: IPessoaJuridica,
    socios: ISocio[]
  }> => {
    return axiosAuthInstance.get(`/pessoa-juridica/find-by-cnpj`, {
      params: {
        userId: data.userId,
        cnpj: data.cnpj
      }
    })
      .then((response) => {
        const pj = response.data?.result?.pessoaJuridica
        const socios = response.data?.result?.socios
        if (pj) {
          return {
            pj: pj,
            socios: socios
          }
        } else {
          throw {
            type: 'NotFoundError',
            message: 'PJ não encontrada'
          }
        }
      }).catch((err) => {
        if (err?.response?.data?.result) {
          throw {
            message: err.response.data.result.message,
            type: err.response.data.result.type
          }
        } else {
          throw {
            message: 'Erro desconhecido',
            type: 'unknown'
          }
        }
      })
  }, [axiosAuthInstance])

  return {
    findPjById,
    findByCnpj
  }
}