import GoogleAdsIcon from '../../../../assets/img/google-ads-icon.png'
import FacebookAdsIcon from '../../../../assets/img/facebook-ads-icon.png'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { dataGridTexts } from '../../../../assets/helper-assets';
import { useEffect, useMemo } from 'react';
import { useExportDownloadService } from '../../../../../use-cases/project-download/useProjectDownloadService';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LockIcon from '@mui/icons-material/Lock';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useProjectExportService } from '../../../../../use-cases/project-export/useProjectExportService';
import { format } from 'date-fns';
import { StatusCell } from './StatusCell';
import { theme } from '../../../../styles';
import { nameAbbreviation } from '../../../../../utils';

export function ProjectExportsTable() {
  const { downloadGoogleADS, downloadFacebookAds } = useExportDownloadService()
  const { setPage } = useProjectExportService()
  const projectExport = useAppSelector((state) => state.projectExport)
  const { company } = useAppSelector((state) => state.company)
  const user = useAppSelector((state) => state.user)

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Nome',
        minWidth: 145,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'company',
        headerName: 'Cliente',
        minWidth: 145,
        flex: 0.7,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'createdBy',
        headerName: 'Usuário',
        minWidth: 140,
        flex: 0.7,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'totalExports',
        headerName: 'Qtd. de registros',
        minWidth: 125,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return params.value?.toLocaleString('pt-BR')
        }
      },
      {
        field: 'createdAt',
        headerName: 'Data de criação',
        minWidth: 125,
        type: 'date',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return format(new Date(params.value as string), 'dd/MM/yyyy - HH:mm ')
        }
      },
      {
        field: 'type',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Tipo',
        minWidth: 100,
        renderCell: (params) => (
          <>
            {
              params.value === 'PROJECT' ? (
                <Tooltip
                  title="Projeto"
                  arrow
                  placement="right"
                  role='dialog'
                >
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                      P
                    </Grid>
                    <Grid item>
                      <BubbleChartIcon />
                    </Grid>
                  </Grid>
                </Tooltip>
              ) :
                <Tooltip
                  title="Enriquecimento"
                  arrow
                  placement="right"
                  role='dialog'
                >
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                      E
                    </Grid>
                    <Grid item>
                      <CloudUploadIcon />
                    </Grid>
                  </Grid>
                </Tooltip>
            }
          </>
        )
      },
      {
        field: 'download',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Download',
        minWidth: 100,
        renderCell: (params) => (
          <>
            {
              user.userType === "SELLER" ?
                params.value.projectExport.companyId === user.companyId ?
                  params.value ? (
                    <StatusCell key={params.value.projectExport.id} {...params.value} />
                  )
                    : null
                  : <LockIcon key={params.value.projectExport.id} color='primary' />
                : params.value ? (
                  <StatusCell key={params.value.projectExport.id} {...params.value} />
                )
                  : null
            }
          </>
        )
      },
      {
        field: 'googleADS',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Google ADS',
        minWidth: 100,
        renderCell: (params) => {
          return (
            user.userType === "SELLER" ?
              params.value.projectExport.companyId === user.companyId ?
                params.value ?
                  <Button
                    key={params.value.projectExport.id}
                    sx={{
                      cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                    }}
                    onClick={() => {
                      if (params.value.fileExists === true) {
                        downloadGoogleADS({
                          projectExportId: params.value?.projectExport.id ?? ''
                        })
                      }
                    }}>
                    <img
                      key={params.value.projectExport.id}
                      width={30}
                      src={GoogleAdsIcon}
                      alt="Google ADS Download"
                      style={{
                        filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                        opacity: params.value.fileExists === true ? 1 : 0.7
                      }}
                    />
                  </Button>
                  : null
                : <LockIcon key={params.value.projectExport.id} color='primary' />
              : params.value ?
                <Button
                  key={params.value.projectExport.id}
                  sx={{
                    cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                  }}
                  onClick={() => {
                    if (params.value.fileExists === true) {
                      downloadGoogleADS({
                        projectExportId: params.value?.projectExport.id ?? ''
                      })
                    }
                  }}>
                  <img
                    key={params.value.projectExport.id}
                    width={30}
                    src={GoogleAdsIcon}
                    alt="Google ADS Download"
                    style={{
                      filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                      opacity: params.value.fileExists === true ? 1 : 0.7
                    }}
                  />
                </Button>
                : null
          )
        }
      },
      {
        field: 'facebookADS',
        headerAlign: 'center',
        align: 'center',
        headerName: 'Facebook ADS',
        minWidth: 115,
        renderCell: (params) => (
          user.userType === "SELLER" ?
            params.value.projectExport.companyId === user.companyId ?
              params.value ?
                <Button
                  key={params.value.projectExport.id}
                  sx={{
                    cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                  }}
                  onClick={() => {
                    if (params.value.fileExists === true) {
                      downloadFacebookAds({
                        projectExportId: params.value?.projectExport.id ?? ''
                      })
                    }
                  }}
                >
                  <img
                    key={params.value.projectExport.id}
                    width={40}
                    src={FacebookAdsIcon}
                    alt="Facebook ADS Download"
                    style={{
                      filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                      opacity: params.value.fileExists === true ? 1 : 0.7
                    }}
                  />
                </Button>
                : null
              : <LockIcon key={params.value.projectExport.id} color='primary' />
            : params.value ?
              <Button
                key={params.value.projectExport.id}
                sx={{
                  cursor: params.value.fileExists === true ? 'pointer' : 'auto'
                }}
                onClick={() => {
                  if (params.value.fileExists === true) {
                    downloadFacebookAds({
                      projectExportId: params.value?.projectExport.id ?? ''
                    })
                  }
                }}
              >
                <img
                  key={params.value.projectExport.id}
                  width={40}
                  src={FacebookAdsIcon}
                  alt="Facebook ADS Download"
                  style={{
                    filter: params.value.fileExists === true ? 'none' : 'grayscale(100%)',
                    opacity: params.value.fileExists === true ? 1 : 0.7
                  }}
                />
              </Button>
              : null
        )
      },
    ]
  }, [downloadFacebookAds, downloadGoogleADS, user.companyId, user.userType])
  const { setProjectExportList } = useProjectExportService()

  useEffect(() => {
    setProjectExportList()
  }, [setProjectExportList, projectExport.page])

  return (
    <Box sx={{
      '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        color: company?.resale === true || company?.resaleId
          ? theme.palette.primary.contrastText
          : '#FFF'
      },
      '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 0
      },
      minWidth: '100%'
    }}>
      <DataGrid
        showCellVerticalBorder
        disableColumnFilter
        disableColumnMenu
        keepNonExistentRowsSelected
        rowSelection={false}
        autoHeight={true}
        localeText={dataGridTexts}
        pagination={true}
        pageSizeOptions={[10]}
        paginationModel={{
          page: projectExport.page - 1,
          pageSize: 10
        }}
        onPaginationModelChange={(params) => {
          setPage({
            page: params.page + 1
          })
        }}
        paginationMode='server'
        loading={projectExport.loading}
        rowCount={projectExport.total}
        rows={projectExport.exportList.map((item) => {
          return {
            id: item.projectExport.id,
            name: item.projectExport.name,
            company: nameAbbreviation(item.projectExport.razaoSocial.toUpperCase()),
            createdBy: nameAbbreviation(item.projectExport.userName.toUpperCase()),
            totalExports: item.projectExport.total,
            createdAt: new Date(item.projectExport.createdAt),
            type: item.projectExport.type,
            googleADS: item,
            facebookADS: item,
            download: item
          }
        })}
        columns={columns.filter((column) => {
          if ((company?.master === false && company?.resale === false) || user.userType === "OPERATOR") {
            return column.field !== 'company' && column.field !== 'createdBy'
          }
          return column
        })}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          minWidth: '100%'
        }}
      />
    </Box>
  );
}