import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { usePasswordRecoveryApi } from "../../infra"
import {
  setActivePasswordRecoveryStep,
  setRecoveryEmail as setRecoveryEmailState,
  setPassword as setPasswordState,
  setPasswordConfirmation as setPasswordConfirmationState,
  setVerificationCode as setVerificationCodeState,
  setError,
  resetPasswordRecoveryState,
  setPasswordRecoveryFieldErrors,
  resetPasswordRecoveryFieldErrors,
  validatePasswordRecoveryFormStep1,
  validatePasswordRecoveryFormStep2,
  validatePasswordRecoveryFormStep3
} from "../../application/states/password-recovery/password-recovery-slice"
import { executeAlert } from "../../application/states/toast-alert"
import { useModalContainerService } from "../modal-container"
import { PasswordChangedModal } from "../../presentation/pages/authentication/request-new-password/password-changed-modal"
import { useLoadingService } from "../loading"

export const usePasswordRecoveryService = () => {

  const {
    requestNewPassword: requestNewPasswordApi,
    validateVerificationCode: validateVerificationCodeApi,
    changePasswordWithToken: changePasswordWithTokenApi
  } = usePasswordRecoveryApi()
  const dispatch = useAppDispatch()
  const { startLoading, stopLoading } = useLoadingService()

  const passwordRecovery = useAppSelector(state => state.passwordRecovery)
  const { openModal } = useModalContainerService()

  const nextStep = useCallback(() => {
    dispatch(setActivePasswordRecoveryStep(passwordRecovery.activeStep + 1))
  }, [passwordRecovery.activeStep, dispatch])

  const prevStep = useCallback(() => {
    dispatch(setActivePasswordRecoveryStep(passwordRecovery.activeStep - 1))
  }, [passwordRecovery.activeStep, dispatch])

  const reset = useCallback(() => {
    dispatch(setActivePasswordRecoveryStep(0))
  }, [dispatch])

  const requestNewPassword = useCallback(() => {
    dispatch(setError())
    dispatch(resetPasswordRecoveryFieldErrors())
    const errors = validatePasswordRecoveryFormStep1({
      email: passwordRecovery.passwordRecoveryModel.email
    })
    if (errors.length > 0) {
      dispatch(setPasswordRecoveryFieldErrors(errors))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
      dispatch(setError({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
    } else {
      startLoading()
      requestNewPasswordApi({
        email: passwordRecovery.passwordRecoveryModel.email,
        domain: window.location.host
      }).then(() => {
        stopLoading()
        dispatch(executeAlert({
          message: 'E-mail enviado com sucesso!',
          type: 'success'
        }))
        nextStep()
      }).catch((error) => {
        stopLoading()
        dispatch(setError({
          message: error.message ?? 'Erro inesperado',
          type: error.type ?? 'error'
        }))
        dispatch(executeAlert({
          message: error.message ?? 'Erro inesperado',
          type: 'error'
        }))
      })
    }
  }, [dispatch, passwordRecovery.passwordRecoveryModel.email, startLoading, requestNewPasswordApi, stopLoading, nextStep])

  const validateVerificationCode = useCallback(() => {
    dispatch(setError())
    dispatch(resetPasswordRecoveryFieldErrors())
    const errors = validatePasswordRecoveryFormStep2({
      verificationCode: passwordRecovery.passwordRecoveryModel.verificationCode
    })
    if (errors.length > 0) {
      dispatch(setPasswordRecoveryFieldErrors(errors))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
      dispatch(setError({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
    } else {

      validateVerificationCodeApi({
        email: passwordRecovery.passwordRecoveryModel.email,
        verificationCode: passwordRecovery.passwordRecoveryModel.verificationCode
      }).then(() => {
        dispatch(executeAlert({
          message: 'Código de verificação válido!',
          type: 'success'
        }))
        nextStep()
      }).catch((error) => {
        dispatch(setError({
          message: error.message ?? 'Erro inesperado',
          type: error.type ?? 'error'
        }))
        dispatch(executeAlert({
          message: error.message ?? 'Erro inesperado',
          type: 'error'
        }))
      })
    }



  }, [
    nextStep,
    dispatch,
    passwordRecovery.passwordRecoveryModel.email,
    validateVerificationCodeApi,
    passwordRecovery.passwordRecoveryModel.verificationCode

  ])


  const changePasswordWithToken = useCallback(() => {
    dispatch(setError())
    dispatch(resetPasswordRecoveryFieldErrors())
    const errors = validatePasswordRecoveryFormStep3({
      confirmPassword: passwordRecovery.passwordRecoveryModel.confirmPassword,
      password: passwordRecovery.passwordRecoveryModel.password
    })

    if (errors.length > 0) {
      dispatch(setPasswordRecoveryFieldErrors(errors))
      dispatch(executeAlert({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
      dispatch(setError({
        message: errors[0].fieldError.message,
        type: 'error'
      }))
    } else {
      changePasswordWithTokenApi({
        email: passwordRecovery.passwordRecoveryModel.email,
        password: passwordRecovery.passwordRecoveryModel.password,
        verificationCode: passwordRecovery.passwordRecoveryModel.verificationCode
      }).then(() => {
        dispatch(executeAlert({
          message: 'Senha alterada com sucesso!',
          type: 'success'
        }))
        openModal({
          content: <PasswordChangedModal />
        })
        dispatch(resetPasswordRecoveryState())
      }).catch((error) => {
        dispatch(setError({
          message: error.message ?? 'Erro inesperado',
          type: error.type ?? 'error'
        }))
        dispatch(executeAlert({
          message: error.message ?? 'Erro inesperado',
          type: 'error'
        }))
      })
    }



  }, [
    dispatch,
    passwordRecovery.passwordRecoveryModel.email,
    passwordRecovery.passwordRecoveryModel.password,
    passwordRecovery.passwordRecoveryModel.verificationCode,
    changePasswordWithTokenApi,
    openModal,
    passwordRecovery.passwordRecoveryModel.confirmPassword
  ])


  const setRecoveryEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setRecoveryEmailState({
      value: data.email,
    }))
  }, [dispatch])

  const setVerificationCode = useCallback((data: {
    verificationCode: string
  }) => {
    dispatch(setVerificationCodeState({
      value: data.verificationCode,
    }))
  }, [dispatch])

  const setPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setPasswordState({
      value: data.password,
    }))
  }, [dispatch])

  const setPasswordConfirmation = useCallback((data: {
    passwordConfirmation: string
  }) => {
    dispatch(setPasswordConfirmationState({
      value: data.passwordConfirmation,
    }))
  }, [dispatch])

  return {
    nextStep,
    prevStep,
    reset,
    requestNewPassword,
    setRecoveryEmail,
    setPassword,
    setVerificationCode,
    setPasswordConfirmation,
    validateVerificationCode,
    changePasswordWithToken
  }
}