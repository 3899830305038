import { Outlet } from "react-router-dom"
import { Header } from "./header";
import { LurikBreadcrumb } from "./breadcrumb";
import { useEffect, useState } from "react";
import { useUserService } from "../../../../use-cases/user";
import { useAuthenticationService } from "../../../../use-cases/authentication";
import { useResaleConfigService } from "../../../../use-cases/resale-config";
import { getSleep } from "../../../../utils";

export const Layout = () => {
  const { setUser } = useUserService()
  const { loadToken } = useAuthenticationService()
  const { getResaleConfigByDomain } = useResaleConfigService()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTheme = async () => {
      try {
        getResaleConfigByDomain({
          domain: window.location.host
        })
      } catch (error) {

      } finally {
        await getSleep(500)
        setLoading(false)
      }
    }

    loadTheme()
  }, [getResaleConfigByDomain])

  useEffect(() => {
    loadToken()
  }, [loadToken])

  useEffect(() => {
    setUser()
  }, [setUser])

  return (
    <>
      {
        loading === true ? <></>
          :
          <>
            <Header />
            <LurikBreadcrumb />
            <Outlet />
          </>
      }
    </>
  )
}



