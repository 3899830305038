import { Visibility } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useAppSelector } from "../../../../../../../application/states/hooks"
import { useUnitSearchService } from "../../../../../../../use-cases/unit-search"
import { anonAddress, anonCnpj, anonPhone, formatCnpj, formatTelefoneBrazil } from "../../../../../../../utils"
import { dataGridTexts } from "../../../../../../assets/helper-assets"
import { theme } from "../../../../../../styles"

export const SamplesTable = () => {
  const samples = useAppSelector((state) => state.project.projectReport?.samples)
  const { company } = useAppSelector((state) => state.company)
  const { viewPj } = useUnitSearchService()
  const columns: GridColDef[] = [
    {
      field: 'view',
      headerAlign: 'center',
      align: 'center',
      headerName: 'Visualizar',
      minWidth: 140,
      renderCell: (params) => {
        return <Button onClick={() => {
          viewPj({
            pjId: params.row.id
          })
        }}>
          <Visibility />
        </Button>
      }
    },
    {
      field: 'cnpj',
      headerAlign: 'center',
      headerName: 'CNPJ',
      minWidth: 200,
      renderCell: (params) => {
        return <>{anonCnpj(formatCnpj(params.value ?? ''))}</>
      },

    },
    {
      field: 'razaoSocial',
      headerAlign: 'center',
      headerName: 'Razão Social',
      minWidth: 320,
      flex: 1
    },
    {
      field: 'nomeFantasia',
      headerAlign: 'center',
      headerName: 'Nome Fantasia',
      minWidth: 250,
      flex: 0.8
    },
    {
      field: 'telefone',
      headerAlign: 'center',
      headerName: 'Telefone',
      minWidth: 200,
      type: 'string',
      renderCell: (params) => {
        const telefoneFormatado = formatTelefoneBrazil(params.value ?? '');
        const telefoneAnonimizado = anonPhone(telefoneFormatado)
        return <>
          {telefoneAnonimizado}
        </>
      }
    },
    {
      field: 'endereco',
      headerAlign: 'center',
      headerName: 'Endereço',
      minWidth: 200,
      flex: 0.5,
      type: 'string',
      renderCell: (params) => {
        return <>
          {anonAddress(params.value ?? '')}
        </>
      }
    }
  ]

  return <>
    <Box sx={{
      '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        color: company?.resale === true || company?.resaleId
          ? theme.palette.primary.contrastText
          : '#FFF'
      },
      '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 0
      },
      minWidth: '100%'
    }}>
      <DataGrid
        showCellVerticalBorder
        disableColumnFilter
        disableColumnMenu
        rowSelection={false}
        autoHeight={true}
        localeText={dataGridTexts}
        pagination={undefined}
        rows={samples ?? []}
        columns={columns}
        slotProps={{
          cell: {
            align: 'center'
          }
        }}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          minWidth: '100%'
        }}
      />
    </Box>
  </>
}