import { useCallback } from "react"
import { useAppDispatch } from "../../application/states/hooks"
import { setModalCloseButton, setModalContainerContent, setModalContainerOpen, setModalOkButton, setModalOnOk } from "../../application/states/modal-container"

export const useModalContainerService = () => {
  const dispatch = useAppDispatch()
  
  const openModal = useCallback((data: {
    content: JSX.Element,
    closeButton?: boolean,
    okButton?: boolean,
    onOk?: () => void
  }) => {
    dispatch(setModalContainerContent(data.content))
    dispatch(setModalContainerOpen(true))
    dispatch(setModalCloseButton(data.closeButton ?? false))
    dispatch(setModalOkButton(data.okButton ?? false))
    dispatch(setModalOnOk(data.onOk ?? (() => {})))
  }, [dispatch])
  
  const closeModal = useCallback(() => {
    dispatch(setModalContainerOpen(false))
  }, [dispatch])

  return {
    openModal,
    closeModal
  }
}