import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { SectorKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const sectorKeyToLabel = (sectorKey: string) => {
  switch (sectorKey) {
    case 'AGRONEGOCIO':
      return 'Agronegócio'
    case 'COMERCIO':
      return 'Comércio'
    case 'INDUSTRIA':
      return 'Indústria'
    case 'SERVICO':
      return 'Serviços'
    default:
      return 'Não definido'
  }
}



export const SectorFilter = () => {
  const sectorFilter = useAppSelector((state) => state.project?.selectedFilter?.sector)
  const { addSectorFilter, removeSectorFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Setor econômico" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Os setores de uma empresa variam de acordo com a tarefa executada, ou seja, esta opção corresponde a área de sua atuação, podendo ser buscado por um ou vários setores ao mesmo tempo.
        </Typography>
        <Typography>
          Exemplo: Agronegócio / Indústria / Comércio / Serviços
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão considerados todos os setores na contagem.
        </Typography>
      </>} />
      <Grid item xs={12} py={3}>
        <Divider />
      </Grid>
      <Box p={3}>
        <Grid container spacing={3}>
          {
            SectorKeys.map((sector) => {
              return <>
                <Grid item xs={6}>
                  <FormControlLabel control={
                    <Switch
                      checked={sectorFilter?.includes(sector) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addSectorFilter({
                            sector: sector
                          })
                        }
                        else {
                          removeSectorFilter({
                            sector: sector
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={sectorKeyToLabel(sector).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}