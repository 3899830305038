import { useCallback } from "react"
import { ICommonModel } from "../../application/models"
import { ICountReport } from "../../application/models/IProjectReport"
import { useAxiosInstances } from "../axios-instance"


export type IProccessSizes = 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg' | 'xxxlg' | 'xxxxlg' | 'count-report'
export const processSizesArray: IProccessSizes[] = [
  'xsm',
  'sm',
  'md',
  'lg',
  'xlg',
  'xxlg',
  'xxxlg',
  'xxxxlg'
]

export type IProcessStatus = 'pending' | 'processing' | 'completed' | 'failed'

type IProjectExportTypes = 'ByFilter' | 'ByCNPJList' | 'ByPessoaJuridicaIDList' | 'count-report'

export interface ILurikProcess extends ICommonModel {
  resourceId: string
  projectId?: string
  userId: string
  companyId: string
  status: IProcessStatus
  queueSize: IProccessSizes,
  type: IProjectExportTypes,
  progress?: number
}




export const useCountReportApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  const createCountReportApi = useCallback((data: {
    countReport: {
      name: string
      companyId: string
      createdBy: string
      projectId: string
      columns: string[]
    }
  }): Promise<ICountReport> => {
    return axiosAuthInstance.post('/count-report', data.countReport)
      .then((response) => {
        const result = response.data.result?.countReport
        if (result) {
          return result
        } else {
          throw new Error('No result')
        }
      })
  }, [axiosAuthInstance])

  const updateCountReportApi = useCallback((data: {
    countReport: {
      name: string,
      id: string,
      columns: string[],

    }
  }): Promise<ICountReport> => {
    return axiosAuthInstance.put('/count-report', data.countReport)
      .then((response) => {
        const result = response.data.result?.countReport
        if (result) {
          return result
        } else {
          throw new Error('No result')
        }
      })
  }, [axiosAuthInstance])

  const deleteCountReportApi = useCallback((data: {
    countReportId: string
  }): Promise<ICountReport> => {
    return axiosAuthInstance.delete(`/count-report`, {
      params: {
        countReportId: data.countReportId
      }
    })
      .then((response) => {
        const result = response.data.result?.countReport
        if (result) {
          return result
        } else {
          throw new Error('No result')
        }
      })
  }, [axiosAuthInstance])


  const findCountReportByProjectIdApi = useCallback((data: {
    projectId: string
  }): Promise<ICountReport[]> => {
    return axiosAuthInstance.get('/count-report/find-by-project-id', {
      params: {
        projectId: data.projectId
      }
    })
      .then((response) => {
        const result = response.data.result?.countReportList
        if (result) {
          return result
        } else {
          throw new Error('No result')
        }
      })
  }, [axiosAuthInstance])

  const proccessCountReportApi = useCallback((data: {
    countReportId: string,
    createdBy: string
  }): Promise<void> => {
    return axiosAuthInstance.post('/count-report/proccess', {
      countReportId: data.countReportId,
      createdBy: data.createdBy
    })
      .then((response) => {

      })
  }, [axiosAuthInstance])


  const checkCountReport = useCallback((data: {
    countReportId: string
  }): Promise<ILurikProcess> => {
    return axiosAuthInstance.get('/count-report/check/' + data.countReportId)
      .then((response) => {
        const result = response.data.result?.lurikProcess
        if (result) {
          return result
        } else {
          throw new Error('No result')
        }
      })

  }, [axiosAuthInstance])



  return {
    createCountReportApi,
    updateCountReportApi,
    deleteCountReportApi,
    findCountReportByProjectIdApi,
    proccessCountReportApi,
    checkCountReport
  }
}