import { Box, Grid, Typography } from "@mui/material"
import { BrazilMap } from "./brazil-map";
import { SubscriptionResume } from "./subscription-resume";
import { useEffect } from "react";
import { DayZeroStats } from "./DayZeroStats";
import { useAppSelector } from "../../../../application/states/hooks";

export const DashboardPage = () => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Home`
  }, [resaleConfig])

  return (
    <>
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <Typography
                variant="h6"
                color="primary"
                fontWeight="bold"
                textTransform={"uppercase"}
              >
                Atuação no Brasil
              </Typography>
            </Box>
            <BrazilMap />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DayZeroStats />
              </Grid>
              <Grid item xs={12}>
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    color="primary"
                    fontWeight="bold"
                    textTransform={"uppercase"}
                  >
                    Dados do plano
                  </Typography>
                </Box>
                <SubscriptionResume />
              </Grid>
            </Grid>


          </Grid>
        </Grid>
      </Box>

    </>
  )
}