import { Box } from "@mui/system"
import { useEffect } from "react"
import { CompaniesTable } from "./companies-table"
import { CompanyForm } from "./company-form"
import { useAppSelector } from "../../../../../application/states/hooks"

export const BackofficeCompaniesSection = () => {
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

    useEffect(() => {
        document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Backoffice / Clientes`
    }, [resaleConfig])

    return (
        <Box>
            <CompaniesTable />
            <CompanyForm />
        </Box>
    )
}