import { AppBar, Box, Button, Dialog, Divider, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { TransitionProps } from "@mui/material/transitions";
import { useMemo, forwardRef, useEffect } from "react";
import React from "react";
import { ImageCreateNotification, MessageCreateNotification, TitleCreateNotification, PopupCreateNotification } from "./fields";
import { CreateNotificationButton } from "./buttons";
import { useAppSelector } from "../../../../../../../application/states/hooks";
import { useBackofficeService } from "../../../../../../../use-cases/backoffice";

export const CreateNotificationForm = () => {
    const Transition = useMemo(() => {
        return forwardRef(function Transition(
            props: TransitionProps & {
                children: React.ReactElement;
            },
            ref: React.Ref<unknown>,
        ) {
            return <Slide direction="up" ref={ref} {...props} />;
        });
    }, [])

    const {
        createNotification,
        closeNotificationForm,
        openNotificationForm,
        setNotificationsList,
        selectNotification
    } = useBackofficeService()
    const notificationState = useAppSelector((state) => state.backofficeNotification)

    useEffect(() => {
        setNotificationsList()
    }, [setNotificationsList])

    return <>
        <IconButton
            onClick={() => {
                openNotificationForm()
                selectNotification({
                    notification: {
                        createdAt: new Date(),
                        createdBy: '',
                        id: '',
                        message: '',
                        popup: false,
                        title: '',
                        updatedAt: new Date()
                    }
                })
            }}
        >
            <AddIcon sx={{ color: '#FFF' }} />
        </IconButton>
        <Dialog
            fullScreen
            open={notificationState.notificationForm.open}
            TransitionComponent={Transition}
            onClose={closeNotificationForm}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={'uppercase'}>
                        Criar nova notificação
                    </Typography>
                    <Button autoFocus color="inherit" onClick={() => {
                        closeNotificationForm()
                    }}>
                        Fechar
                    </Button>
                </Toolbar>
            </AppBar>
            <Box p={5}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    createNotification()
                }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                        }}
                                        color="primary"
                                        align="center"
                                        variant="h6" component="div"
                                    >
                                        Dados da Notificação
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            height: '2px',
                                            width: '50%',
                                            margin: '0 auto'
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ textAlign: 'center' }} xs={12}>
                                    <TitleCreateNotification />
                                </Grid>
                                <Grid item sx={{ textAlign: 'center' }} xs={12}>
                                    <MessageCreateNotification />
                                </Grid>
                                <Grid item sx={{ textAlign: 'center' }} xs={12}>
                                    <ImageCreateNotification />
                                </Grid>
                                <Grid item sx={{ textAlign: 'center' }} xs={12}>
                                    <PopupCreateNotification />
                                </Grid>
                                {/* <Grid item xs={12} pt={3}>
                                    <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
                                        Destinatários
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} p={2} textAlign={'center'}>
                                    <CompanyRecipientForm />
                                </Grid>
                                <Grid item xs={6} p={2} textAlign={'center'}>
                                    <UserRecipientForm />
                                </Grid>
                                <Grid item xs={6} p={2} textAlign={'center'}>
                                    <CompanyRecipientFormFilterPreview />
                                </Grid>
                                <Grid item xs={6} p={2} textAlign={'center'}>
                                    <UserRecipientFormFilterPreview />
                                </Grid> */}
                                <Grid container justifyContent={'center'}>
                                    <Grid xs={12} py={5}>
                                        <CreateNotificationButton />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Dialog >
    </>
}