import { useCallback } from "react"
import { useAppDispatch } from "../../application/states/hooks"
import { setIsLoading } from "../../application/states/loading"

export const useLoadingService = () => {
  const dispatch = useAppDispatch()
  const startLoading = useCallback(() => {
    dispatch(setIsLoading({
      isLoading: true
    }))
  }, [dispatch])

  const stopLoading = useCallback(() => {
    dispatch(setIsLoading({
      isLoading: false
    }))
  }, [dispatch])

  return { startLoading, stopLoading }
}