import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBackofficeNotification, IFieldError, IBackofficeNotificationState } from '../../../models';

export const validateNotificationForm = (data: {
    notification: IBackofficeNotification
}): {
    fieldError: IFieldError,
    field: string
}[] => {
    const errors: {
        fieldError: IFieldError,
        field: string
    }[] = []

    if (!data.notification.title) {
        errors.push({
            field: 'title',
            fieldError: {
                message: 'Título é obrigatório',
                type: 'RequiredFieldError'
            }
        })
    }
    if (!data.notification.message) {
        errors.push({
            field: 'message',
            fieldError: {
                message: 'Mensagem é obrigatório',
                type: 'RequiredFieldError'
            }
        })
    }

    return errors
}

const initialState: IBackofficeNotificationState = {
    notificationList: [],
    filters: {
        searchTerm: '',
        recipient: [],
        title: ''
    },
    notificationForm: {
        open: false,
        action: 'create',
        formFields: {
            message: {
                isValid: true,
                error: undefined
            },
            title: {
                isValid: true,
                error: undefined
            }
        }
    }
};

const backofficeNotificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotificationFormOpenState(state, action: PayloadAction<{
            open: boolean
        }>) {
            state.notificationForm.open = action.payload.open
        },
        setNotificationImageFileState(state, action: PayloadAction<{ file?: File }>) {
            state.notificationForm.imageFile = action.payload.file
        },
        resetNotificationFormErrors(state) {
            const { formFields } = state.notificationForm
            if (formFields) {
                Object.keys(formFields).forEach((key) => {
                    (formFields as any)[key].isValid = true
                        ; (formFields as any)[key].error = undefined
                })
            }
        },
        resetNotificationFormState(state) {
            resetNotificationFormErrors()
            state.notificationForm = initialState.notificationForm
        },

        setNotificationFormFieldErrors(state, action: PayloadAction<{
            field: string,
            fieldError: IFieldError
        }[]>) {
            /* the key field represents the key of formFields object, so put this IFieldErros into de respective field */
            action.payload.forEach(fieldError => {
                const field = fieldError.field.split('.')
                if (field.length > 1) {
                    (state.notificationForm.formFields as any)[field[0]][field[1]].error = fieldError.fieldError;
                    (state.notificationForm.formFields as any)[field[0]][field[1]].isValid = false
                } else {
                    (state.notificationForm.formFields as any)[field[0]].error = fieldError.fieldError;
                    (state.notificationForm.formFields as any)[field[0]].isValid = false
                }
            })
        },
        setNotificationsState(state, action: PayloadAction<{
            notificationList: IBackofficeNotification[]
        }>) {
            state.notificationList = action.payload.notificationList
        },
        setNotificationsSearchTermState(state, action: PayloadAction<{
            searchTerm: string
        }>) {
            state.filters.searchTerm = action.payload.searchTerm
        },
        resetSelectedNotificationState(state) {
            state.selectedNotification = undefined
        },
        setSelectedNotificationState(state, action: PayloadAction<{
            notification: IBackofficeNotification
        }>) {
            const { notification } = action.payload
            state.selectedNotification = notification
        }
    },
});

export const {
    resetNotificationFormErrors,
    resetNotificationFormState,
    resetSelectedNotificationState,
    setNotificationFormFieldErrors,
    setNotificationFormOpenState,
    setNotificationImageFileState,
    setNotificationsSearchTermState,
    setNotificationsState,
    setSelectedNotificationState
} = backofficeNotificationsSlice.actions;


export const backofficeNotificationsReducer = backofficeNotificationsSlice.reducer;