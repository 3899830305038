import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPessoaJuridica } from '../../models/IPessoaJuridica'
import { ISocio } from '../../models/ISocio'

export interface PjResume {
  id: string,
  cnpj: string,
  razaoSocial: string
  telefone: string
  endereco: string
}

interface IUnitSearchState {
  searchTerm?: string
  currentPj?: {
    pj: IPessoaJuridica
    socios: ISocio[]
    pjPhones: string[]
    pjMails: string[]
  }
  pjList: PjResume[]
}

const initialState: IUnitSearchState = {
  searchTerm: undefined,
  pjList: []
};

const unitSearchSlice = createSlice({
  name: 'unit-search',
  initialState,
  reducers: {
    setSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    },
    setCurrentPjId(state, action: PayloadAction<{
      currentPj: {
        pj: IPessoaJuridica,
        socios: ISocio[]
        pjPhones: string[]
        pjMails: string[]
      }
    }>) {
      state.currentPj = action.payload.currentPj
    },
    setPjList(state, action: PayloadAction<{
      pjList: PjResume[]
    }>) {
      state.pjList = action.payload.pjList
    }
  },
});

export const {
  setCurrentPjId,
  setSearchTermState,
  setPjList
} = unitSearchSlice.actions;
export const unitSearchReducer = unitSearchSlice.reducer;