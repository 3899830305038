import { Box, Grid, Typography } from "@mui/material"
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { useMemo } from "react";
import { Pie } from 'react-chartjs-2';
import { useAppSelector } from "../../../../../../../application/states/hooks";
ChartJS.register(ArcElement, ChartTooltip, Legend);

export const PartnerCharts = () => {
  const projectReport = useAppSelector((state) => state.project.projectReport)
  const partnerPhones = useMemo(() => {
    return {

      labels: ['Sócios sem telefone', 'Sócios com telefone'],
      datasets: [
        {
          label: 'sócios',
          data: [
            projectReport?.generalData.partner.phone.withoutPhone,
            projectReport?.generalData.partner.phone.withPhone
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [projectReport?.generalData.partner.phone])
  const partnerEmails = useMemo(() => {
    return {

      labels: ['Sócios sem e-mail', 'Sócios com e-mail'],
      datasets: [
        {
          label: 'sócios',
          data: [
            projectReport?.generalData.partner.email.withoutEmail,
            projectReport?.generalData.partner.email.withEmail
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [projectReport?.generalData.partner.email])

  const partnerAddress = useMemo(() => {
    return {
      labels: ['Sócios sem endereço', 'Sócios com endereço'],
      datasets: [
        {
          label: 'sócios',
          data: [
            projectReport?.generalData.partner.address.withoutAddress,
            projectReport?.generalData.partner.address.withAddress
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [projectReport?.generalData.partner.address])
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography align="center" fontWeight="bold" variant="h4" color="primary">
            Informações sócios
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box p={2}>
            <Grid container justifyContent="center">
              <Grid item>
                <Pie color="white" data={partnerPhones} />
              </Grid>
            </Grid>
            <Grid style={{
              marginTop: '15px'
            }} container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(255, 99, 132, 1)',
                  }}>Sem telefone:</span>  {projectReport?.generalData.partner.phone.withoutPhone.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(54, 162, 235, 1)'
                  }}>Com telefone:</span>  {projectReport?.generalData.partner.phone.withPhone.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box p={2}>
            <Grid container justifyContent="center">
              <Grid item>
                <Pie color="white" data={partnerEmails} />
              </Grid>
            </Grid>
            <Grid style={{
              marginTop: '15px'
            }} container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(255, 99, 132, 1)',
                  }}>Sem e-mail:</span>  {projectReport?.generalData.partner.email.withoutEmail.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(54, 162, 235, 1)'
                  }}>Com e-mail:</span>  {projectReport?.generalData.partner.email.withEmail.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box p={2}>
            <Grid container justifyContent="center">
              <Grid item>
                <Pie color="white" data={partnerAddress} />
              </Grid>
            </Grid>
            <Grid style={{
              marginTop: '15px'
            }} container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(255, 99, 132, 1)',
                  }}>Sem endereço:</span>  {projectReport?.generalData.partner.address.withoutAddress.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" fontWeight="bold">
                  <span style={{
                    color: 'rgba(54, 162, 235, 1)'
                  }}>Com endereço:</span>  {projectReport?.generalData.partner.address.withAddress.toLocaleString('pt-BR')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}