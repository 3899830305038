import { List, Grid, ListItem, Button, Typography, useTheme, Box, ListItemText } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { CompanyTooltip } from "../CompanyTooltip";
import { useAppSelector } from "../../../../../../../../../../application/states/hooks";
import { useUserService } from "../../../../../../../../../../use-cases/user";

export const UserRecipientFormFilterPreview = () => {

    const usersState = useAppSelector((state) => state.user)
    const { removeEmailFilter } = useUserService()
    const theme = useTheme()

    return (
        <>
            <List>
                <Grid container spacing={3}>
                    {usersState?.filters?.email?.map((email, index) => {
                        return (
                            <Grid item xs={12}>
                                <CompanyTooltip
                                    title={<Box style={{
                                        height: '300px',
                                        overflow: 'scroll'
                                    }} p={4}>
                                        <div>
                                            <Box my={3}>
                                                <Typography variant="h6" fontWeight={"bold"}>
                                                    Nome: {usersState.userList.map((user) =>
                                                        user.email.includes(email) ? user.name : null
                                                    )}
                                                </Typography>
                                            </Box>
                                        </div>
                                        <Typography variant="h6" color="primary" fontWeight={"bold"}>
                                            Tipo de usuário: {usersState.userList.map((user) =>
                                                user.email.includes(email) ? user.userType : null
                                            )}
                                        </Typography>
                                        <List>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <ListItem>
                                                        <ListItemText primary={usersState.userList.find((users) =>
                                                            users.email.includes(email) ? users : null
                                                        )!.createdAt.toString()
                                                        } />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </List>
                                    </Box>}
                                    placement="top"
                                >
                                    <div>
                                        <ListItem style={{
                                            backgroundColor: theme.palette.primary.main,
                                            borderRadius: '50px',
                                            padding: '15px'
                                        }} key={index} secondaryAction={<>
                                            <Button
                                                onClick={() => {
                                                    removeEmailFilter({
                                                        email: email
                                                    })
                                                    // removeUserIdRecipientCreateNotification({
                                                    //     userId: usersState.userList.find((users) => {
                                                    //         if (users.email.includes(email)) return users
                                                    //     })!.id
                                                    // })
                                                }}
                                                style={{
                                                    color: 'white'
                                                }} endIcon={<>
                                                    <DeleteIcon style={{
                                                        color: 'white'
                                                    }} />
                                                </>}>
                                                Remover
                                            </Button>
                                        </>
                                        }>
                                            <Box mr={5} style={{
                                                maxWidth: '80%'
                                            }}>
                                                <Typography color="white" fontWeight="bold">
                                                    {email.toLocaleUpperCase()}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    </div>
                                </CompanyTooltip>
                            </Grid>
                        )
                    })}
                </Grid>
            </List >
        </>
    )
}