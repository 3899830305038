import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeNotification, IBackofficeUserId } from "../../../application/models"

export const useBackofficeNotificationApi = () => {
    const { axiosAuthInstance } = useAxiosInstances()

    const findCustomNotificationsApi = useCallback((data: { userId: IBackofficeUserId }): Promise<IBackofficeNotification[] | undefined> => {
        return axiosAuthInstance?.get('/notification/backoffice', { params: data }).then((response) => {
            const result = response.data?.result?.notificationList
            if (result) {
                return result
            } else {
                throw new Error('Erro ao buscar notificações')
            }
        }).catch((error) => {
            throw error
        })
    }, [axiosAuthInstance])

    const createNotificationApi = useCallback((data: {
        notificationData: {
            message: string,
            title: string,
            createdBy: string,
            img: string,
            recipient: IBackofficeUserId[],
            viewedBy: string[],
            popup: boolean
        }
    }): Promise<IBackofficeNotification> => {
        if (!axiosAuthInstance) {
            throw new Error()
        }
        return axiosAuthInstance!.post('/notification', {
            notification: {
                message: data.notificationData.message,
                title: data.notificationData.title,
                createdBy: data.notificationData.createdBy,
                img: data.notificationData.img,
                recipient: data.notificationData.recipient,
                viewedBy: data.notificationData.viewedBy,
                popup: data.notificationData.popup
            }
        }).then((response) => {
            const result = response.data?.result?.notification
            if (result) {
                return result
            } else {
                throw new Error('Erro ao criar notificação.')
            }
        }).catch((error) => {
            throw error
        })
    }, [axiosAuthInstance])

    return {
        findCustomNotificationsApi,
        createNotificationApi
    }
}