import { BottomNavigationProps, Button, Typography } from "@mui/material"
import { BottomNavigationStylesProvider } from "./styles/BottomNavigationStylesProvider"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Stack } from "@mui/system"
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useProjectService } from "../../../../../use-cases/project";
import { useAppSelector } from "../../../../../application/states/hooks";
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect } from "react";
import { theme } from "../../../../styles";
export const BottomNavigation = (props: BottomNavigationProps) => {
    const action = useAppSelector((state) => state.project.action)
    const { setAction, setActiveSection, updateProjectFilter, openExportDialog, setExportDialogTotal } = useProjectService()
    const { selectedFilter } = useAppSelector((state) => state.project)

    useEffect(() => {
        setAction({
            action: 'refresh'
        })
    }, [selectedFilter, setAction])
    return (
        <>
            <div>
                <BottomNavigationStylesProvider action={action}>
                    <div className="wrapper">
                        <div className="navbar">
                            <div className={`bottom-navigation-item`}>
                                <Button style={{
                                    height: '100%',
                                }} onClick={() => {
                                    setActiveSection({
                                        action: 'filter'
                                    })
                                }} fullWidth>
                                    <Stack alignItems={"center"}>
                                        <FilterAltIcon />
                                        <Typography>Filtros</Typography>
                                    </Stack>

                                </Button>
                            </div>
                            <div className={`bottom-navigation-item`}>
                                <Button style={{
                                    height: '100%',
                                }} onClick={() => {
                                    setActiveSection({
                                        action: 'data'
                                    })
                                }} fullWidth>
                                    <Stack alignItems={"center"}>
                                        <TextSnippetIcon />
                                        <Typography>Dados</Typography>
                                    </Stack>
                                </Button>
                            </div>
                            <div className="circle"
                                style={{
                                    backgroundColor: action === 'refresh' ? theme.palette.secondary.main : theme.palette.primary.main
                                }}
                                onClick={() => {
                                    if (action === 'export') {
                                        setExportDialogTotal({
                                            total: 0
                                        })
                                        openExportDialog()
                                    } else if (action === 'refresh') {
                                        if (selectedFilter) {
                                            updateProjectFilter()
                                        }
                                    }
                                }}
                            >
                                {
                                    action === 'export' ? (
                                        <>
                                            <div className="veles-tooltip">
                                                Extrair
                                            </div>

                                            <div className="icon-circle">
                                                <DownloadIcon style={{
                                                    color: 'white'
                                                }} />
                                            </div>
                                        </>
                                    ) : null
                                }

                                {
                                    action === 'refresh' ? (
                                        <>
                                            <div className="veles-tooltip">
                                                Atualizar
                                            </div>

                                            <div className="icon-circle">
                                                <RefreshIcon style={{
                                                    color: 'white'
                                                }} />
                                            </div>
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className="circleBackground"></div>
                        </div>
                    </div>
                </BottomNavigationStylesProvider>
            </div>
        </>
    )
}

