import { Box } from "@mui/material"
import { NotificationTable } from "./table/NotificationTable"
import { useEffect } from "react"
import { CreateNotificationForm } from "./form"

export const BackofficeNotificationsSection = () => {
    useEffect(() => {
        document.title = "Lurik Backoffice - Notificações"
    }, [])

    return <>
        <Box>
            <NotificationTable />
            <CreateNotificationForm />
        </Box>
    </>
}