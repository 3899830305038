import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { IRegimeTributario, RegimeTributarioKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const regimeTributarioKeyToLabel = (statusKey: IRegimeTributario) => {
  switch (statusKey) {
    case 'ISENTO':
      return 'Isento'
    case 'SIMPLES NACIONAL':
      return 'Simples Nacional'
    case 'LUCRO PRESUMIDO':
      return 'Lucro Presumido'
    case 'LUCRO REAL':
      return 'Lucro Real'
    case 'LUCRO ARBITRADO':
      return 'Lucro Arbitrado'
    case 'MICROEMPREENDEDOR INDIVIDUAL':
      return 'Microempreendedor Individual'
    default:
      return ''
  }
}



export const RegimeTributarioFilter = () => {
  const regimeTributarioState = useAppSelector((state) => state.project?.selectedFilter?.regimeTributario)
  const { addRegimeTributarioFilter, removeRegimeTributarioFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Regime Tributário" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Esta opção permite filtrar empresas através do regime tributário em que ela se encontra na Receita Federal. Podendo ser marcado somente uma única opção ou várias ao mesmo tempo
        </Typography>
        <Typography>
          Exemplo: Lucro Real / Simples Nacional etc.
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão considerados todos os regimes tributários na contagem.
        </Typography>
      </>} />
      <Grid item xs={12} py={3}>
        <Divider />
      </Grid>
      <Box p={3}>
        <Grid container spacing={3}>
          {
            RegimeTributarioKeys.map((regimeTributario) => {
              return <>
                <Grid item xs={6}>
                  <FormControlLabel control={
                    <Switch
                      checked={regimeTributarioState?.includes(regimeTributario) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addRegimeTributarioFilter({
                            regimeTributario
                          })

                        }
                        else {
                          removeRegimeTributarioFilter({
                            regimeTributario
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={regimeTributarioKeyToLabel(regimeTributario).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}