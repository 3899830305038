import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"
import { IConsumptionLock } from "../../application/models/IConsumptionLock"

export const useConsumptionApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const createConsumptionLockApi = useCallback((data: {
    userId?: string
    companyId?: string
    limit: number
    createdBy: string
  }): Promise<IConsumptionLock> => {
    return axiosAuthInstance.post('/consumption/lock', data).then((response) => {
      const consumptionLock = response.data?.result?.consumptionLock
      if (consumptionLock) {
        return consumptionLock
      }
    })
  }, [axiosAuthInstance])

  const getUserConsumptionApi = useCallback((data: {
    userId: string
  }): Promise<{
    "consumption": number,
    "userId": string,
    "consumptionLimit"?: number
  }> => {
    return axiosAuthInstance.get('/consumption/user', {
      params: {
        userId: data.userId
      }
    }).then((response) => {
      const consumption = response.data?.result
      if (consumption) {
        return consumption
      }
    })
  }, [axiosAuthInstance])

  return {
    getUserConsumptionApi,
    createConsumptionLockApi
  }
}