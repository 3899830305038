import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../application/states/hooks"
import { RouteKeys, setActiveRoute } from "../../application/states/navigation/navigation-slice"

export const useNavigationService = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const navigateTo = useCallback((data: {
    route: RouteKeys
  }) => {

    navigate(data.route)
    dispatch(setActiveRoute({
      route: data.route
    }))
  }, [navigate, dispatch])

  return {
    navigateTo
  }
}