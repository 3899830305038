import { Box, Divider, Grid, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useSubscriptionService } from "../../../../../use-cases/subscription";
import { useEffect } from "react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  },
}));

export const SubscriptionResume = () => {
  const usage = useAppSelector((state) => state.subscription.usage)
  const { setSubscription } = useSubscriptionService()

  useEffect(() => {
    setSubscription()
  }, [setSubscription])

  return <>
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Box display="flex">
          <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
            marginRight: '10px'
          }}>
            Plano atual:
          </Typography>
          <Typography
            textTransform={"uppercase"}
            color="secondary"
            fontWeight={"bold"}
          >
            {usage?.type}
          </Typography>
          <AutoAwesomeIcon color="secondary" />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex">
          <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
            marginRight: '10px'
          }}>
            Status:
          </Typography>
          <Typography
            textTransform={"uppercase"}
            color="secondary"
            fontWeight={"bold"}
          >
            Ativo
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
          marginRight: '10px'
        }}>
          Consumo do plano:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex">
          <Box mb={3}>
            <Typography
              display={"block"}
              color="primary"
              fontWeight={"bold"}
              textTransform={"uppercase"}
            >
              {usage?.usedBySubscription.toLocaleString('pt-BR')} {usage?.limit ? `/ ${usage?.limit?.toLocaleString('pt-BR')}` : ''} créditos utilizados
            </Typography>
            {
              usage ?
                usage.limit ?
                  <Typography
                    display={"block"}
                    color="primary"
                    fontWeight={"bold"}
                    textTransform={"uppercase"}
                    my={1}
                  >
                    {`${(usage.limit - usage.usedBySubscription).toLocaleString('pt-BR')} créditos restantes`}
                  </Typography>
                  : null
                : null
            }
          </Box>
        </Box>
      </Grid>
      {
        usage?.totalBySeparated ? <>
          <Grid item xs={12}>
            <Box display="flex" mt={2}>
              <Typography textTransform={"uppercase"} color="primary" fontWeight={"bold"} display="inline" style={{
                marginRight: '10px'
              }}>
                Consumo de créditos avulsos:
              </Typography>
              <Box display="flex" mb={3}>
                <Typography
                  textTransform={"uppercase"}
                  color="primary"
                  fontWeight={"bold"}
                  style={{
                    marginRight: '5px'
                  }}
                >
                  {usage?.usedBySeparated.toLocaleString('pt-br')}
                </Typography>
                <Typography
                  textTransform={"uppercase"}
                  color="secondary"
                  fontWeight={"bold"}
                >
                  {/* / {usage?.total.toLocaleString('pt-br')} */} / {usage?.totalBySeparated.toLocaleString('pt-br')}
                </Typography>

                <Typography
                  style={{
                    margin: '0 10px'
                  }}
                  textTransform={"uppercase"}
                  color="primary"
                  fontWeight={"bold"}>
                  -
                </Typography>
                <Typography
                  color="primary"
                  fontWeight={"bold"}
                  textTransform={"uppercase"}
                >
                  {((usage?.totalBySeparated ?? 0) - (usage?.usedBySeparated ?? 0)).toLocaleString('pt-BR')} créditos disponíveis
                </Typography>


              </Box>
            </Box>
            <BorderLinearProgress variant="determinate" value={
              usage?.usedBySeparated && usage?.totalBySeparated ? (usage?.usedBySeparated / usage?.totalBySeparated) * 100 : 0

              // usage?.used && usage?.total ? (usage?.used / usage?.total) * 100 : 0
            } />
          </Grid>
        </> : null
      }

    </Grid>
  </>
}