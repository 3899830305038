import { Button, Grid, List, ListItem, ListItemText, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../application/states/hooks";
import { useDataEnrichmentService } from "../../../../../use-cases/data-enrichment";
import { useProjectService } from "../../../../../use-cases/project";
import { ColumnsDialog } from "./columns-dialog";
import { FileUpload } from "./file-upload";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";

export const DataEnrichmentForm = () => {
  const { selectedColumn, reset } = useDataEnrichmentService()
  const { exportProject } = useProjectService()
  const dataEnrichment = useAppSelector((state) => state.dataEnrichment)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])


  return <>
    <form onSubmit={(e) => {
      e.preventDefault()
      exportProject({
        type: 'enrichment',
      })
    }}>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography align="center" variant="h5" color="primary" fontWeight={"bold"} textTransform="uppercase">
            Formulário de enriquecimento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* Drag and drop compoennte*/}
          <FileUpload label={"Arraste e solte o arquivo aqui ou clique para selecionar"} />

        </Grid>
        {
          dataEnrichment.matchCode.selectedColumn !== '' ? (
            <>
              <Grid item xs={6}>
                <List component="div" role="group">
                  <ListItem
                    key={1}
                    button
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={(() => {
                      setOpen(true)
                    })}
                  >
                    <ListItemText primary="Clique para selecionar a coluna" secondary={dataEnrichment.matchCode.selectedColumn} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List component="div" role="group">
                  <ListItem
                    key={2}
                    button
                    divider
                  >
                    <ListItemText primary="Dados do arquivo" secondary={<>
                      <Typography >
                        <strong> Nome:</strong> {dataEnrichment.fileData.name}
                      </Typography>
                      <Typography >
                        <strong> Tamanho:</strong> {dataEnrichment.fileData.size}
                      </Typography>
                      <Typography >
                        <strong> Tipo:</strong> {dataEnrichment.fileData.type}
                      </Typography>
                      <Typography >
                        <strong> Linhas:</strong> {dataEnrichment.fileData.data.length.toLocaleString('pt-BR')}
                      </Typography>
                      <Typography>
                        <strong> Match codes distintos:</strong> {dataEnrichment.matchCode.distinctValues.toLocaleString('pt-BR')}
                      </Typography>
                      <Typography>
                        <strong> Match codes válidos e distintos:</strong> {dataEnrichment.matchCode.validValues.toLocaleString('pt-BR')}
                      </Typography>
                    </>} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    '&:hover': {
                      backgroundColor: darkenColor(theme.palette.primary.main, 10)
                    },
                    color: theme.palette.primary.contrastText
                  }}
                >
                  Enviar
                </Button>

              </Grid>
            </>
          ) : null
        }

      </Grid>
      <ColumnsDialog
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={(newValue?: string) => {
          setOpen(false);

          if (newValue) {
            selectedColumn({
              selectedColumn: newValue,
            });
          }
        }}
        value={dataEnrichment.matchCode.selectedColumn}
      />
    </form>

  </>
}