import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppSelector } from "../../../../../../application/states/hooks";
import { useBackofficeService } from "../../../../../../use-cases/backoffice";
import { useState } from "react";
import { Button, Divider, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { validateEmail } from "../../../../../../veles-validations";
import { calculateContrastColor, formatTelefoneBrazil, onlyNumbers } from "../../../../../../utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import dayjs from "dayjs";
import ptBR from 'dayjs/locale/pt-br';
import fontOptionsJSON from '../../../../../assets/fonts/fontOptions.json'
import Block from "react-color/lib/components/block/Block";
import tinycolor from 'tinycolor2';

export const WhiteLabelCompanyForm = () => {
    const userState = useAppSelector((state) => state.user)
    const { resaleConfig, faviconFile, headerImageFile, loginPageImg } = useAppSelector((state) => state.backofficeCompany.selectedCompanyResaleConfig)
    const {
        setSelectedCompanyResaleConfigContactBeginHour,
        setSelectedCompanyResaleConfigContactEmail,
        setSelectedCompanyResaleConfigContactEndHour,
        setSelectedCompanyResaleConfigContactPhone,
        setSelectedCompanyResaleConfigDomain,
        setSelectedCompanyResaleConfigEmailProviderSettingHost,
        setSelectedCompanyResaleConfigEmailProviderSettingPassword,
        setSelectedCompanyResaleConfigEmailProviderSettingPort,
        setSelectedCompanyResaleConfigEmailProviderSettingUser,
        setSelectedCompanyResaleConfigFavicon,
        setSelectedCompanyResaleConfigImg,
        setSelectedCompanyResaleConfigLoginPageImg,
        setSelectedCompanyResaleConfigName,
        setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMain,
        setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMain,
        setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColor,
        setSelectedCompanyResaleConfigThemeColorsPrimaryMain,
        setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColor,
        setSelectedCompanyResaleConfigThemeColorsSecondaryMain,
        setSelectedCompanyResaleConfigThemeFontFamily
    } = useBackofficeService()

    dayjs.locale(ptBR)
    const beginHour = dayjs().subtract(3, 'hour')
    const endHour = dayjs().subtract(3, 'hour')
    const splitBeginHour = resaleConfig?.contact.beginHour.split(':')
    const splitEndHour = resaleConfig?.contact.endHour.split(':')
    const beginHourNumber = Number(splitBeginHour ? splitBeginHour[0] : 8)
    const beginMinuteNumber = Number(splitBeginHour ? splitBeginHour[1] : 0)
    const endHourNumber = Number(splitEndHour ? splitEndHour[0] : 18)
    const endMinuteNumber = Number(splitEndHour ? splitEndHour[1] : 0)
    const beginHourInitialState = beginHour.set('hour', beginHourNumber).set('minute', beginMinuteNumber)
    const endHourInitialState = endHour.set('hour', endHourNumber).set('minute', endMinuteNumber)

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        White Label - Nome / Domínio
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={resaleConfig?.name}
                        error={resaleConfig?.name === '' ? true : false}
                        helperText={resaleConfig?.name === '' ? 'Digite o nome da empresa para o título da página' : null}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigName({
                                name: e.target.value
                            })
                        }}
                        label="Nome"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={resaleConfig?.domain}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigDomain({
                                domain: e.target.value
                            })
                        }}
                        label="Domínio"
                        fullWidth
                        type="text"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                            }}
                            color="primary"
                            align="center"
                            variant="h6" component="div"
                        >
                            Logo - Página de Login
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <Divider
                            sx={{
                                backgroundColor: 'primary.main',
                                height: '2px',
                                width: '50%',
                                margin: '0 auto'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} my={2}>
                    <MuiFileInput
                        id="outlined-static"
                        value={loginPageImg}
                        error={resaleConfig?.loginPageImg === '' ? true : false}
                        helperText={resaleConfig?.loginPageImg === '' ? 'É obrigatório selecionar uma imagem para a página de login' : null}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigLoginPageImg(e)
                        }}
                        label="Selecione uma imagem para a página de login"
                        getInputText={(file) => {
                            return file?.name ?? 'Selecione uma imagem'
                        }}
                        InputProps={{
                            inputProps: {
                                accept: '.png, .jpeg, .jpg'
                            }
                        }}
                    />
                    {
                        loginPageImg !== null ?
                            resaleConfig?.loginPageImg ?
                                <Grid container p={1} justifyContent={'center'}>
                                    <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                        <img src={resaleConfig?.loginPageImg} alt="Logo para a página de login" style={{ maxHeight: 180, maxWidth: 200 }} />
                                    </Grid>
                                </Grid>
                                : null
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                            }}
                            color="primary"
                            align="center"
                            variant="h6" component="div"
                        >
                            Logo - Header
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <Divider
                            sx={{
                                backgroundColor: 'primary.main',
                                height: '2px',
                                width: '50%',
                                margin: '0 auto'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} my={2}>
                    <MuiFileInput
                        id="outlined-static"
                        value={headerImageFile}
                        error={resaleConfig?.img === '' ? true : false}
                        helperText={resaleConfig?.img === '' ? 'É obrigatório selecionar uma imagem para o Header' : null}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigImg(e)
                        }}
                        label="Selecione uma imagem para o Header"
                        getInputText={(file) => {
                            return file?.name ?? 'Selecione uma imagem'
                        }}
                        InputProps={{
                            inputProps: {
                                accept: '.png, .jpeg, .jpg'
                            }
                        }}
                    />
                    {
                        headerImageFile !== null ?
                            resaleConfig?.img ?
                                <Grid container p={1} justifyContent={'center'}>
                                    <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                        <img src={resaleConfig?.img} alt="Logo para o Header" style={{ maxHeight: 180, maxWidth: 200 }} />
                                    </Grid>
                                </Grid>
                                : null
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} mt={2}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                            }}
                            color="primary"
                            align="center"
                            variant="h6" component="div"
                        >
                            Logo - Favicon
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <Divider
                            sx={{
                                backgroundColor: 'primary.main',
                                height: '2px',
                                width: '50%',
                                margin: '0 auto'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} my={2}>
                    <MuiFileInput
                        id="outlined-static"
                        value={faviconFile}
                        error={resaleConfig?.favicon === '' ? true : false}
                        helperText={resaleConfig?.favicon === '' ? 'É obrigatório selecionar uma imagem para o Favicon' : null}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigFavicon(e)
                        }}
                        label="Selecione uma imagem para o Favicon"
                        getInputText={(file) => {
                            return file?.name ?? 'Selecione uma imagem'
                        }}
                        InputProps={{
                            inputProps: {
                                accept: '.png, .jpeg, .jpg'
                            }
                        }}
                    />
                    {
                        faviconFile !== null ?
                            resaleConfig?.favicon ?
                                <Grid container p={1} justifyContent={'center'}>
                                    <Grid item pl={2} pr={2} pt={3} justifyContent={'center'}>
                                        <img src={resaleConfig?.favicon} alt="Favicon" style={{ maxHeight: 150, maxWidth: 200 }} />
                                    </Grid>
                                </Grid>
                                : null
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Horário de Atendimento
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            sx={{
                                width: '100%'
                            }}
                            value={beginHourInitialState}
                            label={'Início'}
                            onChange={(newValue) => {
                                if (newValue) {
                                    setSelectedCompanyResaleConfigContactBeginHour({
                                        beginHour: dayjs(newValue).format('HH:mm')
                                    })
                                }
                            }}
                            closeOnSelect
                            minutesStep={1}
                            format="HH:mm"
                            ampm={false}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            sx={{
                                width: '100%'
                            }}
                            value={endHourInitialState}
                            label={'Fim'}
                            onChange={(newValue) => {
                                if (newValue) {
                                    setSelectedCompanyResaleConfigContactEndHour({
                                        endHour: dayjs(newValue).format('HH:mm')
                                    })
                                }
                            }}
                            closeOnSelect
                            minutesStep={1}
                            format="HH:mm"
                            ampm={false}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        E-mail / Telefone de Contato
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={resaleConfig?.contact.email}
                        error={resaleConfig?.contact.email === '' || validateEmail(resaleConfig?.contact.email ?? '') ? true : false}
                        helperText={resaleConfig?.contact.email === '' || validateEmail(resaleConfig?.contact.email ?? '') ? 'Digite um e-mail de contato válido' : null}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigContactEmail({
                                email: e.target.value
                            })
                        }}
                        label="E-mail de Contato"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="email"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={formatTelefoneBrazil(resaleConfig?.contact.phone ?? '')}
                        error={resaleConfig?.contact.phone === '' ? true : false}
                        helperText={resaleConfig?.contact.phone === '' ? 'É obrigatório inserir um telefone para contato' : null}
                        onChange={(e) => {
                            if (e.target.value.length === 16) {
                                return
                            }

                            setSelectedCompanyResaleConfigContactPhone({
                                phone: onlyNumbers(e.target.value)
                            })
                        }}
                        label="Telefone de Contato"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="phone"
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Provedor de E-mail
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={resaleConfig?.emailProviderSettings.user}
                        error={resaleConfig?.emailProviderSettings.user === '' || validateEmail(resaleConfig?.emailProviderSettings.user ?? '') ? true : false}
                        helperText={resaleConfig?.emailProviderSettings.user === '' || validateEmail(resaleConfig?.emailProviderSettings.user ?? '') ? 'Digite um e-mail válido para configurações do provedor' : null}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigEmailProviderSettingUser({
                                user: e.target.value
                            })
                        }}
                        label="E-mail"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="email"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        label="Senha"
                        value={resaleConfig?.emailProviderSettings.password}
                        error={resaleConfig?.emailProviderSettings.password === '' ? true : false}
                        helperText={resaleConfig?.emailProviderSettings.password === '' ? 'A senha é obrigatória para configurações do provedor' : null}
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Button onClick={handleClickShowPassword}>
                                    {showPassword ? (
                                        <Visibility />
                                    ) :
                                        <VisibilityOff />
                                    }
                                </Button>
                            </InputAdornment>,
                        }}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigEmailProviderSettingPassword({
                                password: e.target.value
                            })
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={resaleConfig?.emailProviderSettings.host}
                        error={resaleConfig?.emailProviderSettings.host === '' ? true : false}
                        helperText={resaleConfig?.emailProviderSettings.host === '' ? 'É obrigatório inserir o "host" para configurações do provedor' : null}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigEmailProviderSettingHost({
                                host: e.target.value
                            })
                        }}
                        label="Host"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="url"
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={userState.userType === 'SELLER'}
                        value={resaleConfig?.emailProviderSettings.port}
                        error={resaleConfig?.emailProviderSettings.port === '' ? true : false}
                        helperText={resaleConfig?.emailProviderSettings.port === '' ? 'É obrigatório inserir a porta para configurações do provedor' : null}
                        onChange={(e) => {
                            setSelectedCompanyResaleConfigEmailProviderSettingPort({
                                port: e.target.value
                            })
                        }}
                        label="Porta"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="text"
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Tema - Background / Paper
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.background.body.main}
                        error={resaleConfig?.theme.colors.background.body.main === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.background.body.main === '' ? 'Selecione uma cor' : null}
                        label="Cor de fundo"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.background.body.main}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMain({
                                mainColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.background.body.main).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.background.body.main ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.background.body.main ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.background.paper.main}
                        error={resaleConfig?.theme.colors.background.paper.main === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.background.paper.main === '' ? 'Selecione uma cor' : null}
                        label="Cor terciária"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.background.paper.main}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMain({
                                mainColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.background.paper.main).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.background.paper.main ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.background.paper.main ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Tema - Cor Primária / Cor de Contraste
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.primary.main}
                        error={resaleConfig?.theme.colors.primary.main === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.primary.main === '' ? 'Selecione uma cor' : null}
                        label="Cor primária"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.primary.main}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsPrimaryMain({
                                mainColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.primary.main).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.primary.main ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.primary.main ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.primary.contrastColor}
                        error={resaleConfig?.theme.colors.primary.contrastColor === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.primary.contrastColor === '' ? 'Selecione uma cor' : null}
                        label="Cor de contraste"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.primary.contrastColor}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColor({
                                contrastColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.primary.contrastColor).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.primary.contrastColor ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.primary.contrastColor ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Tema - Cor Secundária / Cor de Contraste
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.secondary.main}
                        error={resaleConfig?.theme.colors.secondary.main === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.secondary.main === '' ? 'Selecione uma cor' : null}
                        label="Cor secundária"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.secondary.main}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsSecondaryMain({
                                mainColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.secondary.main).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.secondary.main ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.secondary.main ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} my={2}>
                    <TextField
                        disabled={true}
                        value={resaleConfig?.theme.colors.secondary.contrastColor}
                        error={resaleConfig?.theme.colors.secondary.contrastColor === '' ? true : false}
                        helperText={resaleConfig?.theme.colors.secondary.contrastColor === '' ? 'Selecione uma cor' : null}
                        label="Cor de contraste"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        type="color"
                    />
                    <Block
                        color={resaleConfig?.theme.colors.secondary.contrastColor}
                        onChangeComplete={(color) => {
                            setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColor({
                                contrastColor: color.hex
                            })
                        }}
                        width="90%"
                        colors={tinycolor(resaleConfig?.theme.colors.secondary.contrastColor).monochromatic(6).map(t => t.toHexString())}
                        styles={{
                            default: {
                                body: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    marginTop: '10px'
                                },
                                card: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                head: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto'
                                },
                                label: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    color: calculateContrastColor(resaleConfig?.theme.colors.secondary.contrastColor ?? '#ffffff')
                                },
                                input: {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 auto',
                                    border: `1px solid #000000`,
                                    fontWeight: 'bold',
                                    fontSize: 13
                                },
                                triangle: {
                                    color: resaleConfig?.theme.colors.secondary.contrastColor ?? '#ffffff',
                                    marginTop: 3
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                        }}
                        color="primary"
                        align="center"
                        variant="h6" component="div"
                    >
                        Tema - Fonte
                    </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Divider
                        sx={{
                            backgroundColor: 'primary.main',
                            height: '2px',
                            width: '50%',
                            margin: '0 auto'
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Fonte</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={resaleConfig?.theme.fontFamily}
                            label="Fonte"
                            onChange={(e) => {
                                setSelectedCompanyResaleConfigThemeFontFamily({
                                    fontFamily: e.target.value
                                })
                            }}
                            style={{
                                fontFamily: resaleConfig?.theme.fontFamily
                            }}
                        >
                            {
                                fontOptionsJSON.fontOptions.map((font: any) => {
                                    return (
                                        <MenuItem value={font.fontFamily} style={{ fontFamily: font.fontFamily }}>{font.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        <FormHelperText>Toda a aplicação seguirá a fonte escolhida</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}