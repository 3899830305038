import * as ExcelJS from 'exceljs';

export async function xlsxGenerator(data: any[], header: string[], filename: string, primaryColor: string, contrastColor: string, fgColor: string) {
    // Criar uma nova instância de Workbook
    const workbook = new ExcelJS.Workbook();

    // Adicionar uma planilha
    const worksheet = workbook.addWorksheet('Dados');

    // Adicionar o header
    worksheet.addRow(header)

    // Estilizar o header até onde houver conteúdo
    const headerRow = worksheet.getRow(1);
    const headerLength = header.length;

    const replacedPrimaryColor = primaryColor.replace('#', '')
    const replacedContrastColor = contrastColor.replace('#', '')


    for (let i = 1; i <= headerLength; i++) {
        const cell = headerRow.getCell(i);
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: replacedPrimaryColor }
        }
        cell.font = {
            bold: true,
            color: { argb: replacedContrastColor }
        }
        cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
        }
        cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
        }
    }

    worksheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1 }
    ]

    // Adicionar os dados à planilha
    for (let i = 0; i < data.length; i++) {
        const rowData = data[i];
        const row = worksheet.addRow(rowData);
        for (let j = 1; j <= header.length; j++) {
            const cell = row.getCell(j)
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'center'
            }
            // Estilizar células com conteúdo alternadamente
            if (i % 2 !== 0) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: fgColor }
                };
            }
        }
    }

    // Aplicar o filtro automático
    worksheet.autoFilter = {
        from: {
            row: 1,
            column: 1
        },
        to: {
            row: data.length + 1,
            column: header.length
        }
    }

    for (let y = 0; y < header.length; y++) {
        const column = worksheet.getColumn(y + 1)
        let maxLength = 0;
        column.eachCell({
            includeEmpty: true
        }, (cell) => {
            const length = cell.value ? cell.value.toString().length : 0
            if (length > maxLength) {
                maxLength = length
            }
        })
        column.width = maxLength + 10
    }

    // Salvar o arquivo Excel em um buffer
    const buffer = await workbook.xlsx.writeBuffer()

    // Cria um objeto blob a partir do buffer
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    // Cria um link para o arquivo blob e faz o download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    link.click();
}