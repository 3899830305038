export const validateCep = (cep: string): boolean => {
    // Verifica se o CEP possui 8 dígitos
    if (cep.length !== 8) {
        return false
    }

    if (cep === '00000000') {
        return false
    }

    return true;
}