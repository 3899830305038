import { Box, Grid, Slider, SliderValueLabelProps, TextField, Tooltip, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { useProjectService } from "../../../../../../use-cases/project";
import { useAppSelector } from "../../../../../../application/states/hooks";
function valuetext(value: number) {
  return `${value}`;
}
function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip open={true} enterTouchDelay={0} placement="top" title={<>
      <Typography variant="h6" color="white" fontWeight={"bold"}>

        {value.toLocaleString('pt-BR')}
      </Typography>
    </>}>
      {children}
    </Tooltip>
  );
}
export const EmployeeFilter = () => {
  const { setMaxEmployeeFilter, setMinEmployeeFilter } = useProjectService()
  const employeeFilterState = useAppSelector((state) => state.project.selectedFilter?.staff?.employee)
  const exportDialog = useAppSelector((state) => state.project.exportDialog)

  return (
    <>

      <FilterHeader title="Número de funcionários" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
        </Typography>
        Esta opção permite filtrar a quantidade mínima e a quantidade máxima de funcionários que a empresa tem no momento.
        <Typography>
          Exemplo: (10 até 27)
        </Typography>
        <Typography>
          Caso estas opções não sejam marcadas, serão considerados todos os porte e quadros de pessoas na contagem.
        </Typography>
      </>} />
      <Box p={3} mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const regexValue = e.target.value.replace(/\D/g, '')
                if (regexValue.length === 0) {
                  return
                }
                setMinEmployeeFilter({
                  minEmployee: Number(regexValue)
                })
              }}
              value={!employeeFilterState?.min ? '' : employeeFilterState?.min}
              fullWidth
              label="Número mínimo de funcionários"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const regexValue = e.target.value.replace(/\D/g, '')
                if (regexValue.length === 0) {
                  return
                }

                setMaxEmployeeFilter({
                  maxEmployee: Number(regexValue)
                })
              }}
              value={!employeeFilterState?.max ? '' : employeeFilterState?.max}
              fullWidth
              label="Número máximo de funcionários"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', marginTop: '60px' }}>
              <Slider
                defaultValue={80}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay={exportDialog.open ? 'off' : 'on'}
                marks={[
                  {
                    value: 1,
                    label: '1',
                  },
                  {
                    value: 500,
                    label: '500',
                  },
                  {
                    value: 1000,
                    label: '1.000',
                  },
                  {
                    value: 3000,
                    label: '3.000',
                  }
                ]}
                min={1}
                max={3000}
                value={[employeeFilterState?.min ?? 0, employeeFilterState?.max ?? 3000]}
                onChange={(event, value: any) => {

                  setMinEmployeeFilter({
                    minEmployee: Number(value[0] ?? 0)
                  })
                  setMaxEmployeeFilter({
                    maxEmployee: Number(value[1] ?? 3000)
                  })
                }}

                slots={{
                  valueLabel: ValueLabelComponent,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}