import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid, TextField, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from "../../../../../application/states/hooks";
import { useUserService } from "../../../../../use-cases/user";
import { useEffect, useState } from "react";
import { MuiFileInput } from "mui-file-input";
import { darkenColor } from "../../../../../utils";
import { theme } from "../../../../styles";

export const ProfileSection = () => {
  const user = useAppSelector((state) => state.user)
  const { setUserEmail, changeUserEmail, setUserName, changeUserName, changeUserImageFile, setUserImageFileInputValue } = useUserService()
  const [inputNameError, setInputNameError] = useState(false)
  const [inputEmailError, setInputEmailError] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const { company } = useAppSelector((state) => state.company)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)

  useEffect(() => {
    document.title = `${resaleConfig?.id !== '' ? `${resaleConfig!.name}` : 'Lurik'} - Configurações / Perfil`
  }, [resaleConfig])
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Email: <strong>{user.email}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={inputEmailError}
                    helperText={inputEmailError ? 'Campo obrigatório' : ''}
                    label="E-mail"
                    value={user.email}
                    onChange={(e) => {
                      setUserEmail({
                        email: e.target.value
                      })
                    }}
                    fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 15)
                      },
                      color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                    }}
                    onClick={() => {
                      setInputEmailError(false)
                      if (user.email === '') {
                        setInputEmailError(true)
                        return
                      }
                      changeUserEmail()
                    }}
                  >
                    Mudar e-mail
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Nome: <strong>{user.name}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={inputNameError}
                    helperText={inputNameError ? 'Campo obrigatório' : ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Nome"
                    value={user.name}
                    onChange={(e) => {
                      setUserName({
                        name: e.target.value
                      })
                    }}
                    fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 15)
                      },
                      color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                    }}
                    onClick={() => {
                      setInputNameError(false)
                      if (user.name === '') {
                        setInputNameError(true)
                        return
                      }
                      changeUserName()
                    }}
                  >
                    Mudar nome
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <strong>Imagem de Perfil</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MuiFileInput
                    id="outlined-static"
                    value={user.img}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => {
                      setUserImageFileInputValue(e)
                      setFile(e)
                    }}
                    label="Clique aqui para adicionar uma imagem"
                    getInputText={(file) => {
                      return file?.name ?? 'Selecione uma imagem'
                    }}
                    InputProps={{
                      inputProps: {
                          accept: '.png, .jpeg, .jpg'
                      }
                  }}
                  />
                  {
                    file === null ?
                      <Grid container p={1} justifyContent={'center'}>
                        <Grid item pl={2} pr={2} pt={2} justifyContent={'center'} >
                          <Avatar src={user.img} alt="preview" sx={{ width: 115, height: 115 }} />
                        </Grid>
                      </Grid>
                      : <Grid container p={1} justifyContent={'center'}>
                        <Grid item pl={2} pr={2} pt={2} justifyContent={'center'} >
                          <Avatar src={user.userImageFileInputValue} alt="preview" sx={{ width: 115, height: 115 }} />
                        </Grid>
                      </Grid>
                  }
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      '&:hover': {
                        backgroundColor: darkenColor(theme.palette.primary.main, 15)
                      },
                      color: company?.resale === true || company?.resaleId ? theme.palette.primary.contrastText : 'white'
                    }}
                    onClick={() => {
                      changeUserImageFile()
                    }}
                  >
                    Atualizar imagem
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  )
}