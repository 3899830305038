export function getSleep(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    })
}

export function nameAbbreviation(name: string): string {
    let abbreviatedName = ''

    const splitName = name.replace('  ', ' ').trim().toUpperCase().split(' ')

    if (splitName) {
        if (splitName.length > 0) {
            if (splitName[0].length === 1) {
                for (let i = 0; i < splitName.length; i++) {
                    if (splitName[i].length === 1 || splitName[i].length === 2) {
                        abbreviatedName = abbreviatedName + ` ${splitName[i]}`
                    } else {
                        break
                    }
                }
            } else if (splitName[0].toUpperCase() === 'GRUPO') {
                if (splitName.length > 0) {
                    abbreviatedName = abbreviatedName + `${splitName[0]} ${splitName[1]}`
                } else {
                    abbreviatedName = abbreviatedName + splitName[0]
                }
            } else {
                abbreviatedName = abbreviatedName + splitName[0]
            }
        } else {
            abbreviatedName = abbreviatedName + splitName[0]
        }
    }

    return abbreviatedName.replace('  ', ' ').trim().toUpperCase()
}

export const GetGeolocationTypeLabel = (addressRank: number): string => {
    if (addressRank === 4) return "País"

    if (addressRank >= 5 && addressRank <= 9) return "Região / Município / Estado"

    if (addressRank >= 10 && addressRank <= 12) return "Distrito / CEP"

    if (addressRank >= 13 && addressRank <= 16) return "Cidade"

    if (addressRank >= 17 && addressRank <= 24) return "Zona / Bairro / Vila / Comunidade"

    if (addressRank >= 26 && addressRank <= 27) return "Rodovia / Avenida / Rua / Ponte"

    if (addressRank >= 28 && addressRank <= 30) return "Monumento / Estrutura / Estação / Complexo / Parque"

    return "Outro"
}

export const getZoomLevel = (radius: number): number => {
    if (radius <= 1000) return 15
    if (radius > 1000 && radius <= 3000) return 14
    if (radius > 3000 && radius <= 5000) return 13
    if (radius > 5000 && radius <= 11000) return 12
    if (radius > 11000 && radius <= 23000) return 11
    if (radius > 23000 && radius <= 44000) return 10
    if (radius > 44000 && radius <= 88000) return 9
    if (radius > 88000 && radius <= 174000) return 8
    if (radius > 174000 && radius <= 350000) return 7
    if (radius > 350000 && radius <= 700000) return 6
    if (radius > 700000 && radius <= 1400000) return 5
    if (radius > 1400000) return 4

    return 4
}