import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { StatusKeys } from "../../../../../../application/models"
import { useAppSelector } from "../../../../../../application/states/hooks"
import { useProjectService } from "../../../../../../use-cases/project"
import { FilterHeader } from "../filter-header"

const statusKeyToLabel = (statusKey: string) => {
  switch (statusKey) {
    case 'ATIVA':
      return 'Ativa'
    case 'BAIXADA':
      return 'Baixada'
    case 'SUSPENSA':
      return 'Suspensa'
    case 'INAPTA':
      return 'Inapta'
    case 'NULA':
      return 'Nula'
    default:
      return 'Não definido'
  }
}



export const StatusFilter = () => {
  const statusFilterState = useAppSelector((state) => state.project?.selectedFilter?.status)
  const { addStatusFilter, removeStatusFilter } = useProjectService()
  return (
    <>
      <FilterHeader title="Situação cadastral" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
          Esta opção permite filtrar empresas através da situação cadastral em que ela se encontra na Receita Federal. Podendo ser marcado somente uma única situação cadastral ou várias ao mesmo tempo
        </Typography>
        <Typography>
          Exemplo: Ativa / Inapta etc.
        </Typography>
        <Typography>
          Caso esta opção não seja marcada serão consideradas todas as situações cadastrais na contagem.
        </Typography>
      </>} />
      <Grid item xs={12} py={3}>
        <Divider />
      </Grid>
      <Box p={3}>
        <Grid container spacing={3}>
          {
            StatusKeys.map((status) => {
              return <>
                <Grid item xs={4}>
                  <FormControlLabel control={
                    <Switch
                      checked={statusFilterState?.includes(status) ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          addStatusFilter({
                            status: status
                          })

                        }
                        else {
                          removeStatusFilter({
                            status: status
                          })
                        }
                      }}

                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } label={statusKeyToLabel(status).toUpperCase()} />
                </Grid>

              </>
            })
          }
        </Grid>

      </Box>
    </>

  )
}