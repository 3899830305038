import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IEconomicActivity } from '../../models/IEconomicActivity';

interface IEconomicActivityState {
  searchTerm: string
  value: string
  economicActivity: IEconomicActivity
  economicActivityList: IEconomicActivity[]
}

const initialState: IEconomicActivityState = {
  searchTerm: '',
  value: '',
  economicActivity: {
    cnaeCodigo: '',
    cnaeDescricao: ''
  },
  economicActivityList: []
};

const economicActivitySlice = createSlice({
  name: 'economicActivity',
  initialState,
  reducers: {
    setEconomicActivityListState(state, action: PayloadAction<{
      economicActivityList: IEconomicActivity[]
    }>) {
      state.economicActivityList = action.payload.economicActivityList
    },
    setEconomicActivityState(state, action: PayloadAction<{
      economicActivity: IEconomicActivity
    }>) {
      state.economicActivity = action.payload.economicActivity
    },
    setEconomicActivitySearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.searchTerm = action.payload.searchTerm
    },
    setEconomicActivityValueState(state, action: PayloadAction<{
      value: string
    }>) {
      state.value = action.payload.value
    }

  },
});

export const {
  setEconomicActivityListState,
  setEconomicActivityState,
  setEconomicActivitySearchTermState,
  setEconomicActivityValueState
} = economicActivitySlice.actions;
export const economicActivityReducer = economicActivitySlice.reducer;