import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotification, INotificationResumeTextColor, INotificationViewMode } from "../../models"

export interface INotificationState {
    selectedNotification?: INotification
    viewedNotificationList: INotification[]
    notViewedNotificationList: INotification[]
    viewMode: INotificationViewMode
    viewLimit: number
    openNotificationDialog: boolean
    resumeNotificationTextColor: INotificationResumeTextColor
}

const initialState: INotificationState = {
    selectedNotification: undefined,
    notViewedNotificationList: [],
    viewedNotificationList: [],
    viewMode: "NOT_VIEWED",
    viewLimit: 3,
    openNotificationDialog: false,
    resumeNotificationTextColor: "primary"
}

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setSelectedNotificationState(state, action: PayloadAction<{
            notification?: INotification
        }>) {
            state.selectedNotification = action.payload.notification
        },
        setViewedByState(state, action: PayloadAction<{
            notificationId: string
            userId: string
        }>) {
            if (state.selectedNotification?.id !== action.payload.notificationId) return
            state.selectedNotification!.viewedBy?.push(action.payload.userId)
        },
        setViewedNotificationsState(state, action: PayloadAction<{
            viewedNotificationList: INotification[]
        }>) {
            state.viewedNotificationList = action.payload.viewedNotificationList
        },
        setNotViewedNotificationsState(state, action: PayloadAction<{
            notViewedNotificationList: INotification[]
        }>) {
            state.notViewedNotificationList = action.payload.notViewedNotificationList
        },
        setViewModeNotificationState(state, action: PayloadAction<{
            viewMode: INotificationViewMode
        }>) {
            state.viewMode = action.payload.viewMode
            state.viewLimit = initialState.viewLimit
        },
        setViewLimitState(state, action: PayloadAction<{
            viewLimit: number
        }>) {
            state.viewLimit = action.payload.viewLimit
        },
        setOpenNotificationDialogState(state, action: PayloadAction<{
            openNotificationDialog: boolean
        }>) {
            state.openNotificationDialog = action.payload.openNotificationDialog
        },
        setCloseNotificationDialogState(state, action: PayloadAction<{
            closeNotificationDialog: boolean
        }>) {
            state.openNotificationDialog = action.payload.closeNotificationDialog
            state.selectedNotification = initialState.selectedNotification
        },
        setResumeDialogTextColorState(state, action: PayloadAction<{
            resumeNotificationTextColor: INotificationResumeTextColor
        }>) {
            state.resumeNotificationTextColor = action.payload.resumeNotificationTextColor
        },
        resetNotificationState(state) {
            state.notViewedNotificationList = initialState.notViewedNotificationList
            state.openNotificationDialog = initialState.openNotificationDialog
            state.resumeNotificationTextColor = initialState.resumeNotificationTextColor
            state.selectedNotification = initialState.selectedNotification
            state.viewLimit = initialState.viewLimit
            state.viewMode = initialState.viewMode
            state.viewedNotificationList = initialState.viewedNotificationList
        }
    }
})

export const {
    setSelectedNotificationState,
    setViewedByState,
    setViewedNotificationsState,
    setNotViewedNotificationsState,
    setViewModeNotificationState,
    setViewLimitState,
    setOpenNotificationDialogState,
    setCloseNotificationDialogState,
    setResumeDialogTextColorState,
    resetNotificationState
} = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer