import { useCallback } from "react"
import { IUser } from "../../application/models"
import { useAxiosInstances } from "../axios-instance"
import { IDownloadLog } from "../../application/models/IDownloadLog"

export const useUserApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()
  
  const findById = useCallback((data: {
    userId: string
  }): Promise<IUser> => {
    return axiosAuthInstance.get('/user/' + data.userId).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const changeUserEmailApi = useCallback((data: {
    userId: string,
    email: string
  }): Promise<IUser> => {
    return axiosAuthInstance.put('/user/email', {
      id: data.userId,
      email: data.email
    }).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const changeUserNameApi = useCallback((data: {
    userId: string,
    name: string
  }): Promise<IUser> => {
    return axiosAuthInstance.put('/user/name', {
      id: data.userId,
      name: data.name
    }).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const changeUserPasswordApi = useCallback((data: {
    userId: string,
    password: string
  }): Promise<IUser> => {
    return axiosAuthInstance.put('/user/password', {
      id: data.userId,
      password: data.password
    }).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])


  const updateUserApi = useCallback((data: {
    userId: string,
    name?: string,
    email?: string,
    userType?: string,
    status?: boolean,
    image?: any
  }): Promise<IUser> => {
    return axiosAuthInstance.put('/user', {
      id: data.userId,
      name: data.name,
      email: data.email,
      userType: data.userType,
      status: data.status,
      img: data.image
    }).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])

  const deleteUserApi = useCallback((data: {
    userId: string
  }): Promise<IUser> => {
    return axiosAuthInstance.delete('/user/' + data.userId).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])


  const findUserByCompanyIdApi = useCallback((data: {
    companyId: string
  }): Promise<IUser[]> => {
    return axiosAuthInstance.get('/user/list/', {
      params: {
        companyId: data.companyId
      }
    }).then((response) => {
      const users = response.data?.result?.userList
      if (users) {
        return users
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])


  const createUserApi = useCallback((data: {
    userType: string,
    companyId: string,
    name: string,
    email: string,
    img?: string
  }): Promise<IUser> => {
    return axiosAuthInstance.post('/user', {
      userType: data.userType,
      companyId: data.companyId,
      name: data.name,
      email: data.email,
      img: data.img
    }).then((response) => {
      const user = response.data?.result?.user
      if (user) {
        return user
      } else {
        throw {
          type: 'NotFoundError',
          message: 'Usuário não encontrado'
        }
      }
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      } else {
        throw {
          message: 'Erro desconhecido',
          type: 'unknown'
        }
      }
    })
  }, [axiosAuthInstance])
  
  const getLastDownloadLog = useCallback((data: {
    userId: string
  }): Promise<IDownloadLog | undefined> => {
    return axiosAuthInstance.get('/user/last-download', {
      params: {
        userId: data.userId
      }
    }).then((response) => {
      const downloadLog = response.data?.result?.downloadLog
      if (downloadLog) {
        return downloadLog
      }
    })
  }, [axiosAuthInstance])


  return {
    findById,
    changeUserEmailApi,
    changeUserNameApi,
    changeUserPasswordApi,
    updateUserApi,
    deleteUserApi,
    findUserByCompanyIdApi,
    createUserApi,
    getLastDownloadLog
  }
}