import { useCallback } from "react"
import { useAxiosInstances } from "../../axios-instance"
import { IBackofficeRevenueReport } from "../../../application/states/backoffice/revenue-report"

export const useBackofficeRevenueReportApi = () => {
  const { axiosAuthInstance } = useAxiosInstances()

  const getRevenueReportApi = useCallback(async (data: {
    date: Date,
    userId: string
  }): Promise<IBackofficeRevenueReport[]> => {
    return axiosAuthInstance.get('/consumption/revenue-report', {
      params: {
        date: data.date,
        userId: data.userId
      }
    }).then((response) => {
      const result = response.data.result?.revenueReport
      if (result) {
        return result
      } else {
        throw new Error('Erro ao buscar relatório de receita')
      }
    }).catch((error) => {
      throw error
    })
  }, [
    axiosAuthInstance
  ])

  const getResaleRevenueReportApi = useCallback(async (data: {
    date: Date,
    userId: string
  }): Promise<IBackofficeRevenueReport[]> => {
    return axiosAuthInstance.get('/consumption/resale-revenue-report', {
      params: {
        date: data.date,
        userId: data.userId
      }
    }).then((response) => {
      const result = response.data.result?.revenueReport
      if (result) {
        return result
      } else {
        throw new Error('Erro ao buscar relatório de receita')
      }
    }).catch((error) => {
      throw error
    })
  }, [
    axiosAuthInstance
  ])

  return {
    getRevenueReportApi,
    getResaleRevenueReportApi
  }
}