import { useEffect, useState } from "react"
import { LoginForm } from "./login-form"
import { useAppSelector } from "../../../../application/states/hooks"
import { useResaleConfigService } from "../../../../use-cases/resale-config"
import { changeFavicon, getSleep } from "../../../../utils"
import TransparentFavicon from '../../../assets/img/transparent_favicon.png'

export const LoginPage = () => {
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const { getResaleConfigByDomain } = useResaleConfigService()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadTheme = async () => {
      try {
        getResaleConfigByDomain({
          domain: window.location.host
        })
      } catch (error) {

      } finally {
        await getSleep(500)
        setLoading(false)
      }
    }

    loadTheme()
  }, [getResaleConfigByDomain])

  useEffect(() => {
    if (loading === false) {
      document.title = `${resaleConfig?.id !== '' ? resaleConfig?.name : 'Lurik'} - Login`
    } else {
      changeFavicon(TransparentFavicon)
    }
  }, [loading, resaleConfig?.id, resaleConfig?.name])

  return (
    <>
      <LoginForm />
    </>
  )
}