import { Grid, Typography, Box, TextField } from "@mui/material"
import { useAppSelector } from "../../../../../application/states/hooks"
import { usePasswordRecoveryService } from "../../../../../use-cases/password-recovery"

export const VerificationCodeForm = () => {
  const email = useAppSelector(state => state.passwordRecovery.passwordRecoveryModel.email)
  const verificationCode = useAppSelector(state => state.passwordRecovery.passwordRecoveryModel.verificationCode)
  const verificationCodeFieldProps = useAppSelector(state => state.passwordRecovery.fields.verificationCode)
  const { setVerificationCode } = usePasswordRecoveryService()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" align="center" color="primary" fontWeight="bold">
            Recuperar senha
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" component="p" align="center">
            Insira o código de verificação que enviamos para o endereço de e-mail {email} no campo de texto abaixo e clique em "Próximo". Em seguida, você será redirecionado para a página de criação de nova senha.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="body1" component="p" align="center" fontWeight="bold">
              Lembre-se de seguir todas as instruções fornecidas pelo sistema para garantir a segurança da sua conta. Se você tiver problemas para recuperar sua senha, entre em contato com o suporte ao cliente para obter ajuda adicional.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Código de verificação"
            value={verificationCode}
            error={!verificationCodeFieldProps.isValid}
            helperText={!verificationCodeFieldProps.isValid && `${verificationCodeFieldProps.error?.message}`}
            onChange={(e) => {
              setVerificationCode({
                verificationCode: e.target.value
              })
            }}

            fullWidth />
        </Grid>
      </Grid>
    </>
  )
}