import * as XLSX from 'xlsx'

export function convertToXLSX(data: any[], header: string[], filename: string) {
    // Cria uma planilha com os dados e cabeçalho
    const worksheet = XLSX.utils.json_to_sheet(data, { header });

    // Cria um livro de trabalho e adiciona a planilha
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    // Salva o arquivo em um buffer
    const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });

    // Cria um objeto blob a partir do buffer
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Cria um link para o arquivo blob e faz o download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    link.click();
}