import { MuiFileInput } from "mui-file-input"
import { Grid } from "@mui/material";
import { useBackofficeService } from "../../../../../../../../../use-cases/backoffice";
import { useAppSelector } from "../../../../../../../../../application/states/hooks";

export const ImageCreateNotification = () => {

    const { setNotificationFileImage } = useBackofficeService()
    const notificationState = useAppSelector((state) => state.backofficeNotification)
    const userState = useAppSelector((state) => state.user)

    return <>
        <MuiFileInput
            disabled={userState.userType === 'SELLER'}
            id="outlined-static"
            value={notificationState.notificationForm.imageFile}
            fullWidth
            variant="outlined"
            onChange={(e) => {
                setNotificationFileImage(e)
            }}
            label="Imagem"
            placeholder="Selecione uma imagem"
        />
        {notificationState.notificationForm.imageFile !== null ?
            notificationState.selectedNotification?.img ?
                <Grid container>
                    <Grid item xs={12} pt={2} pl={2} pr={2} sx={{ textAlign: 'center' }}>
                        <img
                            src={notificationState.selectedNotification.img}
                            alt="preview"
                            style={{
                                maxWidth: 200,
                                maxHeight: 200
                            }}
                        />
                    </Grid>
                </Grid>
                : null
            : null}
    </>
}