import { useCallback } from "react"
import { useAxiosInstances } from "../axios-instance"

export const usePasswordRecoveryApi = () => {
  const { axiosInstance } = useAxiosInstances()
  const requestNewPassword = useCallback((data: {
    email: string
    domain: string
  }) => {
    return axiosInstance.post('/password-recovery', data).then((res) => {
      return res.data.result
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      }
    })
  }, [axiosInstance])

  const validateVerificationCode = useCallback((data: {
    email: string,
    verificationCode: string
  }) => {
    return axiosInstance.get('/password-recovery/', {
      params: {
        email: data.email,
        token: data.verificationCode
      }
    }).then((res) => {
      return res.data.result
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      }
    })
  }, [axiosInstance])

  const changePasswordWithToken = useCallback((data: {
    email: string,
    verificationCode: string,
    password: string
  }) => {
    return axiosInstance.put('/password-recovery/', {
      email: data.email,
      token: data.verificationCode,
      newPassword: data.password
    }).then((res) => {
      return res.data.result
    }).catch((error) => {
      if (error?.response?.data?.result) {
        throw {
          message: error.response.data.result.message,
          type: error.response.data.result.type
        }
      }
    })
  }, [axiosInstance])

  return {
    requestNewPassword,
    validateVerificationCode,
    changePasswordWithToken
  }
}