import { AppBar, Box, Button, Dialog, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Slide, TextField, Toolbar, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { forwardRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useBackofficeService } from "../../../../../../../../../../use-cases/backoffice";
import { useAppSelector } from "../../../../../../../../../../application/states/hooks";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const CompanyRecipientForm = () => {
    const [openCompanyRecipientDialog, setOpenCompanyRecipientDialog] = useState(false)
    const companyState = useAppSelector((state) => state.backofficeCompany)
    const { setSearchTerm } = useBackofficeService()

    return (
        <>
            <Button onClick={() => {
                setOpenCompanyRecipientDialog(true)
            }} fullWidth endIcon={<>
                <AddIcon />
            </>}>
                <Typography variant="h6" fontWeight="bold">
                    Empresas
                </Typography>
            </Button>

            <Dialog
                fullScreen
                open={openCompanyRecipientDialog}
                onClose={() => {
                    setOpenCompanyRecipientDialog(false)
                }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" textTransform={"uppercase"}>
                            Selecionar empresas
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => {
                            setOpenCompanyRecipientDialog(false)
                        }}>
                            Fechar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                value={companyState.filters.searchTerm}
                                onChange={(e) => {
                                    setSearchTerm({
                                        searchTerm: e.target.value
                                    })
                                }}
                                fullWidth
                                label="Digite o nome ou CNPJ da empresa"
                                placeholder="Digite o nome ou CNPJ da empresa"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item xs={6}>
                        <List>
                            {
                                companyState.companyList.filter((company) => {
                                    if (company.company.razaoSocial.toUpperCase().includes(companyState.filters.searchTerm.toUpperCase())) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }).map((company, index) => {
                                    return (<>
                                        <ListItem
                                            key={index}
                                            style={{
                                                padding: 0
                                            }}>
                                            <ListItemButton onClick={(e) => {
                                                // addCompanyIdRecipientFilter({
                                                //     companyId: company.company.id
                                                // })
                                            }}>
                                                <ListItemText primary={company.company.razaoSocial.toUpperCase()} secondary={company.company.cnpj} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                    </>)
                                })
                            })
                        </List>
                    </Grid>
                    {/* {
                        (economicActivityFilterState?.length ?? 0) > 0 && <Grid xs={6}>
                            <Box p={3} my={3}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h5" color="primary" fontWeight="bold" textTransform="uppercase">
                                            Atividades econômicas selecionadas
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <EconomicActivityFilterPreview />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                    } */}
                </Grid>
            </Dialog>
        </>
    )
}