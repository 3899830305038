import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useAppSelector } from '../../../../../application/states/hooks';
import { useProjectService } from '../../../../../use-cases/project';
import { Box, Grid, TextField } from '@mui/material';
import { ProjectReportResume } from '../project-report-resume';
import { SubscriptionResume } from '../../dashboard/subscription-resume';
import { darkenColor } from '../../../../../utils';
import { theme } from '../../../../styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ExportDialog() {
  const open = useAppSelector((state) => state.project.exportDialog.open)
  const { closeExportDialog, exportProject, setExportDialogName, setExportDialogTotal } = useProjectService()
  const projectName = useAppSelector((state) => state.project.selectedProject?.name)
  const exportDialog = useAppSelector((state) => state.project.exportDialog)
  const { company } = useAppSelector((state) => state.company)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        closeExportDialog()
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            EXTRAÇÃO: <Typography display="inline" >{projectName}</Typography>
          </Typography>
          <Button autoFocus color="inherit" onClick={() => {
            closeExportDialog()
          }}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>
      <form onSubmit={(e) => {
        e.preventDefault()
        exportProject({
          type: 'project'
        })
      }}>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography color="primary" fontWeight={"bold"} variant="h4" component="div" align="center" textTransform={"uppercase"}>
                Extrair Projeto
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={exportDialog.name}
                onChange={(e) => {
                  setExportDialogName({
                    name: e.target.value
                  })
                }}
                error={exportDialog.formErrors.name ? true : false}
                helperText={exportDialog.formErrors.name ? exportDialog.formErrors.name : ''}
                fullWidth
                label="Digite o nome da extração"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={exportDialog.total ? false : true}
                fullWidth
                value={exportDialog.total?.toLocaleString('pt-BR') ?? ''}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  const formattedNumber = e.target.value.replace('.', '').replace(',', '')
                  const numberCasted = Number(formattedNumber ?? 0)
                  if (isNaN(numberCasted)) return
                  setExportDialogTotal({
                    total: numberCasted
                  })
                }}

                label="Digite a quantidade de registros a serem extraídos"
                helperText={exportDialog.formErrors.total ? exportDialog.formErrors.total : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"} alignItems="center">
                <Grid item>
                  <ProjectReportResume />
                </Grid>
                <Grid item>
                  <SubscriptionResume />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  '&:hover': {
                    backgroundColor: darkenColor(theme.palette.primary.main, 10),
                    color: company?.resale === true || company?.resaleId
                      ? theme.palette.primary.contrastText
                      : '#FFF'
                  }
                }}
              >
                Extrair
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

    </Dialog>
  );
}