import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFieldError, IFieldProps } from "../../models"
import { ICepList } from "../../models/ICepList"

export const validateCepListForm = (data: {
    name: string,
}): {
    fieldError: IFieldError,
    field: string
}[] => {
    const errors: {
        fieldError: IFieldError,
        field: string
    }[] = []

    if (!data.name) {
        errors.push({
            field: 'name',
            fieldError: {
                message: 'Nome é obrigatório',
                type: 'RequiredFieldError'
            }
        })
    }


    return errors
}

interface ICepListState {
    matchCode: {
        selectedColumn: string,
        distinctValues: number,
        validValues: number
    },
    fileData: {
        name: string,
        size: string,
        columns: string[],
        type: string,
        data: any[],
        validData?: any[],
        invalidData?: any[]
    },
    cepLists: ICepList[]
    selectedCepList?: ICepList
    fileListData: {
        fileListData: string[]
        fileListTableValues: {
            fileListValidData: string[]
            fileListInvalidData: string[]
            fileListRepeatedData: string[]
        }
    }
    cepListForm: {
        action: 'edit' | 'create',
        fields: {
            name: IFieldProps
        }
    }
    searchTerm: string
}

const initialState: ICepListState = {
    fileListData: {
        fileListData: [],
        fileListTableValues: {
            fileListInvalidData: [],
            fileListRepeatedData: [],
            fileListValidData: []
        }
    },
    matchCode: {
        selectedColumn: '',
        distinctValues: 0,
        validValues: 0
    },
    fileData: {
        name: '',
        size: '',
        columns: [],
        type: '',
        data: []
    },
    cepLists: [],
    cepListForm: {
        action: 'create',
        fields: {
            name: {
                isValid: true,
                error: undefined,
            }
        }
    },
    searchTerm: ''
};

const cepListSlice = createSlice({
    name: 'cepList',
    initialState,
    reducers: {
        setCepLists(state, action: PayloadAction<{
            cepLists: ICepList[]
        }>) {
            state.cepLists = action.payload.cepLists
        },
        setSelectedCepList(state, action: PayloadAction<{
            cepList?: ICepList
        }>) {
            state.selectedCepList = action.payload.cepList
        },
        setCepListName(state, action: PayloadAction<{
            value: string,
        }>) {
            if (!state.selectedCepList) return
            state.selectedCepList.name = action.payload.value
        },
        setCepListList(state, action: PayloadAction<{
            value: string[],
        }>) {
            if (!state.selectedCepList) return
            state.selectedCepList.list = action.payload.value
        },
        setCepListFileDataList(state, action: PayloadAction<{
            value: string[],
        }>) {
            if (!state.selectedCepList) return
            state.fileListData.fileListData = action.payload.value
        },
        setCepListFileValidDataList(state, action: PayloadAction<{
            value: string[],
        }>) {
            if (!state.selectedCepList) return
            state.fileListData.fileListTableValues.fileListValidData = action.payload.value
        },
        setCepListFileRepeatedDataList(state, action: PayloadAction<{
            value: string[],
        }>) {
            if (!state.selectedCepList) return
            state.fileListData.fileListTableValues.fileListRepeatedData = action.payload.value
        },
        setCepListFileInvalidDataList(state, action: PayloadAction<{
            value: string[],
        }>) {
            if (!state.selectedCepList) return
            state.fileListData.fileListTableValues.fileListInvalidData = action.payload.value
        },
        addItemCepListList(state, action: PayloadAction<{
            value: string,
        }>) {
            if (!state.selectedCepList) return
            /* If the value is already in the list, do not add it again */
            if (state.selectedCepList.list.includes(action.payload.value)) return
            state.selectedCepList.list.push(action.payload.value)
        },
        removeItemCepListList(state, action: PayloadAction<{
            value: string,
        }>) {
            if (!state.selectedCepList) return
            state.selectedCepList.list = state.selectedCepList.list.filter(item => item !== action.payload.value)
        },
        editItemCepListList(state, action: PayloadAction<{
            value: string,
            newValue: string
        }>) {
            if (!state.selectedCepList) return

            /* If the value is already in the list, do not add it again */
            if (state.selectedCepList.list.includes(action.payload.newValue)) return
            state.selectedCepList.list = state.selectedCepList.list.map(item => item === action.payload.value ? action.payload.newValue : item)
        },
        setCepListSelectedColumn(state, action: PayloadAction<{
            selectedColumn: string
        }>) {
            state.matchCode.selectedColumn = action.payload.selectedColumn
        },
        setCepListDistinctValues(state, action: PayloadAction<{
            distinctValues: number
        }>) {
            state.matchCode.distinctValues = action.payload.distinctValues
        },
        setCepListValidValues(state, action: PayloadAction<{
            validValues: number
        }>) {
            state.matchCode.validValues = action.payload.validValues
        },
        setCepListFileData(state, action: PayloadAction<{
            fileData: {
                name: string,
                size: string,
                columns: string[],
                type: string,
                data: any[],
                validData?: any[],
                invalidData?: any[]
            }
        }>) {
            state.fileData = action.payload.fileData
        },
        setCepListFormErrors(state, action: PayloadAction<{
            errors: {
                field: string,
                fieldError: IFieldError
            }[]
        }>) {
            action.payload.errors.forEach(error => {
                (state.cepListForm.fields as any)[error.field].error = error.fieldError;
                (state.cepListForm.fields as any)[error.field].isValid = false
            })
        },
        resetCepListForm(state) {
            state.cepListForm = initialState.cepListForm
            state.fileData = initialState.fileData
            state.matchCode = initialState.matchCode
            state.selectedCepList = initialState.selectedCepList
        },
        resetCepListFileData(state) {
            state.fileData = initialState.fileData
            state.matchCode = initialState.matchCode
        },
        setCepListStatus(state, action: PayloadAction<{
            status: boolean
        }>) {
            if (!state.selectedCepList) return

            state.selectedCepList.status = action.payload.status
        }
    },
});

export const {
    setCepLists,
    setSelectedCepList,
    setCepListName,
    addItemCepListList,
    removeItemCepListList,
    editItemCepListList,
    setCepListList,
    setCepListSelectedColumn,
    resetCepListForm,
    setCepListDistinctValues,
    setCepListValidValues,
    setCepListFileData,
    setCepListFileDataList,
    setCepListFileValidDataList,
    setCepListFileRepeatedDataList,
    setCepListFileInvalidDataList,
    setCepListFormErrors,
    setCepListStatus,
    resetCepListFileData
} = cepListSlice.actions;
export const cepListReducer = cepListSlice.reducer;