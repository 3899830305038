import { Box, Grid, Slider, SliderValueLabelProps, TextField, Tooltip, Typography } from "@mui/material"
import { FilterHeader } from "../filter-header"
import { useProjectService } from "../../../../../../use-cases/project";
import { useAppSelector } from "../../../../../../application/states/hooks";
function valuetext(value: number) {
  return `${value}`;
}
function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip open={true} enterTouchDelay={0} placement="top" title={<>
      <Typography variant="h6" color="white" fontWeight={"bold"}>

        {value.toLocaleString('pt-BR')}
      </Typography>
    </>}>
      {children}
    </Tooltip>
  );
}
export const BusinessAssociateFilter = () => {
  const { setMaxBusinessAssociateFilter, setMinBusinessAssociateFilter } = useProjectService()
  const businessAssociateFilterState = useAppSelector((state) => state.project.selectedFilter?.staff?.businessAssociate)
  const exportDialog = useAppSelector((state) => state.project.exportDialog)

  return (
    <>
      <FilterHeader title="Número de sócios" description={<>
        <Typography style={{
          marginBottom: '10px'
        }}>
        </Typography>
        Esta opção permite filtrar a quantidade mínima e máxima de sócios que a empresa tem no momento.
        <Typography>
          Exemplo: (2 até 4).
        </Typography>
        <Typography>
          Caso estas opções não sejam marcadas, serão considerados todos os porte e quadros de pessoas na contagem.
        </Typography>
      </>} />
      <Box p={3} mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const regexValue = e.target.value.replace(/\D/g, '')
                if (regexValue.length === 0) {
                  return
                }

                setMinBusinessAssociateFilter({
                  minBusinessAssociate: Number(regexValue)
                })
              }}
              value={businessAssociateFilterState?.min === 0 ? '' : businessAssociateFilterState?.min}
              fullWidth
              label="Número mínimo de sócios"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const regexValue = e.target.value.replace(/\D/g, '')
                if (regexValue.length === 0) {
                  return
                }

                setMaxBusinessAssociateFilter({
                  maxBusinessAssociate: Number(regexValue)
                })
              }}
              value={businessAssociateFilterState?.max === 0 ? '' : businessAssociateFilterState?.max}
              fullWidth
              label="Número máximo de sócios"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', marginTop: '60px' }}>
              <Slider
                defaultValue={2000}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay={exportDialog.open ? 'off' : 'on'}
                marks={[
                  {
                    value: 1,
                    label: '1',
                  },
                  {
                    value: 500,
                    label: '500'
                  },
                  {
                    value: 1000,
                    label: '1000',
                  },
                  {
                    value: 2000,
                    label: '2.000',
                  }
                ]}
                min={1}
                max={2000}
                value={[businessAssociateFilterState?.min || 1, businessAssociateFilterState?.max || 2000]}
                onChange={(e, value: any) => {
                  setMinBusinessAssociateFilter({
                    minBusinessAssociate: Number(value[0])
                  })
                  setMaxBusinessAssociateFilter({
                    maxBusinessAssociate: Number(value[1])
                  })
                }
                }
                slots={{
                  valueLabel: ValueLabelComponent,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}