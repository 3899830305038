import { useCallback } from "react"
import { resetAuthState, setToken } from "../../application/states/auth/auth-slice"
import { useAppDispatch } from "../../application/states/hooks"
import { useLocalStorageService } from "../local-storage"

export const useAuthenticationService = () => {
  const dispatch = useAppDispatch()
  const { getItem } = useLocalStorageService()
  
  const loadToken = useCallback(() => {
    const token = getItem({
      key: 'ACCESS_TOKEN'
    })
    if (token) {
      dispatch(setToken({ token }))
    } else {
      dispatch(resetAuthState())
    }
  }, [dispatch, getItem])

  return {
    loadToken
  }
}