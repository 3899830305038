import { useCallback } from "react"
import { useConsumptionApi } from "../../infra/consumption-api"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { setUserListWithConsumptionState } from "../../application/states/consumption"
import { useSubscriptionService } from "../subscription"
import { executeAlert } from "../../application/states/toast-alert"
import { useLoadingService } from "../loading"

export const useConsumptionService = () => {
  const { getUserConsumptionApi, createConsumptionLockApi } = useConsumptionApi()
  const { setSubscription } = useSubscriptionService()
  const { startLoading, stopLoading } = useLoadingService()

  const userState = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  const setUserListWithConsumption = useCallback(() => {
    const userListWithConsumptionPromises: Promise<{
      userId: string,
      consumption: number,
      consumptionLimit?: number
    }>[] = []
    startLoading()
    for (let i = 0; i < userState.userList.length; i++) {
      const user = userState.userList[i]
      if (user) {
        const result = getUserConsumptionApi({
          userId: user.id
        })
        userListWithConsumptionPromises.push(result)
      }
    }

    Promise.all(userListWithConsumptionPromises).then((result) => {
      const userListWithConsumption: {
        userId: string,
        consumption: number,
        consumptionLimit?: number
      }[] = []
      for (let i = 0; i < result.length; i++) {
        const userConsumption = result[i]
        if (userConsumption) {
          userListWithConsumption.push({
            userId: userConsumption.userId,
            consumption: userConsumption.consumption,
            consumptionLimit: userConsumption.consumptionLimit
          })
        }
      }
      dispatch(setUserListWithConsumptionState({
        userListWithConsumption: userListWithConsumption
      }))
    })
    stopLoading()
  }, [startLoading, stopLoading, userState.userList, getUserConsumptionApi, dispatch])

  const createConsumptionLock = useCallback((data: {
    userId?: string
    companyId?: string
    limit: number
  }) => {
    createConsumptionLockApi({
      createdBy: userState.id,
      limit: data.limit,
      companyId: data.companyId,
      userId: data.userId
    }).then(() => {
      setUserListWithConsumption()
      setSubscription()
      dispatch(executeAlert({
        message: 'Limitação de consumo criada com sucesso',
        type: 'success'
      }))
    })
  }, [createConsumptionLockApi, userState.id, setUserListWithConsumption, setSubscription, dispatch])

  return {
    setUserListWithConsumption,
    createConsumptionLock
  }
}