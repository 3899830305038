import { useCallback } from "react"
import { setEconomicActivityListState, setEconomicActivitySearchTermState, setEconomicActivityState, setEconomicActivityValueState } from "../../application/states/economic-activity"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useEconomicActivityApi } from "../../infra/economic-activity-api"
import { useLoadingService } from "../loading"
import { IEconomicActivity } from "../../application/models/IEconomicActivity"

export const useEconomicActivityService = () => {
  const dispatch = useAppDispatch()
  const { startLoading, stopLoading } = useLoadingService()
  const { findEconomicActivityBySearchTerm, findEconomicActivityByValue } = useEconomicActivityApi()
  const economicActivityState = useAppSelector((state) => state.economicActivity)
  const selectedEconomicActivities = useAppSelector((state) => state.project.selectedFilter?.economicActivity)
  const setSearchTerm = useCallback((data: {
    searchTerm: string
  }) => {
    dispatch(setEconomicActivitySearchTermState({
      searchTerm: data.searchTerm
    }))
  }, [dispatch])

  const setValue = useCallback((data: {
    value: string
  }) => {
    dispatch(setEconomicActivityValueState({
      value: data.value
    }))
  }, [dispatch])

  const searchEconomicActivity: () => void = useCallback(() => {
    startLoading()
    findEconomicActivityBySearchTerm({
      searchTerm: economicActivityState.searchTerm
    }).then((economicActivityList) => {
      const notSelectedEconomicActivities: IEconomicActivity[] = economicActivityList.filter((economicActivity) => {
        if (selectedEconomicActivities?.find((selectedEconomicActivity) => selectedEconomicActivity.cnaeCodigo === economicActivity.cnaeCodigo)) {
          return false
        } else {
          return economicActivity
        }
      })

      dispatch(setEconomicActivityListState({
        economicActivityList: notSelectedEconomicActivities
      }))
      stopLoading()
    }).catch((error) => {
      stopLoading()
    })
  }, [startLoading, findEconomicActivityBySearchTerm, economicActivityState.searchTerm, dispatch, stopLoading, selectedEconomicActivities])

  const searchEconomicActivityValue: () => void = useCallback(() => {
    startLoading()
    findEconomicActivityByValue({
      value: economicActivityState.value
    }).then((economicActivity) => {
      dispatch(setEconomicActivityState({
        economicActivity: economicActivity
      }))
      stopLoading()
    }).catch((error) => {
      stopLoading()
    })
  }, [startLoading, stopLoading, economicActivityState.value, findEconomicActivityByValue, dispatch])

  const resetEconomicActivityList = useCallback(() => {
    dispatch(setEconomicActivityListState({
      economicActivityList: []
    }))
  }, [dispatch])

  return {
    setSearchTerm,
    setValue,
    searchEconomicActivity,
    searchEconomicActivityValue,
    resetEconomicActivityList
  }
}