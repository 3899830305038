import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBlackList, IFieldError, IFieldProps, ISelectedBlackList } from '../../models';

export const validateBlackListForm = (data: {
  name: string,
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []

  if (!data.name) {
    errors.push({
      field: 'name',
      fieldError: {
        message: 'Nome é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }

  return errors
}

interface IBlackListState {
  /* Undefined deve ser o estado onde o usuário ainda não foi validado */
  openFormDialog: boolean,
  matchCode: {
    selectedColumn: string,
    distinctValues: number,
    validValues: number
  },
  fileData: {
    name: string,
    size: string,
    columns: string[],
    type: string,
    data: any[]
  },
  blackLists: IBlackList[]
  selectedBlackList?: ISelectedBlackList
  fileListData: {
    fileListData: string[]
    fileListTableValues: {
      fileListValidData: string[]
      fileListInvalidData: string[]
      fileListRepeatedData: string[]
    }
  }
  blackListForm: {
    action: 'edit' | 'create',
    fields: {
      name: IFieldProps
    }
  }
}

const initialState: IBlackListState = {
  openFormDialog: false,
  fileListData: {
    fileListData: [],
    fileListTableValues: {
      fileListInvalidData: [],
      fileListRepeatedData: [],
      fileListValidData: []
    }
  },
  matchCode: {
    selectedColumn: '',
    distinctValues: 0,
    validValues: 0
  },
  fileData: {
    name: '',
    size: '',
    columns: [],
    type: '',
    data: []
  },
  blackLists: [],
  blackListForm: {
    action: 'create',
    fields: {
      name: {
        isValid: true,
        error: undefined,
      }
    }
  }
};

const blackListSlice = createSlice({
  name: 'blackList',
  initialState,
  reducers: {
    setBlackLists(state, action: PayloadAction<{
      blackLists: IBlackList[]
    }>) {
      state.blackLists = action.payload.blackLists
    },
    setSelectedBlackList(state, action: PayloadAction<{
      blackList?: ISelectedBlackList
    }>) {
      state.selectedBlackList = action.payload.blackList
    },
    setBlackListName(state, action: PayloadAction<{
      value: string,
    }>) {
      if (!state.selectedBlackList) return
      state.selectedBlackList.name = action.payload.value
    },
    setBlackListList(state, action: PayloadAction<{
      value: string[],
    }>) {
      if (!state.selectedBlackList) return
      state.selectedBlackList.list = action.payload.value
    },
    setBlackListFileDataList(state, action: PayloadAction<{
      value: string[],
    }>) {
      if (!state.selectedBlackList) return
      state.fileListData.fileListData = action.payload.value
    },
    setBlackListFileValidDataList(state, action: PayloadAction<{
      value: string[],
    }>) {
      if (!state.selectedBlackList) return
      state.fileListData.fileListTableValues.fileListValidData = action.payload.value
    },
    setBlackListFileRepeatedDataList(state, action: PayloadAction<{
      value: string[],
    }>) {
      if (!state.selectedBlackList) return
      state.fileListData.fileListTableValues.fileListRepeatedData = action.payload.value
    },
    setBlackListFileInvalidDataList(state, action: PayloadAction<{
      value: string[],
    }>) {
      if (!state.selectedBlackList) return
      state.fileListData.fileListTableValues.fileListInvalidData = action.payload.value
    },
    addItemBlackListList(state, action: PayloadAction<{
      value: string,
    }>) {
      if (!state.selectedBlackList) return
      /* If the value is already in the list, do not add it again */
      if (state.selectedBlackList.list.includes(action.payload.value)) return
      state.selectedBlackList.list.push(action.payload.value)
    },
    removeItemBlackListList(state, action: PayloadAction<{
      value: string,
    }>) {
      if (!state.selectedBlackList) return
      state.selectedBlackList.list = state.selectedBlackList.list.filter(item => item !== action.payload.value)
    },
    editItemBlackListList(state, action: PayloadAction<{
      value: string,
      newValue: string
    }>) {
      if (!state.selectedBlackList) return

      /* If the value is already in the list, do not add it again */
      if (state.selectedBlackList.list.includes(action.payload.newValue)) return
      state.selectedBlackList.list = state.selectedBlackList.list.map(item => item === action.payload.value ? action.payload.newValue : item)
    },
    setBlackListSelectedColumn(state, action: PayloadAction<{
      selectedColumn: string
    }>) {
      state.matchCode.selectedColumn = action.payload.selectedColumn
    },
    setBlackListDistinctValues(state, action: PayloadAction<{
      distinctValues: number
    }>) {
      state.matchCode.distinctValues = action.payload.distinctValues
    },
    setBlackListValidValues(state, action: PayloadAction<{
      validValues: number
    }>) {
      state.matchCode.validValues = action.payload.validValues
    },
    setBlackListFileData(state, action: PayloadAction<{
      fileData: {
        name: string,
        size: string,
        columns: string[],
        type: string,
        data: any[]
      }
    }>) {
      state.fileData = action.payload.fileData
    },
    setBlackListFormErrors(state, action: PayloadAction<{
      errors: {
        field: string,
        fieldError: IFieldError
      }[]
    }>) {
      action.payload.errors.forEach(error => {
        (state.blackListForm.fields as any)[error.field].error = error.fieldError;
        (state.blackListForm.fields as any)[error.field].isValid = false
      })
    },
    resetBlackListForm(state) {
      state.blackListForm = initialState.blackListForm
      state.fileData = initialState.fileData
      state.matchCode = initialState.matchCode
      state.selectedBlackList = initialState.selectedBlackList
    },
    setBlackListStatus(state, action: PayloadAction<{
      status: boolean
    }>) {
      if (!state.selectedBlackList) return

      state.selectedBlackList.status = action.payload.status
    },
    setBlackListFormDialogOpenState(state, action: PayloadAction<{
      openFormDialog: boolean
    }>) {
      state.openFormDialog = action.payload.openFormDialog
    },
    resetBlackListFileData(state) {
      state.fileData = initialState.fileData
      state.matchCode = initialState.matchCode
    },
    resetSelectedBlackListState(state) {
      state.selectedBlackList = initialState.selectedBlackList
    }
  }
});

export const {
  setBlackLists,
  setSelectedBlackList,
  setBlackListName,
  addItemBlackListList,
  removeItemBlackListList,
  editItemBlackListList,
  setBlackListList,
  setBlackListSelectedColumn,
  resetBlackListForm,
  setBlackListDistinctValues,
  setBlackListValidValues,
  setBlackListFileData,
  setBlackListFileDataList,
  setBlackListFormErrors,
  setBlackListStatus,
  setBlackListFormDialogOpenState,
  setBlackListFileInvalidDataList,
  setBlackListFileRepeatedDataList,
  setBlackListFileValidDataList,
  resetBlackListFileData,
  resetSelectedBlackListState
} = blackListSlice.actions;
export const blackListReducer = blackListSlice.reducer;