import { Typography, Button, Box, Stepper, Step, StepLabel, Grid } from "@mui/material"
import * as React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../application/states/hooks";
import { usePasswordRecoveryService } from "../../../../use-cases/password-recovery";
import { ChangePasswordForm } from "./change-password-form";
import { RequestPasswordForm } from "./request-password-form";
import { VerificationCodeForm } from "./verification-code-form";
import { useResaleConfigService } from "../../../../use-cases/resale-config";
import { getSleep } from "../../../../utils";

const steps = ['Solicitar nova senha', 'Validar código', 'Redefinir a senha'];

export const RequestNewPasswordPage = () => {
  const activeStep = useAppSelector(state => state.passwordRecovery.activeStep)
  const formError = useAppSelector(state => state.passwordRecovery.error)
  const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
  const { getResaleConfigByDomain } = useResaleConfigService()
  const [loading, setLoading] = useState(true)

  const {
    prevStep,
    requestNewPassword,
    validateVerificationCode,
    changePasswordWithToken
  } = usePasswordRecoveryService()
  const navigate = useNavigate()

  useEffect(() => {
    const loadTheme = async () => {
      try {
        getResaleConfigByDomain({
          domain: window.location.host
        })
      } catch (error) {

      } finally {
        await getSleep(500)
        setLoading(false)
      }
    }

    loadTheme()
  }, [getResaleConfigByDomain])

  useEffect(() => {
    if (loading === false) document.title = `${resaleConfig?.id !== '' ? resaleConfig?.name : 'Lurik'} - Recuperar senha`

  }, [loading, resaleConfig?.id, resaleConfig?.name])

  return (
    <>
      {
        loading === true ? <></>
          : <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box my={3}>

                  {activeStep === 0 && (
                    <RequestPasswordForm />
                  )}
                  {activeStep === 1 && (
                    <VerificationCodeForm />
                  )}
                  {activeStep === 2 && (
                    <ChangePasswordForm />
                  )}
                </Box>
                {
                  formError && (
                    <Box sx={{ mt: 3 }}>
                      <Typography color="error" align="center">
                        {formError.message}
                      </Typography>
                    </Box>
                  )
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={prevStep}
                    sx={{ mr: 1 }}
                  >
                    Voltar
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={() => {
                    if (activeStep === 0) {
                      requestNewPassword()
                    } else if (activeStep === 1) {
                      validateVerificationCode()
                    } else if (activeStep === 2) {
                      changePasswordWithToken()
                    }
                  }}>
                    {activeStep === steps.length - 1 ? 'Enviar' : 'Próximo'}
                  </Button>
                </Box>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate('/authentication/login')
                      }}
                      sx={{
                        mt: 3,
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: '1rem',
                      }}
                    >
                      Clique aqui para fazer login
                    </Button>


                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Box>
      }
    </>
  )
}