import { useCallback } from "react"
import { useLoadingService } from "../loading"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useModalContainerService } from "../modal-container"
import { Box, Grid, Typography, Button } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { executeAlert } from "../../application/states/toast-alert"
import { useCepListApi, useUserApi } from "../../infra"
import { setCepListSelectedColumn, setCepListDistinctValues, setCepListValidValues, setCepListFileDataList, setCepListFileData, setCepListName, resetCepListForm, setCepListList, setCepLists, setCepListFormErrors, setCepListStatus, setSelectedCepList, addItemCepListList, editItemCepListList, removeItemCepListList, validateCepListForm, setCepListFileValidDataList, setCepListFileRepeatedDataList, setCepListFileInvalidDataList, resetCepListFileData } from "../../application/states/cep-list"
import { ICepList } from "../../application/models"
import { cepNumerico, validateCep, formatCep, darkenColor } from "../../utils"
import { getDistinctValues } from "../data-enrichment"
import { theme } from "../../presentation/styles"


export const useCepListService = () => {
    const { createCepListApi, findCepListByCompanyId, updateCepListApi } = useCepListApi()
    const dispatch = useAppDispatch()
    const { findById } = useUserApi()
    const { openModal, closeModal } = useModalContainerService()
    const userState = useAppSelector((state) => state.user)
    const cepListState = useAppSelector((state) => state.cepList)
    const { startLoading, stopLoading } = useLoadingService()

    const findCepKeyInArray = useCallback((array: any[]) => {
        for (let i = 0; i < array.length; i++) {
            const keys = Object.keys(array[i])
            for (let k = 0; k < keys.length; k++) {
                if (validateCep(cepNumerico(`${array[i][keys[k]]}`))) {
                    return keys[k]
                }
            }
        }
        return null
    }, [])

    const resetFileData = useCallback(() => {
        dispatch(resetCepListFileData())
    }, [dispatch])

    const selectedColumn = useCallback((data: {
        selectedColumn: string,
        data?: any[]
    }) => {
        startLoading()
        dispatch(setCepListSelectedColumn({
            selectedColumn: data.selectedColumn
        }))
        /* performatic code to get distinct values */
        let dataToUse = cepListState.fileData.data
        if (data.data) {
            dataToUse = data.data
        }

        const distinctValues = getDistinctValues(dataToUse.map((item: any) => item[data.selectedColumn]))

        dispatch(setCepListDistinctValues({
            distinctValues: distinctValues.length
        }))

        const filteredList = distinctValues.filter((item) => {
            const replacedItem = cepNumerico(String(item))
            return !cepListState.selectedCepList?.list?.includes(replacedItem)
        })

        const distinctValidValues = distinctValues.filter((value: any) => {
            let isValid = validateCep(cepNumerico(value))
            if (isValid) {
                return true
            } else {
                return false
            }
        })

        const repeatedList = distinctValues.filter((item) => {
            const replacedItem = cepNumerico(item)
            return cepListState.selectedCepList?.list?.includes(replacedItem)
        })

        const validValues = filteredList.filter((value: any) => {
            let isValid = validateCep(cepNumerico(value))
            if (isValid) {
                return true
            } else {
                return false
            }
        })

        const invalidValues = filteredList.filter((value: any) => {
            let isValid = validateCep(cepNumerico(value))
            if (!isValid) {
                return true
            } else {
                return false
            }
        })

        dispatch(setCepListValidValues({
            validValues: distinctValidValues.length
        }))
        dispatch(setCepListFileDataList({
            value: validValues
        }))
        dispatch(setCepListFileValidDataList({
            value: validValues
        }))
        dispatch(setCepListFileInvalidDataList({
            value: invalidValues
        }))
        dispatch(setCepListFileRepeatedDataList({
            value: repeatedList
        }))

        stopLoading()
    }, [dispatch, startLoading, stopLoading, cepListState.fileData.data, cepListState.selectedCepList?.list])

    const setFile = useCallback((data: {
        name: string,
        size: string,
        columns: string[],
        type: string,
        data: any[]
    }) => {
        startLoading()
        dispatch(setCepListFileData({
            fileData: {
                columns: data.columns,
                data: data.data,
                name: data.name,
                size: data.size,
                type: data.type
            }
        }))

        const cepKey = findCepKeyInArray(data.data)

        if (cepKey) {
            const replacedFileData = []
            for (let i = 0; i < data.data.length; i++) {
                const cep = data.data[i]

                if (typeof cep[cepKey] === 'number') {
                    const replacedCnpj = cepNumerico(String(cep[cepKey]) as string)

                    replacedFileData.push(replacedCnpj)
                }
                if (typeof cep[cepKey] === 'string') {
                    const replacedCnpj = cepNumerico(String(cep[cepKey]))

                    replacedFileData.push(replacedCnpj)
                }
            }

            const filteredList = replacedFileData.filter((item) => {
                return !cepListState.selectedCepList?.list?.includes(item)
            })

            const repeatedList = replacedFileData.filter((item) => {
                const replacedItem = cepNumerico(item)
                return cepListState.selectedCepList?.list?.includes(replacedItem)
            })

            const validValues = filteredList.filter((value: string) => {
                const numericCep = cepNumerico(value)

                let isValid = validateCep(numericCep)
                if (isValid) {
                    return numericCep
                } else {
                    return false
                }
            })
            if (validValues.length < 1) {
                if (repeatedList.length > 0) {
                    stopLoading()
                    openModal({
                        content: <>
                            <Box p={3}>
                                <Grid container alignItems={"center"} flexDirection="column">
                                    <Grid item>
                                        <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                                            Encontramos apenas CEPs já inseridos na sua lista atual.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" sx={{ mt: 2 }} align="center">
                                            Não encontramos nenhuma coluna com CEPs válidos e novos no arquivo. Selecione um arquivo com CEPs válidos.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Box my={3}>
                                            <Button variant="contained"
                                                sx={{
                                                    mt: 2,
                                                    '&:hover': {
                                                        backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                                    }
                                                }}
                                                onClick={() => {
                                                    closeModal()
                                                    resetFileData()
                                                }}
                                            >
                                                OK
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    })
                } else {
                    stopLoading()
                    openModal({
                        content: <>
                            <Box p={3}>
                                <Grid container alignItems={"center"} flexDirection="column">
                                    <Grid item>
                                        <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                                            Não encontramos CEPs válidos no seu arquivo!
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" sx={{ mt: 2 }} align="center">
                                            Não encontramos nenhuma coluna com CEPs válidos e novos. Selecione outro arquivo.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Box my={3}>
                                            <Button variant="contained" sx={{
                                                mt: 2,
                                                '&:hover': {
                                                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                                }
                                            }}
                                                onClick={() => {
                                                    closeModal()
                                                    resetFileData()
                                                }}
                                            >
                                                OK
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    })
                }
            } else {
                selectedColumn({
                    selectedColumn: cepKey,
                    data: data.data
                })
                openModal({
                    content: <>
                        <Box p={3}>
                            <Grid container alignItems={"center"} flexDirection="column">
                                <Grid item>
                                    <CheckCircleIcon color="primary" sx={{ fontSize: 100 }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                                        Arquivo <strong>{data.name}</strong> carregado com sucesso!
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" sx={{ mt: 2 }} align="center">
                                        Selecionamos a coluna <strong>{cepKey}</strong> como a coluna que contém os CEPs. Caso esta não seja a coluna correta, selecione a correta no menu indicado.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box my={3}>
                                        <Button variant="contained"
                                            sx={{
                                                mt: 2,
                                                '&:hover': {
                                                    backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                                }
                                            }}
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >
                                            OK
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                })
            }
        } else {
            stopLoading()
            openModal({
                content: <>
                    <Box p={3}>
                        <Grid container alignItems={"center"} flexDirection="column">
                            <Grid item>
                                <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                                    Não encontramos CEPs válidos no seu arquivo!
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                                    Não encontramos nenhuma coluna com CEPs válidos. Selecione outro arquivo.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box my={3}>
                                    <Button variant="contained"
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: darkenColor(theme.palette.primary.main, 10)
                                            }
                                        }}
                                        onClick={() => {
                                            closeModal()
                                            resetFileData()
                                        }}
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            })
        }
    }, [startLoading, dispatch, findCepKeyInArray, cepListState.selectedCepList?.list, stopLoading, openModal, closeModal, resetFileData, selectedColumn])

    const setName = useCallback((data: {
        name: string
    }) => {
        dispatch(setCepListName({
            value: data.name
        }))
    }, [dispatch])

    const reset = useCallback(() => {
        dispatch(resetCepListForm())
    }, [dispatch])

    const addFileListToCepList = useCallback(() => {
        startLoading()
        /* Do not insert if there is no list selected */
        if (!cepListState.selectedCepList?.list) {
            return
        }
        /* Do not insert repeated values */
        const filteredList = cepListState.fileListData.fileListData.filter((item) => {
            return !cepListState.selectedCepList?.list?.includes(item)
        })
        if (filteredList.length > 0) {
            dispatch(setCepListList({
                value: [
                    ...cepListState.selectedCepList?.list,
                    ...cepListState.fileListData.fileListTableValues.fileListValidData,
                ]
            }))
            dispatch(executeAlert({
                message: 'Lista adicionada com sucesso!',
                type: 'success'
            }))
        }
        stopLoading()
    }, [startLoading, stopLoading, cepListState.fileListData, cepListState.selectedCepList?.list, dispatch])

    const selectCepList = useCallback((data: {
        cepList?: ICepList
    }) => {
        dispatch(setSelectedCepList({
            cepList: data.cepList
        }))
    }, [dispatch])

    const selectCepListToEdit = useCallback((data: {
        cepListId: string
    }) => {
        const cepList = cepListState.cepLists.find((item) => item.id === data.cepListId)

        if (cepList) {
            selectCepList({
                cepList: cepList
            })
        }
    }, [cepListState.cepLists, selectCepList])

    const addItemToList = useCallback((data: {
        item: string
    }) => {
        const replacedCep = data.item.replace(/\D/g, '')?.toString() ?? ''

        if (replacedCep.length > 8 || replacedCep === '') {
            dispatch(executeAlert({
                message: 'CEP inválido.',
                type: 'error'
            }))
        } else {
            const cepPadronizado = formatCep(data.item)

            if (cepListState.selectedCepList!.list.includes(cepPadronizado)) {
                dispatch(executeAlert({
                    message: 'Este CEP já se encontra na lista.',
                    type: 'error'
                }))
            }

            if (cepListState.selectedCepList!.list.includes(cepPadronizado) === false) {
                dispatch(
                    addItemCepListList({
                        value: cepPadronizado
                    })
                )
                dispatch(executeAlert({
                    message: 'CEP adicionado com sucesso.',
                    type: 'success'
                }))
            }
        }


    }, [dispatch, cepListState.selectedCepList])

    const editItemList = useCallback((data: {
        value: string,
        newValue: string
    }) => {
        const novoCepPadronizado = cepNumerico(data.newValue)

        if (validateCep(novoCepPadronizado)) {
            if (cepListState.selectedCepList!.list.includes(novoCepPadronizado)) {
                dispatch(executeAlert({
                    message: 'Este CEP já se encontra na lista de deduplicação.',
                    type: 'error'
                }))
            }

            if (cepListState.selectedCepList!.list.includes(novoCepPadronizado) === false) {
                dispatch(
                    editItemCepListList({
                        value: data.value,
                        newValue: novoCepPadronizado
                    })
                )
                dispatch(executeAlert({
                    message: 'CEP atualizado com sucesso.',
                    type: 'success'
                }))
            }
        } else {
            dispatch(executeAlert({
                message: 'CEP inválido',
                type: 'error'
            }))
        }
    }, [dispatch, cepListState.selectedCepList])

    const removeItemList = useCallback((data: {
        value: string
    }) => {
        dispatch(removeItemCepListList({
            value: data.value
        }))
        dispatch(executeAlert({
            message: 'CEP removido com sucesso.',
            type: 'success'
        }))
    }, [dispatch])

    const setupCepList = useCallback(() => {
        if (userState.companyId === '' || !userState.companyId) {
            return
        }

        startLoading()
        findCepListByCompanyId({
            companyId: userState.companyId
        }).then(async (res) => {
            const listWIthUserNames: ICepList[] = []
            for (let i = 0; i < res.length; i++) {
                const user = await findById({
                    userId: res[i].createdBy
                })

                listWIthUserNames.push({
                    ...res[i],
                    createdBy: user?.name ?? 'Usuário não encontrado'
                })
            }
            dispatch(setCepLists({
                cepLists: listWIthUserNames
            }))
            stopLoading()
        })
    }, [startLoading, findCepListByCompanyId, userState.companyId, dispatch, stopLoading, findById])

    const createCepList = useCallback(() => {
        const validateCepList = validateCepListForm({
            name: cepListState.selectedCepList?.name ?? '',
        })
        if (validateCepList.length > 0) {
            dispatch(executeAlert({
                message: validateCepList[0].fieldError.message,
                type: 'error'
            }))
            dispatch(setCepListFormErrors({
                errors: validateCepList
            }))
            return
        } else {
            createCepListApi({
                createdBy: userState.id,
                name: cepListState.selectedCepList?.name ?? '',
            }).then((cepList) => {
                selectCepList({
                    cepList: cepList
                })
                setupCepList()
            })
        }

    }, [cepListState.selectedCepList?.name, createCepListApi, selectCepList, userState.id, dispatch, setupCepList])



    const updateCepList = useCallback(() => {
        const validateCepList = validateCepListForm({
            name: cepListState.selectedCepList?.name ?? '',
        })
        if (validateCepList.length > 0) {
            dispatch(executeAlert({
                message: validateCepList[0].fieldError.message,
                type: 'error'
            }))
            dispatch(setCepListFormErrors({
                errors: validateCepList
            }))
            return
        } else {
            updateCepListApi({
                id: cepListState.selectedCepList?.id ?? '',
                name: cepListState.selectedCepList?.name ?? '',
                list: cepListState.selectedCepList?.list ?? [],
            }).then((cepList) => {
                dispatch(executeAlert({
                    message: 'Alterações salvas.',
                    type: 'success'
                }))
                selectCepList({
                    cepList: cepList
                })
                setupCepList()
            })
        }

    }, [cepListState.selectedCepList?.id, cepListState.selectedCepList?.list, cepListState.selectedCepList?.name, dispatch, selectCepList, setupCepList, updateCepListApi])

    const activeCepListStatus = useCallback((data: {
        status: boolean
    }) => {
        dispatch(setCepListStatus({
            status: data.status
        }))
    }, [dispatch])

    const disableCepListStatus = useCallback((data: {
        status: boolean
    }) => {
        dispatch(setCepListStatus({
            status: data.status
        }))
    }, [dispatch])

    const verifyCepListFileData = useCallback(() => {
        startLoading()
        const validCeps = cepListState.fileListData.fileListTableValues.fileListValidData
        const invalidCeps = cepListState.fileListData.fileListTableValues.fileListInvalidData

        if (validCeps.length < 1 && invalidCeps.length > 0) {
            stopLoading()
            openModal({
                content: <>
                    <Box p={3}>
                        <Grid container alignItems={"center"} flexDirection="column">
                            <Grid item>
                                <ErrorIcon color="secondary" sx={{ fontSize: 100 }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" sx={{ mt: 2 }} align="center" textTransform="uppercase">
                                    A coluna <strong>{cepListState.matchCode.selectedColumn}</strong> não possui CEPs válidos.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" sx={{ mt: 2 }} align="center">
                                    Por favor, selecione uma coluna com CEPs válidos, ou outro arquivo.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Box my={3}>
                                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => {
                                        closeModal()
                                    }}>
                                        OK
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            })
            return false
        } else {
            stopLoading()
            return true
        }
    }, [startLoading, stopLoading, cepListState.fileListData.fileListTableValues.fileListInvalidData, cepListState.fileListData.fileListTableValues.fileListValidData, cepListState.matchCode.selectedColumn, closeModal, openModal])

    return {
        selectedColumn,
        setFile,
        setName,
        reset,
        addFileListToCepList,
        selectCepList,
        addItemToList,
        selectCepListToEdit,
        createCepList,
        setupCepList,
        updateCepList,
        editItemList,
        removeItemList,
        activeCepListStatus,
        disableCepListStatus,
        resetFileData,
        verifyCepListFileData
    }
}