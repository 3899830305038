import { SliderValueLabelProps, Typography, Tooltip } from "@mui/material";
import { NumericFormat } from "react-number-format";

export function NumberFormatCustom(props: any) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }
            }
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
        />
    );
}

export function valuetext(value: number) {
    return `${value}`;
}

export function ValueLabelComponent(props: SliderValueLabelProps) {
    const { children, value } = props;

    const formattedValue = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    return (
        <Tooltip open={true} enterTouchDelay={0} placement="top" title={
            <Typography variant="caption" color="white" fontWeight={"bold"}>
                {formattedValue}
            </Typography>
        }>
            {children}
        </Tooltip>
    );
}