import styled, { keyframes } from 'styled-components';

interface PreLoaderProps {
    primaryColor?: string;
    secondaryColor?: string;
    tertiaryColor?: string;
}

const spin = keyframes`
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`
export const PreLoaderStylesProvider = styled.div<PreLoaderProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & > div {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: ${({ primaryColor }) => primaryColor || '#723989'};
        -webkit-animation: ${spin} 2s linear infinite;
        animation: ${spin} 2s linear infinite;
        &::before{
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: ${({ secondaryColor }) => secondaryColor || '#ee9338'};
            -webkit-animation: ${spin} 3s linear infinite;
            animation: ${spin} 3s linear infinite;
        }
        &::after{
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: ${({ tertiaryColor }) => tertiaryColor || '#ae4035'};
            -webkit-animation: ${spin} 1.5s linear infinite;
            animation: ${spin} 1.5s linear infinite;
        }
    }
`