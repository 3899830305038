import { styled, keyframes } from '@mui/material/styles'
import { darkenColor } from '../../../../../utils';

const gradientAnimation = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

export const BackgroundGradientAnimation = styled('div')(({ theme }) => ({
	background: `linear-gradient(-45deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main}, ${theme.palette.primary.main !== '#723989' ? darkenColor(theme.palette.primary.main, 10) : '#2f2664'})`,
	backgroundSize: `400% 400%`,
	animation: `${gradientAnimation} 9s ease infinite`,
	height: `100vh`,
	width: `100vw`,
	position: `fixed`,
	zIndex: -1
}));
