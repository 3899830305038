import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProjectExport } from '../../models';

interface IProjectExportState {
  page: number;
  loading: boolean;
  pageSize: number;
  total: number;
  exportList: Array<{
    projectExport: IProjectExport,
    fileExists: boolean
  }>

}

const initialState: IProjectExportState = {
  exportList: [],
  page: 1,
  pageSize: 10,
  total: 0,
  loading: false,
};

const projectExportSlice = createSlice({
  name: 'projectExport',
  initialState,
  reducers: {
    setExportListState: (state, action: PayloadAction<{
      exportList: {
        projectExport: IProjectExport,
        fileExists: boolean,
      }[],
      page: number,
      total: number,
    }>) => {
      state.exportList = action.payload.exportList;
      state.page = action.payload.page;
      state.total = action.payload.total;
    },
    setProjectExportPageState: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setProjectExportListLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }


  },
});

export const {
  setExportListState,
  setProjectExportPageState,
  setProjectExportListLoadingState,
} = projectExportSlice.actions;


export const projectExportReducer = projectExportSlice.reducer;