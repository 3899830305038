import { useState } from "react"
import { darkenColor } from "../../../../../utils"

export const useHoverFunctions = () => {
    const [darkerACPrimaryColor, setDarkerACPrimaryColor] = useState("")
    const [darkerALPrimaryColor, setDarkerALPrimaryColor] = useState("")
    const [darkerAMPrimaryColor, setDarkerAMPrimaryColor] = useState("")
    const [darkerAPPrimaryColor, setDarkerAPPrimaryColor] = useState("")
    const [darkerBAPrimaryColor, setDarkerBAPrimaryColor] = useState("")
    const [darkerCEPrimaryColor, setDarkerCEPrimaryColor] = useState("")
    const [darkerDFPrimaryColor, setDarkerDFPrimaryColor] = useState("")
    const [darkerESPrimaryColor, setDarkerESPrimaryColor] = useState("")
    const [darkerGOPrimaryColor, setDarkerGOPrimaryColor] = useState("")
    const [darkerMAPrimaryColor, setDarkerMAPrimaryColor] = useState("")
    const [darkerMGPrimaryColor, setDarkerMGPrimaryColor] = useState("")
    const [darkerMSPrimaryColor, setDarkerMSPrimaryColor] = useState("")
    const [darkerMTPrimaryColor, setDarkerMTPrimaryColor] = useState("")
    const [darkerPAPrimaryColor, setDarkerPAPrimaryColor] = useState("")
    const [darkerPBPrimaryColor, setDarkerPBPrimaryColor] = useState("")
    const [darkerPEPrimaryColor, setDarkerPEPrimaryColor] = useState("")
    const [darkerPIPrimaryColor, setDarkerPIPrimaryColor] = useState("")
    const [darkerPRPrimaryColor, setDarkerPRPrimaryColor] = useState("")
    const [darkerRJPrimaryColor, setDarkerRJPrimaryColor] = useState("")
    const [darkerRNPrimaryColor, setDarkerRNPrimaryColor] = useState("")
    const [darkerROPrimaryColor, setDarkerROPrimaryColor] = useState("")
    const [darkerRRPrimaryColor, setDarkerRRPrimaryColor] = useState("")
    const [darkerRSPrimaryColor, setDarkerRSPrimaryColor] = useState("")
    const [darkerSCPrimaryColor, setDarkerSCPrimaryColor] = useState("")
    const [darkerSEPrimaryColor, setDarkerSEPrimaryColor] = useState("")
    const [darkerSPPrimaryColor, setDarkerSPPrimaryColor] = useState("")
    const [darkerTOPrimaryColor, setDarkerTOPrimaryColor] = useState("")

    const handleHoverAC = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerACPrimaryColor(darkerColor);
    }

    const handleLeaveAC = () => {
        setDarkerACPrimaryColor('');
    }

    const handleHoverAL = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerALPrimaryColor(darkerColor);
    }

    const handleLeaveAL = () => {
        setDarkerALPrimaryColor('');
    }
    const handleHoverAM = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerAMPrimaryColor(darkerColor);
    }

    const handleLeaveAM = () => {
        setDarkerAMPrimaryColor('');
    }
    const handleHoverAP = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerAPPrimaryColor(darkerColor);
    }

    const handleLeaveAP = () => {
        setDarkerAPPrimaryColor('');
    }
    const handleHoverBA = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerBAPrimaryColor(darkerColor);
    }

    const handleLeaveBA = () => {
        setDarkerBAPrimaryColor('');
    }
    const handleHoverCE = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerCEPrimaryColor(darkerColor);
    }

    const handleLeaveCE = () => {
        setDarkerCEPrimaryColor('');
    }
    const handleHoverDF = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerDFPrimaryColor(darkerColor);
    }

    const handleLeaveDF = () => {
        setDarkerDFPrimaryColor('');
    }
    const handleHoverES = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerESPrimaryColor(darkerColor);
    }

    const handleLeaveES = () => {
        setDarkerESPrimaryColor('');
    }
    const handleHoverGO = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerGOPrimaryColor(darkerColor);
    }

    const handleLeaveGO = () => {
        setDarkerGOPrimaryColor('');
    }
    const handleHoverMA = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerMAPrimaryColor(darkerColor);
    }

    const handleLeaveMA = () => {
        setDarkerMAPrimaryColor('');
    }
    const handleHoverMG = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerMGPrimaryColor(darkerColor);
    }

    const handleLeaveMG = () => {
        setDarkerMGPrimaryColor('');
    }
    const handleHoverMS = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerMSPrimaryColor(darkerColor);
    }

    const handleLeaveMS = () => {
        setDarkerMSPrimaryColor('');
    }
    const handleHoverMT = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerMTPrimaryColor(darkerColor);
    }

    const handleLeaveMT = () => {
        setDarkerMTPrimaryColor('');
    }
    const handleHoverPA = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerPAPrimaryColor(darkerColor);
    }

    const handleLeavePA = () => {
        setDarkerPAPrimaryColor('');
    }
    const handleHoverPB = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerPBPrimaryColor(darkerColor);
    }

    const handleLeavePB = () => {
        setDarkerPBPrimaryColor('');
    }
    const handleHoverPE = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerPEPrimaryColor(darkerColor);
    }

    const handleLeavePE = () => {
        setDarkerPEPrimaryColor('');
    }
    const handleHoverPI = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerPIPrimaryColor(darkerColor);
    }

    const handleLeavePI = () => {
        setDarkerPIPrimaryColor('');
    }
    const handleHoverPR = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerPRPrimaryColor(darkerColor);
    }

    const handleLeavePR = () => {
        setDarkerPRPrimaryColor('');
    }
    const handleHoverRJ = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerRJPrimaryColor(darkerColor);
    }

    const handleLeaveRJ = () => {
        setDarkerRJPrimaryColor('');
    }
    const handleHoverRN = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerRNPrimaryColor(darkerColor);
    }

    const handleLeaveRN = () => {
        setDarkerRNPrimaryColor('');
    }
    const handleHoverRO = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerROPrimaryColor(darkerColor);
    }

    const handleLeaveRO = () => {
        setDarkerROPrimaryColor('');
    }
    const handleHoverRR = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerRRPrimaryColor(darkerColor);
    }

    const handleLeaveRR = () => {
        setDarkerRRPrimaryColor('');
    }
    const handleHoverRS = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerRSPrimaryColor(darkerColor);
    }

    const handleLeaveRS = () => {
        setDarkerRSPrimaryColor('');
    }
    const handleHoverSC = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerSCPrimaryColor(darkerColor);
    }

    const handleLeaveSC = () => {
        setDarkerSCPrimaryColor('');
    }
    const handleHoverSE = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerSEPrimaryColor(darkerColor);
    }

    const handleLeaveSE = () => {
        setDarkerSEPrimaryColor('');
    }
    const handleHoverSP = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerSPPrimaryColor(darkerColor);
    }

    const handleLeaveSP = () => {
        setDarkerSPPrimaryColor('');
    }
    const handleHoverTO = (color: string) => {
        const darkerColor = darkenColor(color, 20) // escurecer em 20%
        setDarkerTOPrimaryColor(darkerColor);
    }

    const handleLeaveTO = () => {
        setDarkerTOPrimaryColor('');
    }

    return {
        darkerACPrimaryColor,
        darkerALPrimaryColor,
        darkerAMPrimaryColor,
        darkerAPPrimaryColor,
        darkerBAPrimaryColor,
        darkerCEPrimaryColor,
        darkerDFPrimaryColor,
        darkerESPrimaryColor,
        darkerGOPrimaryColor,
        darkerMAPrimaryColor,
        darkerMGPrimaryColor,
        darkerMSPrimaryColor,
        darkerMTPrimaryColor,
        darkerPAPrimaryColor,
        darkerPBPrimaryColor,
        darkerPEPrimaryColor,
        darkerPIPrimaryColor,
        darkerPRPrimaryColor,
        darkerRJPrimaryColor,
        darkerRNPrimaryColor,
        darkerROPrimaryColor,
        darkerRRPrimaryColor,
        darkerRSPrimaryColor,
        darkerSCPrimaryColor,
        darkerSEPrimaryColor,
        darkerSPPrimaryColor,
        darkerTOPrimaryColor,
        handleHoverAC,
        handleHoverAL,
        handleHoverAM,
        handleHoverAP,
        handleHoverBA,
        handleHoverCE,
        handleHoverDF,
        handleHoverES,
        handleHoverGO,
        handleHoverMA,
        handleHoverMG,
        handleHoverMS,
        handleHoverMT,
        handleHoverPA,
        handleHoverPB,
        handleHoverPE,
        handleHoverPI,
        handleHoverPR,
        handleHoverRJ,
        handleHoverRN,
        handleHoverRO,
        handleHoverRR,
        handleHoverRS,
        handleHoverSC,
        handleHoverSE,
        handleHoverSP,
        handleHoverTO,
        handleLeaveAC,
        handleLeaveAL,
        handleLeaveAM,
        handleLeaveAP,
        handleLeaveBA,
        handleLeaveCE,
        handleLeaveDF,
        handleLeaveES,
        handleLeaveGO,
        handleLeaveMA,
        handleLeaveMG,
        handleLeaveMS,
        handleLeaveMT,
        handleLeavePA,
        handleLeavePB,
        handleLeavePE,
        handleLeavePI,
        handleLeavePR,
        handleLeaveRJ,
        handleLeaveRN,
        handleLeaveRO,
        handleLeaveRR,
        handleLeaveRS,
        handleLeaveSC,
        handleLeaveSE,
        handleLeaveSP,
        handleLeaveTO
    }
}