import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IActiveDebt, IBusinessSize, IContact, ICreditScoreDescription, IEconomicGroup, IFieldError, IFieldProps, ILocation, IProjectFilter, IRegimeTributario, ISector, ISegmento, IStatus, SpecialStatus } from '../../models';
import { IEconomicActivity } from '../../models/IEconomicActivity';
import { IProject } from '../../models/IProject';
import { ICountReport, IProjectReport } from '../../models/IProjectReport';
import { IProjectResume } from './types';

export const validateCreateProject = (data: {
  name: string
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []

  if (!data.name) {
    errors.push({
      field: 'name',
      fieldError: {
        message: 'Nome é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  return errors
}

export const validateCountReport = (data: {
  name: string,
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []

  if (!data.name) {
    errors.push({
      field: 'name',
      fieldError: {
        message: 'Nome é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  return errors
}

export type ActionTypes = 'refresh' | 'export'

interface IProjectState {
  page: number
  pageSize: number
  total: number
  active: boolean
  createFormAction: 'CREATE' | 'CLONE' | 'EDIT',
  projectToClone?: string
  createProjectForm: {
    open: boolean,
    name: string,
    fields: {
      name: IFieldProps
    }
  }
  countReportForm: {
    fields: {
      name: IFieldProps
    }
  }
  selectedCountReport: ICountReport,
  countReportModalOpen: boolean,
  countReportInputValue: string,
  countReportModalAction?: 'create' | 'edit',
  countReportList: ICountReport[],
  projectList: IProjectResume[]
  selectedProject?: IProject
  activeSection: 'filter' | 'data',
  action: ActionTypes
  activeFilter: number
  activeDataSection: number
  selectedFilter?: IProjectFilter
  projectReport?: IProjectReport
  exportDialog: {
    open: boolean,
    name?: string,
    total?: number,
    formErrors: {
      name?: string,
      total?: string
    }
  }
  tableFilters: {
    name?: string
    createdBy?: string
    startDate?: Date
    endDate?: Date
  }
}


const initialState: IProjectState = {
  createFormAction: 'CREATE',
  countReportForm: {
    fields: {
      name: {
        isValid: true,
        error: undefined
      }
    }
  },
  createProjectForm: {
    open: false,
    name: '',
    fields: {
      name: {
        isValid: true,
        error: undefined
      }
    }
  },
  projectList: [],
  countReportList: [],
  selectedProject: undefined,
  selectedCountReport: {
    columns: [],
    rows: [],
    id: '',
    companyId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    name: '',
    projectId: ''
  },
  countReportModalOpen: false,
  activeSection: 'filter',
  activeFilter: 0,
  activeDataSection: 0,
  action: 'refresh',
  exportDialog: {
    open: false,
    name: '',
    formErrors: {}
  },
  countReportInputValue: '',
  page: 1,
  pageSize: 10,
  total: 0,
  active: true,
  tableFilters: {
    name: undefined,
    createdBy: undefined,
    startDate: undefined,
    endDate: undefined
  }
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCreateProjectFormActionState(state, action: PayloadAction<{
      action: 'CREATE' | 'CLONE' | 'EDIT',
      projectToClone?: string
    }>) {
      state.createFormAction = action.payload.action
      state.projectToClone = action.payload.projectToClone
    },
    setCountReportFieldErrorsState(state, action: PayloadAction<{
      errors: {
        field: string,
        fieldError: IFieldError
      }[]
    }>) {
      action.payload.errors.forEach(error => {
        if (error.field === 'name') {
          state.countReportForm.fields.name.isValid = false
          state.countReportForm.fields.name.error = error.fieldError
        }
      })
    },
    resetCountReportFieldErrorsState(state) {
      state.countReportForm.fields.name.isValid = true
      state.countReportForm.fields.name.error = undefined
    },
    setCreateProjectFormOpenState(state, action: PayloadAction<{
      open: boolean
    }>) {
      state.createProjectForm.open = action.payload.open
    },
    setProjectNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.createProjectForm.name = action.payload.name
    },
    setProjectFormErrorsState(state, action: PayloadAction<{
      errors: {
        field: string,
        fieldError: IFieldError
      }[]
    }>) {
      action.payload.errors.forEach(error => {
        if (error.field === 'name') {
          state.createProjectForm.fields.name.isValid = false
          state.createProjectForm.fields.name.error = error.fieldError
        }
      })
    },

    resetProjectFormErrorsState(state) {
      state.createProjectForm.fields.name.isValid = true
      state.createProjectForm.fields.name.error = undefined
    },

    setCountReportListState(state, action: PayloadAction<{
      countReportList: ICountReport[]
    }>) {
      state.countReportList = action.payload.countReportList
    },
    selectCountReportState(state, action: PayloadAction<{
      selectedCountReport: ICountReport
    }>) {
      state.selectedCountReport = action.payload.selectedCountReport
    },
    setCountReportModalOpenState(state, action: PayloadAction<{
      countReportModalOpen: boolean
    }>) {
      state.countReportModalOpen = action.payload.countReportModalOpen
    },
    setCountReportModalActionState(state, action: PayloadAction<{
      countReportModalAction: 'create' | 'edit'
    }>) {
      state.countReportModalAction = action.payload.countReportModalAction
    },
    setCountReportInputValueState(state, action: PayloadAction<{
      countReportInputValue: string
    }>) {
      state.countReportInputValue = action.payload.countReportInputValue
    },
    setProjectListState(state, action: PayloadAction<{
      projectList: IProjectResume[]
      page: number
      total: number
      pageSize: number
    }>) {
      state.projectList = action.payload.projectList
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.total = action.payload.total
    },
    setSelectedProjectState(state, action: PayloadAction<{
      selectedProject: IProject
    }>) {
      state.selectedProject = action.payload.selectedProject
      state.selectedFilter = action.payload.selectedProject.filters[0]
    },
    setProjectActiveSectionState(state, action: PayloadAction<{
      activeSection: 'filter' | 'data'
    }>) {
      state.activeSection = action.payload.activeSection
    },
    setActiveFilterState(state, action: PayloadAction<{
      activeFilter: number
    }>) {
      state.activeFilter = action.payload.activeFilter
    },
    setActiveDataSectionState(state, action: PayloadAction<{
      activeDataSection: number
    }>) {
      state.activeDataSection = action.payload.activeDataSection
    },
    addLocationFilterState(state, action: PayloadAction<{
      location: ILocation
    }>) {
      /* Do not insert duplicated locations */
      /* Compare value and categoria fields */
      /* Add to location field of filters */
      /* Use selectedFilter */
      const { location } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.location) {
          const findLocation = selectedFilter.location.find(loc => loc.value === location.value && loc.categoria === location.categoria)
          if (!findLocation) {
            selectedFilter.location.push(location)
          }
        } else {
          selectedFilter.location = []
          selectedFilter.location.push(location)
        }
      }
    },
    removeLocationFilterState(state, action: PayloadAction<{
      location: ILocation
    }>) {
      /* Use SelectedFilter to get the filter index */
      /* Compare value and categoria fields */
      /* Remove from location field of filters */
      const { location } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.location) {
          const findLocation = selectedFilter.location.find(loc => loc.value === location.value && loc.categoria === location.categoria)
          if (findLocation) {
            const index = selectedFilter.location.indexOf(findLocation)
            selectedFilter.location.splice(index, 1)
          }
        }
      }

    },
    addTermFilterState(state, action: PayloadAction<{
      term: string
    }>) {
      /* Do not insert duplicated terms */
      /* Compare term field */
      /* Add to term field of filters */
      /* Use selectedFilter */
      const { term } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.termsFilter) {
          const findTerm = selectedFilter.termsFilter.find(t => t === term)
          if (!findTerm) {
            selectedFilter.termsFilter.push(term)
          }
        } else {
          selectedFilter.termsFilter = []
          selectedFilter.termsFilter.push(term)
        }
      }
    },
    removeTermFilterState(state, action: PayloadAction<{
      term: string
    }>) {
      /* Use SelectedFilter to get the filter index */
      /* Compare term field */
      /* Remove from term field of filters */
      const { term } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.termsFilter) {
          const findTerm = selectedFilter.termsFilter.find(t => t === term)
          if (findTerm) {
            const index = selectedFilter.termsFilter.indexOf(findTerm)
            selectedFilter.termsFilter.splice(index, 1)
          }
        }
      }
    },
    addNotIncludeTermFilterState(state, action: PayloadAction<{
      term: string
    }>) {
      /* Do not insert duplicated terms */
      /* Compare term field */
      /* Add to term field of filters */
      /* Use selectedFilter */
      const { term } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.notIncludeTermsFilter) {
          const findTerm = selectedFilter.notIncludeTermsFilter.find(t => t === term)
          if (!findTerm) {
            selectedFilter.notIncludeTermsFilter.push(term)
          }
        } else {
          selectedFilter.notIncludeTermsFilter = []
          selectedFilter.notIncludeTermsFilter.push(term)
        }
      }
    },
    removeNotIncludeTermFilterState(state, action: PayloadAction<{
      term: string
    }>) {
      /* Use SelectedFilter to get the filter index */
      /* Compare term field */
      /* Remove from term field of filters */
      const { term } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.notIncludeTermsFilter) {
          const findTerm = selectedFilter.notIncludeTermsFilter.find(t => t === term)
          if (findTerm) {
            const index = selectedFilter.notIncludeTermsFilter.indexOf(findTerm)
            selectedFilter.notIncludeTermsFilter.splice(index, 1)
          }
        }
      }
    },
    addPurgeLocationFilterState(state, action: PayloadAction<{
      location: ILocation
    }>) {
      /* Do not insert duplicated locations */
      /* Compare value and categoria fields */
      /* Add to purgeLocation field of filters */
      /* Use selectedFilter */
      const { location } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.purgeLocation) {
          const findLocation = selectedFilter.purgeLocation.find(loc => loc.value === location.value && loc.categoria === location.categoria)
          if (!findLocation) {
            selectedFilter.purgeLocation.push(location)
          }
        } else {
          selectedFilter.purgeLocation = []
          selectedFilter.purgeLocation.push(location)
        }
      }
    },
    setJustPrimaryActivityFilterState(state, action: PayloadAction<{
      justPrimaryActivity: boolean
    }>) {
      const { justPrimaryActivity } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        selectedFilter.justPrimaryActivity = justPrimaryActivity
      }
    },
    removePurgeLocationFilterState(state, action: PayloadAction<{
      location: ILocation
    }>) {
      /* Use SelectedFilter to get the filter index */
      /* Compare value and categoria fields */
      /* Remove from location field of filters */
      const { location } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.purgeLocation) {
          const findLocation = selectedFilter.purgeLocation.find(loc => loc.value === location.value && loc.categoria === location.categoria)
          if (findLocation) {
            const index = selectedFilter.purgeLocation.indexOf(findLocation)
            selectedFilter.purgeLocation.splice(index, 1)
          }
        }
      }
    },

    resetProjectState(state) {
      state.projectList = initialState.projectList
      state.selectedProject = initialState.selectedProject
    },
    addSectorFilterState(state, action: PayloadAction<{
      sector: ISector
    }>) {
      const { sector } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.sector) {
          const findSector = selectedFilter.sector.find(sec => sec === sector)
          if (!findSector) {
            selectedFilter.sector.push(sector)
          }
        } else {
          selectedFilter.sector = []
          selectedFilter.sector.push(sector)
        }
      }
    },
    removeSectorFilterState(state, action: PayloadAction<{
      sector: ISector
    }>) {
      const { sector } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.sector) {
          const findSector = selectedFilter.sector.find(sec => sec === sector)
          if (findSector) {
            const index = selectedFilter.sector.indexOf(findSector)
            selectedFilter.sector.splice(index, 1)
          }
        }
      }
    },
    addSegmentoFilterState(state, action: PayloadAction<{
      segmento: ISegmento
    }>) {
      const { segmento } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.segmentos) {
          const findSegmento = selectedFilter.segmentos.find(seg => seg.segmento === segmento.segmento)
          if (!findSegmento) {
            selectedFilter.segmentos.push(segmento)
          }
        } else {
          selectedFilter.segmentos = []
          selectedFilter.segmentos.push(segmento)
        }
      }
    },
    removeSegmentoFilterState(state, action: PayloadAction<{
      segmento: ISegmento
    }>) {
      const { segmento } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.segmentos) {
          const findSegmento = selectedFilter.segmentos.find(seg => seg.segmento === segmento.segmento)
          if (findSegmento) {
            const index = selectedFilter.segmentos.indexOf(findSegmento)
            selectedFilter.segmentos.splice(index, 1)
          }
        }
      }
    },
    addEconomicActivityFilterState(state, action: PayloadAction<{
      economicActivity: IEconomicActivity
    }>) {
      const { economicActivity } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.economicActivity) {
          const findEconomicActivity = selectedFilter.economicActivity.find(ea => ea.cnaeCodigo === economicActivity.cnaeCodigo)
          if (!findEconomicActivity) {
            selectedFilter.economicActivity.push(economicActivity)
          }
        } else {
          selectedFilter.economicActivity = []
          selectedFilter.economicActivity.push(economicActivity)
        }
      }
    },
    removeEconomicActivityFilterState(state, action: PayloadAction<{
      economicActivity: IEconomicActivity
    }>) {
      const { economicActivity } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.economicActivity) {
          const findEconomicActivity = selectedFilter.economicActivity.find(ea => ea.cnaeCodigo === economicActivity.cnaeCodigo)
          if (findEconomicActivity) {
            const index = selectedFilter.economicActivity.indexOf(findEconomicActivity)
            selectedFilter.economicActivity.splice(index, 1)
          }
        }
      }
    },
    addBlackListFilterState(state, action: PayloadAction<{
      blackListId: string
    }>) {
      const { blackListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.blackLists) {
          const findBlackList = selectedFilter.blackLists.find(bl => bl === blackListId)
          if (!findBlackList) {
            selectedFilter.blackLists.push(blackListId)
          }
        } else {
          selectedFilter.blackLists = []
          selectedFilter.blackLists.push(blackListId)
        }
      }
    },
    removeBlackListFilterState(state, action: PayloadAction<{
      blackListId: string
    }>) {
      const { blackListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.blackLists) {
          const findBlackList = selectedFilter.blackLists.find(bl => bl === blackListId)
          if (findBlackList) {
            const index = selectedFilter.blackLists.indexOf(findBlackList)
            selectedFilter.blackLists.splice(index, 1)
          }
        }
      }
    },

    addCepListFilterState(state, action: PayloadAction<{
      cepListId: string
    }>) {
      const { cepListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.cepLists) {
          const findCepList = selectedFilter.cepLists.find(cl => cl === cepListId)
          if (!findCepList) {
            selectedFilter.cepLists.push(cepListId)
          }
        } else {
          selectedFilter.cepLists = []
          selectedFilter.cepLists.push(cepListId)
        }
      }
    },
    removeCepListFilterState(state, action: PayloadAction<{
      cepListId: string
    }>) {
      const { cepListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.cepLists) {
          const findCepList = selectedFilter.cepLists.find(cl => cl === cepListId)
          if (findCepList) {
            const index = selectedFilter.cepLists.indexOf(findCepList)
            selectedFilter.cepLists.splice(index, 1)
          }
        }
      }
    },
    addPurgeCepListFilterState(state, action: PayloadAction<{
      cepListId: string
    }>) {
      /* Do not insert duplicated cep lists */
      /* Compare value and categoria fields */
      /* Add to purgeCepLists field of filters */
      /* Use selectedFilter */
      const { cepListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.purgeCepLists) {
          const findCepLists = selectedFilter.purgeCepLists.find(cepList => cepList === cepListId)
          if (!findCepLists) {
            selectedFilter.purgeCepLists.push(cepListId)
          }
        } else {
          selectedFilter.purgeCepLists = []
          selectedFilter.purgeCepLists.push(cepListId)
        }
      }
    },
    removePurgeCepListFilterState(state, action: PayloadAction<{
      cepListId: string
    }>) {
      /* Use SelectedFilter to get the filter index */
      /* Compare value and categoria fields */
      /* Remove from cep list field of filters */
      const { cepListId } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.purgeCepLists) {
          const findCepLists = selectedFilter.purgeCepLists.find(cepList => cepList === cepListId)
          if (findCepLists) {
            const index = selectedFilter.purgeCepLists.indexOf(findCepLists)
            selectedFilter.purgeCepLists.splice(index, 1)
          }
        }
      }
    },

    addBusinessSizeFilterState(state, action: PayloadAction<{
      businessSize: IBusinessSize
    }>) {
      const { businessSize } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.businessSize) {
          const findBusinessSize = selectedFilter.businessSize.find(bs => bs === businessSize)
          if (!findBusinessSize) {
            selectedFilter.businessSize.push(businessSize)
          }
        } else {
          selectedFilter.businessSize = []
          selectedFilter.businessSize.push(businessSize)
        }
      }
    },
    removeBusinessSizeFilterState(state, action: PayloadAction<{
      businessSize: IBusinessSize
    }>) {
      const { businessSize } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.businessSize) {
          const findBusinessSize = selectedFilter.businessSize.find(bs => bs === businessSize)
          if (findBusinessSize) {
            const index = selectedFilter.businessSize.indexOf(findBusinessSize)
            selectedFilter.businessSize.splice(index, 1)
          }
        }
      }
    },
    setMinRevenueFilterState(state, action: PayloadAction<{
      minRevenue: number
    }>) {
      const { minRevenue } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.revenues) {
          selectedFilter.revenues.min = minRevenue
        } else {
          selectedFilter.revenues = {
            min: minRevenue,
            max: undefined
          }
        }
      }
    },
    setMaxRevenueFilterState(state, action: PayloadAction<{
      maxRevenue: number
    }>) {
      const { maxRevenue } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.revenues) {
          selectedFilter.revenues.max = maxRevenue
        } else {
          selectedFilter.revenues = {
            min: undefined,
            max: maxRevenue
          }
        }
      }
    },
    setMaxEmployeesFilterState(state, action: PayloadAction<{
      maxEmployees: number
    }>) {
      /* Use the field staff.employee to set the max employees */
      /* If its undefined make sure that correct value is set */
      const { maxEmployees } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.staff) {
          selectedFilter.staff.employee = {
            max: maxEmployees,
            min: selectedFilter.staff.employee.min
          }
        } else {
          selectedFilter.staff = {
            employee: {
              max: maxEmployees,
              min: undefined
            },
            businessAssociate: {
              max: undefined,
              min: undefined
            }
          }
        }
      }
    },
    setMinEmployeesFilterState(state, action: PayloadAction<{
      minEmployees: number
    }>) {
      /* Use the field staff.employee to set the min employees */
      /* If its undefined make sure that correct value is set */
      const { minEmployees } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.staff) {
          selectedFilter.staff.employee = {
            max: selectedFilter.staff.employee.max,
            min: minEmployees
          }
        } else {
          selectedFilter.staff = {
            employee: {
              max: undefined,
              min: minEmployees
            },
            businessAssociate: {
              max: undefined,
              min: undefined
            }
          }
        }
      }
    },
    setMaxBusinessAssociateFilterState(state, action: PayloadAction<{
      maxBusinessAssociate: number
    }>) {
      /* Use the field staff.businessAssociate to set the max business associate */
      /* If its undefined make sure that correct value is set */
      const { maxBusinessAssociate } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.staff) {
          selectedFilter.staff.businessAssociate = {
            max: maxBusinessAssociate,
            min: selectedFilter.staff.businessAssociate.min
          }
        } else {
          selectedFilter.staff = {
            employee: {
              max: undefined,
              min: undefined
            },
            businessAssociate: {
              max: maxBusinessAssociate,
              min: undefined
            }
          }
        }
      }
    },
    setMinBusinessAssociateFilterState(state, action: PayloadAction<{
      minBusinessAssociate: number
    }>) {
      /* Use the field staff.businessAssociate to set the min business associate */
      /* If its undefined make sure that correct value is set */
      const { minBusinessAssociate } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.staff) {
          selectedFilter.staff.businessAssociate = {
            max: selectedFilter.staff.businessAssociate.max,
            min: minBusinessAssociate
          }
        } else {
          selectedFilter.staff = {
            employee: {
              max: undefined,
              min: undefined
            },
            businessAssociate: {
              max: undefined,
              min: minBusinessAssociate
            }
          }
        }
      }
    },
    addStatusFilterState(state, action: PayloadAction<{
      status: IStatus
    }>) {
      const { status } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.status) {
          const findStatus = selectedFilter.status.find(s => s === status)
          if (!findStatus) {
            selectedFilter.status.push(status)
          }
        } else {
          selectedFilter.status = []
          selectedFilter.status.push(status)
        }
      }
    },
    removeStatusFilterState(state, action: PayloadAction<{
      status: IStatus
    }>) {
      const { status } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.status) {
          const findStatus = selectedFilter.status.find(s => s === status)
          if (findStatus) {
            const index = selectedFilter.status.indexOf(findStatus)
            selectedFilter.status.splice(index, 1)
          }
        }
      }
    },
    addRegimeTributarioFilterState(state, action: PayloadAction<{
      regimeTributario: IRegimeTributario
    }>) {
      const { regimeTributario } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.regimeTributario) {
          const findRegimeTributario = selectedFilter.regimeTributario.find(s => s === regimeTributario)
          if (!findRegimeTributario) {
            selectedFilter.regimeTributario.push(regimeTributario)
          }
        } else {
          selectedFilter.regimeTributario = []
          selectedFilter.regimeTributario.push(regimeTributario)
        }
      }
    },
    removeRegimeTributarioFilterState(state, action: PayloadAction<{
      regimeTributario: IRegimeTributario
    }>) {
      const { regimeTributario } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.regimeTributario) {
          const findRegimeTributario = selectedFilter.regimeTributario.find(s => s === regimeTributario)
          if (findRegimeTributario) {
            const index = selectedFilter.regimeTributario.indexOf(findRegimeTributario)
            selectedFilter.regimeTributario.splice(index, 1)
          }
        }
      }
    },
    addEconomicGroupFilterState(state, action: PayloadAction<{
      economicGroup: IEconomicGroup
    }>) {
      const { economicGroup } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.economicGroup) {
          const findEconomicGroup = selectedFilter.economicGroup.find(s => s === economicGroup)
          if (!findEconomicGroup) {
            selectedFilter.economicGroup.push(economicGroup)
          }
        } else {
          selectedFilter.economicGroup = []
          selectedFilter.economicGroup.push(economicGroup)
        }
      }
    },
    removeEconomicGroupFilterState(state, action: PayloadAction<{
      economicGroup: IEconomicGroup
    }>) {
      const { economicGroup } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.economicGroup) {
          const findEconomicGroup = selectedFilter.economicGroup.find(s => s === economicGroup)
          if (findEconomicGroup) {
            const index = selectedFilter.economicGroup.indexOf(findEconomicGroup)
            selectedFilter.economicGroup.splice(index, 1)
          }
        }
      }
    },
    setMinCreatedAtFilterState(state, action: PayloadAction<{
      minCreatedAt?: Date
    }>) {
      const { minCreatedAt } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.createdAt) {
          selectedFilter.createdAt.min = minCreatedAt
        } else {
          selectedFilter.createdAt = {
            max: undefined,
            min: minCreatedAt
          }
        }
      }
    },
    setMaxCreatedAtFilterState(state, action: PayloadAction<{
      maxCreatedAt?: Date
    }>) {
      const { maxCreatedAt } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.createdAt) {
          selectedFilter.createdAt.max = maxCreatedAt
        } else {
          selectedFilter.createdAt = {
            max: maxCreatedAt,
            min: undefined
          }
        }
      }
    },
    addBusinessContactFilterState(state, action: PayloadAction<{
      businessContact: IContact
    }>) {
      /* Use the contacts fields business and partner fields */
      /* If its undefined make sure that correct value is set */
      const { businessContact } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.contacts) {
          if (selectedFilter.contacts.business) {
            const findContact = selectedFilter.contacts.business.find(c => c === businessContact)
            if (!findContact) {
              selectedFilter.contacts.business.push(businessContact)
            }
          } else {
            selectedFilter.contacts.business = []
            selectedFilter.contacts.business.push(businessContact)
          }
        } else {
          selectedFilter.contacts = {
            business: [],
            partner: []
          }
          selectedFilter.contacts?.business?.push(businessContact)
        }
      }
    },
    removeBusinessContactFilterState(state, action: PayloadAction<{
      businessContact: IContact
    }>) {
      /* Use the contacts fields business and partner fields */
      /* If its undefined make sure that correct value is set */
      const { businessContact } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.contacts) {
          if (selectedFilter.contacts.business) {
            const findContact = selectedFilter.contacts.business.find(c => c === businessContact)
            if (findContact) {
              const index = selectedFilter.contacts.business.indexOf(findContact)
              selectedFilter.contacts.business.splice(index, 1)
            }
          }
        }
      }
    },
    addPartnetContactFilterState(state, action: PayloadAction<{
      partnerContact: IContact
    }>) {
      /* Use the contacts fields business and partner fields */
      /* If its undefined make sure that correct value is set */
      const { partnerContact } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.contacts) {
          if (selectedFilter.contacts.partner) {
            const findContact = selectedFilter.contacts.partner.find(c => c === partnerContact)
            if (!findContact) {
              selectedFilter.contacts.partner.push(partnerContact)
            }
          } else {
            selectedFilter.contacts.partner = []
            selectedFilter.contacts.partner.push(partnerContact)
          }
        } else {
          selectedFilter.contacts = {
            business: [],
            partner: []
          }
          selectedFilter.contacts?.partner?.push(partnerContact)
        }
      }
    },
    removePartnerContactFilterState(state, action: PayloadAction<{
      partnerContact: IContact
    }>) {
      /* Use the contacts fields business and partner fields */
      /* If its undefined make sure that correct value is set */
      const { partnerContact } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.contacts) {
          if (selectedFilter.contacts.partner) {
            const findContact = selectedFilter.contacts.partner.find(c => c === partnerContact)
            if (findContact) {
              const index = selectedFilter.contacts.partner.indexOf(findContact)
              selectedFilter.contacts.partner.splice(index, 1)
            }
          }
        }
      }
    },
    addSpecialStatusFilterState(state, action: PayloadAction<{
      specialStatus: SpecialStatus
    }>) {
      const { specialStatus } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.specialStatus) {
          const findSpecialStatus = selectedFilter.specialStatus.find(s => s === specialStatus)
          if (!findSpecialStatus) {
            selectedFilter.specialStatus.push(specialStatus)
          }
        } else {
          selectedFilter.specialStatus = []
          selectedFilter.specialStatus.push(specialStatus)
        }
      }
    },
    removeSpecialStatusFilterState(state, action: PayloadAction<{
      specialStatus: SpecialStatus
    }>) {
      const { specialStatus } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.specialStatus) {
          const findSpecialStatus = selectedFilter.specialStatus.find(s => s === specialStatus)
          if (findSpecialStatus) {
            const index = selectedFilter.specialStatus.indexOf(findSpecialStatus)
            selectedFilter.specialStatus.splice(index, 1)
          }
        }
      }
    },

    setFilterByLatAndLngState(state, action: PayloadAction<{
      filterByLatAndLng: boolean
    }>) {
      const { filterByLatAndLng } = action.payload
      if (state.selectedFilter) {
        state.selectedFilter.filterByLatLong = filterByLatAndLng
      }
    },

    setFilterByLatAndLngRadiusState(state, action: PayloadAction<{
      filterByLatAndLngRadius: number
    }>) {
      const { filterByLatAndLngRadius } = action.payload
      if (state.selectedFilter) {
        state.selectedFilter.radius = filterByLatAndLngRadius
      }
    },
    setFilterByLatAndLngCepState(state, action: PayloadAction<{
      filterByLatAndLngCep: string
    }>) {
      const { filterByLatAndLngCep } = action.payload
      if (state.selectedFilter) {
        state.selectedFilter.cepFilterByLatLong = filterByLatAndLngCep
      }
    },
    setActionState(state, action: PayloadAction<{
      actionType: ActionTypes
    }>) {
      const { actionType } = action.payload
      state.action = actionType
    },
    setSelectedFilterState(state, action: PayloadAction<{
      selectedFilter?: IProjectFilter
    }>) {
      const { selectedFilter } = action.payload
      state.selectedFilter = selectedFilter
    },
    setProjectReportState(state, action: PayloadAction<{
      projectReport: IProjectReport | undefined
    }>) {
      const { projectReport } = action.payload
      state.projectReport = projectReport
    },
    setExportDialogOpen(state, action: PayloadAction<{
      exportDialogOpen: boolean
    }>) {
      const { exportDialogOpen } = action.payload
      state.exportDialog.open = exportDialogOpen
    },
    setExportDialogNameState(state, action: PayloadAction<{
      exportDialogName: string
    }>) {
      const { exportDialogName } = action.payload
      if (exportDialogName === '' || !exportDialogName) {
        state.exportDialog.formErrors.name = 'Nome é um campo obrigatório'
      } else {
        state.exportDialog.formErrors.name = undefined
      }
      state.exportDialog.name = exportDialogName
    },
    setExportDialogTotalState(state, action: PayloadAction<{
      exportDialogTotal: number
    }>) {
      const { exportDialogTotal } = action.payload
      if (exportDialogTotal === 0 || !exportDialogTotal) {
        state.exportDialog.formErrors.total = 'Total é um campo obrigatório'
      } else {
        state.exportDialog.formErrors.total = undefined
      }
      state.exportDialog.total = exportDialogTotal
    },
    setProjectPageState(state, action: PayloadAction<{
      page: number
    }>) {
      state.page = action.payload.page
    },
    setProjectActiveFilterState(state, action: PayloadAction<{
      active: boolean
    }>) {
      state.active = action.payload.active
    },
    setProjectPageSizeState(state, action: PayloadAction<{
      pageSize: number
    }>) {
      state.pageSize = action.payload.pageSize
    },
    setMinShareCapitalFilterState(state, action: PayloadAction<{
      minShareCapital: number
    }>) {
      const { minShareCapital } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.shareCapital) {
          selectedFilter.shareCapital.min = minShareCapital
        } else {
          selectedFilter.shareCapital = {
            min: minShareCapital,
            max: undefined
          }
        }
      }
    },
    setMaxShareCapitalFilterState(state, action: PayloadAction<{
      maxShareCapital: number
    }>) {
      const { maxShareCapital } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.shareCapital) {
          selectedFilter.shareCapital.max = maxShareCapital
        } else {
          selectedFilter.shareCapital = {
            min: undefined,
            max: maxShareCapital
          }
        }
      }
    },
    addActiveDebtsFilterState(state, action: PayloadAction<{
      activeDebt: IActiveDebt
    }>) {
      const { activeDebt } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.debts) {
          if ((selectedFilter.debts.debt?.length ?? 0) > 0) {
            const findContact = selectedFilter.debts.debt!.find(debt => debt === activeDebt)
            if (!findContact) {
              selectedFilter.debts.debt!.push(activeDebt)
            }
          } else {
            selectedFilter.debts.debt = []
            selectedFilter.debts.debt.push(activeDebt)
          }
        } else {
          selectedFilter.debts = {
            debt: []
          }
          selectedFilter.debts?.debt?.push(activeDebt)
        }
      }
    },
    removeActiveDebtsFilterState(state, action: PayloadAction<{
      activeDebt: IActiveDebt
    }>) {
      const { activeDebt } = action.payload
      const { selectedFilter } = state

      if (selectedFilter) {
        if (selectedFilter.debts) {
          if (selectedFilter.debts.debt) {
            const findContact = selectedFilter.debts.debt.find(c => c === activeDebt)
            if (findContact) {
              const index = selectedFilter.debts.debt.indexOf(findContact)
              selectedFilter.debts.debt.splice(index, 1)
            }
          }
        }
      }
    },
    setIncludeDebtsFilterState(state, action: PayloadAction<{
      includeDebts: boolean
    }>) {
      const { includeDebts } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        selectedFilter.includeActiveDebts = includeDebts
      }
    },
    addCreditScoreDescriptionFilterState(state, action: PayloadAction<{
      creditScoreDescription: ICreditScoreDescription
    }>) {
      const { creditScoreDescription } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.creditScoreDescriptions) {
          const findCreditScoreDescription = selectedFilter.creditScoreDescriptions.find(creditScore => creditScore === creditScoreDescription)
          if (!findCreditScoreDescription) {
            selectedFilter.creditScoreDescriptions.push(creditScoreDescription)
          }
        } else {
          selectedFilter.creditScoreDescriptions = []
          selectedFilter.creditScoreDescriptions.push(creditScoreDescription)
        }
      }
    },
    removeCreditScoreDescriptionFilterState(state, action: PayloadAction<{
      creditScoreDescription: ICreditScoreDescription
    }>) {
      const { creditScoreDescription } = action.payload
      const { selectedFilter } = state
      if (selectedFilter) {
        if (selectedFilter.creditScoreDescriptions) {
          const findBusinessSize = selectedFilter.creditScoreDescriptions.find(creditScore => creditScore === creditScoreDescription)
          if (findBusinessSize) {
            const index = selectedFilter.creditScoreDescriptions.indexOf(findBusinessSize)
            selectedFilter.creditScoreDescriptions.splice(index, 1)
          }
        }
      }
    },
    setGeolocationFilterState(state, action: PayloadAction<{
      geolocation?: {
        lat: number
        lon: number
        radius: number
      }
    }>) {
      if (state.selectedFilter) {
        state.selectedFilter.geolocation = action.payload.geolocation
      }
    },
    setGeolocationFilterRadiusState(state, action: PayloadAction<{
      radius: number
    }>) {
      if (state.selectedFilter) {
        if (state.selectedFilter.geolocation) {
          state.selectedFilter.geolocation.radius = action.payload.radius
        }
      }
    },
    setUseGeolocationFilterState(state, action: PayloadAction<{
      useGeolocationFilter: boolean
    }>) {
      if (state.selectedFilter) {
        state.selectedFilter.useGeolocationFilter = action.payload.useGeolocationFilter
      }
    },
    resetTableFiltersInitialState(state) {
      state.tableFilters = initialState.tableFilters
    },
    setTableFilterNameState(state, action: PayloadAction<{
      name: string
    }>) {
      state.tableFilters.name = action.payload.name
    },
    setTableFilterCreatedByState(state, action: PayloadAction<{
      createdBy: string
    }>) {
      state.tableFilters.createdBy = action.payload.createdBy
    },
    setTableFilterStartDateState(state, action: PayloadAction<{
      startDate?: Date
    }>) {
      state.tableFilters.startDate = action.payload.startDate
    },
    setTableFilterEndDateState(state, action: PayloadAction<{
      endDate?: Date
    }>) {
      state.tableFilters.endDate = action.payload.endDate
    }
  }
});

export const {
  setExportDialogTotalState,
  setExportDialogNameState,
  setExportDialogOpen,
  setProjectReportState,
  setSelectedFilterState,
  setProjectListState,
  setSelectedProjectState,
  resetProjectState,
  setProjectActiveSectionState,
  setActiveFilterState,
  addLocationFilterState,
  removeLocationFilterState,
  addSectorFilterState,
  removeSectorFilterState,
  addSegmentoFilterState,
  removeSegmentoFilterState,
  addEconomicActivityFilterState,
  removeEconomicActivityFilterState,
  addBusinessSizeFilterState,
  removeBusinessSizeFilterState,
  setMinRevenueFilterState,
  setMaxRevenueFilterState,
  setMaxEmployeesFilterState,
  setMinEmployeesFilterState,
  setMaxBusinessAssociateFilterState,
  setMinBusinessAssociateFilterState,
  addStatusFilterState,
  removeStatusFilterState,
  addEconomicGroupFilterState,
  removeEconomicGroupFilterState,
  setMinCreatedAtFilterState,
  setMaxCreatedAtFilterState,
  addBusinessContactFilterState,
  removeBusinessContactFilterState,
  addPartnetContactFilterState,
  removePartnerContactFilterState,
  addSpecialStatusFilterState,
  removeSpecialStatusFilterState,
  setActionState,
  setActiveDataSectionState,
  selectCountReportState,
  setCountReportInputValueState,
  setCountReportModalActionState,
  setCountReportModalOpenState,
  setCountReportListState,
  addPurgeLocationFilterState,
  removePurgeLocationFilterState,
  setProjectNameState,
  resetProjectFormErrorsState,
  setProjectFormErrorsState,
  setCreateProjectFormOpenState,
  resetCountReportFieldErrorsState,
  setCountReportFieldErrorsState,
  setCreateProjectFormActionState,
  addBlackListFilterState,
  removeBlackListFilterState,
  addCepListFilterState,
  removeCepListFilterState,
  addPurgeCepListFilterState,
  removePurgeCepListFilterState,
  setJustPrimaryActivityFilterState,
  setFilterByLatAndLngCepState,
  setFilterByLatAndLngRadiusState,
  setFilterByLatAndLngState,
  addTermFilterState,
  removeTermFilterState,
  addNotIncludeTermFilterState,
  removeNotIncludeTermFilterState,
  addRegimeTributarioFilterState,
  removeRegimeTributarioFilterState,
  setProjectPageState,
  setProjectActiveFilterState,
  setProjectPageSizeState,
  setMaxShareCapitalFilterState,
  setMinShareCapitalFilterState,
  addActiveDebtsFilterState,
  removeActiveDebtsFilterState,
  setIncludeDebtsFilterState,
  addCreditScoreDescriptionFilterState,
  removeCreditScoreDescriptionFilterState,
  setGeolocationFilterState,
  setGeolocationFilterRadiusState,
  setUseGeolocationFilterState,
  resetTableFiltersInitialState,
  setTableFilterStartDateState,
  setTableFilterCreatedByState,
  setTableFilterNameState,
  setTableFilterEndDateState
} = projectSlice.actions;
export const projectReducer = projectSlice.reducer;